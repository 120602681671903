import { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import HrTopNavbar from "../../../Sidebar/HrTopNavbar";
import "./Print.css";

const ProcessandToolPrint = () => {
  const [ArrInfura, setArrInfura] = useState([]);
  const [policiesState, setPolociesState] = useState({});
  const companyId = localStorage.getItem("companyId");

  const functionCallOnSelect = (data) => {
    // let name = data.heading.replaceAll(" ", "");
    // setPolociesState((prev) => ({ ...prev, [name]: data.ans }));

    let arr = [];
    for (let i = 0; i < ArrInfura.length; i++) {
      if (i == data.index) {
        let obj = {};
        obj.heading = ArrInfura[i].heading;
        obj.description = ArrInfura[i].description;
        obj.isAgree = data.ans;
        obj.type = ArrInfura[i].type;
        arr.push(obj);
      } else {
        arr.push(ArrInfura[i]);
      }
    }
    let configrecrut = {
      method: "patch",

      url: `${IP}/processAndTools?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify(arr),
    };

    axios(configrecrut)
      .then((res) => {
        fetchdata();
      })
      .catch((ee) => {
        console.log("t");
      });
  };

  const fetchdata = () => {
    let configrecrut = {
      method: "get",

      url: `${IP}/processAndTools?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut).then((res) => {
      setArrInfura([]);
      setArrInfura(res.data);
    });
  };

  useEffect(() => {
    fetchdata();

    // setgenderbyRegionStates(ex);
  }, []);
  return (
    <Box className="pagebreak">
      <Box
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#F3F1FF",
          zIndex: "2",
          pt: "24px",
        }}
      >
        <HrTopNavbar title={"Processes and tools"} index={5}></HrTopNavbar>
      </Box>

      <Stack
        spacing={4}
        className="tablebgColorForHr"
        style={{
          marginTop: "24px",
          backgroundColor: "#F3F1FF",
          marginLeft: "auto",
          marginRight: "auto",
          width: "97%",
          marginBottom: "100px",
        }}
      >
        {ArrInfura?.map((el, index) => (
          <Box
            sx={{
              display: "grid",

              gridTemplateColumns: "6fr 1fr",

              // justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 18fr 2fr",
              }}
            >
              <Box
                sx={{
                  color: "#000",
                  backgroundColor: "#F3F1FF",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                }}
              >
                {index + 1}.
              </Box>
              <Box
                sx={{
                  whiteSpace: "initial",
                }}
              >
                <Typography>{el.heading}</Typography>
              </Box>
              <Box></Box>
            </Box>

            <Box style={{ display: "flex", gap: "20px" }}>
              <Button
                disabled={true}
                sx={{
                  width: "64px",
                  height: "40px",
                  borderRadius: "12px",
                  backgroundColor: el.isAgree ? "#E1F566" : "#BCBCBC",
                }}
              >
                Yes
              </Button>

              <Button
                disabled={true}
                sx={{
                  width: "64px",
                  height: "40px",
                  borderRadius: "12px",
                  backgroundColor: el.isAgree === false ? "#E1F566" : "#BCBCBC",
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default ProcessandToolPrint;
