import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import "./strategies.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { ColorPicker, useColor } from "react-color-palette";
import { Input, MenuItem, Select, TextField } from "@mui/material";
import AdminloadingScreen from "../loadingadmin";
export const AddEditSection = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);

  const location = useLocation();
  console.log(location.state);
  //================company settings===========================//

  const [loadingopen, setloadingopen] = useState(false);
  const [editfalg, setEditFlag] = useState(false);
  const [sectiondata, setSectiondata] = useState({
    id: "",
    topic: "",
    description: "",
    file: "",
  });
  useEffect(() => {
    getStartegiList();

    if (location.state) {
      setEditFlag(true);
      setSectiondata(location.state);
      setImage({ url: location.state.file });
    }
  }, []);
  const [template, settemplate] = useState([1, 2, 3, 4, 5, 6]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);
  const [color, setColor] = useColor("hex", "#121212");
  const [errorSms, setErrorSms] = useState("");
  const deleteCompany = httpsCallable(functions, "deleteCompany");

  const [strList, setStrList] = useState([]);
  const [image, setImage] = useState([]);
  // delete company
  const deletetemplate = (id) => {
    //console.log(id);
    deleteCompany({ companyId: id }).then((res) => {
      //console.log(res);
      alert("Company Deleted");
      setdeltemp(!deltemp);
    });
  };
  // get company and department data

  const getStartegiList = () => {
    setLoading(true);
    setloadingopen(true);
    // let data = JSON.stringify(sectiondata);
    var config = {
      method: "get",

      url: `${IP}/strategies`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        setloadingopen(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          setStrList(res.data);
          //   settemplate(res.data);
        }
      })
      .catch((err) => {
        setloadingopen(false);
        setLoading(false);
        //console.log(err);
      });
  };

  const checkFormData = () => {
    if (
      sectiondata.description == "" ||
      sectiondata.file == "" ||
      sectiondata.topic == "" ||
      sectiondata.id == ""
    ) {
      return "enter all the required data";
    }
    return true;
  };

  const createSection = () => {
    let check = checkFormData();
    if (check !== true) {
      setErrorSms(check);
      return;
    }
    setErrorSms("");
    setLoading(true);
    setloadingopen(true);
    let data = JSON.stringify(sectiondata);
    var config = {
      method: "post",

      url: `${IP}/strategies/section`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setloadingopen(false);
        setLoading(false);
        navigate(-1);
        //console.log(res);
        if (res.data?.error) {
        } else {
          //   settemplate(res.data);
        }
      })
      .catch((err) => {
        setloadingopen(false);
        setLoading(false);
        //console.log(err);
      });
  };

  const updateSection = () => {
    let check = checkFormData();
    if (check !== true) {
      setErrorSms(check);
      return;
    }
    setErrorSms("");
    setLoading(true);

    setloadingopen(true);
    let data = JSON.stringify(sectiondata);
    var config = {
      method: "patch",

      url: `${IP}/strategies/section`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        setloadingopen(false);
        navigate(-1);
        if (res.data?.error) {
        } else {
          //   settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setloadingopen(false);
        //console.log(err);
      });
  };
  const handleFileChange = (e) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      // setBase64Image(reader.result);
      //   //console.log("setBase64Image done", reader.result);
      setSectiondata({ ...sectiondata, file: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Strategies")} />
        {/* <Navbar /> */}

        <AdminloadingScreen open={loadingopen} />
        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              {/* Company settings */}
              {/* <div className="editaddstrategiesheding"> Strategies name</div> */}
              <div
                style={{
                  background: "#FFFFFF",
                  borderRadius: "20px",
                  padding: "20px",
                }}
              >
                <div>
                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Strategies Heading
                    </div>
                    <div>
                      {" "}
                      <Select
                        sx={{ width: "250px", height: "30px" }}
                        value={sectiondata.id}
                        onChange={(e) => {
                          setSectiondata((prev) => ({
                            ...prev,
                            id: e.target.value,
                          }));
                        }}
                      >
                        {strList.map((el) => (
                          <MenuItem value={el.id}> {el.name} </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Section Topic
                    </div>
                    <div>
                      {" "}
                      <input
                        value={sectiondata.topic}
                        onChange={(e) => {
                          setSectiondata((prev) => ({
                            ...prev,
                            topic: e.target.value,
                          }));
                        }}
                        className="inputbox"
                        type="text"
                        placeholder="Section Topic 1"
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "250px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      section Description
                    </div>
                    <div>
                      {" "}
                      <TextField
                        InputProps={{
                          sx: {
                            resize: "both",
                            overflow: "auto",
                          },
                        }}
                        multiline
                        value={sectiondata.description}
                        onChange={(e) => {
                          setSectiondata((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }));
                        }}
                        className="inputbox"
                        sx={{ width: "250px" }}
                        type="text"
                        placeholder=" section Description"
                      />
                    </div>
                  </div>

                  <div className="inputandlabelboxforstrategies">
                    <div className="inputlabelnameForstrategi">
                      {" "}
                      Section File
                    </div>
                    <div>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "50px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {" "}
                          <Button
                            variant="contained"
                            component="label"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                            startIcon={<FileUploadIcon />}
                          >
                            Upload
                            <input
                              name="logo"
                              hidden
                              accept="image/*"
                              multiple
                              type="file"
                              onChange={handleFileChange}
                            />
                          </Button>{" "}
                        </div>{" "}
                        <div>
                          {" "}
                          {image.url && (
                            <div style={{ width: "400px", height: "200px" }}>
                              <iframe
                                src={image.url}
                                frameborder="0"
                                width={400}
                                height={200}
                              ></iframe>
                            </div>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "24px",

                    color: "red",
                  }}
                >
                  {" "}
                  {errorSms}
                </div>
                <div>
                  {" "}
                  <Button
                    onClick={() => {
                      editfalg ? updateSection() : createSection();
                    }}
                    style={{
                      backgroundColor: "#007C84",
                      marginTop: "24px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {" "}
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
