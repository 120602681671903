import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";

const certificateFunction = (
  data,
  value,
  setLoading,
  setFlagForReload,
  flagForReload
) => {
  if (data?.certificateLink && value == "certificate") {
    window.open(data?.certificateLink, "_blank");
    return;
  }
  if (value == "delete" && data) {
    setLoading(true);
    console.log("kjfhka");
    let dataex = JSON.stringify({
      courseId: data?.courseId,
      userId: data?.userId,
      courseType: data?.courseType,
    });
    let configExInt = {
      method: "delete",
      params: { select: 0, userId: data?.userId },
      url: `${IP}/adminUserProfile/report`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setFlagForReload(!flagForReload);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    return;
  }
};

export { certificateFunction };
