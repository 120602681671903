import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Select } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";
import "./event.css";
const MoreEventPage = (props) => {
  const { onClose, open, data } = props;
  const handleClose = () => {
    onClose();
  };
  const navigate = useNavigate();
  const [eldata, seteldata] = useState([]);

  useEffect(() => {
    seteldata(data);
  }, [data]);
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0].split("-")[0]
  );
  const [loading, setLoading] = useState(false);

  const [inputId, setInputId] = useState("2");

  let navigater = useNavigate();
  const runGenerateCertificateTraining = () => {
    /// navigater("/admin/course-reports")
    if (inputId == 2) {
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth="200px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "300px",
          maxHeight: "400px"
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      {/* <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        
      </DialogTitle> */}
      <Box
        sx={{
          padding: "16px",
        }}
      >
        <Box
          sx={{
            // height: "216px",
            backgroundColor: "#FFFFFF",
            borderRadius: "24px",
            paddingBottom: "10px",
            position: "relative"
          }}
        >
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            position: "sticky",
            top: "0",
            left: "0",
            padding: "10px",
            backgroundColor: "inherit"
          }}>

            <Box
              id="month"
              sx={{
                color: "#666666",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "400",
                paddingLeft: "8px",
              }}
            >
              {eldata?.month}
            </Box>

            <span
              onClick={() => {
                onClose();
                handleClose();
              }}
              sx={{
                width: "20px",
                height: "20px",
                marginLeft: "auto",
                borderRadius: "5px",
                border: "none",
              }}
            >
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
                  fill="#707EAE"
                />
              </svg>
            </span>


          </Box>
          <Box sx={{
            mt: "16px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px"
          }}>
            {eldata?.item?.map((el, i) => (
              <Box
                onClick={() => {
                  navigate("/hr/DEI_view", {
                    state: {
                      ...el,
                    },
                  });
                }}
                sx={{
                  width: "100%",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "400",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "32px",
                  borderRadius: "8px",
                  padding: "5px 8px",
                  cursor: "pointer",
                  backgroundColor:
                    el.basedon == "day"
                      ? "rgba(254, 156, 191, 0.2)"
                      : "rgba(225, 245, 102, 0.2)",
                }}
              >
                {" "}
                {el.name}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default MoreEventPage;
