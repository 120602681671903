import { Box } from "@mui/material";

export default function HrFooter({ setSteperStep, steperStep }) {
  return (
    <Box
      sx={{
        // width: "20%",
        // minWidth: 400,

        height: "40px",
        // backgroundColor: "#D6D4E2",
        color: "#514F57",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: "600",
        display: "none",
        margin: "30px 10px 0px 10px",
        justifyItems: "center",
        alignItems: "center",
        alignContent: "center",
        cursor: "pointer",
        //  gridTemplateColumns: "1fr 1fr 1fr 1fr",
        marginBottom: "24px",
      }}
      onClick={() => {
        window.open("https://krishworks.com/", "_blank");
      }}
    >
      {" "}
      <Box sx={{ margin: "auto" }}> Powered by Krishworks LMS</Box>
    </Box>
  );
}
