import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";

export default function GroupBar({ data, Gbbvbar }) {
  // console.log({ series });
  // { name: "Company", data: data?.company },

  // { name: "Male", data: data?.male },
  // { name: "FeMale", data: data?.female },
  // { name: "Non-Binary", data: data?.nonBinary },

  //   const [option, setOptions] = React.useState({
  //     series: series,

  //   });

  console.log(data);
  useEffect(() => {
    console.log(data?.categories);
    if (!data?.data) return;
    setOptions({
      series: data?.data,
      options: {
        chart: {
          type: "bar",

          height: 350,
          width: "100%",
          // stacked: true,
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "70%",
            borderRadius: 2,
            endingShape: "rounded",
            borderRadiusApplication: "end",
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          // formatter: function (val) {
          //   return val + "%";
          // },
          formatter: (value) => {
            return value;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#000000"],
          },
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: data?.cat || [],
          labels: {
            show: true,
            align: "center",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: "16px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-yaxis-label",
            },
            offsetX: 0,
            offsetY: 5,
          },
        },
        yaxis: {
          show: false,
          title: {
            text: "",
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return value;
            },
          },
        },
        colors: ["#7EC1FF", "#AFFB63", "#FE9CBF"],

        fill: {
          opacity: 1,
          colors: ["#7EC1FF", "#AFFB63", "#FE9CBF"],
        },
        legend: {
          position: "bottom",
          colors: ["#7EC1FF", "#AFFB63", "#FE9CBF"],

          horizontalAlign: "left",
          offsetX: 50,
          offsetY: 10,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              xaxis: {
                labels: {
                  maxWidth: 20,
                  hideOverlappingLabels: true,
                  trim: true,
                  style: {
                    fontSize: "10px",
                  },
                },
              },
            },
          },
        ],
      },
    });
  }, [data]);

  const [option, setOptions] = useState({
    series: [
      {
        name: "Non-binary",
        data: [44, 55, 57, 56],
      },
      {
        name: "Female",
        data: [76, 85, 101, 98],
      },
      {
        name: "Male",
        data: [35, 41, 36, 26],
      },
    ],

    options: {
      chart: {
        type: "bar",

        // stacked: true,
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 2,
          endingShape: "rounded",
          borderRadiusApplication: "end",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        // formatter: function (val) {
        //   return val + "%";
        // },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#000000"],
        },
      },
      stroke: {
        // width: 1,
        curve: "smooth",
        // colors: ["#03C988"],
      },

      xaxis: {
        categories: [
          [" Resumes ", "received"],
          ["Candidates", "shortlisted"],
          ["Offer letters", "sent"],
          ["Candidates", "recruited"],
        ],

        labels: {
          show: true,
          align: "center",
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: [],
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-yaxis-label",
          },
          offsetX: 0,
          offsetY: 5,
          rotate: 0,
        },
      },
      yaxis: {
        show: false,
        title: {
          text: "",
        },
      },
      colors: ["#FE9CBF", "#AFFB63", "#7EC1FF"],

      fill: {
        opacity: 1,
        colors: ["#FE9CBF", "#AFFB63", "#7EC1FF"],
      },
      legend: {
        position: "bottom",
        colors: ["#FE9CBF", "#AFFB63", "#7EC1FF"],

        horizontalAlign: "left",
        offsetX: 50,
        offsetY: 30,
      },
    },
  });

  return (
    <Box
      sx={{
        height: "90%",
        width: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop:'20px'
      }}
    >
      {!data?.data ? (
        <img
          src={Gbbvbar}
          alt=""
          style={{
            width: "80%",
            height: "80%",
            objectFit: "cover",
            overflow: "hidden",
          }}
        />
      ) : (
        <div className="responsiveGraphhide">
     
          <Chart
            options={option.options}
            series={option.series}
            width="100%"
            height={320}
            type="bar"
          />
     
        </div>
      )}
      {/* <Chart
        options={option.options}
        series={option.series}
        width="100%"
        height={400}
        type="bar"
      /> */}
    </Box>
  );
}
