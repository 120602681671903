import "./maturity.css";
// import { PieChart, Pie, Cell } from "recharts";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { useEffect, useState } from "react";
import { steps } from "./maturityData";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import { ValidateAuth } from "../../../AuthToken";
import { getCookie } from "../../../Cookies";
import { useLocation, useNavigate } from "react-router";
export default function Maturity() {
  const [StrategiesList, setStrategiesList] = useState([0, 0, 0, 0]);
  const [indexForMaturity, setIndexForMaturity] = useState(10);

  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const location = useLocation();
  let companyId;
  console.log("location", location);
  if (location.state) {
    companyId = location.state.companyId;
  }

  useEffect(() => {
    // ValidateAuth();
    // let newobj = JSON.parse(getCookie("roles"));
    // if (!newobj.HRDI?.includes(getCookie("ROLE"))) {
    //   goToHome();
    //   return <></>;
    // }
  }, []);
  useEffect(() => {
    let configrecrut = {
      method: "get",

      url: `${IP}/maturityScale?companyId=${companyId}`,
      //params: { country: country, state: state, year: year },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut).then((res) => {
      setIndexForMaturity(res.data.index);
    });

    return () => {};
  }, []);

  return (
    <Box className="maturitymanbox">
      <Box className="maturityMainContainer">
        <Box className="maturityBox1">
          <Box className="maturityboxpaichart">
            <Box className="MaturaityStepContainer">
              {steps?.map((el, index) => (
                <Box className="maturityStepItem" sx={{ width: "100%" }}>
                  <Box className="MaturitySVGandText">
                    <Box className="MaturityStepItemSVG">
                      {index == indexForMaturity ? el.cimg : el.img}
                    </Box>

                    <Box className="maturynamelevel">{el.name}</Box>
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      width: "100%",
                      mt: "10px",
                      gridTemplateColumns: "3fr 1fr 3fr",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      {" "}
                      <hr />
                    </Box>
                    <Box>
                      {" "}
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          border: "1px solid #000000",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        {index == indexForMaturity && (
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              border: "1px solid #000000",

                              backgroundColor: "#E1F566",
                            }}
                          ></div>
                        )}{" "}
                      </div>{" "}
                    </Box>
                    <Box>
                      {" "}
                      <hr />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box>
              {" "}
              <Box className="MaturityCurrentStepText">
                {/* <Button
                  className="maturynameButtenname"
                  sx={{
                    backgroundColor: "#E1F566",
                    borderRadius: "15px",
                    display: indexForMaturity == "10" ? "none" : "",
                    cursor: "default",
                    padding: "16px",
                    color: "#000",
                    fontSize: "18px",
                    fontWeight: 400,
                    "&:hover": {
                      background: "#E1F566",
                    },
                  }}
                >
                  {" "}
                  {steps[indexForMaturity]?.name}
                </Button> */}
              </Box>
              <Box
                className="maturynameButtennameandDis"
                sx={{
                  mt: "20px",
                  color: "#666666",
                  width: "100%",
                  padding: {
                    lg: "0 100px",
                    xs: "0 20px",
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    paddingBottom: "24px",
                  }}
                >
                  {steps[indexForMaturity]?.dis}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {indexForMaturity == "10" && (
          <Box className="maturityBox2">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box sx={{ fontWeight: "600" }}>Strategies </Box>
              <Box></Box>
            </Box>
            <Box sx={{ mt: "20px", fontWeight: "600" }}>
              {" "}
              {steps[indexForMaturity]?.Strategies}
            </Box>
            <Box
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
                textAlign: "left",
                fontFamily: "Poppins",
                alignItems: "center",
              }}
            >
              {" "}
              Please click on the “Update data” button to view your team's
              progress on the Gender Advancement scale. This will also give you
              access to customized strategies to take your DEI agenda to the
              next stage.
            </Box>
          </Box>
        )}

        {indexForMaturity != "10" && (
          <Box className="maturityBox2">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box sx={{ fontWeight: "600" }}>Strategies </Box>
            </Box>
            {/* <Box sx={{ mt: "20px", fontWeight: "600" }}>
              {" "}
              {steps[indexForMaturity]?.Strategies}
            </Box> */}
            <Box
              sx={{
                mt: "20px",
                fontWeight: "700",
                fontSize: "16px",
                fontFamily: "Poppins",
              }}
            >
              {" "}
              Recommended next steps
            </Box>
            <Box sx={{ mt: "8px" }}>
              <ul style={{ marginLeft: "20px" }}>
                {steps[indexForMaturity]?.next?.map((el) => (
                  <li
                    style={{
                      marginTop: "10px",
                      lineHeight: "24px",
                      fontWeight: "400",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontFamily: "Poppins",
                    }}
                  >
                    {" "}
                    {el}
                  </li>
                ))}
              </ul>
            </Box>

            <Box
              sx={{
                mt: "20px",
                fontWeight: "700",
                fontSize: "16px",
                fontFamily: "Poppins",
              }}
            >
              {" "}
              Recommended learning
            </Box>
            <Box sx={{ mt: "8px" }}>
              <ul style={{ marginLeft: "20px" }}>
                {steps[indexForMaturity]?.Recommended?.map((el) => (
                  <li
                    style={{
                      marginTop: "10px",
                      lineHeight: "24px",
                      fontWeight: "400",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontFamily: "Poppins",
                    }}
                  >
                    {" "}
                    {el}
                  </li>
                ))}
              </ul>
            </Box>
            <Box
              sx={{
                mt: "20px",
              }}
            >
              <Button
                onClick={() => {
                  navigate("/hr/Strategies");
                }}
                sx={{
                  border: "1px solid #000",
                  padding: "5px 30px",
                  borderRadius: "10px",
                }}
              >
                {" "}
                Learn more
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
