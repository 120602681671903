import React from "react";

function Home({ clr }) {
  return (
    <svg
      width="auto"
      height="28"
      viewBox="0 0 153 154"
      fill={clr}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M112.137 39.3603C112.134 47.1399 109.836 54.7439 105.534 61.2113C101.232 67.6787 95.118 72.7192 87.9659 75.6955C80.8138 78.6719 72.9443 79.4505 65.3519 77.9331C57.7596 76.4157 50.7853 72.6702 45.3106 67.1703C39.8358 61.6703 36.1064 54.6626 34.5937 47.033C33.0809 39.4034 33.8528 31.4943 36.8117 24.3054C39.7706 17.1166 44.7838 10.9706 51.2175 6.64446C57.6512 2.31828 65.2167 0.00603628 72.9577 5.48423e-07C83.3468 -0.00174095 93.3111 4.14411 100.659 11.5255C108.006 18.907 112.135 28.9194 112.137 39.3603Z"
        fill={clr}
      />
      <path
        d="M83.4249 85.4616C67.2202 103.052 67.3135 129.346 82.9011 146.759C82.4722 146.839 82.039 146.893 81.6039 146.921C58.4853 146.928 35.3667 146.924 12.2481 146.911C5.27678 146.898 0.164615 141.751 0.0893822 134.717C0.0409367 130.189 -0.186421 125.611 0.348378 121.138C1.2525 113.576 5.62513 107.858 11.2422 103.073C18.7579 96.671 27.5857 92.8077 36.9186 90.0216C51.7855 85.5835 66.984 84.2836 82.4149 85.338C82.6818 85.3562 82.9468 85.4022 83.4249 85.4616Z"
        fill={clr}
      />
      <path
        d="M153 116.343C152.993 123.766 150.796 131.02 146.687 137.189C142.578 143.358 136.742 148.163 129.915 150.999C123.089 153.835 115.58 154.573 108.336 153.12C101.093 151.667 94.4407 148.089 89.2206 142.837C84.0004 137.585 80.4467 130.896 79.0087 123.615C77.5707 116.334 78.3129 108.788 81.1416 101.931C83.9702 95.0736 88.7583 89.2131 94.9006 85.0902C101.043 80.9673 108.264 78.7671 115.65 78.7677C136.204 78.7139 152.966 95.5776 153 116.343ZM120.983 111.018C120.983 108.576 121.112 106.34 120.931 104.13C120.838 102.867 120.442 101.646 119.777 100.571C118.543 98.6968 116.246 98.0925 114.151 98.7452C113.043 99.0618 112.068 99.736 111.379 100.664C110.689 101.592 110.322 102.722 110.335 103.88C110.257 106.207 110.318 108.538 110.318 111.011C107.981 111.011 105.864 110.851 103.785 111.067C102.369 111.19 101.002 111.644 99.7925 112.392C97.993 113.592 97.4791 116.04 98.1832 118.118C98.5081 119.136 99.138 120.029 99.9867 120.674C100.836 121.319 101.862 121.685 102.925 121.721C105.344 121.793 107.766 121.737 110.311 121.737C110.311 124.144 110.207 126.273 110.351 128.384C110.403 129.601 110.719 130.792 111.278 131.872C111.876 132.861 112.789 133.618 113.869 134.02C114.949 134.422 116.133 134.445 117.227 134.085C118.3 133.759 119.241 133.096 119.913 132.194C120.584 131.292 120.951 130.198 120.96 129.072C121.018 126.689 120.972 124.303 120.972 121.756C123.443 121.756 125.671 121.915 127.861 121.696C129.219 121.581 130.524 121.109 131.643 120.326C133.439 118.954 133.851 116.901 133.182 114.72C132.831 113.649 132.152 112.716 131.242 112.054C130.332 111.392 129.238 111.036 128.116 111.035C125.801 110.96 123.482 111.018 120.983 111.018Z"
        fill={clr}
      />
    </svg>
  );
}

export default Home;
