import { t } from "i18next";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ValidateAuth } from "../../AuthToken";
import { getCookie } from "../../Cookies";
import {
  ExternalTrainingIcons,
  InternalOfflineTrainingIcons,
  InternalTrainingLmsIcons,
} from "../../utilities/Icons/iconsComponent";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./Trainings.css";
import { DrawerContext } from "../../utilities/context/DrawerProvider";

function Trainings() {
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate;
  // useEffect(() => {
  //   ValidateAuth();

  //   if (!getCookie("roles").includes(getCookie("ROLE"))) {
  //     goToHome();
  //     return <></>;
  //   }
  // }, []);

  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);
  return (
    <div className="courselist">
      <Navbar page={t("Training")} />
      <div className="courselist-main">
        <Sidebar page={"training"} hidepage={false} />
        <div className="courselist-main-cnt">
          {/* <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">{t("InternalTrainingLMS")}</div>
          </div> */}
          <div className="admin-dashboard-main-bottom">
            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <InternalTrainingLmsIcons />
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  {t("InternalTrainingLMS")}{" "}
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  {t("ListOfInternalTrainingLMS")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/training/internal"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("View")}</div>
                      </button>
                    </Link>
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/courseBuilder"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Add")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <ExternalTrainingIcons />
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  {t("Micro-learnings")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  {t("List Of Micro-learnings")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/Micro-learnings/view"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("View")}</div>
                      </button>
                    </Link>
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/Micro-learnings"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Add")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-main-bottom-cnt">
              <div className="admin-dashboard-main-bottom-left-icon">
                <InternalOfflineTrainingIcons />
              </div>
              <div className="admin-dashboard-main-bottom-right">
                <div className="admin-dashboard-main-bottom-right-top">
                  {t("Games")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btm">
                  {t("List Of Games")}
                </div>
                <div className="admin-dashboard-main-bottom-right-btn">
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/Games/view"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("View")}</div>
                      </button>
                    </Link>
                  </div>

                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/Games"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Add")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trainings;
