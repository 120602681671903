import React, { useEffect, useState } from "react";
import Sidebar from "../../../Component/Sidebar";
import { t } from "i18next";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavbarTop from "../../../Component/NavbarTop";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CompanyReportBeforeView = () => {
  const location = useLocation();
  const companyId = location.state;
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { clrs } = useSelector((state) => state.createClr);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // delete company

  return (
    <section className="dashboard">
      <NavbarTop page={t("Company And Department Settings")} />
      {/* <Navbar /> */}

      <div style={{ height: "41vw" }} className="dashboard-main">
        <Sidebar page={"Dashboard"} hidepage={false} />
        <div className="admin-dashboard-main srollbar_div">
          <div
            className="outer-bigger-container"
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {/* Company settings */}

            <Button
              variant="contained"
              style={{
                display: "flex",

                alignItems: "center",
                marginBottom: "20px",
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
                width: "50px",
              }}
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>

            <h1>Company Report</h1>

            <Link
              to="/admin/settings/companyReportDEI"
              state={{ type: "deiReport", companyId: companyId }}
            >
              <button
                className="bigger-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  borderRadius: "5px 5px 0px 0px",
                  width: "50%",
                }}
              >
                <div>DEI Report</div>
                <ArrowForwardIcon />
              </button>
            </Link>
            <Link
              to="/admin/settings/companyReportView"
              state={{ type: "course", companyId: companyId }}
            >
              <button
                className="bigger-container"
                style={{
                  display: "flex",

                  alignItems: "center",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  borderRadius: "5px 5px 0px 0px",
                  width: "50%",
                }}
              >
                <div>Course Report</div>
                <ArrowForwardIcon />
              </button>
            </Link>
            <Link
              to="/admin/settings/companyReportView"
              state={{ type: "microCourse", companyId: companyId }}
            >
              <button
                className="bigger-container"
                style={{
                  display: "flex",

                  alignItems: "center",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  borderRadius: "5px 5px 0px 0px",
                  width: "50%",
                }}
              >
                <div>Micro-course Report</div>
                <ArrowForwardIcon />
              </button>
            </Link>
            <Link
              to="/admin/settings/companyReportView"
              state={{ type: "games", companyId: companyId }}
            >
              <button
                className="bigger-container"
                style={{
                  display: "flex",

                  alignItems: "center",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  borderRadius: "5px 5px 0px 0px",
                  width: "50%",
                }}
              >
                <div>Games Report</div>
                <ArrowForwardIcon />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyReportBeforeView;
