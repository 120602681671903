import React, { useEffect } from "react";
import { useState } from "react";
import CreateQuiz from "./CreateQuiz";
import $, { hasData } from "jquery";
import { Timestamp } from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Item from "./Item";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import "./styles/item.css";
import { DeleteSVg, EditSvg, ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";
const CreateLesson = ({
  quizData,
  itemsData,
  topicsData,
  courseId,
  lessonId,
}) => {
  const storage = getStorage();
  //Topic
  const createTopic = httpsCallable(functions, "createTopic");
  const addTopicExtraInfo = httpsCallable(functions, "addTopicExtraInfo");
  const addLessonTopicOrder = httpsCallable(functions, "addLessonTopicOrder");
  const getTopicInfo = httpsCallable(functions, "getTopicInfo");
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuestionSetInfo = httpsCallable(functions, "getQuestionSetInfo");
  const deleteTopic = httpsCallable(functions, "deleteTopic");
  const [editQuizArr, setEditQuizArr] = useState([]);
  // deleteTopic
  const [topicName, setTopicName] = useState("");
  const [topicDescription, setTopicDescription] = useState("");
  const [daysAfterTopic, setDaysAfterTopic] = useState(0);
  const [startDateTopic, setStartDateTopic] = useState("");
  const [topicId, setTopicId] = useState("");
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckedFailed] = useState(false);
  const [list, setList] = useState([]);
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteTopicId, setDeleteTopicId] = useState("");
  const [checkErrorInForm, setCheckErrorInForm] = useState({
    topicName: "",
    topicDescription: "",
  });

  function checkForm() {
    if (topicName == "") {
      setCheckErrorInForm((prev) => ({ ...prev, topicName: "error" }));
      return "topicName";
    }
    if (topicDescription == "") {
      setCheckErrorInForm((prev) => ({ ...prev, topicDescription: "error" }));
      return "topicDescription";
    }
    return "allIsWell";
  }

  async function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    let data = JSON.stringify({
      lessonId: lessonId,
      topics: orderList,
      edit: true,
    });
    var configLes = {
      method: "post",

      url: `${IP}/courseBuilder/addLessonTopicOrder`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(configLes).then((res) => {
      //  console.log(res);
    });
  }
  var docURL = [];
  var files = [];
  const upload = (pdf, i, link, referDoc) => {
    const metadata = {
      contentType: pdf.type,
    };
    const fileName = pdf.name;
    const storageRef = ref(
      storage,
      link + i + fileName.substring(fileName.lastIndexOf("."))
    );
    const uploadTask = uploadBytesResumable(storageRef, pdf, metadata);
    files.push(topicId + fileName.substring(fileName.lastIndexOf(".")));
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            //console.log("Upload is paused");
            break;
          case "running":
            //console.log("Upload is running");
            break;
        }
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          //console.log("File available at", downloadURL);
          docURL.push(downloadURL);
          referDoc
            .collection("extraInfo")
            .doc("infoDoc")
            .update({ documentURL: docURL });
        });
      }
    );
  };

  const addTopic = () => {
    let check = checkForm();

    let nsn = topicName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }

    if (check != "allIsWell") {
      setCheckedFailed(true);
      return;
    }
    setCheckErrorInForm({
      topicName: "",
      topicDescription: "",
    });
    setCheckedFailed(false);
    const schedule = true;

    const newList = list.concat({
      id: topicId,
      name: topicName,
      description: topicDescription,
      sampleTopic: false,
      hasQuiz: true,
      schedule: schedule,
      start: "null",
      created: Timestamp.now(),
    });
    toggle();
    setList(newList);
    saveOrder(newList);
    docURL = [];
    files = [];
    alert("Topic Added");

    let data = JSON.stringify({
      created: new Date().toString(),
      lessonAssociated: lessonId,
      topicDescription: topicDescription,
      topicName: topicName,
      topicId: topicId,
    });
    var configLes = {
      method: "post",

      url: `${IP}/courseBuilder/topic`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(configLes).then((res) => {
      // console.log(res);
    });
    // createTopic({
    //   created: new Date().toString(),
    //   lessonAssociated: lessonId,
    //   topicDescription: topicDescription,
    //   topicName: topicName,
    //   topicId: topicId,
    // });

    let dataex = JSON.stringify({
      topicId: topicId,
      hasQuiz: true,
      sample: false,
      schedule: schedule,
      start: "today",
    });
    var configEx = {
      method: "patch",

      url: `${IP}/courseBuilder/topic`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx).then((res) => {
      //console.log(res);
    });

    setTopicName("");
    setTopicDescription("");
    setDaysAfterTopic("");
    setStartDateTopic("");
  };

  const toggle = (e) => {
    resetForm();
    document.getElementById(lessonId + "topicForm").style.display =
      document.getElementById(lessonId + "topicForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }
  function edit(topic) {
    setEditable(true);
    document.getElementById(lessonId + "topicForm").style.display = "";
    setTopicName(topic.name);
    setTopicId(topic.id);
    setTopicDescription(topic.description);
    document.getElementById(lessonId + "topicName").value = topic.name;
    document.getElementById(lessonId + "topicId").value = topic.id;
    document.getElementById(lessonId + "topicDescription").value =
      topic.description;
    document.getElementById(lessonId + "sampleTopic").value = topic.sampleLesson
      ? "yes"
      : "no";
    document.getElementById(lessonId + "hasTopicQuiz").value = topic.hasQuiz
      ? "yes"
      : "no";
  }
  function resetForm() {
    // setEditable(true);
    // document.getElementById(lessonId + "topicForm").style.display = "";
    setTopicName("");
    setTopicId("");
    setTopicDescription("");
    // document.getElementById(lessonId + "topicName").value = topic.name;
    // document.getElementById(lessonId + "topicId").value = topic.id;
    // document.getElementById(lessonId + "topicDescription").value = topic.description;
    // document.getElementById(lessonId + "sampleTopic").value = topic.sampleLesson ? "yes" : "no";
    // document.getElementById(lessonId + "hasTopicQuiz").value = topic.hasQuiz ? "yes" : "no";
    // document.getElementById(lessonId + "releaseSchedule").value = topic.schedule;
    // if (topic.schedule == "enrolment") {
    //   document.getElementById(lessonId + "daysAfter").value = topic.start;
    // } else if (topic.schedule == "specific") {
    //   document.getElementById(lessonId + "startDate").value = topic.start;
    // }
  }

  function updateTopic() {
    let check = checkForm();
    if (check != "allIsWell") {
      setCheckedFailed(true);
      return;
    }
    setCheckedFailed(false);

    const schedule = "date is not entered";

    let newArr = Array.from(list);
    for (let i = 0; i < newArr.length; i++) {
      if (topicId === newArr[i].id) {
        newArr[i] = {
          id: topicId,
          name: topicName,
          description: topicDescription,
          sampleTopic: false,
          hasQuiz: true,

          created: Timestamp.now(),
        };
      }
    }
    setList(newArr);

    docURL = [];
    files = [];
    alert("Topic Added");

    setEditable(false);
    document.getElementById(lessonId + "topicForm").style.display = "none";

    let data = JSON.stringify({
      created: new Date().toString(),
      lessonAssociated: lessonId,
      topicDescription: topicDescription,
      topicName: topicName,
      topicId: topicId,
    });
    var configLes = {
      method: "post",

      url: `${IP}/courseBuilder/topic`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(configLes).then((res) => {
      //console.log(res);
    });
    // createTopic({
    //   created: new Date().toString(),
    //   lessonAssociated: lessonId,
    //   topicDescription: topicDescription,
    //   topicName: topicName,
    //   topicId: topicId,
    // });

    let dataex = JSON.stringify({
      topicId: topicId,
      hasQuiz: true,
      sample: false,
      schedule: schedule,
      start: "today",
    });
    var configEx = {
      method: "patch",

      url: `${IP}/courseBuilder/topic`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx).then((res) => {
      //console.log(res);
    });

    saveOrder(newArr);
  }

  async function del(topic) {
    setopenLessonPopUp(true);
    setDeleteTopicId(topic);
    // deleteTopic({ topicId: topic.id });
    // alert("Deleted " + topic.name);
    // const item = [];
    // for (let i = 0; i < list.length; i++) {
    //   if (topic.id !== list[i].id) {
    //     item.push(list[i]);
    //   }
    // }
    // setList(item);
    // saveOrder(item);
  }
  const delfunction = (topic) => {
    deleteTopic({ topicId: topic.id });
    alert("Deleted " + topic.name);
    const item = [];
    for (let i = 0; i < list.length; i++) {
      if (topic.id !== list[i].id) {
        item.push(list[i]);
      }
    }
    setList(item);
    saveOrder(item);
  };
  return (
    <>
      <br></br>
      <Item itemsData={itemsData} courseId={courseId} lessonId={lessonId} />
      <DeleteLesson
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteTopicId}
        itemName={"Topic"}
      />
      <br></br>
      {/* 
      <CreateQuiz
        editQuizArr={editQuizArr}
        courseId={courseId}
        lessonId={lessonId}
      /> */}
    </>
  );
};

export default CreateLesson;
