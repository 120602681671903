import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./AdminSetting.css";
import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { useSelector } from "react-redux";

function AdminSettings() {
  const { clrs } = useSelector((state) => state.createClr);
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="admin-dashboard-main-bottom">
              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Certificate Settings")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and edit certificate templates")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/available-certificates"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Email Settings")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and edit Email Template")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/email"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Company Settings")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and edit Company")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/company-department"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Department Settings")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and edit Department")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/Department-department"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Advancement Scale")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and Edit Advancement Scale")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/AdvancementScaleList"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Strategies")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and Edit Strategies")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/StrategiesList"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Policies")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and Edit Policies")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/PoliciesAddList"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Infrastructure")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and Edit Infrastructure")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/InfrastructureAddList"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Process & Tools")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and Edit Process & Tools")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/Process&ToolsAddList"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Webinars")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and Edit Webinars")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/WebinarsList"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Subscription")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and Edit Subscription")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/SubscriptionList"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Event")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and Edit Event")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/EventList"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Survey form")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Add and Edit Survey form")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/PsyclogicalSafetyList"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("ICC Members")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("ICC Members list")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/ICC-list"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Company Report")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Groupwise and Coursewise Report")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/companyReport"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                style={{ width: "24vw" }}
                className="admin-dashboard-main-bottom-item"
              >
                <div className="admin-dashboard-main-bottom-item-left">
                  <div className="admin-dashboard-main-bottom-item-left-top">
                    {t("Backup")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-mid">
                    {t("Download Backup")}
                  </div>
                  <div className="admin-dashboard-main-bottom-item-left-btm">
                    <Link to={"/admin/settings/backupDownload"}>
                      <button
                        style={{
                          background: `${
                            clrs.CourseListBtn
                              ? clrs.CourseListBtn
                              : "hwb(224deg 78% 3%)"
                          }`,
                        }}
                      >
                        <div>{t("Open")}</div>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdminSettings;
