import React, { useEffect, useState } from "react";
import Sidebar from "../../../Component/Sidebar";
import { t } from "i18next";
import axios from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavbarTop from "../../../Component/NavbarTop";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { CSVLink } from "react-csv";

import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CompanyReportView = (props) => {
  const [selectedGroup, setSelectedGroup] = useState("");
  //   const selectedCompany = location.state.compantyId;
  const location = useLocation();

  const { type, companyId } = location.state;

  const [allData, setAllData] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [allCourse, setAllCourse] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [edit, setEdit] = useState(null);
  const [editableField, setEditableField] = useState({
    empName: "",
    totalLessons: "",
    lessonsComplete: "",
    lastStep: "",
    startDate: "",
    completeDate: "",
    status: "",
  });
  const [bucketPath,setBucketPath] = useState("");
  // const [template, settemplate] = useState([]);
  const removeTimeStamp=(groupId)=>{
    const arr = groupId.split('_');
    let st="";
    for(let i=0;i<arr.length-1;i++){
      st+=arr[i];
    }
    return st;
    
  }

  const getTableData = () => {
    // let url =
    //   "https://storage.googleapis.com/serein-devqa-internal-gcp.appspot.com/FinalReport/vkxHBk9rVutKM7qIdlzS/omkartempcomgroup3_1698227969193/Safetyandinclusionattheworkplace_1679986444016/report.json?GoogleAccessId=firebase-adminsdk-kpe6v%40serein-devqa-internal-gcp.iam.gserviceaccount.com&Expires=2532709800&Signature=aomj1n6SiVSA8onk4Dyzg1dCrhBeZ74duxue6XurppAbGSpBPC23yjNY9wLRK89G%2FyKT8AnWAD8N8yug%2FiAFdpwFxNmWRofgRgz%2BMnRxOMZC40yzyXVsCbnZRFnGY%2FjX%2BKWU0N5e2NZpLLES7XOLddCM26WQji5yYh4U26UEQ7jEyQXGkSk2ixvmDcBMuL4RrYh1nng%2F7x%2FTfnRUBt3AoRj0xpuWdEuaATGv9N72EFL6R5Sv6UZH2yK4yN6uRXIl%2FzSOhNydPzrJH4yVncri8ze3%2B%2BvI7qiuaMjyu1NyJIKBBrQpMy53nSuFFQXlbFEFKyjN3RytZnh2qai2mvO33Q%3D%3D";
    let url = "";
    allCourse.map((_, idx) => {
      if (_.courseId == selectedCourse) {
        url = _.report;
      }
    });
    if(url != '')
    {
    const arr=url.split('/')
    console.log('arr',arr);
    let zz=arr[4]+'/'+arr[5]+'/'+arr[6]+'/'+arr[7]+'/';
    let cc=arr[8].split('?')[0];
    let final = zz+cc;
    console.log('final',final);
    // return;
    setBucketPath(final);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => setTableData(result))
      .catch((error) => console.log("error", error));
    }
  };
  useEffect(() => {
    getTableData();
  }, []);
  const getAllCourse = () => {
    const tempArr = [];
    allData.map((_, idx) => {
      if (_.groupId == selectedGroup) tempArr.push(_);
    });
    console.log(tempArr);
    setSelectedCourse(tempArr[0]?.courseId);
    setAllCourse([...tempArr]);
  };

  const getAllGroups = () => {
    const st = new Set();
    var config = {
      method: "get",

      url: `${IP}/getReportFromDb?companyId=${companyId}&type=${type}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config).then((res) => {
      console.log(res);
      console.log("all ok", res.data.data[0]);
      setAllData(res.data.data);
      setSelectedGroup(res.data.data[0]?.groupId);
      setSelectedCourse(res.data.data[0].courseId);
      res.data.data.map((_, idx) => {
        st.add(_.groupId);
      });
      let tArr = [...st];
      setAllGroups(tArr);
      console.log(tArr);
    });
  };

  useEffect(() => {
    getAllGroups();
  }, []);
  useEffect(() => {
    getAllCourse();
  }, [selectedGroup]);
  useEffect(() => {
    getTableData();
  }, [selectedCourse]);
  const formatDate = (date) => {
    let zz = date;
    let xx = new Date(zz);
    let stM =
      xx.getMonth() > 10
        ? xx.getMonth().toString()
        : "0" + xx.getMonth().toString();
    let stD =
      xx.getDate() > 10
        ? xx.getDate().toString()
        : "0" + xx.getDate().toString();
    let da = xx.getFullYear().toString() + "-" + stM + "-" + stD;
    return da;
  };
  const navigate = useNavigate();
  const { clrs } = useSelector((state) => state.createClr);

  const saveData=(toSendData)=>{
      console.log(bucketPath);
     let dataex = JSON.stringify({
       toSendData:JSON.stringify(toSendData),
       path:bucketPath
      });

      let configExInt = {
        method: "patch",
        // params: { userId: uid },
        url: `${IP}/getReportFromDb`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };

    axios(configExInt).then((res) => {
      console.log(res);
      console.log("all ok", res.data);
    });



  }

  const headers = [
  { label: "Employee name", key: "userName" },
  { label: "Total lessons", key: "totalLessonCount" },
  { label: "Lessons completed", key: "lessonCompleted" },
  { label: "Last step completed", key: "course_last_step" },
  { label: "Start date", key: "course_started_on" },
  { label: "Completion date", key: "course_completed_on" },
  { label: "Status", key: "status" },

];
  return (
    <section className="dashboard">
      <NavbarTop page={t("Company And Department Settings")} />
      {/* <Navbar /> */}

      <div style={{ height: "41vw" }} className="dashboard-main">
        <Sidebar page={"Dashboard"} hidepage={false} />
        <div className="admin-dashboard-main srollbar_div">
          <div className="outer-bigger-container">
            {/* Company settings */}

            <Button
              variant="contained"
              style={{
                marginBottom: "20px",
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>

            <h1 style={{ textTransform: "capitalize" }}>{type} Report</h1>

            <p>Select a Group</p>

            {console.log(allGroups)}
            <select
              value={selectedGroup}
              onChange={(e) => {
                setSelectedGroup(e.target.value);
              }}
            >
              {allGroups.length > 0 &&
                allGroups.map((val, idx) => {
                  return (
                    <option value={val} key={idx}>
                      {removeTimeStamp(val)}
                    </option>
                  );
                })}

              {/* {allGroups?.map((val, idx) => {
                return (
                  <option value={val.groupId} key={idx}>
                    {val.groupName}
                  </option>
                );
              })} */}
            </select>

            <p>Select A Course</p>
            <select
              value={selectedCourse}
              onChange={(e) => {
                setSelectedCourse(e.target.value);
              }}
            >
              {allCourse.length > 0 &&
                allCourse.map((val, idx) => {
                  return (
                    <option value={val.courseId} key={idx}>
                      {val.courseName}
                    </option>
                  );
                })}
            </select>
            <div>
            {/* Download Report */}
            <Button
              variant="contained"
              style={{
                
                
                marginTop: "20px",
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
              }}>
          <CSVLink data={tableData} headers={headers}   filename={`${selectedCourse}_${selectedGroup}.csv`}>
            <Typography sx={{color:'white'}}>Download Report</Typography>
          </CSVLink>
            </Button>
          </div>
          </div>
          {/* <Typography sx={{textAlign:'right',marginRight:'24px'}} onClick={downloadReport}>Download Report</Typography> */}

          <div>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                margin: "0px 24px 0px 24px",
                background: "#FFFFFF",
                padding: "8px 24px",
                fontFamily: "Poppins",
                fontSize: "16px",
                gridGap: "10px",
                // height: "72px",
                fontWeight: "700",
                alignContent: "center",
                alignItems: "center",
                color: "#000000",
                borderBottom: "1px solid #BFBFBF",
              }}
            >
              {/* <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                Sr. no
              </Typography> */}
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                Employee name
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                Total lessons
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {" "}
                Lessons completed
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {" "}
                Last step completed
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {" "}
                Start date
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {" "}
                Completion date
              </Typography>

              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {" "}
                Status
              </Typography>

              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                Edit
              </Typography>
            </Box>
          </div>
          {tableData.length == 0 && (
            <h1 style={{ marginLeft: "24px", marginTop: "12px" }}>
              There is no Data
            </h1>
          )}
          {tableData.length > 0 &&
            tableData?.map((el, index) => (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                  gridRow: "40px",
                  margin: "0px 24px 0px 24px",
                  padding: "8px 24px",
                  background: "#FFFFFF",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  minHeight: "60px",
                  fontWeight: "700",
                  gridGap: "10px",
                  alignContent: "center",
                  color: "#666666",
                  borderBottom: "1px solid #BFBFBF",
                  alignItems: "center",
                }}
              >
                {/* {index + 1} */}

                {/* <Typography> {index + 1}</Typography> */}
                {edit == index && (
                  <>
                    <input
                      type="text"
                      value={editableField.empName}
                      onChange={(e) => {
                        setEditableField({
                          ...editableField,
                          empName: e.target.value,
                        });
                      }}
                    />
                    <input
                      type="number"
                      value={editableField.totalLessons}
                      style={{ width: "40px", marginLeft: "10px" }}
                      onChange={(e) => {
                        setEditableField({
                          ...editableField,
                          totalLessons: e.target.value,
                        });
                      }}
                      disabled
                    />
                    <input
                      type="number"
                      value={editableField.lessonsComplete}
                      style={{ width: "40px", marginLeft: "10px" }}
                      onChange={(e) => {
                        if (e.target.value > el.totalLessonCount) return;
                        if (e.target.value < 0) return;
                        setEditableField({
                          ...editableField,
                          lessonsComplete: e.target.value,
                        });
                      }}
                    />
                    <input
                      type="text"
                      value={editableField.lastStep}
                      style={{ marginLeft: "-20px" }}
                      onChange={(e) => {
                        setEditableField({
                          ...editableField,
                          lastStep: e.target.value,
                        });
                      }}
                    />
                    <input
                      type="date"
                      value={editableField.startDate}
                      onChange={(e) => {
                        setEditableField({
                          ...editableField,
                          startDate: e.target.value,
                        });
                      }}
                    />
                    <input
                      type="date"
                      value={editableField.completeDate}
                      onChange={(e) => {
                        setEditableField({
                          ...editableField,
                          completeDate: e.target.value,
                        });
                      }}
                    />
                    <select
                      onChange={(e) =>
                        setEditableField({
                          ...editableField,
                          status: e.target.value,
                        })
                      }
                      value={editableField.status}
                      // defaultValue={el.status}
                    >
                      <option value={"Completed"}>Completed</option>
                      <option value={"Not Completed"}>Not Completed</option>
                    </select>
                    <button
                      className="GRViewCheckbox"
                      style={{
                        backgroundColor: "white",
                        width: "30px",
                        border: "none",
                      }}
                      onClick={() => {
                        setEdit(index);
                        const res1 = formatDate(el.course_started_on);
                        const res2 = formatDate(el.course_completed_on);
                        setEditableField({
                          empName: el.userName,
                          totalLessons: el.totalLessonCount,
                          lessonsComplete: el.lessonCompleted,
                          lastStep: el.course_last_step,
                          startDate: res1,
                          completeDate: res2,
                          status: el.status,
                        });
                        if (edit == index) {
                          let text = "Do you want to save it ?";
                          if (window.confirm(text) === true) {
                            alert("report changed");
                            
                            console.log(editableField);
                            const toSendData = tableData;
                            toSendData[index].userName = editableField.empName;
                            toSendData[index].lessonCompleted =
                              editableField.lessonsComplete;

                            toSendData[index].course_completed_on =
                              editableField.completeDate;

                            toSendData[index].course_started_on =
                              editableField.startDate;
                            toSendData[index].course_last_step =
                              editableField.lastStep;
                            toSendData[index].status = editableField.status;

                            console.log(toSendData);
                            saveData(toSendData);

                            //api call
                            setEdit(null);
                            // getTableData();
                          } else {
                            setEdit(null);
                            // setEditableField({
                            //   empName: el.userName,
                            //   totalLessons: el.totalLessonCount,
                            //   lessonsComplete: el.lessonCompleted,
                            //   lastStep: el.course_last_step,
                            //   startDate: res1,
                            //   completeDate: res2,
                            //   status: el.status,
                            // });
                            alert("report not changed");
                          }
                          return;
                        }
                      }}
                      // disabled={index != edit}
                    >
                      {index == edit ? <SaveIcon /> : <EditIcon />}
                    </button>
                  </>
                )}
                {edit != index && (
                  <>
                    <Typography> {el.userName}</Typography>

                    {/* <Typography>
                {" "}
                {edit == index ? editableField.empName : el.userName}
              </Typography> */}
                    {/* {edit == index ? editableField.empName : el.userName} */}
                    <Typography> {el.totalLessonCount}</Typography>
                    <Typography> {el.lessonCompleted}</Typography>
                    <Typography> {el.course_last_step}</Typography>
                    <Typography> {el?.course_started_on}</Typography>
                    <Typography> {el?.course_completed_on}</Typography>
                    <Box
                      sx={{
                        color: el.status != "Completed" && "red",
                      }}
                    >
                      {el.status}
                    </Box>
                    <button
                      className="GRViewCheckbox"
                      style={{
                        backgroundColor: "white",
                        width: "30px",
                        border: "none",
                      }}
                      onClick={() => {
                        setEdit(index);
                        const res1 = formatDate(el.course_started_on);
                        const res2 = formatDate(el.course_completed_on);
                        setEditableField({
                          empName: el.userName,
                          totalLessons: el.totalLessonCount,
                          lessonsComplete: el.lessonCompleted,
                          lastStep: el.course_last_step,
                          startDate: res1,
                          completeDate: res2,
                          status: el.status,
                        });
                        if (edit == index) {
                          let text = "Do you want to save it ?";
                          if (window.confirm(text) === true) {
                            alert("report changes");
                            console.log(editableField);
                            const toSendData = tableData;
                            toSendData[index].userName = editableField.empName;
                            toSendData[index].lessonCompleted =
                              editableField.lessonsComplete;

                            toSendData[index].course_completed_on =
                              editableField.completeDate;

                            toSendData[index].course_started_on =
                              editableField.startDate;
                            toSendData[index].course_last_step =
                              editableField.lastStep;
                            toSendData[index].status = editableField.status;

                            console.log(toSendData);
                            //api call
                            setEdit(null);
                            // getTableData();
                          } else {
                            setEdit(null);
                            // setEditableField({
                            //   empName: el.userName,
                            //   totalLessons: el.totalLessonCount,
                            //   lessonsComplete: el.lessonCompleted,
                            //   lastStep: el.course_last_step,
                            //   startDate: res1,
                            //   completeDate: res2,
                            //   status: el.status,
                            // });
                            alert("report not changed");
                          }
                          return;
                        }
                      }}
                      // disabled={index != edit}
                    >
                      {index == edit ? <SaveIcon /> : <EditIcon />}
                    </button>
                  </>
                )}
              </Box>
            ))}
        </div>
      </div>
    </section>
  );
};

export default CompanyReportView;
