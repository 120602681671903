import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/system";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { CustomTooltip } from "./lineDemo";

export default function LineGraph({ data, dbbvbarline, type }) {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    console.log(data);
    if (Array.isArray(data)) {
      setSeries(data);
    }
  }, [data]);

  return (
    <div
      style={{
        height: "300px",

        width: "100%",
        overflowX: "scroll",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // "@media (max-width: 780px)": {
        //     marginLeft: "0px",
        //   },
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {series?.length > 0 ? (
        <ResponsiveContainer width="100%" height={"100%"}>
          <LineChart>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="category"
              type="category"
              allowDuplicatedCategory={false}
            />
            <YAxis dataKey="value" />
            <Tooltip content={<CustomTooltip />} />

            <Legend />
            {series?.map((s) => (
              <Line
                dataKey="value"
                stroke={s.color}
                data={s.data}
                name={s.name}
                key={s.name}
                strokeWidth={4}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <img
          src={dbbvbarline}
          alt=""
          style={{
            width: "80%",
            objectFit: "cover",
            overflow: "hidden",
          }}
        />
      )}
    </div>
  );
}
