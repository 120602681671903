import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";

import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import CreateMcqQuestion from "./mcqQuestion";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";
import axios from "axios";
const Choosethecorrectimage = ({
  quizId,
  questionData,
  editQuestion,
  setfetchDataFromApi,
  fetchDataFromApi,
  flagForSeconstEdit,
}) => {
  const storage = getStorage();

  const createQuestion = httpsCallable(functions, "createQuestion");
  const addQuestionSetOrder = httpsCallable(functions, "addQuestionSetOrder");
  //addQuestionSetOrder
  // added by Himanshu
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });
  const [trueFalse, setTrueFalse] = useState(false);

  const [fillUpsAns, setFillUpsAns] = useState("");
  // rearrange
  const [canEditRearrange, setCanEditRearrange] = useState(true);
  const [rearrangeOptions, setRearrangeOptions] = useState([
    {
      text: "First",
      position: 1,
    },
    {
      text: "Second",
      position: 2,
    },
    {
      text: "Third",
      position: 3,
    },
    {
      text: "Fourth",
      position: 4,
    },
  ]);
  /* arr element example
    {
      text:"rearrange",
      position: 1
    }
  */

  const scrambleArr = (arr) => {
    //console.log(arr[0]);
    let res = Array.from(arr);
    return res.sort((a, b) => 0.5 - Math.random());
  };

  const [canEditMatchPair, setCanEditMatchPair] = useState(true);
  const [leftOptions, setLeftOptions] = useState(new Array(4).fill(""));
  const [rightOptions, setRightOptions] = useState([
    {
      text: "",
      match: 1,
    },
    {
      text: "",
      match: 2,
    },
    {
      text: "",
      match: 3,
    },
    {
      text: "",
      match: 4,
    },
  ]);

  // match words to image

  const [imageOptions, setImageOptions] = useState(
    new Array(4).fill("options")
  );
  const [imageFiles, setImageFiles] = useState([
    {
      base64: "",
      match: 1,
      url: "",
    },
    {
      base64: "",
      match: 2,
      url: "",
    },
    {
      base64: "",
      match: 3,
      url: "",
    },
    {
      base64: "",
      match: 4,
      url: "",
    },
  ]);
  const [disableImage, setDisableImage] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [imageMcq, setImageMcq] = useState([
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
  ]);
  const [imageMcqAnswer, setImageMcqAnswer] = useState(0);

  const handleImageMcqInput = (e, index) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image too large, compress and try again");
        e.target.value = "";
        return;
      }
      setImageMcq((prev) => {
        let newArr = Array.from(prev);
        newArr[index].base64 = reader.result;
        newArr[index].blobUrl = url;
        return newArr;
      });
      //console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    // setImageMcq((prev) => {
    //   let newArr = Array.from(prev);
    //   return newArr;
    // });
  };

  //

  useEffect(() => {
    if (!editQuestion) return;
    // console.log(questionData);
    setqus(questionData.data?.question);
    setQuestionName(questionData?.name);
    setQuestionId(questionData?.id);
    setqusInstruction(questionData.data?.questionInstruction);
    setImageMcqAnswer(Number(questionData.data?.correctImage));
    if (questionData.data.imageOptions) {
      let arr = [];
      for (let i = 0; i < questionData.data.imageOptions.length; i++) {
        arr[i] = { blobUrl: questionData.data.imageOptions[i] };

        //console.log(questionData.data.imageOptions[i]);

        if (i == 3) {
          setImageMcq(arr);
        }
      }
    }
  }, [flagForSeconstEdit]);

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    questionName: "",
    qus: "",
    qusInstruction: "",
    imageMcq: [
      { blobUrl: "" },
      { blobUrl: "" },
      { blobUrl: "" },
      { blobUrl: "" },
    ],
  });
  const formCheckFunction = () => {
    if (questionName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "question Name";
    }

    if (qus === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
      return "question ";
    }
    if (qusInstruction === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "error" }));
      return "questionc ";
    }

    if (imageMcq[0].blobUrl === "") {
      let op = [
        { blobUrl: "error" },
        { blobUrl: "" },
        { blobUrl: "" },
        { blobUrl: "" },
      ];
      setCheckErrorInForm((prev) => ({ ...prev, imageMcq: op }));
      return "question ";
    }
    if (imageMcq[1].blobUrl === "") {
      let op = [
        { blobUrl: "" },
        { blobUrl: "error" },
        { blobUrl: "" },
        { blobUrl: "" },
      ];
      setCheckErrorInForm((prev) => ({ ...prev, imageMcq: op }));
      return "question ";
    }
    if (imageMcq[2].blobUrl === "") {
      let op = [
        { blobUrl: "" },
        { blobUrl: "" },
        { blobUrl: "error" },
        { blobUrl: "" },
      ];
      setCheckErrorInForm((prev) => ({ ...prev, imageMcq: op }));
      return "question ";
    }
    if (imageMcq[3].blobUrl === "") {
      let op = [
        { blobUrl: "" },
        { blobUrl: "" },
        { blobUrl: "" },
        { blobUrl: "error" },
      ];
      setCheckErrorInForm((prev) => ({ ...prev, imageMcq: op }));
      return "question ";
    }

    return "alliswell";
  };

  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("Choose the correct image");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("Option 1");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    let dataex = JSON.stringify({
      questionSetId: quizId,
      questions: orderList,
      edit: true,
    });
    let configEx = {
      method: "patch",

      url: `${IP}/microCourseBuilder/questionSet`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx);
  }

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();

    let nsn = questionName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }

    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "Choose the correct image") {
      let imageOptionFromUser = imageMcq.map((val) =>
        val.base64 ? val.base64 : val.blobUrl
      );
      let questionDatafromUser = {
        correctImage: imageMcqAnswer,
        imageOptions: imageOptionFromUser,
        question: qus,
        questionInstruction: qusInstruction,
        word: qus2.word,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 2;
    }
    const docData = {
      questionName: questionName,
      questionId: questionId,
      associatedQuiz: quizId,
      questionSet: quizId,
      data: managequestionData,
      questionType: qustype,
      created: Timestamp.now(),
    };
    //console.log(docData);

    let dataex = JSON.stringify({
      questionId: questionId,
      questionName: questionName,
      questionType: qustype,
      created: new Date().toString(),
      data: managequestionData,
      quizId: quizId,
    });
    var configEx = {
      method: "post",

      url: `${IP}/microCourseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx).then((res) => {
      setfetchDataFromApi(fetchDataFromApi + 1);
      alert("data saved successfully");
    });

    document.getElementById(quizId + "question-form").reset();
    document.getElementById(quizId + "questionName").value = "";
    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };

  function edit(lesson) {
    toggleQuestionForm();
  }

  function resetData() {
    setQuestionName("");
    setQuestionId("");
    setqustype("Choose the correct image");
    setqus("");
    setqusInstruction("");

    setImageOptions(new Array(4).fill("options"));
    setDisableImage(false);
    setImageFiles([
      {
        base64: "",
        match: 1,
        url: "",
      },
      {
        base64: "",
        match: 2,
        url: "",
      },
      {
        base64: "",
        match: 3,
        url: "",
      },
      {
        base64: "",
        match: 4,
        url: "",
      },
    ]);
    setImageMcq([
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
    ]);
  }

  return (
    <section className="quizForm-container">
      <form id={quizId + "question-form"} onSubmit={submit}>
        <div className="quizForm-mainForm-infoRow">
          <p className="quizForm-mainForm-label">Question Name :</p>
          <input
            type="text"
            className="quizForm-mainForm-inputBar"
            value={questionName}
            style={{
              border: `${
                checkErrorInForm.questionName == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            id={quizId + "questionName"}
            onChange={(e) => {
              setQuestionName(e.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, questionName: "" }));

              let value = e.target.value.replaceAll(" ", "");
              let time = new Date().getTime();
              //  setQuestionId(value + "_" + time);
              if (!editQuestion) {
                setQuestionId(value + "_" + time);
              }
            }}
          />

          {checkErrorInForm.questionName == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Name
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Question : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus}
            style={{
              border: `${
                checkErrorInForm.qus == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qus: "" }));
            }}
          ></textarea>

          {checkErrorInForm.qus == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Instruction : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qusInstruction}
            style={{
              border: `${
                checkErrorInForm.qusInstruction == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqusInstruction(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qusInstruction == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Instruction
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Correct Image: </label>
          <select
            className="quizForm-mainForm-select"
            value={imageMcqAnswer}
            onChange={(e) => setImageMcqAnswer(e.target.value)}
          >
            <option value={0}>Image 1</option>
            <option value={1}>Image 2</option>
            <option value={2}>Image 3</option>
            <option value={3}>Image 4</option>
          </select>
        </div>
        {imageMcq.map((val, index) => (
          <div className="quizForm-chooseImage-infoRow">
            <label className="quizForm-chooseImage-label">
              Image {index + 1}
            </label>
            <input
              type={"file"}
              accept="image/png, image/gif, image/jpeg"
              style={{
                border: `${
                  checkErrorInForm.imageMcq[index].blobUrl == "error"
                    ? "2px solid red"
                    : "1px solid #d7d7d7"
                }`,
              }}
              onChange={(e) => {
                handleImageMcqInput(e, index);
                setCheckErrorInForm((prev) => ({
                  ...prev,
                  imageMcq: ["", "", "", ""],
                }));
              }}
            />
            {checkErrorInForm.imageMcq[index].blobUrl == "error" && (
              <span
                style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
              >
                select image
              </span>
            )}
            <img
              className="quizForm-chooseImage-image"
              src={
                val.blobUrl != ""
                  ? val.blobUrl
                  : "https://media.istockphoto.com/vectors/thumbnail-image-vector-graphic-vector-id1147544807?k=20&m=1147544807&s=612x612&w=0&h=pBhz1dkwsCMq37Udtp9sfxbjaMl27JUapoyYpQm0anc="
              }
            />
          </div>
        ))}
      </form>
      {formCheck && <div> Fill all the Required fields.</div>}
      <div className="quizForm-mainForm-infoRow">
        <button
          className="quizFrom-button"
          style={{ marginLeft: "auto" }}
          onClick={storedDataOnFirebase}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default Choosethecorrectimage;
