import { Box } from "@mui/system";
import ResponsiveDrawer from "../Sidebar/HrSideBar";
import HrTopNavbar from "../Sidebar/HrTopNavbar";
import img from "../devlopment.png";
import { getCookie } from "../../Cookies";
import { Button, MenuItem, Select } from "@mui/material";
import MoreEventPage from "./MoreOption";
import { useContext, useEffect, useState } from "react";
import paly from "./play.svg";
import Download from "./Download.svg";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { IP } from "../../baseUrlAndConfig";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import HrloadingScreen from "../LodingHrscreen";
import MobileTopBar from "../Sidebar/MobaileTopBar";

const EventView = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [color, setcolor] = useState([
    { cl: "#AFFB63" },
    { cl: "#7EC1FF" },
    { cl: "#FE9CBF" },
  ]);
  const [eventData, setEventData] = useState({});
  const location = useLocation();

  const [loadingScreen, setLoadingScreen] = useState(false);
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    // let newobj =
    //   rolesAndPermission?.Calender?.componentPermission.includes(
    //     "DEI Calender"
    //   );
    // // console.log(newobj.HRDI);
    // if (!newobj) {
    //   navigate(-1);
    //   return <></>;
    // }

    if (!rolesAndPermission) {
      setLoadingScreen(true);
    } else {
      setLoadingScreen(false);

      let newobj =
        rolesAndPermission?.Calender?.componentPermission.includes(
          "DEI Calender"
        );
      // console.log(newobj.HRDI);
      if (!newobj) {
        navigate(-1);
        return <></>;
      }
    }
  }, [rolesAndPermission]);
  useEffect(() => {
    console.log(location.state);

    if (location.state) {
      console.log(location.state);
      setEventData(location.state);

      let configrecrutci = {
        method: "get",

        url: `${IP}/event/item`,
        params: { eventId: location.state.eventId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "dataregionf",
      };
      axios(configrecrutci)
        .then((res) => {
          console.log(res.data);
          // setEventList(res.data);9**
          setData(res.data);
          // setUserCourses([...res.data]);
          // const temp = res.data.find((course) => course.courseId == courseId);
          // console.log(temp);
          // setUserCourses(temp);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, []);

  return (
    <div
      className="hrmainbox"
      style={{
        background: "#F3F1FF",
        height: "100vh",
      }}
    >
      <ResponsiveDrawer sidebar={"MyLearning"} />

      <HrloadingScreen open={loadingScreen} />

      <Box sx={{ width: "100%" }}>
        <MobileTopBar title={"DEI-Calendar"} />
        <HrTopNavbar title={eventData?.name}>
          <Box>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              sx={{ color: "#6846C7", border: "1px solid #6846C7" }}
            >
              Back
            </Button>
          </Box>
        </HrTopNavbar>

        <Box sx={{ width: "100%", px: "15px" }}>
          <Box
            sx={{
              marginTop: "48px",
              color: "#666666",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {eventData?.description?.map((el) => (
              <Box> {el} </Box>
            ))}
          </Box>

          <Box className="DEIEventViewGrid">
            {/* */}
            {data?.map((el, index) => (
              <Box
                onClick={() => {
                  window.open(el.filetype == "file" ? el.file : el.link);
                }}
                sx={{
                  display: "grid",
                  gridGap: "10px",
                  borderRadius: "24px",
                  cursor: "pointer",
                  gridTemplateColumns: "4fr 1fr",
                  background: `${color[index].cl}`,
                  height: "auto",
                  minHeight: "175px",
                  padding: "8px",
                }}
              >
                {console.log(el)}
                <Box>
                  <Box
                    sx={{
                      color: "#000000",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      p: "16px",
                    }}
                  >
                    {el.topic}
                  </Box>
                  <Box
                    sx={{
                      color: "#666666",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "400",
                      marginTop: "13px",
                      marginLeft: "16px",
                      textOverflow: "ellipsis",
                      // whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {" "}
                    {el.description}
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    onClick={() => {
                      window.open(el.filetype == "file" ? el.file : el.link);
                    }}
                    sx={{
                      color: "#6846C7",
                      borderRadius: "16px",
                      width: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "48px",
                      marginTop: "90px",
                      marginLeft: "auto",
                      background: "#FFFFFF",
                    }}
                  >
                    {el.filetype == "file" ? (
                      <img src={Download} alt="" />
                    ) : (
                      <img src={paly} alt="" />
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default EventView;
