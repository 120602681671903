import { MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import axios from "axios";
import { httpsCallable } from "firebase/functions";
import Papa from "papaparse";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getCookie } from "../../../../Cookies";
import { functions } from "../../../../Firebase";
import { IP } from "../../../../baseUrlAndConfig";
import { DrawerContext } from "../../../../utilities/context/DrawerProvider";
const BulkDialog = ({ showBulk, handleClose }) => {
  const bulkUserCreation = httpsCallable(functions, "bulkUserCreation");
  const [showHistory, setShowHistory] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [userCompany, setUserCompany] = useState([]);
  const [actionType, setActionType] = useState("");
  const [inviteType, setInviteType] = useState("");
  const [groupId, setGroupId] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    companyId: "",
    courseId: "",
  });
  const [courseData, setCourseData] = useState([]);
  const [groupData, setGroupData] = useState([]);

  const goToHome = () => navigate("/");
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);

  const getCourseData = (companyId) => {
    setCourseData([]);

    let dataCom = JSON.stringify({
      companyId: companyId,
    });
    let configCom = {
      method: "post",

      url: `${IP}/company/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      console.log(res.data);
      setCourseData(res.data.course);
    });
  };

  const getGroupData = (companyId) => {
    setGroupData([]);

    let dataCom = JSON.stringify({
      companyId: companyId,
    });
    let configCom = {
      method: "get",
      params: { companyId: companyId },
      url: `${IP}/company/group`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      console.log(res.data);
      setGroupData(res.data.groupData);
    });
  };

  useEffect(() => {
    let dataCom = JSON.stringify({});
    let configCom = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      if (Array.isArray(res.data)) {
        setUserCompany(res.data);
      }
    });

    // getUserList();
  }, []);

  useEffect(() => {
    setJsonData([]);
  }, [showBulk]);
  const uploadBulkUser = () => {
    if (jsonData.length == 0) {
      toast.warning("Upload data");
      setLoading(false);
      return;
    }
    setLoading(true);
    if (actionType == "createBulkUser") {
      let dataex = JSON.stringify({
        // csvArray: jsonData,
        companyId: userData.companyId,
        groupId: groupId,
        users: jsonData,
      });
      let configExInt = {
        method: "post",

        url: `${IP}/adminUserProfile/bulkUser/${userData.companyId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          setLoading(false);
          setJsonData(res.data);
          //console.log(res.data);
        })
        .catch((er) => {
          toast.error("something went wrong");
          setLoading(false);
        });
    }

    if (actionType == "sendBulkInvite") {
      let dataex = JSON.stringify({
        // csvArray: jsonData,
        companyId: userData.companyId,
        groupId: groupId,
        courseId: userData.courseId,
        courseName: courseData.filter(
          (el) => el.courseId == userData.courseId
        )[0]?.courseName,
        dueDate: courseData.filter((el) => el.courseId == userData.courseId)[0]
          ?.dueDate,
        type: inviteType,
        users: jsonData,
      });
      let configExInt = {
        method: "post",

        url: `${IP}/adminUserProfile/invite/${userData.companyId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          setLoading(false);
          setJsonData(res.data);
          //console.log(res.data);
        })
        .catch((er) => {
          toast.error("something went wrong");
          setLoading(false);
        });
    }

    if (actionType == "deleteBulkUser") {
      let dataex = JSON.stringify({
        // csvArray: jsonData,
        companyId: userData.companyId,

        users: jsonData,
      });
      let configExInt = {
        method: "delete",

        url: `${IP}/adminUserProfile/bulkUser/${userData.companyId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          setLoading(false);
          setJsonData(res.data);
          //console.log(res.data);
        })
        .catch((er) => {
          toast.error("something went wrong");
          setLoading(false);
        });
    }

    if (actionType == "completeCourseBulk") {
      let dataex = JSON.stringify({
        // csvArray: jsonData,
        companyId: userData.companyId,
        courseId: userData.courseId,
        courseType: "course",
        groupId: groupId,

        users: jsonData,
      });
      let configExInt = {
        method: "post",
        // courseCompletionThroughCsv
        url: `${IP}/adminUserProfile/courseCompletionThroughCsv`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          setLoading(false);
          setJsonData(res.data);
          //console.log(res.data);
        })
        .catch((er) => {
          toast.error(er.error);
          setLoading(false);
        });
    }
  };
  const handleFile = async (e) => {
    const file = e.target.files[0];
    //console.log(file);
    const name = file.name;
    if (name.split(".")[1] != "csv") {
      alert("Not proper format");
      e.target.value = "";
      return;
    }
    let temp = [];
    let text = await file.text();
    const parsedData = Papa.parse(text);
    if (parsedData.data.length > 101) {
      toast.error("can not upload more then 100 user at a time");
      e.target.value = "";
      return;
    }
    //console.log(text, parsedData.data);
    for (let i = 1; i < parsedData.data.length; i++) {
      //console.log(i)
      let data = {};
      parsedData.data[0].forEach((item, id) => {
        data[item] = parsedData.data[i][id]?.trim();
      });
      // if(parsedData.data.length)
      temp.push({ ...data, status: "TBD", role: "USER" }); // default role by bulk add is user
    }
    //console.log(temp);
    setJsonData(temp);
  };

  return (
    <Dialog
      open={showBulk}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ textAlign: "center" }}>{"Bulk task"}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">Bulk User Create</DialogContentText> */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "40%",
          }}
        >
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {" "}
            <Box> Select company</Box>
            <Select
              // value={userData.companyId}
              onChange={(e) => {
                // getCourseData(e.target.value);
                getGroupData(e.target.value);
                setUserData((prev) => ({
                  ...prev,
                  companyId: e.target.value,
                }));
              }}
              sx={{
                width: "300px",
                height: "30px",
                mt: "10px",
              }}
            >
              <MenuItem> select</MenuItem>
              {userCompany?.map((el) => (
                <MenuItem value={el.companyId}> {el.name}</MenuItem>
              ))}
            </Select>{" "}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "40%",
          }}
        >
          <div
            style={{
              marginTop: "20px",
            }}
          >
            {" "}
            <Box> Select bulk action</Box>
            <Select
              value={actionType}
              onChange={(e) => {
                setActionType(e.target.value);
              }}
              sx={{
                width: "300px",
                height: "30px",
                mt: "10px",
              }}
            >
              <MenuItem> select</MenuItem>
              <MenuItem value={"createBulkUser"}> Create bulk user</MenuItem>
              <MenuItem value={"sendBulkInvite"}> Send bulk invite</MenuItem>
              <MenuItem value={"deleteBulkUser"}> Delete bulk user</MenuItem>
              <MenuItem value={"completeCourseBulk"}>
                {" "}
                Complete course bulk
              </MenuItem>
            </Select>{" "}
          </div>
        </div>

        {actionType == "createBulkUser" && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "40%",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                {" "}
                <Box> Select group</Box>
                <Select
                  value={groupId}
                  onChange={(e) => {
                    setGroupId(e.target.value);
                  }}
                  sx={{
                    width: "300px",
                    height: "30px",
                    mt: "10px",
                  }}
                >
                  <MenuItem> select group</MenuItem>
                  {groupData?.map((el) => (
                    <MenuItem value={el?.groupId}> {el?.groupName}</MenuItem>
                  ))}
                </Select>{" "}
              </div>
            </div>
          </div>
        )}

        {actionType == "sendBulkInvite" && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "40%",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                {" "}
                <Box> Select invite type</Box>
                <Select
                  value={inviteType}
                  onChange={(e) => {
                    setInviteType(e.target.value);
                  }}
                  sx={{
                    width: "300px",
                    height: "30px",
                    mt: "10px",
                  }}
                >
                  <MenuItem value="0"> DEI SaaS Tool invite - HR</MenuItem>

                  <MenuItem value="1"> Course invite</MenuItem>
                  <MenuItem value="2"> Needstreet Course invite </MenuItem>
                  <MenuItem value="3"> Coc Course Invite </MenuItem>
                  <MenuItem value="4"> Gainsight Course Invite </MenuItem>
                  <MenuItem value="5"> UC Values and Policies Invite </MenuItem>
                  <MenuItem value="6"> UC Handbook for Senior </MenuItem>
                  <MenuItem value="7"> Gainsight Refresher Training </MenuItem>
                </Select>{" "}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "40%",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                {" "}
                <Box> Select group</Box>
                <Select
                  value={groupId}
                  onChange={(e) => {
                    let data = [];
                    setCourseData(
                      groupData.filter((el) => el.groupId == e.target.value)[0]
                        ?.course
                    );
                    setGroupId(e.target.value);
                  }}
                  sx={{
                    width: "300px",
                    height: "30px",
                    mt: "10px",
                  }}
                >
                  <MenuItem> select group</MenuItem>
                  {groupData?.map((el) => (
                    <MenuItem value={el?.groupId}> {el?.groupName}</MenuItem>
                  ))}
                </Select>{" "}
              </div>
            </div>

            {inviteType == "1" && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "40%",
                  }}
                >
                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    <Box> Select course</Box>
                    <Select
                      value={userData.courseId}
                      onChange={(e) => {
                        // getCourseData(e.target.value);
                        setUserData((prev) => ({
                          ...prev,
                          courseId: e.target.value,
                        }));
                      }}
                      sx={{
                        width: "300px",
                        height: "30px",
                        mt: "10px",
                      }}
                    >
                      <MenuItem> select</MenuItem>

                      {courseData?.map((el) => (
                        <MenuItem value={el.courseId}>
                          {" "}
                          {el.courseName}
                        </MenuItem>
                      ))}
                    </Select>{" "}
                  </div>
                </div>
              </>
            )}
            {(inviteType == "2" ||
              inviteType == "3" ||
              inviteType == "4" ||
              inviteType == "5" ||
              inviteType == "6") && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "40%",
                  }}
                >
                  <div
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    <Box> Select course</Box>
                    <Select
                      onChange={(e) => {
                        // getCourseData(e.target.value);
                        setUserData((prev) => ({
                          ...prev,
                          courseId: e.target.value,
                        }));
                      }}
                      sx={{
                        width: "300px",
                        height: "30px",
                        mt: "10px",
                      }}
                    >
                      <MenuItem> select</MenuItem>

                      {courseData?.map((el) => (
                        <MenuItem value={el.courseId}>
                          {" "}
                          {el.courseName}
                        </MenuItem>
                      ))}
                    </Select>{" "}
                  </div>
                </div>
              </>
            )}
          </div>
        )}

        {actionType == "completeCourseBulk" && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "40%",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                {" "}
                <Box> Select group</Box>
                <Select
                  value={groupId}
                  onChange={(e) => {
                    let data = [];
                    setCourseData(
                      groupData.filter((el) => el.groupId == e.target.value)[0]
                        ?.course
                    );
                    setGroupId(e.target.value);
                  }}
                  sx={{
                    width: "300px",
                    height: "30px",
                    mt: "10px",
                  }}
                >
                  <MenuItem> select group</MenuItem>
                  {groupData?.map((el) => (
                    <MenuItem value={el?.groupId}> {el?.groupName}</MenuItem>
                  ))}
                </Select>{" "}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "40%",
              }}
            >
              <div
                style={{
                  marginTop: "20px",
                }}
              >
                {" "}
                <Box> Select course</Box>
                <Select
                  value={userData.courseId}
                  onChange={(e) => {
                    // getCourseData(e.target.value);
                    setUserData((prev) => ({
                      ...prev,
                      courseId: e.target.value,
                    }));
                  }}
                  sx={{
                    width: "300px",
                    height: "30px",
                    mt: "10px",
                  }}
                >
                  <MenuItem> select</MenuItem>

                  {courseData?.map((el) => (
                    <MenuItem value={el.courseId}> {el.courseName}</MenuItem>
                  ))}
                </Select>{" "}
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <p>Upload CSV File</p> <br></br>
          <input type={"file"} accept={"text/csv"} onChange={handleFile} />
        </div>
        {actionType == "completeCourseBulk" ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {jsonData?.length != 0 && (
              <div className="bulkUser-row">
                <div>{"Name"}</div>
                <div>{"Email"}</div>
                <div>{"Start date"}</div>

                <div>{"End date"}</div>

                <div>{"Status"}</div>
              </div>
            )}
            {jsonData.map((item) => {
              return (
                <div className="bulkUser-row">
                  <div>{item.name}</div>
                  <div>{item.email}</div>
                  <div>{item.courseStartDate}</div>

                  <div>{item.courseEndDate}</div>

                  <div>{item?.status}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {jsonData?.length != 0 && (
              <div className="bulkUser-row">
                <div>{"Name"}</div>
                <div>{"Email"}</div>
                <div>{"Department"}</div>

                <div>{"JobTitle"}</div>

                <div>{"Status"}</div>
              </div>
            )}
            {jsonData.map((item) => {
              return (
                <div
                  className="bulkUser-row"
                  style={{
                    gap: "20px",
                  }}
                >
                  {/* {JSON.stringify(item)} */}
                  <div>{item.name}</div>
                  <div>{item.email}</div>
                  <div>{item.departmentId}</div>

                  <div>{item.jobTitle}</div>

                  <div>{item?.status}</div>
                </div>
              );
            })}
          </div>
        )}

        <History
          showHistory={showHistory}
          handleClose={() => setShowHistory(false)}
          data={{
            ...userData,
            groupId: groupId,
            actionType: actionType,
            inviteType: inviteType,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            window.open(
              "https://firebasestorage.googleapis.com/v0/b/serein-demo-lms-gcp.appspot.com/o/csv%20for%20course%20completion.csv?alt=media&token=ad8d1fd6-de11-4161-9f3c-26a19c0620df",
              "_blank"
            );
          }}
        >
          sample_csv for course completion
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            window.open(
              "https://firebasestorage.googleapis.com/v0/b/serein-demo-lms-gcp.appspot.com/o/users%2FBook2.csv?alt=media&token=486ef499-cda1-4864-b08f-08f6d2f69137",
              "_blank"
            );
          }}
        >
          sample_csv for other
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            if (userData.companyId == "") {
              toast.warning("select all fields ");
              return;
            }
            setShowHistory(true);
          }}
        >
          {"Show History"}
        </Button>
        <Button disabled={loading} onClick={uploadBulkUser}>
          {loading ? "Loading..." : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>

    // <Modal show={showDialog}>
    //   <Modal.Header>
    //     <Modal.Title>Warning</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <b>There are some changes?</b>
    //     <br /> Are you sure you want to navigate!!!!
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="primary" onClick={cancelNavigation}>
    //       No
    //     </Button>
    //     <Button variant="danger" onClick={confirmNavigation}>
    //       Yes
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  );
};
export default BulkDialog;

const History = ({ showHistory, handleClose, data }) => {
  const fetchBulkHistory = httpsCallable(functions, "fetchBulkHistory");
  const [histortData, setHistoryData] = useState(null);
  const [loading, setLoading] = useState(false);

  const styleObj = { display: "grid", gridTemplateColumns: "2fr 1fr 1fr" };
  useEffect(() => {
    if (!showHistory) return;
    setLoading(true);
    let configExInt = {
      method: "get",
      params: { companyId: data.companyId, actionType: data.actionType },
      url: `${IP}/adminUserProfile/bulkHistory`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify(data),
    };
    axios(configExInt)
      .then((res) => {
        console.log(res.data);
        setHistoryData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showHistory]);
  return (
    <Dialog
      open={showHistory}
      onClose={() => {
        // if (loading) return;
        handleClose();
      }}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {"Bulk User Creation History"}
      </DialogTitle>
      <DialogContent>
        {loading && "loading..."}
        {!loading && (
          <div style={styleObj}>
            <div>Created: {histortData?.created}</div>
            <div>failed: {histortData?.failed}</div>

            <div>Errors</div>
          </div>
        )}
        {!loading && (
          <div style={styleObj}>
            <div>Date</div>
            <div>Created</div>
            <div>Errors</div>
          </div>
        )}
        {!loading &&
          histortData?.data?.map((ele) => {
            let createdOn = new Date(ele.createdOn?._seconds * 1000)
              .toISOString()
              .split("T")[0];
            return (
              <div style={styleObj}>
                <div>{createdOn}</div>
                <div>{ele.created}</div>
                <div>{ele.failed}</div>
              </div>
            );
          })}
      </DialogContent>
    </Dialog>
  );
};
