import Checkbox from "@mui/material/Checkbox";
import React, { createRef, useContext, useEffect, useState } from "react";
import { CustomAudioPlayer } from "./CustomAudioPlayer";
import { ItemContext } from "../Context/ItemContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";
import screenfull from "screenfull";
import { getCookie } from "../../../../Cookies";
import { Button } from "@mui/material";
const HtmlItem = ({ url, setCanNavigate, itemId }) => {
  const [completed, setCompleted] = useState(false);
  const { courseId, isLinear } = useContext(ItemContext);
  const getItemProgress = httpsCallable(functions, "getItemProgress");
  const setHTMLProgress = httpsCallable(functions, "setHTMLProgress");

  useEffect(() => {
    let configrecrut = {
      method: "get",

      url: `${IP}/userMicroItemDetails/item`,
      params: { courseId: courseId, itemId: itemId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
    });

    return () => {};
  }, []);

  useEffect(() => {
    //console.log(completed);
    if (completed) {
      let configrecrut = {
        method: "post",

        url: `${IP}/userCourseContent/html`,
        // params: { courseId: courseId, itemId: itemId },
        data: JSON.stringify({
          courseId: courseId,
          itemId: itemId,
          lastVisited: new Date().toString(),
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
      };
      axios(configrecrut).then((res) => {
        console.log(res);
      });

      // setHTMLProgress({
      //   courseId: courseId,
      //   itemId: itemId,
      //   lastVisited: new Date().toString(),
      // });
    }
    setCanNavigate(completed || !isLinear);
  }, [completed]);

  const fullscreen = () => {
    // screenfull.request(findDOMNode("body"));

    // document.getElementById("testdomel").style.height = "1000px";
    // ds.style.width = "800px";
    // playerRef.current
    screenfull.request(
      // playerRef
      document.getElementById("testdomel")
    );
  };

  return (
    <div className="htmlItem-container">
      <iframe
        src={url}
        id="testdomel"
        className="htmlItem-iframe"
        allowfullscreen={true}
      >
        Something
      </iframe>
      <div className="markCompleted">
        <Button
          sx={{
            width: "120px",
            hight: "40px",
            // border: "1px solid #F3F1FF",
            backgroundColor: "#E1F566",
          }}
          onClick={() => {
            fullscreen();
          }}
        >
          {" "}
          Full Screen{" "}
        </Button>
        <Checkbox
          aria-label="Mark Completed"
          onChange={(e) => setCompleted(e.target.checked)}
          checked={completed}
          sx={{
            color: "#007c84",
            "&.Mui-checked": {
              color: "#007C84",
            },
          }}
        />
        Mark Completed
      </div>
      {/* <CustomAudioPlayer
        url={
          "https://firebasestorage.googleapis.com/v0/b/alghaithdev.appspot.com/o/download?alt=media&token=7ac7a6ad-17a7-4d0b-b85a-a7033c067203"
        }
      /> */}
    </div>
  );
};
export default HtmlItem;
