import React, { useContext } from "react";
import { useState, setState, useEffect } from "react";
import { setCookie, getCookie } from "../../../Cookies";
import { db, functions } from "../../../Firebase";
import {
  useNavigate,
  Link,
  Navigate,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import "../StyleAdminComponents/GroupList.css";
import { render } from "@testing-library/react";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import { t } from "i18next";
import "./Groups.css";
import { useSelector } from "react-redux";
import { httpsCallable } from "firebase/functions";
import { ValidateAuth } from "../../../AuthToken";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import { toast } from "react-toastify";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
function Groups() {
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const [groups, setGroups] = useState(null);
  const [showLoading, setLoading] = useState(true);
  const [tempLoading, setTemploading] = useState("test");
  const [companyId, setCompanyId] = useState("");
  const [arrLength, setArrLength] = useState(0);
  const [userCompany, setUserCompany] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [storeUserLastDataList, setStoreUserLastDataList] = useState({});
  const [curentUrl, setCurrentUrl] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [extraArrForGroupUser, setExtraArrForGroupUser] = useState([]);
  const [archiveDataArr, setArchivedDataArr] = useState([]);
  const [buttonTogalForChangeState, setButtonTogalForarchived] = useState(true);
  const [handelUseEffectTogal, setHandelUseEffectTogal] = useState(true);
  const [deleteDialog,setDeleteDialog] = useState(false);
  const [optionYes,setOptionYes] = useState(false);
  let stateDataFromGroupEdit = useLocation();
  // if(stateDataFromGroupEdit.state.formGroup==true)
  // useEffect(()=>{

  // },[stateDataFromGroupEdit.state])

  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);

  const goToEditPage = (state, e, id) => {
    if (e.target.value == "archive") {
      setTemploading(id);

      let dataex = JSON.stringify({
        groupId: state.groupId,
      });
      let configExInt = {
        method: "patch",

        url: `${IP}/group/archive`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          //console.log(res);
          setTemploading("test");
          let archivedData = { ...state, archived: true };

          let dataddd = extraArrForGroupUser.filter((el, index) => index != id);
          setGroups(dataddd);

          setArchivedDataArr((prev) => prev.concat(archivedData));
          setExtraArrForGroupUser(dataddd);

          setCurrentPage(0);
          setArrLength(dataddd.length);
          // setHandelUseEffectTogal(!handelUseEffectTogal);
        })
        .catch((err) => {
          setTemploading("test");
        });

      return;
    }
    // if(e.target.value == 'reset'){
    //   alert('do something')
    // }

    if (e.target.value == "unarchive") {
      setTemploading(id);

      let dataex = JSON.stringify({
        groupId: state.groupId,
      });
      let configExInt = {
        method: "post",

        url: `${IP}/group/archive`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          //console.log(res);
          setTemploading("test");
          // setHandelUseEffectTogal(!handelUseEffectTogal);
          let archivedData = { ...state, archived: false };

          let dataddd = archiveDataArr.filter((el, index) => index != id);
          setGroups(dataddd);

          setExtraArrForGroupUser((prev) => prev.concat(archivedData));
          setArchivedDataArr(dataddd);

          setCurrentPage(0);
          setArrLength(dataddd.length);

          alert(res.data.message);
        })
        .catch((err) => {
          setTemploading("test");
        });

      return;
    }
    console.log(e.target.value);

    // return;
    if (e.target.value == "delete") {
      if(window.confirm('do you want to delete it?')){
      setLoading(true)
      let dataex = JSON.stringify({
        groupId: state.groupId,
        companyId: state.companyId,
        isReset: false,
      });
      let configExInt = {
        method: "delete",

        url: `${IP}/group`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt).then((res) => {
        setDefault();
        setDeleteDialog(false);
        alert("deleted");
        setLoading(false)
      });
      }
      setLoading(false);
      return;

    }
    if (e.target.value == "reset") {
       setLoading(true)
      if(window.confirm('do you want to reset this group?')){
      let dataex = JSON.stringify({
        groupId: state.groupId,
        companyId: state.companyId,
        isReset: true,
      });
      let configExInt = {
        method: "delete",

        url: `${IP}/group`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt).then((res) => {
        setLoading(false)
        setDefault();
        alert("group reset successfully");
      });
      }
      setLoading(false);
      return;
    }
    console.log(state);
    navigate("/admin/groupEdits", { state: { ...state } });
  };

  const handleOnChange = (e) => {
    var url = `/admin/groupEdits/${e}`;
    if (e) {
      //console.log(url);
      navigate(url);
    }
  };

  useEffect(() => {
    let dataCom = JSON.stringify({});
    let configCom = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      if (Array.isArray(res.data)) {
        setUserCompany(res.data);
      }
    });

    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${IP}/group?limit=${10}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt).then((result) => {
      if (result.data?.error) {
        //console.log(result.data?.error);
        setGroups([]);
        setExtraArrForGroupUser([]);
        setButtonTogalForarchived(true);
        setArrLength(0);
        setLoading(false);
        return;
      }
      setCurrentUrl(`${IP}/group`);
      setStoreUserLastDataList(result.data);

      setButtonTogalForarchived(true);
      setExtraArrForGroupUser(
        result.data?.data
          ?.map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true)
      );
      setGroups(
        result.data?.data
          ?.map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true)
      );
      setArchivedDataArr(
        result.data?.data
          ?.map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived == true)
      );

      setCurrentPage(result.data.c_page);
      setArrLength(result.data.t_count);
      setTotalPage(result.data.t_page);
      setPageSize(10);
      setLoading(false);
    });
  }, [handelUseEffectTogal]);

  const setDefault = () => {
    setHandelUseEffectTogal(!handelUseEffectTogal);
  };
  // const archiveRows = (e) => {
  //   e.preventDefault();
  //   var inputs = document.querySelectorAll(".row_select");

  //   for (var i = 0; i < inputs.length; i++) {
  //     if (inputs[i].checked) //console.log(inputs[i].value);
  //   }
  // };

  function createdDate(createdOn) {
    let displayDate = Timestamp.fromMillis(createdOn._seconds * 1000)
      .toDate()
      .toDateString();
    return displayDate;
  }

  const clickhandler = (url) => {
    // if (i < 0) return;
    // if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    setLoading(true);
    setCurrentUrl(`${url}`);

    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",
      
      url: `${url}&limit=${pageSize}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    
    axios(configExInt)
      .then((res) => {
        setStoreUserLastDataList(res.data);
        setCurrentUrl(`${url}`);
        setLoading(false);

        setGroups(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );
        setCurrentPage(res.data.c_page);
        setArrLength(res.data.t_count);
        setTotalPage(res.data.t_page);
      })
      .catch((err) => {
        setLoading(false);
        toast.warning("something went wrong");
      });
  };
  const buttonForArchivedFun = (type) => {
    if (type == "user") {
      setGroups(extraArrForGroupUser);
      setCurrentPage(0);
      setArrLength(extraArrForGroupUser.length);
    } else if (type == "archive") {
      setGroups(archiveDataArr);
      setCurrentPage(0);
      setArrLength(archiveDataArr.length);
    }
    setButtonTogalForarchived(!buttonTogalForChangeState);
  };

  const changePageLimit = (pageLimit) => {
    setLoading(true);

    let URL = curentUrl;

    if (curentUrl.split("?")[1]) {
      URL = `${URL}&limit=${pageLimit}`;
    } else {
      URL = `${URL}?limit=${pageLimit}`;
    }
    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setStoreUserLastDataList(res.data);
        setLoading(false);

        setGroups(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );
        setCurrentPage(res.data.c_page);
        setArrLength(res.data.t_count);
        setTotalPage(res.data.t_page);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.warning("something went wrong");
      });
  };
  const searchGroupForCompany = (value) => {
    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",

      url: `${IP}/group?companyId=${value}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((res) => {
        setStoreUserLastDataList(res.data);
        setLoading(false);

        setGroups(
          res.data?.data
            ?.map((item) => ({ ...item, selected: false }))
            .filter((item) => item.archived != true)
        );
        setCurrentPage(res.data.c_page);
        setArrLength(res.data.t_count);
        setTotalPage(res.data.t_page);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.warning("something went wrong");
      });
  };
  return (
    <div className="admin-groups">
      <Navbar page={t("Groups")} />
      <div
        className="admin-groups-main"
        style={{ gridTemplateColumns: "19vw 1fr" }}
      >
        <Sidebar page={"groups"} hidepage={false} />
        <div className="admin-overdue">
          <div
            class="admin-dashboard-main-top-last"
            style={{
              height: " 3vh",
              width: "10vh",
              marginTop: "1vw",
              marginLeft: "67.5vw",
            }}
          >
            {buttonTogalForChangeState ? (
              <></>
              // <button
              //   style={{
              //     width: "10vw",

              //     background: `${
              //       clrs.CourseListBtn
              //         ? clrs.CourseListBtn
              //         : "hwb(224deg 78% 3%)"
              //     }`,
              //   }}
              //   onClick={() => {
              //     buttonForArchivedFun("archive");
              //   }}
              // >
              //   <div></div>
              //   <div style={{ marginLeft: "-2vw" }}>{t("Show Archive ")}</div>
              // </button>
            ) : (
              <button
                style={{
                  width: "10vw",

                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={() => {
                  buttonForArchivedFun("user");
                }}
              >
                <div></div>
                <div style={{ marginLeft: "-2vw" }}>{t("Show Groups")}</div>
              </button>
            )}
          </div>
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
            className="admin-dashboard-main-mid"
          >
            <div className="admin-dashboard-main-mid-item">
              <div className="admin-dashboard-main-mid-item-txt">
                {t("Company")}
              </div>
              <div style={{ position: "relative" }}>
                <select
                  style={{ width: "11vw" }}
                  name="companyId"
                  value={companyId}
                  type="text"
                  onChange={(e) => {
                    searchGroupForCompany(e.target.value);
                    setCompanyId(e.target.value);
                  }}
                  placeholder="Search By Company"
                >
                  {userCompany?.map((item) => (
                    <option value={item.companyId}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div
              class="admin-dashboard-main-top-last"
              style={{ height: " 16vh", width: "10vh" }}
            >
              <button
                style={{
                  width: "10vw",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={setDefault}
              >
                <div>
                  <svg
                    width="auto"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div>{t("ClearFilters")}</div>
              </button>
            </div>
          </div>
          <div style={{ height: "8vw" }} className="courselist-main-cnt-top">
            {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}

            <div
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
                marginTop: "6vw",
                marginLeft: "0vw",
              }}
              className="head"
            >
              {t("GroupList")}
            </div>
            <div style={{ display: "flex", hight: "25vh" }}>
              <div>
                <Link to={"/admin/groupEdits/"}>
                  <button
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                      width: "13vw",
                      fontWeight: "500",
                      height: "5vh",
                    }}
                  >
                    {t("AddGroup")}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            style={{ margin: "0vw 2vw 2vw 2vw" }}
            className="admin-overdue-bottom"
          >
            <div className="admin-overdue-bottom-table">
              <div className="admin-overdue-bottom-table-cnt-grp">
                <div className="admin-overdue-bottom-table-head">
                  {t("SRNo")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Group ID")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Name")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("NumberOfMember")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("NoOfCourse")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("CreatedOn")}
                </div>
                <div className="admin-overdue-bottom-table-head">
                  {t("Action")}
                </div>
              </div>
              {showLoading && (
                <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td">
                    <div className="spinner-container">
                      <div className="loading-spinner"></div>
                    </div>
                  </div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div className="admin-overdue-bottom-table-td"></div>
                  <div
                    className="admin-overdue-bottom-table-td"
                    style={{ color: "#004577" }}
                  ></div>
                </div>
              )}
              {groups?.map((item, id) => {
                return (
                  <div
                    className="admin-overdue-bottom-table-cnt-grp"
                    key={id}
                    style={{ gridGap: "20px" }}
                  >
                    <div className="admin-overdue-bottom-table-td">
                      {id + 1}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.groupId}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.groupName}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item.memberCount}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {item["courseList"] == undefined
                        ? "0"
                        : item.courseList.length}
                    </div>
                    <div className="admin-overdue-bottom-table-td">
                      {createdDate(item.createdOn)}
                    </div>

                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    >
                      <div>
                        {item.archived ? (
                          tempLoading == id ? (
                            <p>Loading...</p>
                          ) : (
                            <select
                              style={{
                                border: "none",
                                backgroundColor: "#F2F2F2",
                                height: "4vh",
                                width: "7vw",
                                padding: "0 1vh",
                                fontWeight: "700",
                                color: "#717579",
                                borderRadius: "6px",
                              }}
                              onChange={(e) => {
                                goToEditPage(item, e, id);
                              }}
                            >
                              <option>{t("Action")}</option>
                              <option value={"unarchive"} id={item.groupName}>
                                {" "}
                                {t("Unarchived")}
                              </option>
                            </select>
                          )
                        ) : tempLoading == id ? (
                          <p>Loading...</p>
                        ) : (
                          <select
                            style={{
                              border: "none",
                              backgroundColor: "#F2F2F2",
                              height: "4vh",
                              width: "7vw",
                              padding: "0 1vh",
                              fontWeight: "700",
                              color: "#717579",
                              borderRadius: "6px",
                            }}
                            onChange={(e) => {
                              goToEditPage(item, e, id);
                            }}
                          >
                            <option>{t("Action")}</option>
                            <option value={item.groupName} id={item.groupName}>
                              {" "}
                              {t("Edit")}
                            </option>
                            <option value={"delete"}>{t("Delete")}</option>

                            <option value={"reset"}>{t("Reset")}</option>
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="admin-overdue-bottom-pagination">
              <div className="admin-overdue-bottom-pagination-cnt">
                <div className="admin-overdue-bottom-pagination-cnt-item">
                  <svg
                    onClick={() => {
                      if (storeUserLastDataList?.prev == "") {
                        return;
                      }
                      clickhandler(storeUserLastDataList?.prev);
                    }}
                    width="auto"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                  {currentPage} of {totalPage}
                </div>
                <div
                  style={{ marginRight: "19vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <svg
                    onClick={() => {
                      if (storeUserLastDataList?.next == "") {
                        return;
                      }
                      console.log(storeUserLastDataList?.next);
                      clickhandler(storeUserLastDataList?.next);
                    }}
                    width="auto"
                    height="20"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                      fill="#717579"
                    />
                  </svg>
                </div>
                <div
                  style={{ display: "flex", marginRight: "-30vw" }}
                  className="admin-overdue-bottom-pagination-cnt-item"
                >
                  <label className="admin-row">{t("Show")}</label>

                  <select
                    style={{
                      width: "4vw",
                      margin: "0.5vw",
                      marginBottom: "1.5vw",
                      height: "1.5vw",
                    }}
                    value={pageSize}
                    onChange={(e) => {
                      changePageLimit(e.target.value);
                      setPageSize(e.target.value);
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </select>
                  <label className="admin-row"> {t("PagePerItem")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <Dialog
        open={deleteDialog}
        onClose={()=>setDeleteDialog(false)}
        // sx={{backgroundColor:'white'}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete this Group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setDeleteDialog(false)}>Cancel</Button>
          <Button onClick={()=>setOptionYes(true)} autoFocus sx={{color:'red'}}>
            Delete
          </Button>
        </DialogActions>
        </Dialog>
    </div>
  );
}

export default Groups;
