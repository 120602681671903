import React, { useContext } from "react";
import { useState, setState } from "react";
import { auth, db, functions } from "../../../Firebase";
import "../StyleAdminComponents/GroupList.css";
import { Link, useParams } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";

import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import { t } from "i18next";
import { useSelector } from "react-redux";
import GroupMembers from "./Components/GroupMembers";
import ListOfAllUsers from "./Components/ListOfAllUser";
import { httpsCallable } from "firebase/functions";
import { ValidateAuth } from "../../../AuthToken";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import { MenuItem, Select } from "@mui/material";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
function GroupEdits() {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const getUserList = httpsCallable(functions, "getUserList");
  const editGroup = httpsCallable(functions, "editGroup");
  const updateGroupMembers = httpsCallable(functions, "updateGroupMembers");
  const [expDate, setExpDate] = useState("");

  const { clrs } = useSelector((state) => state.createClr);

  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);

  const data = useLocation();

  let editGroupList = data.state;
  console.log(data.state);
  const [companyData, setCompanyData] = useState([]);
  const [groupDetails, setGroupDetails] = useState({
    groupName: "",
    groupDesc: "",
    companyId: "",
    expDate: "",
  });
  const [userList, setUserList] = useState();
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupId, setGroupId] = useState("");
  // const [isChecked, setIsChecked] = useState(false);
  // const [newSetuserList, setNewSetuserList] = useState([]);
  // const [newDataGroupMembers, setNewDataGroupMembers] = useState([]);
  // const [flagState, setFlagState] = useState(true);
  const [editgroup, setEditGroup] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  const [removedGroupMember, setRemovedGroupMember] = useState([]);
  const [extarArrForMembers, setExtraArrForMembers] = useState([]);
  // const { id } = useParams();

  const saveGroup = () => {
    setButtonLoading(true);
    let memberList = removedGroupMember.map((ele) => ele.userId);
    // //console.log("rahul"+memberList)
    if(!groupDetails.companyId){
      alert('Company Name is required')
      setButtonLoading(false);
      return;
    }
    if(!groupDetails.groupDesc){
      alert('Group Description is required')
      setButtonLoading(false);

      return 
    }
    if(!groupDetails.groupName){
      alert('Group Name is required')
      setButtonLoading(false);

      return;
    }
    if(!groupDetails.expDate){
      alert('Expiry Date is required')
      setButtonLoading(false);

      return;
    }

    if (editGroupList) {
      // //console.log(
      //   groupDetails.groupDesc,editGroupList.groupId
      // )

      let dataex = JSON.stringify({
        groupName: groupDetails.groupName,
        companyId: groupDetails.companyId,
        expDate: groupDetails.expDate,
        groupDescription: groupDetails.groupDesc,
        groupId: editGroupList.groupId,
        newMembers: memberList,
      });
      let configExInt = {
        method: "patch",

        url: `${IP}/group/updateMember`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          // //console.log(res.data);
          setButtonLoading(false);
          navigate("/admin/groups", { state: { formGroup: true } });
          setGroupMembers([]);
          setExtraArrForMembers([]);
        })
        .catch((er) => {
          setButtonLoading(false);
        });
    } else {
      // if (memberList.length == 0) {
      //   alert("minimum one member should have in group");
      //   setButtonLoading(false);
      //   return;
      // }

      if (groupId == "" || groupDetails.groupName == "") {
        alert("groupName required");
        setButtonLoading(false);
        return;
      }
      if (groupDetails.groupDesc == "") {
        alert("group Description Required");
        setButtonLoading(false);
        return;
      }

      let dataex = JSON.stringify({
        groupName: groupDetails.groupName,
        companyId: groupDetails.companyId,
        expDate: groupDetails.expDate,
        groupDescription: groupDetails.groupDesc,
        createdOn: new Date(),
        createdBy: auth.currentUser?.uid,
        groupId: groupId,
        groupMembers: memberList,
      });
      let configExInt = {
        method: "patch",

        url: `${IP}/group`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt)
        .then((res) => {
          // //console.log(res)
          setButtonLoading(false);
          navigate("/admin/groups", { state: { formGroup: true } });
        })
        .catch((err) => {
          setButtonLoading(false);

          alert("Some INTERNAL Error ");
        });
    }
  };

  const handleInput = (e) => {
    if (e.target.name == "companyId") {
      getUserListForcompany(e.target.value);
    }
    const { name, value } = e.target;
    // console.log(name, value);
    if (name == "groupName") {
      setGroupId(
        value.replaceAll(" ", "") + "_" + new Date().getTime().toString()
      );
    }
    setGroupDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleRemove = async (e) => {
    e.preventDefault();

    console.log(groupMembers);

    setRemovedGroupMember(groupMembers.filter((ele) => ele.checked == true));
    setGroupMembers((prev) => {
      return prev.filter((ele) => ele.checked == false);
    });
    setExtraArrForMembers((prev) => {
      return prev.filter((ele) => ele.checked == false);
    });
  };
  var today = new Date().toISOString().split("T")[0];

  const handleAdd = (e) => {
    e.preventDefault();
    // //console.log(groupMembers)
    // //console.log(userList)

    let objtemp = {};
    userList?.map((element) => {
      if (element.checked == true) {
        console.log(element);
        objtemp[element.userId] = element;
      }
    });

    groupMembers?.map((element) => {
      objtemp[element.userId] = element;
      return;
    });
    let temparr = Object.values(objtemp);
    console.log(objtemp);
    setGroupMembers(
      temparr?.map(
        (e) => ({ ...e, checked: false })
        // userList
        //   ?.filter((element) => element.checked == true)
        //   .map((element) => ({ ...element, checked: false }))
      )
    );
    setExtraArrForMembers(
      temparr?.map(
        (e) => ({ ...e, checked: false })
        // userList
        //   ?.filter((element) => element.checked == true)
        //   .map((element) => ({ ...element, checked: false }))
      )
    );

    setUserList(userList?.filter((element) => element.checked != true));
    setDataList(dataList?.filter((element) => element.checked != true));
    //console.log("I am from newUser List", userList);
  };

  // useEffect(() => {
  //   if(!editGroupList)
  //   getUserList({}).then((result) => {

  //     if (result.error) return;
  //     setUserList(result.data?.map((res) => ({ ...res, checked: false })));
  //      //console.log(result.data)
  //   });
  // }, []);
  // //console.log("rahul",newSetuserList)
  //  //console.log(dataarr)

  const getUserListForcompany = (company) => {
    let dataex = JSON.stringify({});
    let configExInt = {
      method: "get",
      params: { company: company, type: "group" },

      url: `${IP}/adminUserProfile/companyUserList`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt).then((result) => {
      if (result.error) return;
      //console.log(result.data)
      setUserList(
        result.data
          ?.map((res) => ({ ...res, checked: false }))
          .filter((ele) => !ele.archived)
      );
      setDataList(
        result.data
          ?.map((res, id) => ({ ...res, checked: false, nu: id }))
          .filter((ele) => !ele.archived)
      );
      //  //console.log(result.data)
    });
  };
  useEffect(() => {
    let dataCom = JSON.stringify({});
    let configCom = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataCom,
    };
    axios(configCom).then((res) => {
      if (Array.isArray(res.data)) {
        setCompanyData(res.data);
      }
    });

    if (editGroupList) {
      // console.log(editGroupList);
      setEditGroup(true);
      console.log(editGroupList);
      let dataarr = [];
      let dataex = JSON.stringify({});
      let configExInt = {
        method: "get",
        params: {
          company: editGroupList?.companyId,
          type: "group",
          groupId: editGroupList?.groupId,
        },
        url: `${IP}/group/users`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: dataex,
      };
      axios(configExInt).then((result) => {
        if (result.error) return;
        let resData = result.data;
        setGroupMembers(
          resData?.users?.map((res) => ({ ...res, checked: false }))
        );
        setExtraArrForMembers(
          resData?.users?.map((res) => ({ ...res, checked: false }))
        );
        let newresDataFilter = [];
        // setNewSetuserList(result.data);
        // for (let j = 0; j < result.data.length; j++) {
        //   let moved = false;
        //   for (let i = 0; i < editGroupList?.groupMembers.length; i++) {
        //     if (editGroupList.groupMembers[i] == result.data[j].userId) {
        //       moved = true;
        //       resData[j] = { ...result.data[j], checked: false };

        //       dataarr.push(result.data[j]);
        //     }
        //   }
        //   if (!moved) {
        //     newresDataFilter.push({
        //       ...result.data[j],
        //       checked: false,
        //       nu: j,
        //     });
        //   }
        // }

        // setUserList(newresDataFilter);
        // setDataList(newresDataFilter);
        // setExpDate(editGroupList.expDate);
        let corrDate = null;
        if (editGroupList?.expDate) {
          console.log(editGroupList?.expDate)
          corrDate = new Date(
            editGroupList?.expDate._seconds * 1000
          ).toISOString();
          corrDate = corrDate.split("T")[0];
        }

        console.log(editGroupList);
        setGroupDetails({
          groupName: editGroupList.groupName,
          groupDesc: editGroupList.groupDescription,
          companyId: editGroupList.companyId,
          expDate: corrDate,
        });
        // console.log(groupDetails);
      });
    }
  }, []);

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("Groups")} />
      <div className="createcourse-main">
        <Sidebar page={"groups"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("AddNewGroupEditGroup")}
            </div>
          </div>
          <div
            className="admin-overdue-mid-grp "
            style={{ backgroundColor: "#E5E5E5", margin: "0 0vw" }}
          >
            <div className="group-input-row">
              <div
                className="admin-dashboard-main-mid-item-txt "
                style={{ width: "20vw" }}
              >
                {t("Select A company")}
              </div>

              <Select
                name="companyId"
                value={groupDetails.companyId}
                disabled={editGroupList ? true : false}
                onChange={handleInput}
                sx={{
                  width: "260px",
                  height: "40px",
                }}
              >
                {companyData.map((el) => (
                  <MenuItem value={el.companyId}>{el.name} </MenuItem>
                ))}
              </Select>
            </div>
            <div className="group-input-row">
              <div
                className="admin-dashboard-main-mid-item-txt"
                style={{ width: "20vw" }}
              >
                {t("GroupName")}
              </div>

              <input
                className="group-input-row-head"
                type="text"
                style={{ color: "#000" }}
                value={groupDetails.groupName}
                name="groupName"
                onChange={handleInput}
              />
            </div>
            <div className="group-input-row">
              <div
                className="admin-dashboard-main-mid-item-txt "
                style={{ width: "20vw" }}
              >
                {t("GroupDescription")}
              </div>

              <textarea
                className="group-input-row-head"
                value={groupDetails.groupDesc}
                name="groupDesc"
                onChange={handleInput}
              />
            </div>
            <div className="group-input-row">
              <div
                className="admin-dashboard-main-mid-item-txt"
                style={{ width: "20vw" }}
              >
                {t("Expiry Date")}*
              </div>
              <input
                type="date"
                name="expDate"
                // value={formatDate(groupDetails.expDate?._seconds)}
                value={groupDetails.expDate}
                min={today}
                className="admin-course-main-top-input"
                // onChange={(e) => setExpDate(e.target.value)}
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="admin-overdue">
            <div style={{ height: "8vw" }} className="courselist-main-cnt-top">
              {/*     <div className="courselist-main-cnt-top-txt">Group List</div> */}
              <div
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  marginTop: "4.9vw",
                  marginLeft: "0vw",
                  width: "22vw",
                  height: "3vw",
                  paddingTop: "0.5vw",
                }}
                className="head"
              >
                {t("ListOfGroupMember")}
              </div>
              <div>
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    width: "11vw",
                    height: "6vh",
                    fontWeight: "500",
                    fontSize: "1.5vw",
                    marginTop: "3vw",
                  }}
                  onClick={handleRemove}
                >
                  {t("Remove")}
                </button>
              </div>
            </div>
            <GroupMembers
              groupMembers={groupMembers}
              extarArrForMembers={extarArrForMembers}
              setExtraArrForMembers={setExtraArrForMembers}
              setGroupMembers={setGroupMembers}
              editGroupList={editgroup}
            />
          </div>

          {/* <div className="admin-overdue">
            <div style={{ height: "8vw" }} className="courselist-main-cnt-top">
             
              <div
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  marginTop: "4.9vw",
                  marginLeft: "0vw",
                  width: "14vw",
                  height: "3vw",
                  paddingTop: "0.5vw",
                }}
                className="head"
              >
                {t("ListOfAllUser")}
              </div>
              <div>
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    width: "12vw",
                    height: "6vh",
                    fontWeight: "500",
                    fontSize: "1.5vw",
                    marginTop: "3vw",
                  }}
                  onClick={handleAdd}
                >
                  {t("AddInGroup")}
                </button>
              </div>
            </div>
            <ListOfAllUsers
              userList={userList}
              setUserList={setUserList}
              dataList={dataList}
            />
          </div> */}
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>

        <div
          className="courselist-main-cnt-back-btn"
          style={{ margin: "2vw", display: "grid", placeItems: "center" }}
        >
          {buttonLoading ? (
            <button
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
                color: "white",
                border: "none",
                height: "4vh",
                width: "10vw",
                borderRadius: "6px",
              }}
            >
              {t("Loading...")}
            </button>
          ) : (
            <button
              onClick={saveGroup}
              style={{
                background: `${
                  clrs.CourseListBtn ? clrs.CourseListBtn : "hwb(224deg 78% 3%)"
                }`,
                color: "white",
                border: "none",
                height: "4vh",
                width: "10vw",
                borderRadius: "6px",
              }}
            >
              {t("SaveUpdate")}
            </button>
          )}
        </div>

        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/groups"}>
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                {t("Back")}
              </button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
    // <>
  );
}

export default GroupEdits;
