import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import CreateMcqQuestion from "./mcqQuestion";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";
import axios from "axios";
const FillInTheBlackquestion = ({
  quizId,
  questionData,
  editQuestion,
  setfetchDataFromApi,
  fetchDataFromApi,
  flagForSeconstEdit,
}) => {
  const storage = getStorage();

  const createQuestion = httpsCallable(functions, "createQuestion");
  const addQuestionSetOrder = httpsCallable(functions, "addQuestionSetOrder");

  //addQuestionSetOrder
  // added by Himanshu
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [trueFalse, setTrueFalse] = useState(false);

  const [fillUpsAns, setFillUpsAns] = useState("");
  // rearrange
  const [canEditRearrange, setCanEditRearrange] = useState(true);
  const [rearrangeOptions, setRearrangeOptions] = useState([
    {
      text: "First",
      position: 1,
    },
    {
      text: "Second",
      position: 2,
    },
    {
      text: "Third",
      position: 3,
    },
    {
      text: "Fourth",
      position: 4,
    },
  ]);

  // match words to image

  const [imageMcqAnswer, setImageMcqAnswer] = useState(0);

  //

  useEffect(() => {
    if (!editQuestion) return;
    // console.log(questionData)
    setqus(questionData.data?.question);
    setQuestionName(questionData?.name);
    setQuestionId(questionData?.id);
    setqusInstruction(questionData.data?.questionInstruction);

    if (questionData.data.answer) {
      let arr = "";
      for (let i = 0; i < questionData.data.answer.length; i++) {
        if (i == questionData.data.answer.length - 1) {
          arr = arr + questionData.data.answer[i];
        } else {
          arr = arr + questionData.data.answer[i] + ",";
        }

        // arr[op]=questionData.data.options[i].o

        if (i == questionData.data.answer.length - 1) {
          setFillUpsAns(arr);
        }
      }
    }
  }, [flagForSeconstEdit]);

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    questionName: "",
    qus: "",
    qusInstruction: "",
    fillUpsAns: "",
  });
  const formCheckFunction = () => {
    if (questionName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "question Name";
    }

    if (qus === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
      return "question ";
    }
    if (qusInstruction === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "error" }));
      return "questionc ";
    }
    if (fillUpsAns === "") {
      setCheckErrorInForm((prev) => ({ ...prev, fillUpsAns: "error" }));
      return "fillUpsAns ";
    }

    return "alliswell";
  };

  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("Fill in the blank");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("Option 1");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }

    let dataex = JSON.stringify({
      questionSetId: quizId,
      questions: orderList,
      edit: true,
    });
    let configEx = {
      method: "patch",

      url: `${IP}/courseBuilder/questionSet`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx);

    // db.collection("quiz")
    //   .doc(quizId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
    // db.collection("questionSet")
    //   .doc(quizId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
  }

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();

    let nsn = questionName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }

    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "Fill in the blank") {
      const myAnswer = fillUpsAns.split(",");
      let questionDatafromUser = {
        answer: myAnswer,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
    }

    const docData = {
      questionName: questionName,
      questionId: questionId,
      associatedQuiz: quizId,
      questionSet: quizId,
      data: managequestionData,
      questionType: qustype,
      created: Timestamp.now(),
    };
    //console.log(docData);

    let dataex = JSON.stringify({
      questionId: questionId,
      questionName: questionName,
      questionType: qustype,
      created: new Date().toString(),
      data: managequestionData,
      quizId: quizId,
    });
    var configEx = {
      method: "post",

      url: `${IP}/courseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx).then((res) => {
      setfetchDataFromApi(fetchDataFromApi + 1);
      alert("data saved successfully");
    });
    // createQuestion({
    //   questionId: questionId,
    //   questionName: questionName,
    //   questionType: qustype,
    //   created: new Date().toString(),
    //   data: managequestionData,
    //   quizId: quizId,
    // }).then((res) => {
    //   alert("data saved successfully");
    //   setfetchDataFromApi(fetchDataFromApi + 1);
    // });

    //alert("data saved successfully");
    const newList = list.concat({
      id: questionId,
      name: questionName,
      type: qustype,
    });
    setList(newList);
    saveOrder(newList);
    document.getElementById(quizId + "question-form").reset();
    document.getElementById(quizId + "questionName").value = "";
    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    toggleQuestionForm();
  }

  function resetData() {
    setQuestionName("");
    setQuestionId("");
    setqustype("Fill in the blank");
    setqus("");
    setqusInstruction("");

    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setqus5Answer("Option 1");
  }
  async function del(question) {
    setopenLessonPopUp(true);
    setDeleteQuestionId(question);
  }

  const delfunction = (question) => {
    db.collection("question").doc(question.id).delete();

    alert("Deleted " + question.name);
    const item1 = [];
    for (let i = 0; i < list.length; i++) {
      if (question.id !== list[i].id) {
        item1.push(list[i]);
      }
    }
    setList(item1);
    saveOrder(item1);
  };
  return (
    <section className="quizForm-container">
      <form id={quizId + "question-form"} onSubmit={submit}>
        <div className="quizForm-mainForm-infoRow">
          <p className="quizForm-mainForm-label">Question Name :</p>
          <input
            type="text"
            className="quizForm-mainForm-inputBar"
            value={questionName}
            id={quizId + "questionName"}
            style={{
              border: `${
                checkErrorInForm.questionName == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(e) => {
              setQuestionName(e.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, questionName: "" }));

              let value = e.target.value.replaceAll(" ", "");
              let time = new Date().getTime();
              if (!editQuestion) {
                setQuestionId(value + "_" + time);
              }
              // setQuestionId(value + "_" + time);
            }}
          />

          {checkErrorInForm.questionName == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Name
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Question : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus}
            style={{
              border: `${
                checkErrorInForm.qus == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qus: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qus == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Instruction : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qusInstruction}
            style={{
              border: `${
                checkErrorInForm.qusInstruction == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqusInstruction(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qusInstruction == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Instruction
            </span>
          )}
        </div>

        <label className="quizForm-sequence-label">
          Note: '_' (under score) is treated as blank in the Question. Seprate
          the answers with a comma.
        </label>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Answers : </label>
          <textarea
            type="text"
            placeholder="Answer 1, Answer 2"
            className="quizForm-mainForm-textarea"
            value={fillUpsAns}
            style={{
              border: `${
                checkErrorInForm.fillUpsAns == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(e) => {
              setFillUpsAns(e.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, fillUpsAns: "" }));
            }}
          />
          {checkErrorInForm.fillUpsAns == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Ans
            </span>
          )}
        </div>
      </form>
      {formCheck && <div> Fill all the Required fields.</div>}
      <div className="quizForm-mainForm-infoRow">
        <button
          className="quizFrom-button"
          style={{ marginLeft: "auto" }}
          onClick={storedDataOnFirebase}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default FillInTheBlackquestion;
