import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import imh from "./VectorTrue.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { MuiThemeProvider } from "@material-ui/core";
import { useRef } from "react";

// const muiTheme = getMuiTheme({
//   stepper: {
//     iconColor: "#fffff", // or logic to change color
//   },
// });

const steps = [
  {
    label: "Overall representation",
  },
  {
    label: "Recruitment",
  },
  {
    label: "Retention",
  },

  {
    label: "Policies",
  },
  {
    label: "Processes and tools",
  },
  {
    label: "Infrastructure",
  },
];

export default function VerticalLinearStepper({
  setSteperStep,
  steperStep,
  admin,
  companyId,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const componentRef = useRef();
  const navigate = useNavigate();
  const handleNext = () => {
    if (steperStep < 5) {
      setSteperStep(steperStep + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (steperStep == 5) {
      setSteperStep(steperStep + 1);
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (steperStep > 0) {
      setSteperStep(steperStep - 1);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const moveToNextOnClick = (el) => {
    console.log("el", el);
    setSteperStep(el);
    setActiveStep(el);
    // setActiveStep(el);
  };
  return (
    <Box
      sx={{
        height: "95vh",
        position: "fixed",
        padding: "10px",
        width: "19%",
        overflowY: "auto",
        backgroundColor: "#E1F566",
        borderRadius: "24px",
        marginRight: "10px",
        justifyItems: "center",
        marginBottom: "24px",
      }}
    >
      {steps?.map((el, index) => (
        <Box>
          <Box sx={{ width: "90%", height: "40px", display: "flex" }}>
            <Box
              sx={{
                width: "20%",
                height: "40px",

                margin: "auto",
              }}
            >
              {index != 0 && (
                <div
                  style={{
                    width: "1.3px",
                    height: "100%",
                    backgroundColor: "#000000",
                    // border: "0.5px solid black",
                    margin: "auto",
                  }}
                ></div>
              )}
            </Box>
            <Box sx={{ width: "80%", height: "50%" }}> </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "20%", height: "50%", margin: "auto" }}>
              {" "}
              {index < steperStep ? (
                <div
                  onClick={() => {
                    moveToNextOnClick(index);
                  }}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: "1px solid #000000",
                    justifyContent: "center",
                    margin: "auto",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "#E1F566",
                  }}
                >
                  <img src={imh} alt="" />
                </div>
              ) : (
                <div
                  onClick={() => {
                    moveToNextOnClick(index);
                  }}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    border: "1px solid #000000",
                    justifyContent: "center",
                    margin: "auto",
                    cursor: "pointer",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: index > steperStep ? "#E1F566" : "#FFFFFF",
                  }}
                >
                  {index + 1}
                </div>
              )}
            </Box>
            <Box
              sx={{
                width: "90%",
                height: "10%",
                // display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                // alignContent: "center",
                ml: "10px",
              }}
            >
              {" "}
              {el.label}
            </Box>
          </Box>
        </Box>
      ))}
      {admin && (
        <Box
          sx={{
            marginTop: "50px",
            marginLeft: "16px",
            marginBottom: "-6px",
            display: "flex",
            gap: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            window.open(
              "/print/DiversityMetric",
              "",
              "toolbars=no,resizable=0,width=900,height=700,left=200,top=200"
            );
            localStorage.setItem("companyId", companyId);
            localStorage.setItem("allApiData", JSON.stringify("allApiData"));
            localStorage.setItem("componentRef", JSON.stringify(componentRef));
          }}
        >
          <FileDownloadOutlinedIcon sx={{ color: "#6846C7" }} />
          <Typography sx={{ color: "#6846C7" }}>Download Report</Typography>
        </Box>
      )}
      <Box sx={{ mb: "16px", mt: "12vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "auto",
            width: "95%",
          }}
        >
          <Button
            onClick={handleBack}
            sx={{
              mt: 1,
              mr: 1,
              ml: "5px",
              width: "120px",
              height: "50px",
              borderRadius: "16px",
              border: "1px solid #000000",
              fontFamily: "Poppins",
              color: "#000",
            }}
          >
            Back
          </Button>

          {steperStep == 5 && !admin ? (
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{
                mt: 1,
                mr: 1,
                width: "120px",
                height: "50px",
                backgroundColor: "#000000",
                borderRadius: "16px",
              }}
            >
              Go to index
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={steperStep >= 5}
              sx={{
                mt: 1,
                mr: 1,
                width: "120px",
                height: "50px",
                backgroundColor: "#000000",
                borderRadius: "16px",
                fontFamily: "Poppins",
              }}
            >
              Next
            </Button>
          )}
        </div>
      </Box>

      {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
    </Box>
  );
}
