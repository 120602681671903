import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Maturity from "./Maturity";
import OverallRepresentationDI from "./OverallRepresentationdi";
import RecruitmentandRetention from "./RecruitmentandRetention";

import { useNavigate } from "react-router";
import HrTopNavbar from "../../Sidebar/HrTopNavbar";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import HrloadingScreen from "../../LodingHrscreen";
import MobileTopBar from "../../Sidebar/MobaileTopBar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CultureIndex({ setSteperStep }) {
  const [value, setValue] = React.useState(0);
  const [lodingscreen, setlodingscreen] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const { rolesAndPermission, setRolesAndPermission } =
    React.useContext(DrawerContext);

  const horizontalScrollTabsTheme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          flexContainer: {
            overflowX: "scroll",
          },
        },
      },
    },
  });

  // React.useEffect(() => {
  //   if (!rolesAndPermission) {
  //     setlodingscreen(true);
  //   } else {
  //     setlodingscreen(false);

  //     let newobj = rolesAndPermission?.DEI?.viewPermission;
  //     // console.log(newobj.HRDI);
  //     if (!newobj) {
  //       navigate(-1);
  //       return <></>;
  //     }
  //   }
  // }, [rolesAndPermission]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#F3F1FF",
          zIndex: "2",
          mr: "40px",
          width: "100%",
        }}
      >
        <HrloadingScreen open={lodingscreen} />
        <MobileTopBar sidebar={"Diversity"} />

        <HrTopNavbar title={"Diversity metric"}>
          <Box>
            <Button
              sx={{ color: "#6846C7", border: "1px solid #6846C7" }}
              onClick={() => {
                setSteperStep(0);
              }}
            >
              Update data
            </Button>
          </Box>
        </HrTopNavbar>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItem: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              width: "95%",
              borderColor: "divider",
              textAlign: "left",
              mt: "6px",
            }}
          >
            {/* <ThemeProvider theme={horizontalScrollTabsTheme}> */}

            <Tabs
              value={value}
              textColor="inherit"
              indicatorColor="primary"
              onChange={handleChange}
              orientation="horizontal"
              TabIndicatorProps={{
                sx: {
                  "@media only screen and (max-width: 600px)": {
                    display: "none",
                  },
                },
                style: {
                  color: "#6846C7",
                  marginLeft: "13px",
                  textAlign: "left",
                  width: "5%",
                },
              }}
              variant="scrollable"
              className="culturalIndexTabs"
              // sx={{ fontFamily: "Manrope", fontWeight: "600", color: "#6846C7" }}
            >
              {rolesAndPermission?.DEI?.componentPermission.includes(
                "OverAll Representation"
              ) && (
                <Tab
                  sx={{
                    color: "#6846C7",
                    fontWeight: "600",
                    fontFamily: "Poppins",

                    fontSize: "16px",
                  }}
                  style={{
                    scrollMargin: "200px",
                  }}
                  onClick={(e) => {
                    e.target.scrollIntoView({
                      behaviour: "smooth",
                      inline: "center",
                    });
                  }}
                  label="Overall representation"
                />
              )}

              {rolesAndPermission?.DEI?.componentPermission.includes(
                "Recuritment and Retention"
              ) && (
                <Tab
                  sx={{
                    color: "#6846C7",
                    fontWeight: "600",
                    fontFamily: "Poppins",

                    fontSize: "16px",
                  }}
                  style={{
                    scrollMargin: "200px",
                  }}
                  onClick={(e) => {
                    e.target.scrollIntoView({
                      behaviour: "smooth",
                      inline: "end",
                    });
                  }}
                  label="Recruitment and retention"
                />
              )}

              {rolesAndPermission?.DEI?.componentPermission.includes(
                "Advancement Scale"
              ) && (
                <Tab
                  sx={{
                    color: "#6846C7",
                    fontWeight: "600",
                    fontFamily: "Poppins",

                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                  style={{
                    scrollMargin: "200px",
                  }}
                  onClick={(e) => {
                    e.target.scrollIntoView({
                      behaviour: "smooth",
                      inline: "start",
                    });
                  }}
                  label="Advancement scale"
                />
              )}
              {/* <Tab
            sx={{
              color: "#6846C7",
              fontWeight: "600",
              fontFamily: "Poppins",

              fontSize: "16px",
            }}
            label="Psychological Safety"
          /> */}
              {/* <Tab
            sx={{
              color: "#6846C7",
              fontWeight: "600",
              fontFamily: "Poppins",

              fontSize: "16px",
            }}
            label="Bystander Intervention"
          /> */}
            </Tabs>
            {/* </ThemeProvider> */}
          </Box>
        </Box>
      </Box>

      <TabPanel value={value} index={2}>
        <Maturity />
      </TabPanel>
      <TabPanel value={value} index={0}>
        <OverallRepresentationDI />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RecruitmentandRetention />
      </TabPanel>

      {/* <TabPanel value={value} index={3}>
        <PsyclogicalSafety />
      </TabPanel> */}
      {/* <TabPanel value={value} index={4}>
        <Bystandard />
      </TabPanel> */}
    </Box>
  );
}
