import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../Firebase";
import { ReactComponent as EndQuizPass } from "./icons/endQuizPass.svg";
import { ItemContext } from "../Context/ItemContext";
import { getCookie } from "../../../../Cookies";
import { Button } from "@mui/material";
import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";

import { border, color } from "@mui/system";
import HrloadingScreen from "../../../LodingHrscreen";

import gifimg from "../../With Text.gif";
import downloadimg from "../../Download.svg";
const Node = ({ node, setCanNavigate, courseStatus, certificateInfo }) => {
  const { courseId, isLinear, progressIndex } = useContext(ItemContext);
  const setTopicComplete = httpsCallable(functions, "setTopicComplete");
  const getNodeProgress = httpsCallable(functions, "getNodeProgress");
  const setLessonComplete = httpsCallable(functions, "setLessonComplete");
  const setCourseComplete = httpsCallable(functions, "setCourseComplete");

  const lessonCompletedUserNotification = httpsCallable(
    functions,
    "lessonCompletedUserNotification"
  );
  const [progress, setProgress] = useState(null);
  const [isNodeComplete, setIsNotComplete] = useState(false);

  const [lodingscreen, setlodingscreen] = useState(false);

  const [cirtificategen, setcirtificategen] = useState(null);
  const [loadingflag, setloadingflag] = useState(false);
  useEffect(() => {
    let timesNow = new Date();
    setCanNavigate(true);
    //console.log(isLinear);
    if (!isLinear) {
      if (node.nodeType == "topic") {
        let configrecrut = {
          method: "get",

          url: `${IP}/userMicroItemDetails/nodeProgress`,
          params: { courseId: courseId, topicId: node.id },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataregionf",
        };
        axios(configrecrut).then((result) => {
          setProgress(result.data);
          if (result.data.completed == result.data.total) {
            setIsNotComplete(true);

            let configrecruttopic = {
              method: "patch",

              url: `${IP}/userMicroProgress/topic`,
              // params: {  courseId: courseId,
              //   topicId: node.id, },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                courseId: courseId,
                topicId: node.id,
                lastVisited: timesNow.toString(),
              }),
            };
            axios(configrecruttopic);
          }
        });
      } else if (node.nodeType == "lesson") {
        let configrecrut = {
          method: "get",

          url: `${IP}/userMicroItemDetails/nodeProgress`,
          params: { courseId: courseId, lessonId: node.id },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataregionf",
        };
        axios(configrecrut).then((result) => {
          setProgress(result.data);
          if (result.data.completed == result.data.total) {
            setIsNotComplete(true);
            //alert("lesson sent")

            let configrecruttopic = {
              method: "patch",

              url: `${IP}/userMicroProgress/lessons`,
              // params: {  courseId: courseId,
              //   topicId: node.id, },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                courseId: courseId,
                lessonId: node.id,
                lastVisited: timesNow.toString(),
              }),
            };
            axios(configrecruttopic);

            // alert("lesson sent")

            let configLessonCom = {
              method: "post",

              url: `${IP}/userNotification/lessonCompletedUserNotification`,
              // params: {  courseId: courseId,
              //   topicId: node.id, },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                id: node.id,
                uid: getCookie("UID"),
              }),
            };
            axios(configLessonCom);
          }
        });
      } else if (node.nodeType == "course") {
        //console.log("calling getNodeProgress");
        let configrecrut = {
          method: "get",

          url: `${IP}/userMicroItemDetails/nodeProgress`,
          params: { courseId: courseId },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataregionf",
        };
        axios(configrecrut).then((result) => {
          //console.log(result.data.completed, result.data.total);
          setProgress(result.data);
          if (result.data.completed == result.data.total) {
            //console.log(result.data.completed, result.data.total);
            setIsNotComplete(true);

            let configrecrut = {
              method: "patch",

              url: `${IP}/userMicroProgress`,
              params: { courseId: courseId },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                completionDate: timesNow.toString(),
                courseId: courseId,
              }),
            };
            axios(configrecrut);
            setlodingscreen(true);
            let configGeneCerti = {
              method: "post",

              url: `${IP}/microCertificate/certificate`,
              params: { courseId: courseId },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getCookie("bearer")}`,
              },
              data: JSON.stringify({
                courseId: courseId,
                completionDate: timesNow.toString(),
              }),
            };
            axios(configGeneCerti)
              .then((res) => {
                setlodingscreen(false);
                console.log(res.data.link);
                setcirtificategen(res.data.link);

                // setcirtificategen(res.data);
              })
              .catch((er) => {
                setlodingscreen(false);
              });
          }
        });
      }
      return;
    }
    if (node.nodeType == "topic") {
      let configGeneCerti = {
        method: "patch",

        url: `${IP}/userMicroProgress/topic`,
        params: { courseId: courseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          topicId: node.id,
          lastVisited: timesNow.toString(),
        }),
      };
      axios(configGeneCerti);
    } else if (node.nodeType == "lesson") {
      let configGeneCerti = {
        method: "patch",

        url: `${IP}/userMicroProgress/lessons`,
        params: { courseId: courseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          lessonId: node.id,
          lastVisited: timesNow.toString(),
        }),
      };
      axios(configGeneCerti);

      let configLessonCom = {
        method: "post",

        url: `${IP}/userNotification/lessonCompletedUserNotification`,
        // params: {  courseId: courseId,
        //   topicId: node.id, },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          id: node.id,
          uid: getCookie("UID"),
        }),
      };
      axios(configLessonCom);
    } else if (node.nodeType == "course") {
      let configLessonCom = {
        method: "patch",

        url: `${IP}/userMicroProgress`,
        // params: { courseId: courseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          completionDate: timesNow.toString(),
          courseId: courseId,
        }),
      };
      axios(configLessonCom);
      setlodingscreen(true);
      let configGeneCerti = {
        method: "post",

        url: `${IP}/microCertificate/certificate`,

        params: { courseId: courseId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          completionDate: timesNow.toString(),
        }),
      };
      axios(configGeneCerti)
        .then((res) => {
          console.log(res.data.link);
          setcirtificategen(res.data.link);

          setlodingscreen(false);
        })
        .catch((er) => {
          setlodingscreen(false);
        });
    }
  }, []);

  useEffect(() => {
    setloadingflag(!loadingflag);
  }, [lodingscreen]);
  return (
    <>
      <HrloadingScreen open={lodingscreen} />

      {isLinear || isNodeComplete ? (
        <div className="node-container">
          {/* <EndQuizPass /> */}
          {node?.nodeType !== "course" && (
            <>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "40px",
                  fontWeight: "600",
                }}
              >
                Congratulations!
              </p>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                You have completed a {node.nodeType}
              </p>
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                {node.name}
              </p>
            </>
          )}

          {(cirtificategen ? cirtificategen : certificateInfo) &&
            !lodingscreen &&
            node.nodeType == "course" && (
              <div>
                {" "}
                <img src={gifimg} width="90%" />
              </div>
            )}

          {(cirtificategen ? cirtificategen : certificateInfo) &&
            !lodingscreen &&
            node.nodeType == "course" && (
              <div
                style={{
                  height: "56px",
                  width: "90%",
                  border: "1px solid #6846C7",
                  display: "grid",
                  margin: "40px auto auto auto",
                  borderRadius: "16px",
                  justifyItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  background: "#FFFFFF",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    window.open(
                      certificateInfo ? certificateInfo : cirtificategen,
                      "_blank"
                    );
                  }}
                >
                  {" "}
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {" "}
                    Download certificate
                    <img
                      src={downloadimg}
                      style={{
                        display: "inline",
                        marginLeft: "10px",
                      }}
                    />{" "}
                  </div>{" "}
                </div>
              </div>
            )}
        </div>
      ) : progress ? (
        <div className="node-container">
          {/* <EndQuizPass /> */}
          <p
            style={{
              fontFamily: "Poppins",
              fontSize: "40px",
              fontWeight: "600",
            }}
          >
            You have completed {progress.completed} out of {progress.total} in{" "}
            {node.nodeType}
          </p>
          <p
            style={{
              marginTop: "24px",

              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "400",
            }}
          >
            {node.name}
          </p>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Node;
