import React, { useEffect, useRef, useState } from "react";
import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../Cookies";
import { Country, State, City } from "country-state-city";
import "./Print.css";

import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  FormControl,
  InputLabel,
  Typography,
  Modal,
} from "@mui/material";
import HrTopNavbar from "../../../Sidebar/HrTopNavbar";

const OverallRepresentationPrint = () => {
  const [list, setList] = useState([]);
  const [listbuver, setListbuver] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setstateListList] = useState([]);
  const [overallgender, setOverallgender] = useState([]);
  const Item = styled(Paper)(({ theme }) => ({
    background: "#FFF",
    textAlign: "center",
    borderRadius: "20px",
    padding: "20px 15px",
  }));
  const Item2 = styled(Paper)(({ theme }) => ({
    background: "#FFF",
    textAlign: "center",
    borderRadius: "20px",
  }));

  const Item3 = styled(Paper)(({ theme }) => ({
    background: "#FFF",
    textAlign: "center",
    borderRadius: "20px",
    // padding: "20px 15px",
  }));
  const companyId = localStorage.getItem("companyId");

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [bussinessvertical, setBussinessvertical] = useState([]);
  const [genderList, setgenderList] = useState([
    { name: "Junior" },
    { name: "MidLevel" },
    { name: "Senior" },
  ]);

  const [
    OverallGenderRepresentationState,
    setOverallGenderRepresentationState,
  ] = useState([]);

  const [genderbyRegionStates, setgenderbyRegionStates] = useState([]);
  const [genderbyBusinessVerticalstate, setGenderbyBusinessVerticalstate] =
    useState([]);

  const getApiDataForYear = () => {
    let config = {
      method: "get",

      url: `${IP}/overAllRepresentation/overAllGenderRep?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(config)
      .then((res) => {
        setOverallGenderRepresentationState(res.data);

        // console.log(res.data);
      })
      .catch((er) => {
        // console.log(er);
      });
  };

  const getBussinessVer = () => {
    let configvr = {
      method: "get",

      url: `${IP}/bussinessVertical?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configvr).then((res) => {
      setBussinessvertical(res.data);
    });
  };

  const getGenterBybussinessVerticalAndRegib = () => {
    let dataregionf = JSON.stringify(genderbyRegionStates);

    let configregiony = {
      method: "get",

      url: `${IP}/overAllRepresentation/genderByBussinessVertical?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregion",
    };

    axios(configregiony)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setListbuver((prev) => prev.concat({ year: key }));
        }
        setGenderbyBusinessVerticalstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const getoverAllRepresentationgenderByRegion = () => {
    let dataregion = JSON.stringify(genderbyRegionStates);

    let configregion = {
      method: "get",

      url: `${IP}/overAllRepresentation/genderByRegion?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregion,
    };

    axios(configregion)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setList((prev) => prev.concat({ year: key }));
        }
        setgenderbyRegionStates(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  useEffect(() => {
    getBussinessVer();
    getApiDataForYear();

    getGenterBybussinessVerticalAndRegib();
    getoverAllRepresentationgenderByRegion();
  }, []);

  useEffect(() => {
    let data = JSON.stringify(OverallGenderRepresentationState);

    //console.log(data);
    let config = {
      method: "patch",

      url: `${IP}/overAllRepresentation/overAllGenderRep?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    if (OverallGenderRepresentationState.length > 0) {
      axios(config)
        .then((res) => {
          if (res.data) {
            setOverallGenderRepresentationState(res.data);
          }
          // console.log(res.data);
        })
        .catch((er) => {
          // console.log(er);
        });
    }

    let dataregion = JSON.stringify(genderbyRegionStates);

    let configregion = {
      method: "patch",

      url: `${IP}/overAllRepresentation/genderByRegion?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregion,
    };
    if (genderbyRegionStates?.length > 0) {
      axios(configregion)
        .then((res) => {
          //console.log(res.data);
        })
        .catch((er) => {
          // console.log(er);
        });
    }

    setOverallgender((prev) => ({
      ...prev,
      OverallGenderRepresentationState: OverallGenderRepresentationState
        ? OverallGenderRepresentationState
        : [],

      genderbyRegionStates: genderbyRegionStates ? genderbyRegionStates : [],
      genderbyBusinessVerticalstate: genderbyBusinessVerticalstate
        ? genderbyBusinessVerticalstate
        : [],
    }));

    // setgenderbyRegionStates(ex);
  }, [
    genderbyRegionStates,
    genderbyBusinessVerticalstate,
    OverallGenderRepresentationState,
  ]);

  useEffect(() => {
    if (genderbyBusinessVerticalstate?.length > 0) {
      let data = JSON.stringify(genderbyBusinessVerticalstate);

      let config = {
        method: "patch",

        url: `${IP}/overAllRepresentation/genderByBussinessVertical?companyId=${companyId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: data,
      };
      axios(config)
        .then((res) => {
          // console.log(res.data);
        })
        .catch((er) => {
          //console.log(er);
        });
    }
  }, [genderbyBusinessVerticalstate]);
  useEffect(() => {
    let ls = overallgender.OverallGenderRepresentationState
      ? overallgender.OverallGenderRepresentationState
      : [];

    setList([]);
    setListbuver([]);
    for (let i = 0; i < ls.length; i++) {
      // setList((prev) => prev.concat({ year: ls[i]?.year }));
      // setListbuver((prev) => prev.concat({ year: ls[i]?.year }));
    }

    setOverallGenderRepresentationState(
      overallgender.OverallGenderRepresentationState
        ? overallgender.OverallGenderRepresentationState
        : []
    );

    let recrut = overallgender.genderbyRegionStates
      ? overallgender.genderbyRegionStates
      : [];
    let newobj = {};
    for (let i = 0; i < recrut.length; i++) {
      newobj[recrut[i]?.year] = "af";
    }
    for (let key in newobj) {
      setList((prev) => prev.concat({ year: key }));
    }
    setgenderbyRegionStates(
      overallgender.genderbyRegionStates
        ? overallgender.genderbyRegionStates
        : []
    );

    let rvcall = overallgender.genderbyBusinessVerticalstate
      ? overallgender.genderbyBusinessVerticalstate
      : [];
    let newobjt = {};
    for (let i = 0; i < rvcall.length; i++) {
      newobjt[rvcall[i]?.year] = "af";
    }
    for (let key in newobjt) {
      setListbuver((prev) => prev.concat({ year: key }));
    }
    setGenderbyBusinessVerticalstate(
      overallgender.genderbyBusinessVerticalstate
        ? overallgender.genderbyBusinessVerticalstate
        : []
    );

    // setgenderbyRegionStates(ex);
  }, []);

  const universalInd = useRef(0);
  return (
    <div style={{ backgroundColor: "#EEECFF" }} className="scroll">
      <Box>
        {/* stick navbar  */}
        <Box
          sx={{
            position: "sticky",
            top: "0",
            backgroundColor: "#F3F1FF",
            zIndex: "2",
            paddingTop: "20px",
          }}
        >
          <HrTopNavbar title={"Overall representation"} index={1} />
        </Box>

        {/* popup  - dialog */}

        <Box sx={{ marginTop: "24px" }}>
          <Box className="tableHedingName" sx={{ mt: "32px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                ml: "24px",
                mt: "5px",
              }}
            >
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#E5E0F9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                1
              </Box>
              Overall gender representation
            </Box>
          </Box>

          <Box className="pagebreak tablebgColorForHr" sx={{ mt: "24px" }}>
            <Grid container spacing={2}>
              {OverallGenderRepresentationState?.map((el) => (
                <Grid item md={(2, 4)}>
                  <Item>
                    <Stack spacing={2}>
                      {" "}
                      <Box
                        sx={{ display: "grid", gridTemplateColumns: "7fr 1fr" }}
                      >
                        <Box style={{}}>{el?.year} </Box>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #E7E3F2",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <Box className="genderbyRegionInputBoxLabel">
                          {" "}
                          Male{" "}
                        </Box>
                        <Box>
                          {" "}
                          <input
                            disabled={true}
                            style={{
                              border: "none",
                              outline: "none",
                              textAlign: "center",
                              padding: "10px",
                            }}
                            className="OverallGenderRepresentationTable"
                            min="0"
                            type="Number"
                            name="tes"
                            value={el.male}
                          />{" "}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #E7E3F2",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <Box className="genderbyRegionInputBoxLabel">
                          {" "}
                          Female{" "}
                        </Box>
                        <Box>
                          {" "}
                          <input
                            disabled={true}
                            style={{
                              border: "none",
                              outline: "none",
                              textAlign: "center",
                              padding: "10px",
                            }}
                            min="0"
                            type="Number"
                            className="OverallGenderRepresentationTable"
                            value={el.female}
                          />{" "}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #E7E3F2",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <Box className="genderbyRegionInputBoxLabel">
                          {" "}
                          Non-binary{" "}
                        </Box>
                        <Box>
                          {" "}
                          <input
                            disabled={true}
                            style={{
                              border: "none",
                              outline: "none",
                              textAlign: "center",
                              padding: "10px",
                            }}
                            min="0"
                            type="Number"
                            value={el.nonBinary}
                            className="OverallGenderRepresentationTable"
                          />{" "}
                        </Box>
                      </Box>
                    </Stack>
                  </Item>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box
            className="tableHedingNamefontFamily"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              mt: "30px",
            }}
          >
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#E5E0F9",
                display: "flex",
                justifyContent: "center",
                ml: "24px",
                alignItems: "center",
              }}
            >
              2
            </Box>{" "}
            Gender by region
          </Box>
          <Box
            className="tablebgColorForHr"
            sx={{ padding: "0px 20px", mt: "20px" }}
          >
            {console.log(list)}
            {list.map((listel, ind) => {
              let z = 0;
              return (
                <Box className="pagebreak" sx={{ mt: "15px" }}>
                  {" "}
                  <Box style={{ display: "flex" }}>
                    <h1>{listel.year}</h1>
                  </Box>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ mt: "5px" }}
                  >
                    {genderbyRegionStates?.map((elementList, i) => {
                      if (elementList.year == listel.year) {
                        z += 1;

                        return (
                          <Grid
                            item
                            xs={6}
                            className={`${z % 4 == 0 ? "pagebreak" : ""}`}
                            sx={{ mb: "10px" }}
                          >
                            {/* <Grid item xs={6} className={`${i<3 ?"":(i+1)%4 == 0 ?"pagebreak":""}`}> */}
                            {/* {set/} */}

                            <Item3>
                              <Box className="genderbyRegionTableContent">
                                <Stack spacing={2}>
                                  {" "}
                                  <Box sx={{ display: "flex" }}>
                                    <Typography
                                      sx={{
                                        fontWeight: 400,
                                        color: "#666",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Country -&nbsp;
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        color: "black",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {elementList.countryName}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Box sx={{ display: "flex" }}>
                                      <Typography
                                        sx={{
                                          fontWeight: 400,
                                          color: "#666",
                                          fontSize: "12px",
                                        }}
                                      >
                                        State -&nbsp;
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontWeight: 600,
                                          color: "black",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {elementList.state}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Stack>

                                <Box
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "0.5fr 0.5fr 0.5fr",
                                    gridGap: "10px",
                                  }}
                                >
                                  {genderList?.map((el) => (
                                    <Box
                                      style={{
                                        marginTop: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Box>
                                        <Box> {el.name} </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Male{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            className="genderbyRegionInputBox"
                                            value={elementList[el.name]?.male}
                                            min="0"
                                            type="Number"
                                          />{" "}
                                        </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Female{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            className="genderbyRegionInputBox"
                                            min="0"
                                            type="Number"
                                            value={elementList[el.name]?.female}
                                          />{" "}
                                        </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Non-binary{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            min="0"
                                            type="Number"
                                            className="genderbyRegionInputBox"
                                            value={
                                              elementList[el.name]?.nonBinary
                                            }
                                          />{" "}
                                        </Box>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Item3>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </Box>
              );
            })}
          </Box>

          <Box
            className="tableHedingNamefontFamily"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              mt: "30px",
            }}
          >
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#E5E0F9",
                display: "flex",
                justifyContent: "center",
                ml: "24px",
                alignItems: "center",
              }}
            >
              3
            </Box>{" "}
            Gender by Business Vertical
          </Box>
          <Box
            className="tablebgColorForHr"
            sx={{ padding: "0px 20px", mt: "20px" }}
          >
            {console.log(listbuver)}
            {listbuver.map((listele, ind) => {
              let z = 0;
              return (
                <Box className="pagebreak">
                  {console.log(listele)}
                  <Box sx={{ display: "flex" }}>
                    <h1>{listele.year}</h1>
                  </Box>

                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ mt: "5px" }}
                  >
                    {genderbyBusinessVerticalstate?.map((elementList, i) => {
                      if (elementList.year == listele.year) {
                        z += 1;
                        return (
                          <Grid
                            item
                            xs={6}
                            className={`${z % 4 == 0 ? "pagebreak" : ""}`}
                            sx={{ mb: "10px" }}
                          >
                            <Item3>
                              <Box className="genderbyRegionTableContent">
                                <Stack spacing={2}>
                                  {" "}
                                  <Box sx={{ display: "flex" }}>
                                    <Typography
                                      sx={{
                                        fontWeight: 400,
                                        color: "#666",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Business Vertical -&nbsp;
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        color: "black",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {elementList.businessVertical}
                                    </Typography>
                                  </Box>
                                </Stack>

                                <Box
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "0.5fr 0.5fr 0.5fr",
                                    gridGap: "10px",
                                  }}
                                >
                                  {genderList?.map((el) => (
                                    <Box
                                      style={{
                                        marginTop: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Box>
                                        <Box> {el.name} </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Male{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            className="genderbyRegionInputBox"
                                            value={elementList[el.name]?.male}
                                            min="0"
                                            type="Number"
                                          />{" "}
                                        </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Female{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            className="genderbyRegionInputBox"
                                            min="0"
                                            type="Number"
                                            value={elementList[el.name]?.female}
                                          />{" "}
                                        </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Non-binary{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            min="0"
                                            type="Number"
                                            className="genderbyRegionInputBox"
                                            value={
                                              elementList[el.name]?.nonBinary
                                            }
                                          />{" "}
                                        </Box>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Item3>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default OverallRepresentationPrint;
