import { Backdrop, CircularProgress, Modal } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
const style = {
  position: "absolute",
  //   top: "50%",
  right: "2%",
  width: "85%",
  height: "100%",

  //   transform: "translate(-50%, -50%)",
  border: "none",
  p: 4,
};
export default function HrloadingScreenDEI({ open }) {
  //   const { loading } = useContext(AuthContext);

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backdropFilter: "blur(8px)", // Apply the blur effect
      }}
      open={open}
    >
      <CircularProgress size="100px" sx={{ color: "#3A36DB" }} />
    </Backdrop>
  );
}
