import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../Firebase";
import {
  ContentCourseWebIcons,
  ContentCoursePdfIcons,
  ContentCourseVideoIcons,
  TickCompleted,
} from "../../../../utilities/Icons/Icons";
import { ItemContext } from "../Context/ItemContext";

import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../Cookies";
import circulMoveImg from "../../circulImg.svg";
import empatyImg from "../../onlyCirculImg.svg";
const ItemsTimeLine = ({ first, itemId, connect }) => {
  const getItemInfo = httpsCallable(functions, "getItemInfo");
  const getItemProgress = httpsCallable(functions, "getItemProgress");
  const [itemInfo, setItemInfo] = useState(null);
  const [completed, setCompleted] = useState(false);
  const { courseId, itemsArray, progressIndex, setProgressIndex, isLinear } =
    useContext(ItemContext);
  const access = first || !isLinear;
  useEffect(() => {
    // setItemInfo(null);

    let configrecrut = {
      method: "get",

      url: `${IP}/userCourseDetails/item`,
      params: { itemId: itemId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: JSON.stringify({
      //   courseId: courseId,
      //   itemId: itemId,
      //   lastVisited: new Date().toString(),
      //   lastDuration: progress,
      //   completed: Boolean(percent >= COMPLETE_PERCENT),
      // }),
    };
    axios(configrecrut).then((result) => setItemInfo(result.data));
  }, []);

  useEffect(() => {
    if (completed) return; // reduce api calls
    let configrecrut = {
      method: "get",

      url: `${IP}/userProgress/items`,
      params: { courseId: courseId, itemId: itemId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      // data: JSON.stringify({
      //   courseId: courseId,
      //   itemId: itemId,
      //   lastVisited: new Date().toString(),
      //   lastDuration: progress,
      //   completed: Boolean(percent >= COMPLETE_PERCENT),
      // }),
    };
    axios(configrecrut).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
      //console.log(result);
    });
  }, [progressIndex]);

  const setMainItem = (element) => element.id == itemId;

  return (
    <TimelineItem>
      <TimelineSeparator className={"separator_content_padding"}>
        {/* <TimelineDot className={"timeline_dot"} />
        {connect && <TimelineConnector />} */}
        {connect && <TimelineConnector style={{ marginBottom: "4px" }} />}
        {completed ? (
          <img src={circulMoveImg} style={{ margin: "auto" }} />
        ) : (
          <img src={empatyImg} style={{ margin: "auto" }} />
        )}
        {connect && <TimelineConnector style={{ width: "0px" }} />}
      </TimelineSeparator>
      <TimelineContent>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            pt: "2px",
          }}
        >
          {itemInfo?.itemType == "html" && <ContentCourseWebIcons />}
          {itemInfo?.itemType == "pdf" && <ContentCoursePdfIcons />}
          {itemInfo?.itemType == "video" && <ContentCourseVideoIcons />}
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Poppins",
              fontWeight: 400,
              color: "#000000",
              cursor: completed || access ? "pointer" : "not-allowed",
            }}
            onClick={() => {
              if (completed || access)
                setProgressIndex(itemsArray.findIndex(setMainItem));
            }}
          >
            {itemInfo?.itemName}
          </Typography>
          {/* {completed && (
            <TickCompleted
              style={{ marginLeft: "auto", marginRight: "30px" }}
            />
          )} */}
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ItemsTimeLine;
