import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { InfoIcon } from "../../icons/Icons";
import "./tooltipcss.css";
const style = {
  position: "relative",
  top: "0%",
  left: "0%",
  // transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 3,
  zIndex: 2,
  color: "black",
  borderRadius: "10px",

  fontSize: "12px",
  fontFamily: "Poppins",
};

export default function InfrastructureInfoPopup({ data }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {}, [data]);
  return (
    <span class="tooltip-wrap">
      <InfoIcon />
      <div class="tooltip-content">
        <Box sx={style}>{data}</Box>
      </div>
    </span>
  );
}
