import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
//import axios from 'axios';
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../Firebase";
import "./AdminSetting.css";
import Sidebar from "../../Component/Sidebar";
import NavbarTop from "../../Component/NavbarTop";
import { t } from "i18next";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { getCookie } from "../../../Cookies";
import { IP } from "../../../baseUrlAndConfig";

function AvailableCertificates() {
  const navigate = useNavigate();
  const { clrs } = useSelector((state) => state.createClr);
  const [template, settemplate] = useState(null);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const fetchCertificateTemplate = httpsCallable(
    functions,
    "fetchCertificateTemplate"
  );
  const deleteTemplate = httpsCallable(functions, "deleteTemplate");

  const makeCirtificateDefault = httpsCallable(
    functions,
    "makeCirtificateDefault"
  );
  const deletetemplate = (id) => {
    let data = JSON.stringify({
      tempuid: id,
    });
    var config = {
      method: "delete",

      url: `${IP}/certificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        alert(res.data.msg);
        setdeltemp(!deltemp);
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
    //console.log(id);
  };

  const fetchdata = () => {
    setLoading(true);

    // let data = JSON.stringify(sectiondata);
    var config = {
      method: "get",

      url: `${IP}/certificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log("cerificate fetch:", res);
        settemplate(res.data);
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);

  const makeCirtificateDefaultfun = (ele) => {
    setLoading(true);

    let data = JSON.stringify({
      cirtificateId: ele.id,
    });
    var config = {
      method: "post",

      url: `${IP}/certificate/default`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        fetchdata();
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
    // makeCirtificateDefault({
    //   cirtificateId: ele.id,
    // })
    //   .then((el) => {
    //     setLoading(false);
    //     fetchdata();
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });
    // console.log(ele);
  };
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Certificate Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              <div className="create-new-box">
                <Link to={{ pathname: "/admin/settings/certificatecreate" }}>
                  <Button
                    variant="contained"
                    component="span"
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<AddIcon />}
                  >
                    Create New
                  </Button>
                </Link>
              </div>

              <div
                className="bigger-container"
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
              >
                <div> Available Certificate</div>
                <div style={{ display: "flex" }}>
                  <div>Action</div>
                  <div style={{ marginLeft: "100px", marginRight: "10px" }}>
                    Make Default
                  </div>
                </div>
              </div>
              <div className="">
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}

                {template?.map((ele) => {
                  return (
                    <div className="outer-inner-container">
                      <div className="templatename">{ele.templateName}</div>

                      <div className="button-box">
                        {ele.templateName != "Default Template" && (
                          <div className="button-box-inner mx-3 my-1">
                            <Button
                              variant="contained"
                              component="span"
                              color="warning"
                              onClick={() => {
                                deletetemplate(ele.id);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                        <div className="button-box-inner mx-3 my-1">
                          <Link
                            to={{
                              pathname: "/admin/settings/certificate",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              Edit
                            </Button>
                          </Link>
                        </div>
                        <div className="button-box-inner mx-3 my-1">
                          {/* <Link to={{
                pathname: "/certificatedetails",
            state: { fromDashboard: "it is first" }}}> */}
                          <a
                            href={ele.templateUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              View
                            </Button>
                          </a>
                          {/* </Link>   */}
                        </div>

                        <div
                          className="button-box"
                          style={{ marginLeft: "100px", marginRight: "50px" }}
                        >
                          <input
                            type="checkbox"
                            name={"default"}
                            onChange={(e) => {
                              makeCirtificateDefaultfun(ele);
                            }}
                            checked={ele.isDefault === true}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AvailableCertificates;
