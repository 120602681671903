import "./overallreoresentation.css";
import { AgChartsReact } from "ag-charts-react";
import { useEffect, useState } from "react";
import DI from "./Di";
import { display } from "@mui/system";
import Stacked from "./stacked";
import LineGraph from "./LineGraph";
import { Country, State, City } from "country-state-city";
import female from "../../images/non-binary.png";
import male from "../../images/male.png";
// import { yearData } from "./yeardata";
import Gbbvbar from "./Gbbvbar.png";
import dbbvbarline from "./dbbvbarline.png";
import Genderobar from "./Genderobar.png";
import orgline from "./orgline.png";
import overallgroup from "./overallgroup.png";
import nonBinary from "../../images/female.png";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import { ValidateAuth } from "../../../AuthToken";
import { getCookie } from "../../../Cookies";
import StackedForOrg from "./StatedFororg";
import { useNavigate } from "react-router";
import HrloadingScreen from "../../LodingHrscreen";
import ORGrapfLegent from "./ORGrapfLegent";
export default function OverallRepresentationDI() {
  const [yearData, setyearData] = useState([]);
  const [lodingspagestate, setlodingspagestate] = useState(false);
  const [bussinessverticalarr, setbussinessverticalarr] = useState([]);
  const [countryAndState, setCountryAndState] = useState({
    state: "",
    country: "",
    year: "",
  });
  const [regionAndYear, setRegionAndYear] = useState({
    year: "",

    bussinessvr: "",
  });
  const [
    strockedGrafForBussinessVertical,
    setstrockedGrafForBussinessVertical,
  ] = useState([]);

  const [
    RepresentationofGendebybusinessverticalbuvr,
    setRepresentationofGendebybusinessverticalbuvr,
  ] = useState([]);
  let intarr = new Array(100).fill(0);
  const [tempArr, setTempArr] = useState(intarr);
  const [positionInorgGraph, setPositionInorgGraph] = useState([]);
  const [positionInorgLineGraph, setPositionInorgLineGraph] = useState([]);
  const [caountryansState, setCountryandState] = useState({});
  const [stateListForOverall, setStateListForOverall] = useState([]);
  const [dataList, setdataList] = useState([]);

  const [flagForGenderByBV, setFlagForGenderByBV] = useState(true);
  const [valueForGenderByBV, setValueForGenderByBV] = useState({
    year: "",
    br: "",
  });

  const [valueForGenderByposition, setValueForGenderByposition] = useState("");
  const [flagForGenderByposition, setFlagForGenderByposition] = useState(true);

  useEffect(() => {
    let configvr = {
      method: "get",

      url: `${IP}/bussinessVertical`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configvr).then((res) => {
      setbussinessverticalarr(res.data);
    });

    let configarr = {
      method: "get",

      url: `${IP}/bussinessVertical/year`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configarr).then((res) => {
      setyearData(res.data);
    });

    let configreyerr = {
      method: "get",

      url: `${IP}/bussinessVertical/country`,
      // params: { year: year, businessVertical: region },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configreyerr).then((res) => {
      console.log(res.data);
      setCountryandState(res.data);
      //  setPositionInorgGraph(res.data);
    });
  }, []);

  const fetchdata = (country, state, year) => {
    if (!country || !state || !year) {
      // alert("inside");
      return;
    }
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/getOverAllGenderRepresentationGraph`,
      params: { country: country, state: state, year: year },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        // console.log(res.data);
        setlodingspagestate(false);
        setdataList(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };

  const fetchdataForStrockedBar = (year) => {
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/getGenderByBusinessVerticalGraphByYear`,
      params: { year: year },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingspagestate(false);
        setstrockedGrafForBussinessVertical(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };

  const fetchRepresentationofGenderbusinessvertical = (vr) => {
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/getGenderByBusinessVerticalGraphByBusinessLevel`,
      params: { businessVertical: vr },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingspagestate(false);
        setRepresentationofGendebybusinessverticalbuvr(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };

  const fetchGenderbypositionintheorggraph = (region, year) => {
    if (!region || !year) {
      return;
    }
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/getGenderByPositionInOrgGraph`,
      params: { year: year, businessVertical: region },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setlodingspagestate(false);
        setPositionInorgGraph(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };

  const fetchcountryandState = (region, year) => {};
  const fetchGenderbypositionintheorgLineGraph = (gender) => {
    setlodingspagestate(true);
    let configrecrut = {
      method: "get",

      url: `${IP}/graph/getGenderBussinessVerticalInOrgGraphByGender`,
      params: { gender: gender },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        // console.log(res.data);
        setlodingspagestate(false);
        setPositionInorgLineGraph(res.data);
      })
      .catch((err) => {
        setlodingspagestate(false);
      });
  };

  return (
    <Stack spacing={5}>
      <HrloadingScreen open={lodingspagestate} />

      {/* graph box 1  */}
      <Box
        className="overallRepresentationDi"
        sx={{
          backgroundColor: "#fff",
          padding: "25px 20px",
          mt: "20px",
        }}
      >
        <Box className="overallcityandcountry">
          {/* select 1  */}
          <FormControl className="ORformSelectInput">
            <InputLabel id="demo-simple-select-label2">Country</InputLabel>
            <Select
              className="ORCityCountrySelect"
              label="Country"
              labelId="demo-simple-select-label2"
              id="demo-simple-select"
              MenuProps={{
                sx: {
                  maxHeight: "250px",
                  maxWidth: "250px",
                },
              }}
              sx={{
                fontSize: "18px",
                backgroundColor: "transparent",
                borderRadius: "15px",
              }}
              value={countryAndState.country}
              onChange={(e) => {
                setdataList([]);
                setCountryAndState((prev) => ({
                  ...prev,
                  country: e.target.value,
                  state: "",
                  year: "",
                }));

                console.log(e.target.value);
                let tes = e.target.value;
                setStateListForOverall(caountryansState?.region?.[tes]);
              }}
            >
              {caountryansState?.country?.map((el) => (
                <MenuItem
                  sx={{
                    width: "500px",
                  }}
                  value={el}
                >
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* select 2  */}

          <FormControl className="ORformSelectInput">
            <InputLabel id="demo-simple-select-label1">State</InputLabel>
            <Select
              className="ORCityCountrySelect"
              label="State"
              labelId="demo-simple-select-label1"
              id="demo-simple-select"
              MenuProps={{
                sx: {
                  maxHeight: "250px",
                  maxWidth: "250px",
                },
              }}
              sx={{
                height: "55px",
                fontSize: "18px",
                backgroundColor: "transparent",
                borderRadius: "15px",
              }}
              value={countryAndState.state}
              onChange={(e) => {
                setCountryAndState((prev) => ({
                  ...prev,
                  state: e.target.value,
                }));

                fetchdata(
                  countryAndState.country,
                  e.target.value,
                  countryAndState.year
                );
              }}
            >
              {stateListForOverall?.map((ele) => (
                <MenuItem value={ele}> {ele}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* select 3  */}

          <FormControl className="ORformSelectInput">
            <InputLabel id="demo-simple-select-label">Year</InputLabel>
            <Select
              className="ORCityCountrySelect"
              label="Year"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              MenuProps={{
                sx: {
                  maxHeight: "250px",
                  maxWidth: "250px",
                },
              }}
              sx={{
                height: "55px",
                fontSize: "18px",
                backgroundColor: "transparent",
                borderRadius: "15px",
              }}
              value={countryAndState.year}
              onChange={(e) => {
                setCountryAndState((prev) => ({
                  ...prev,
                  year: e.target.value,
                }));
                fetchdata(
                  countryAndState.country,
                  countryAndState.state,

                  e.target.value
                );
              }}
            >
              {yearData?.map((ele) => (
                <MenuItem value={ele}> {ele}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* graph  */}
        {dataList.nonBinary || dataList.male || dataList.female ? (
          <Box className="ORGraphData1">
            {dataList.nonBinary
              ? //dataList?.nonBinary
                tempArr?.map((ele, index) => {
                  if (index < dataList?.nonBinary) {
                    return (
                      <Box>
                        <img
                          src={nonBinary}
                          style={{ height: "32px" }}
                          alt=""
                        />
                      </Box>
                    );
                  } else {
                    return;
                  }
                })
              : ""}

            {dataList?.female
              ? //dataList?.nonBinary
                tempArr?.map((ele, index) => {
                  if (index < dataList?.female) {
                    return (
                      <Box>
                        <img src={female} style={{ height: "32px" }} alt="" />
                      </Box>
                    );
                  } else {
                    return;
                  }
                })
              : ""}

            {dataList?.male
              ? //dataList?.nonBinary
                tempArr?.map((ele, index) => {
                  if (index < dataList?.male) {
                    return (
                      <Box>
                        <img src={male} style={{ height: "32px" }} alt="" />
                      </Box>
                    );
                  } else {
                    return;
                  }
                })
              : ""}
          </Box>
        ) : (
          <img src={overallgroup} style={{ width: "90%" }} />
        )}

        {/* legends  */}
        <Box className="ORGraph1Legend">
          {/* non binary  */}
          <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Box
              style={{
                backgroundColor: "#FE9CBF",
                color: "red",
                width: "10px",
                height: "10px",
              }}
            ></Box>
            <Typography className="ORDiversityLegendText">
              Non-binary {dataList?.nonBinary} %
            </Typography>
          </Box>

          {/* female  */}
          <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Box
              style={{
                backgroundColor: "#AFFB63",
                color: "red",
                width: "10px",
                height: "10px",
              }}
            ></Box>
            <Typography className="ORDiversityLegendText">
              Female {dataList?.female} %
            </Typography>
          </Box>

          {/* male  */}
          <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Box
              style={{
                backgroundColor: "#7EC1FF",

                width: "10px",
                height: "10px",
              }}
            ></Box>
            <Typography className="ORDiversityLegendText">
              Male {dataList?.male} %
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className="ORGradingMainBox">
        <Box className="ORHeadings">Gender by business vertical</Box>{" "}
        <Box
          className="ORGradingMainBoxButton"
          sx={{
            display: "flex",

            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Box
            sx={{
              width: "18px",
              height: "18px",
              borderRadius: "50%",
              background: flagForGenderByBV ? "" : "black",
              border: "2px solid black",
              cursor: "pointer",
            }}
            onClick={() => {
              setFlagForGenderByBV(!flagForGenderByBV);
            }}
          >
            {" "}
          </Box>
          <Typography
            onClick={() => {
              setFlagForGenderByBV(!flagForGenderByBV);
            }}
            sx={{
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            Time series
          </Typography>
        </Box>
      </Box>

      <div
        className="genbybussinessvertical"
        style={{
          marginTop: "0px",
        }}
      >
        {/* graph box 2  */}
        {flagForGenderByBV ? (
          <Box className="genbybussinessverticalchiled">
            <Box className={"GraphBoxWithSelect"}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "column",
                  gap: "20px",
                  marginRight: "10px",
                  height: "25%",
                  zIndex: 10,
                  "@media(maxWidth: 600px)": {
                    width: "80%",
                    display: "grid",
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <Box>
                  <FormControl className="SingleSelectFC">
                    <InputLabel id="demo-simple-select-label3">Year</InputLabel>
                    <Select
                      className="ORSingleSelect"
                      label="Year"
                      labelId="demo-simple-select-label3"
                      id="demo-simple-select"
                      MenuProps={{
                        sx: {
                          maxHeight: "250px",
                          maxWidth: "250px",
                        },
                      }}
                      sx={{
                        fontSize: "18px",
                        backgroundColor: "transparent",
                        borderRadius: "15px",
                      }}
                      value={valueForGenderByBV.year}
                      //value={countryAndState.country}
                      onChange={(e) => {
                        // setCountryAndState((prev) => ({
                        //   ...prev,
                        //   country: e.target.value,
                        // }));
                        setValueForGenderByBV((prev) => ({
                          ...prev,
                          year: e.target.value,
                        }));
                        fetchdataForStrockedBar(e.target.value);
                      }}
                    >
                      {yearData.map((el) => (
                        <MenuItem value={el}> {el}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <ORGrapfLegent />
              </Box>

              <Box
                sx={{
                  height: "73%",
                  mt: "20px",
                }}
              >
                <Stacked
                  data={strockedGrafForBussinessVertical}
                  Gbbvbar={Gbbvbar}
                  overAll={"overAll"}
                />
              </Box>

              {/* <Box
                sx={{
                  height: "80%",
                  overflow: "scroll",
                }}
              >
               
              </Box> */}
            </Box>
          </Box>
        ) : (
          <Box className="genbybussinessverticalchiled">
            {/* graph box 3 */}
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: "25px 10px",
                height: "100%",
                borderRadius: "0px 0px 24px 24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "20%",
                  justifyContent: "start",
                  gap: "20px",
                  marginRight: "10px",
                }}
              >
                <FormControl className="SingleSelectFC">
                  <InputLabel id="demo-simple-select-label90">
                    Business vertical
                  </InputLabel>
                  <Select
                    className="ORSingleSelect"
                    label="Business vertical"
                    labelId="demo-simple-select-label90"
                    id="demo-simple-select"
                    value={valueForGenderByBV.br}
                    MenuProps={{
                      sx: {
                        maxHeight: "250px",
                        maxWidth: "250px",
                      },
                    }}
                    sx={{
                      fontSize: "18px",
                      backgroundColor: "transparent",
                      borderRadius: "15px",
                    }}
                    onChange={(e) => {
                      // setRegionAndYear((prev) => ({
                      //   ...prev,
                      //   bussinessvr: e.target.value,
                      // }));
                      // fetchdataForStrockedBar(e.target.value);
                      setValueForGenderByBV((prev) => ({
                        ...prev,
                        br: e.target.value,
                      }));
                      fetchRepresentationofGenderbusinessvertical(
                        e.target.value
                      );
                    }}
                  >
                    {bussinessverticalarr?.map((ele) => (
                      <MenuItem value={ele}> {ele}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box
                sx={{
                  height: "75%",
                  width: "97%",
                  overflowX: "scroll",
                  overflowY: "scroll",
                  marginTop: "25px",
                }}
              >
                <LineGraph
                  data={RepresentationofGendebybusinessverticalbuvr}
                  dbbvbarline={dbbvbarline}
                  type={"Timeseriesofgenderbybusinessvertical"}
                />
              </Box>
            </Box>
          </Box>
        )}
      </div>

      {/* ////Gender by position in the org */}

      <Box className="ORGradingMainBox">
        <Box className="ORHeadings">Gender by position</Box>
        <Box
          className="ORGradingMainBoxButton"
          sx={{
            display: "flex",

            gap: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <Box
            sx={{
              width: "18px",
              height: "18px",
              borderRadius: "50%",
              background: flagForGenderByposition ? "" : "black",
              border: "2px solid black",
              cursor: "pointer",
            }}
            onClick={() => {
              setFlagForGenderByposition(!flagForGenderByposition);
            }}
          >
            {" "}
          </Box>
          <Typography
            onClick={() => {
              setFlagForGenderByposition(!flagForGenderByposition);
            }}
            sx={{
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            Time series
          </Typography>
        </Box>
      </Box>
      <Box
        className="genbybussinessvertical"
        style={{
          marginTop: "0px",
        }}
      >
        {flagForGenderByposition ? (
          <Box className="genbybussinessverticalchiled">
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: "25px 10px",
                borderRadius: "0px 0px 24px 24px",
              }}
            >
              {/* // <Stacked data={positionInorgGraph} /> */}
              <Box
                // sx={{
                //   display: "flex",
                //   justifyContent: "start",
                //   gap: "20px",
                //   marginRight: "10px",
                // }}

                sx={{
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "column",
                  gap: "20px",
                  marginRight: "10px",
                  height: "25%",
                  zIndex: 10,
                  "@media(maxWidth: 600px)": {
                    width: "80%",
                    display: "grid",
                    gridTemplateColumns: "1fr",
                  },
                }}
              >
                <Box
                  sx={{
                    gap: "20px",
                    display: "flex",
                  }}
                >
                  <FormControl className="ORformSelectInput">
                    <InputLabel id="demo-simple-select-label5">
                      Business vertical
                    </InputLabel>
                    <Select
                      className="ORCityCountrySelect"
                      label="Business vertical"
                      labelId="demo-simple-select-label5"
                      id="demo-simple-select"
                      MenuProps={{
                        sx: {
                          maxHeight: "250px",
                          maxWidth: "250px",
                        },
                      }}
                      sx={{
                        fontSize: "18px",
                        backgroundColor: "transparent",
                        borderRadius: "15px",
                      }}
                      value={regionAndYear.bussinessvr}
                      onChange={(e) => {
                        setRegionAndYear((prev) => ({
                          ...prev,
                          bussinessvr: e.target.value,
                        }));

                        fetchGenderbypositionintheorggraph(
                          e.target.value,
                          regionAndYear.year
                        );
                        // fetchdataForStrockedBar(e.target.value);
                      }}
                    >
                      {bussinessverticalarr?.map((ele) => (
                        <MenuItem value={ele}> {ele}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className="ORformSelectInput">
                    <InputLabel id="demo-simple-select-label6">Year</InputLabel>
                    <Select
                      className="ORCityCountrySelect"
                      label="Year"
                      labelId="demo-simple-select-label6"
                      id="demo-simple-select"
                      MenuProps={{
                        sx: {
                          maxHeight: "250px",
                          maxWidth: "250px",
                        },
                      }}
                      sx={{
                        fontSize: "18px",
                        backgroundColor: "transparent",
                        borderRadius: "15px",
                      }}
                      value={regionAndYear.year}
                      onChange={(e) => {
                        setRegionAndYear((prev) => ({
                          ...prev,
                          year: e.target.value,
                        }));
                        fetchGenderbypositionintheorggraph(
                          regionAndYear.bussinessvr,
                          e.target.value
                        );
                      }}
                    >
                      {yearData.map((el) => (
                        <MenuItem value={el}> {el}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <ORGrapfLegent />
              </Box>

              <StackedForOrg
                data={positionInorgGraph}
                legendPosition={"top"}
                Gbbvbar={Genderobar}
              />
            </Box>
          </Box>
        ) : (
          <Box className="genbybussinessverticalchiled">
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: "25px 10px",
                borderRadius: "0px 0px 24px 24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  marginRight: "10px",
                }}
              >
                <FormControl className="SingleSelectFC">
                  <InputLabel id="demo-simple-select-label7">Gender</InputLabel>

                  <Select
                    className="ORSingleSelect"
                    label="Gender"
                    labelId="demo-simple-select-label7"
                    id="demo-simple-select"
                    value={valueForGenderByposition}
                    sx={{
                      fontSize: "18px",
                      backgroundColor: "transparent",
                      borderRadius: "15px",
                    }}
                    name=""
                    onChange={(e) => {
                      setValueForGenderByposition(e.target.value);
                      //setPositionInorgLineGraph(e.target.value);
                      fetchGenderbypositionintheorgLineGraph(e.target.value);
                    }}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>

                    <MenuItem value="nonBinary">Non-binary</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  width: "90%",
                  height: "90%",
                  overflow: "hidden",
                  marginTop: "50px",
                }}
              >
                <LineGraph
                  data={positionInorgLineGraph}
                  dbbvbarline={orgline}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Stack>
  );
}
