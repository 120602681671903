import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";

import "./event.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { Timestamp } from "firebase/firestore";
import { Input, MenuItem, Select } from "@mui/material";
import HrloadingScreen from "../../../../Hr/LodingHrscreen";
export const EventList = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);

  const [showLoading, setLoading] = useState(true);
  const [inputdata, setinputdata] = useState("");

  const [lodingScreenPop, setlodingScreenPop] = useState(false);
  const [extarArr, setextarArr] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // delete company

  const [filterState, setFilterState] = useState({
    month: "",
    year: "",
  });

  // get company and department data

  const yerarAndMonthFilter = (year, month) => {
    let data = extarArr.filter((el) => {
      let temp = {};
      if (el.year == year && el.month == month) {
        return true;
      } else if (!month && el.year == year) {
        return true;
      } else if (!year && el.month == month) {
        return true;
      }
      return false;
    });
    settemplate(data);
  };
  const fetchdata = () => {
    setLoading(true);
    setlodingScreenPop(true);
    var config = {
      method: "get",

      url: `${IP}/event`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        setlodingScreenPop(false);
        if (res.data?.error) {
        } else {
          setextarArr(res.data);
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setlodingScreenPop(false);
        setLoading(false);
        //console.log(err);
      });
  };

  const eventdelete = (ele) => {
    setLoading(true);

    const data = JSON.stringify({
      eventId: ele.eventId,
    });
    var config = {
      method: "delete",

      url: `${IP}/event`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    console.log(ele);
    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        fetchdata();
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const eventnamefilter = (name) => {
    // console.log(inputdata);

    let temparr = template.filter((el) => el.name.includes(inputdata));
    // console.log(temparr);
    settemplate(temparr);
  };
  useEffect(() => {
    fetchdata();
  }, [deltemp]);

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <HrloadingScreen
          open={lodingScreenPop}
          onClose={() => {
            setlodingScreenPop(false);
          }}
        />
        <NavbarTop page={t("Event")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>{" "}
                </div>

                <div>
                  {" "}
                  {/* <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={() => navigate("/admin/settings/CreateWebinars")}
                  >
                    Add
                  </Button>{" "} */}
                </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  height: "100px",
                  marginBottom: "10px",
                  display: "grid",

                  gridTemplateColumns: "1.5fr 1fr 1fr 1fr",
                  gridGap: "20px",
                }}
              >
                <div>
                  {" "}
                  <div className="admin-dashboard-main-mid-item">
                    <div className="admin-dashboard-main-mid-item-txt">
                      {t("Event Name")}
                    </div>
                    <div style={{ position: "relative" }}>
                      <input
                        type="text"
                        value={inputdata}
                        onChange={(e) => {
                          setinputdata(e.target.value);
                        }}
                        style={{ width: "170px", height: "28px" }}
                      />

                      <div
                        className="admin-dashboard-main-top-last"
                        style={{
                          position: "absolute",
                          top: "-0.5vw",
                          left: "13.5vw",
                        }}
                      >
                        <button
                          onClick={eventnamefilter}
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                            height: "28px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          //   onClick={() => handleSearch("company")}
                        >
                          <div style={{ marginRight: "-2.9vw" }}>
                            {t("Filter")}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className="admin-dashboard-main-mid-item">
                  <div className="admin-dashboard-main-mid-item-txt">
                    {t("Year")}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Select
                      value={filterState.year}
                      onChange={(e) => {
                        setFilterState((prev) => ({
                          ...prev,
                          year: e.target.value,
                        }));
                        yerarAndMonthFilter(e.target.value, filterState.month);
                      }}
                      type="text"
                      sx={{ width: "100%", height: "28px", marginTop: "5px" }}
                    >
                      <MenuItem>select</MenuItem>
                      <MenuItem value={"2021"}>2021</MenuItem>

                      <MenuItem value={"2022"}>2022</MenuItem>
                      <MenuItem value={"2023"}> 2023</MenuItem>
                      <MenuItem value="2024"> 2024</MenuItem>
                      <MenuItem value="2025"> 2025</MenuItem>
                      <MenuItem value="2026"> 2026</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="admin-dashboard-main-mid-item">
                  <div className="admin-dashboard-main-mid-item-txt">
                    {t("Month")}
                  </div>
                  <div style={{ position: "relative" }}>
                    <Select
                      className="inputbox"
                      value={filterState.month}
                      onChange={(e) => {
                        setFilterState((prev) => ({
                          ...prev,
                          month: e.target.value,
                        }));
                        yerarAndMonthFilter(filterState.year, e.target.value);
                      }}
                      MenuProps={{
                        sx: {
                          height: "250px",
                        },
                      }}
                      placeholder="  Webinar Topic"
                      sx={{ width: "100%", height: "28px", marginTop: "5px" }}
                      // sx={{
                      //   padding: "5px",
                      //   height: "35px",
                      //   width: "250px",
                      // }}
                    >
                      <MenuItem value="january"> January </MenuItem>
                      <MenuItem value="february"> February </MenuItem>
                      <MenuItem value="march"> March </MenuItem>

                      <MenuItem value="april"> April</MenuItem>

                      <MenuItem value="may"> May </MenuItem>
                      <MenuItem value="june"> June</MenuItem>
                      <MenuItem value="july"> July </MenuItem>

                      <MenuItem value="august"> August </MenuItem>
                      <MenuItem value="september"> September </MenuItem>
                      <MenuItem value="october"> October</MenuItem>
                      <MenuItem value="november"> November </MenuItem>

                      <MenuItem value="december"> December </MenuItem>
                    </Select>{" "}
                  </div>
                </div>
                <div
                  class="admin-dashboard-main-top-last"
                  style={{ height: " 14vh", width: "10vh", marginTop: "5px" }}
                >
                  <button
                    style={{
                      width: "10vw",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={() => {
                      fetchdata();
                      setFilterState({
                        month: "",
                        year: "",
                      });
                      setinputdata();
                    }}
                  >
                    <div>
                      <svg
                        width="auto"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M14.5411 0.306653C14.4915 0.270984 14.4322 0.25 14.3711 0.25H1.96654C1.3371 0.25 0.481658 0.546574 0.872148 1.04025C1.11132 1.34261 1.3505 1.64497 1.58968 1.94732C2.90969 3.61597 4.22969 5.28461 5.54797 6.95471C5.60263 7.02401 5.65578 7.0522 5.74312 7.05203C7.07158 7.04905 8.40008 7.04905 9.72854 7.05206C9.81602 7.05226 9.86925 7.02387 9.92375 6.95477C10.8668 5.75927 11.8117 4.56525 12.7565 3.37123C12.9509 3.12564 13.1452 2.88004 13.3396 2.63444C13.4306 2.51932 13.5217 2.40421 13.6128 2.28911C13.9566 1.85476 14.3003 1.42041 14.6435 0.985631C14.7914 0.798352 14.8165 0.605055 14.6948 0.442077C14.6546 0.388249 14.5979 0.347451 14.5411 0.306653ZM9.39023 8.05537C9.39023 7.9714 9.32216 7.90332 9.23818 7.90332H6.21498C6.14052 7.90332 6.08016 7.96368 6.08016 8.03814C6.08016 8.56841 6.08014 9.09867 6.08012 9.62894C6.08006 11.1763 6.08 12.7236 6.08047 14.2709C6.08058 14.675 6.38111 14.8623 6.7375 14.6802C7.02361 14.534 7.30944 14.3872 7.59527 14.2404C8.09356 13.9845 8.59185 13.7286 9.09161 13.4758C9.30188 13.3694 9.39333 13.2126 9.39264 12.9707C9.38915 11.7332 9.38956 10.4956 9.38997 9.25803C9.3901 8.85715 9.39023 8.45626 9.39023 8.05537Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div>{t("ClearFilters")}</div>
                  </button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {" "}
                <Button
                  variant="contained"
                  style={{
                    marginBottom: "20px",
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={() => navigate("/admin/settings/CreateEvent")}
                >
                  Add
                </Button>{" "}
              </div>
              <div style={{ backgroundColor: "#ffffff", padding: "20px" }}>
                <div
                  className="eventTabel eventTabelTabelHeders"
                  style={{
                    gridGap: "10px",
                  }}
                >
                  <div> S.No</div>
                  <div>Event Name </div>
                  <div>Month </div>

                  <div>Day</div>
                  <div>Year</div>
                  <div>No.of.item</div>
                  <div>Action</div>
                </div>

                {/* <div className="webinarsTabel webinarsTabelTabelBody"> */}
                {template?.map((ele, index) => {
                  return (
                    <div
                      className="eventTabel eventTabelTabelBody borderStyleFortabel"
                      style={{
                        lineBreak: "anywhere",
                        gridGap: "10px",
                      }}
                    >
                      <div>{index}</div>

                      <div>{ele.name}</div>
                      <div style={{}}>
                        {ele.month.charAt(0).toUpperCase() + ele.month.slice(1)}
                      </div>
                      <div>
                        {ele.dayfrom} -{ele.dayto}
                      </div>
                      <div>{ele.year}</div>
                      <div>{ele.count}</div>

                      {/* <div>
                        {Timestamp.fromMillis(ele.date._seconds * 1000)
                          .toDate()
                          .toDateString()}
                      </div> */}

                      <div style={{ display: "flex" }}>
                        <div className="button-box-inner mx-3 my-1">
                          <Link
                            to={{
                              pathname: "/admin/settings/CreateEvent",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              Edit
                            </Button>
                          </Link>
                        </div>

                        <div className="button-box-inner mx-3 my-1">
                          <Button
                            onClick={() => {
                              eventdelete(ele);
                            }}
                            variant="contained"
                            component="span"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
