import { Box } from "@mui/system";
import ResponsiveDrawer from "../Sidebar/HrSideBar";
import HrTopNavbar from "../Sidebar/HrTopNavbar";

import { getCookie } from "../../Cookies";
// import { RightArrowIcon } from "../icons/Icons";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { IP } from "../../baseUrlAndConfig";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import HrloadingScreen from "../LodingHrscreen";
import MobileTopBar from "../Sidebar/MobaileTopBar";
import imgarrow from "./arrow.svg";
const GroupReport = () => {
  const [coursedata, setCoursedata] = useState({});
  const [microData, setMicroData] = useState([]);
  const [gamesData, setGamesData] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [allGroups, setAllGroups] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  const navigate = useNavigate();
  const [lodingscreen, setlodingscreen] = useState(false);
  const [lodingscreenMicro, setlodingscreenMicro] = useState(false);
  const [lodinggames, setlodinggames] = useState(false);

  const [gamesColor, setGamesColor] = useState([
    "#F7D8EC",
    "#DFF5D0",
    "#D0E3FE",
  ]);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    plan: "",
    startDate: "",
    endDate: "",
    subscription: "",
  });

  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    // let newobj =
    //   rolesAndPermission?.Report?.componentPermission.includes("Report");
    // // console.log(newobj.HRDI);
    // if (!newobj) {
    //   navigate("/hr/Learning");
    //   return <></>;
    // }

    if (!rolesAndPermission) {
      setlodingscreen(false);
    } else {
      setlodingscreen(false);

      let newobj =
        rolesAndPermission?.Report?.componentPermission.includes("Report");
      // console.log(newobj.HRDI);
      if (!newobj) {
        navigate(-1);
        return <></>;
      }
    }
  }, [rolesAndPermission]);
  const getevent = (year) => {
    setlodingscreen(true);
    if (!selectedCompany || !selectedGroup) return;
    let configrecrutci = {
      method: "get",

      // url: `${IP}/hrGroupReport/getGroupWiseReport/?groupId=${selectedGroup}`,
      url: `${IP}/hrGroupReport?groupId=${encodeURIComponent(
        selectedGroup
      )}&companyId=${selectedCompany}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrutci)
      .then((res) => {
        setlodingscreen(false);
        setCoursedata(res.data);
      })
      .catch((err) => {
        setlodingscreen(false);
        //console.log(err);
      });

    // setlodingscreen(true);
    // let configmicro = {
    //   method: "get",

    //   url: `${IP}/hrGroupReport/allMicroCoursesReport`,

    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${getCookie("bearer")}`,
    //   },
    //   data: "dataregionf",
    // };
    // axios(configmicro)
    //   .then((res) => {
    //     setlodingscreen(false);
    //     console.log(res.data?.courseUserData);
    //     setMicroData(res.data?.courseUserData);
    //   })
    //   .catch((err) => {
    //     setlodingscreen(false);
    //     //console.log(err);
    //   });
  };

  const geteventdataMicroCourse = (year) => {
    setlodingscreenMicro(true);
    if (!selectedCompany || !selectedGroup) return;
    let configmicro = {
      method: "get",

      url: `${IP}/hrGroupReport/allMicroCoursesReport?groupId=${encodeURIComponent(
        selectedGroup
      )}&companyId=${selectedCompany}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configmicro)
      .then((res) => {
        console.log("MICROCOURSE", res.data);
        setlodingscreenMicro(false);
        console.log(res.data?.courseUserData);
        setMicroData(res.data?.courseUserData);
      })
      .catch((err) => {
        setlodingscreenMicro(false);
        //console.log(err);
      });
  };

  const geteventdataGames = (year) => {
    setlodinggames(true);
    if (!selectedCompany || !selectedGroup) return;
    let configmicro = {
      method: "get",

      url: `${IP}/hrGroupReport/gameEmployee/?groupId=${encodeURIComponent(
        selectedGroup
      )}&companyId=${selectedCompany}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configmicro)
      .then((res) => {
        setlodinggames(false);

        console.log(res.data?.courseUserData);
        setGamesData(res.data?.courseUserData);
      })
      .catch((err) => {
        setlodinggames(false);
        //console.log(err);
      });
  };
  const getUserProfile = () => {
    let data = JSON.stringify({});

    var config = {
      method: "get",

      url: `${IP}/userProfile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(config).then((res) => {
      //console.log(res.data.message);
      setUserData(res.data.message);
    });
  };
  const getAllGroups = () => {
    var config = {
      method: "get",
      url: `${IP}/hrGroupReport/getAllGroups?companyId=${selectedCompany}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config).then((res) => {
      console.log(res);
      console.log("all ok");
      setSelectedGroup(res.data[0]?.groupId);
      setAllGroups(res.data);
      if (res.data[0].expiryDate) {
        setExpiryDate(res.data[0].expiryDate._seconds);
      }
    });
  };
  const getAllCompanies = () => {
    var config = {
      method: "get",
      url: `${IP}/hrGroupReport/getAllCompany`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config).then((res) => {
      console.log(res.data.companyArray);
      setSelectedCompany(res.data.companyArray[0].companyId);
      setAllCompanies(res.data.companyArray);
    });
  };

  useEffect(() => {
    getAllCompanies();
  }, []);
  useEffect(() => {
    getAllGroups();
  }, [selectedCompany]);
  useEffect(() => {
    allGroups?.map((_) => {
      if (_.groupId == selectedGroup) {
        setExpiryDate(_?.expiryDate?._seconds);
      }
    });
    if (selectedCompany) {
      geteventdataMicroCourse();
      geteventdataGames();
      getevent();
    }
    getUserProfile();
  }, [selectedGroup, selectedCompany]);

  const diff_weeks = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    return Math.round(diff);
  };

  const formatDate = (date) => {
    const xx = new Date(date * 1000);
    const nowDate = new Date();
    console.log(xx.getTime(), nowDate.getTime());
    if (xx.getTime() < nowDate.getTime()) {
      return -1;
    }
    // if (xx < nowDate) {
    //   if (
    //     Math.abs(diff_weeks(xx, nowDate)) == 1 ||
    //     Math.abs(diff_weeks(xx, nowDate)) == 0
    //   ) {
    //     return 1;
    //   }
    //   return -1;
    else if (xx.getTime() > nowDate.getTime()) {
      if (
        Math.abs(diff_weeks(xx, nowDate)) == 1 ||
        Math.abs(diff_weeks(xx, nowDate)) == 0
      ) {
        return 1;
      }
      console.log("more than week");
      return 10;
    }
  };
  const niceExpiry = (param) => {
    const datee = new Date(param * 1000).toUTCString();
    // console.log({ datee });
    // const date = new Date(param * 1000).toUTCString().getDate();
    // const day = new Date(param * 1000).toUTCString().getUTCDay();
    // const month = new Date(param * 1000)
    //   .toUTCString()
    //   .toLocaleString("default", {
    //     month: "long",
    //   });
    // const year = new Date(param * 1000).toUTCString().getFullYear();
    // let res = `${month} ${date},${year}`;
    return datee.split("T")[0];
  };
  return (
    <div
      className="hrmainbox"
      style={{
        background: "#F3F1FF",
        minHeight: "100vh",
      }}
    >
      <Box className="mobileSideBar_leaning">
        {" "}
        <ResponsiveDrawer sidebar={"GroupReport"} />{" "}
      </Box>

      <HrloadingScreen open={lodingscreen} />
      {/* <HrloadingScreen open={lodingscreenMicro} /> */}
      <Box sx={{ width: "100vw", position: "relative" }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 20,
            backgroundColor: "#F3F1FF",
          }}
        >
          <MobileTopBar sidebar={"Progress-Report"} />
          <HrTopNavbar title={`Hello ${getCookie("EMNAME")}!`} />
        </Box>
        <Box
          sx={{
            display: "flex",
            minHeight: "283px",
            padding: "0px 24px 24px 24px",
            gap: "10%",
            "@media (max-width: 780px)": {
              flexDirection: "column-reverse",
            },
          }}
        >
          <Box
            sx={{
              width: "60%",
              "@media (max-width: 780px)": {
                width: "100%",
              },
              marginTop: "24px",
            }}
          >
            {allCompanies.length > 1 && (
              <FormControl
                sx={{
                  width: "15rem",
                  borderRadius: "15px",
                  marginTop: "15px",
                  marginRight: "15px",
                }}
              >
                <InputLabel id="demo-simple-select-label">Company</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCompany}
                  label="Company"
                  onChange={(e) => setSelectedCompany(e.target.value)}
                  sx={{ borderRadius: "15px" }}
                >
                  {/* {allCompanies?.map((val, idx) => {
                  return <h1>Hello</h1>;
                })} */}
                  {allCompanies?.map((val, idx) => {
                    {
                      console.log(val.companyId);
                    }
                    return (
                      <MenuItem value={val.companyId} key={idx}>
                        {val.companyName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            {allGroups.length > 1 && (
              <FormControl
                sx={{ width: "15rem", borderRadius: "15px", marginTop: "15px" }}
              >
                <InputLabel id="demo-simple-select-label">Group</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedGroup}
                  label="Group"
                  onChange={(e) => {
                    setSelectedGroup(e.target.value);
                  }}
                  sx={{ borderRadius: "15px" }}
                >
                  {allGroups.map((val, idx) => {
                    return (
                      <MenuItem value={val.groupId} key={idx}>
                        {val.groupName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
            {/* {console.log((expiryDate))} */}
            <Typography sx={{ color: "red", ml: "2px" }}>
              {expiryDate !== "" &&
                formatDate(expiryDate) < 0 &&
                "GROUP EXPIRED"}

              {/* {!expiryDate === ""
                ? formatDate(expiryDate) < 0
                  ? "This Group has expired"
                  : formatDate(expiryDate) == 1 || formatDate(expiryDate) == 0
                  ? `This group will expire within a week on ${niceExpiry(
                      expiryDate
                    )}`
                  : ""
                : ""} */}
              {/* {expiryDate
                ? formatDate(expiryDate) < 0
                  ? "This Group has expired"
                  : formatDate(expiryDate) == 1 || formatDate(expiryDate) == 0
                  ? `This Group will expire within a week on ${niceExpiry(
                      expiryDate
                    )}`
                  : ""
                : ""} */}
            </Typography>

            <Box
              sx={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "500",
                marginTop: "30px",
              }}
            >
              {" "}
              Employees who have completed
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                "@media (max-width: 780px)": {
                  gridTemplateColumns: "1fr",
                },
                gridGap: "12px",
                marginTop: "24px",
              }}
            >
              {coursedata?.courseUserData?.map((el) => {
                return (
                  <Box
                    sx={{
                      padding: "24px",
                      width: "350px",
                      "@media (max-width: 780px)": {
                        width: "100%",
                      },
                      overflow: "hidden",
                      background: "#D6D4E2",
                      borderRadius: "24px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "4fr 1fr",
                      }}
                    >
                      {" "}
                      <Box
                        className="microlerningdiscription"
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: "40px",
                          color:
                            !el.count || el.count === 0 ? "#bcbcbc" : "#6846C7",
                        }}
                      >
                        {el?.count || "0"}
                      </Box>
                      <Box
                        onClick={() => {
                          navigate("/hr/GroupReportView", {
                            state: {
                              ...el,
                              type: "course",
                              groupId: selectedGroup,
                              courseId: el.courseId,
                              count: el.count ? el.count : 0,
                              name: el.name,
                            },
                          });
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                          alignContent: "center",
                          cursor: "pointer",
                          alignItems: "center",
                        }}
                      >
                        <img src={imgarrow} alt="" />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "400",

                        fontSize: "16px",
                      }}
                    >
                      {el.name}
                    </Box>
                  </Box>
                );
              })}
              {coursedata?.courseUserData?.length == 0 && (
                <Box
                  sx={{
                    padding: "24px 10px 24px 10px",
                    width: "350px",
                    height: "188px",
                    background: "#D6D4E2",
                    borderRadius: "24px",
                    display: "flex",
                    justifyItems: "center",
                    // alignItems: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "400",

                      fontSize: "16px",
                    }}
                  >
                    No courses completed
                    {/* {el.name} */}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              width: "30%",

              "@media (max-width: 780px)": {
                width: "100%",
              },
              maxHeight: "283px",
              background: "#E1F566",
              borderRadius: "0px 0px 24px 24px;",
              display: "grid",
              gridTemplateColumns: "1fr",
              gridGap: "10px",
              padding: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyItems: "center",
                background: "#000000",
                color: "#FFFFFF",
                borderRadius: "8px",
                width: "max-content",
                padding: "5px 8px",
                height: "30px",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",

                  whiteSpace: "nowrap",
                }}
              >
                {" "}
                {userData?.subscription} subscription
              </div>
            </Box>
            <Box>
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  opacity: "0.7",
                }}
              >
                {" "}
                Start date
              </Box>
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                {userData?.startDate}
              </Box>
            </Box>
            <Box
              sx={{
                border: "0.5px solid black",
                borderColor: "black",
                opacity: "0.3",
                height: "0.5px",
              }}
            ></Box>
            <Box>
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "400",
                  opacity: "0.7",
                }}
              >
                {" "}
                End date
              </Box>
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "400",
                }}
              >
                {userData?.endDate}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            padding: "24px",
            mt: "1px",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {" "}
            Employees who have completed micro-courses
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gridGap: "12px",
              marginTop: "24px",
            }}
          >
            {lodingscreenMicro && microData?.length == 0 && (
              <Box
                sx={{
                  padding: "24px 10px 24px 10px",
                  width: "350px",
                  height: "188px",
                  background: "#FFFFFF",
                  borderRadius: "24px",
                  display: "flex",
                  justifyItems: "center",
                  // alignItems: "center",
                  alignItems: "center",
                  justifyContent: "center",

                  alignContent: "center",
                }}
              >
                <CircularProgress size="100px" sx={{ color: "#3A36DB" }} />
              </Box>
            )}
            {microData?.length == 0 && !lodingscreenMicro && (
              <Box
                sx={{
                  padding: "24px 10px 24px 10px",
                  width: "350px",
                  height: "188px",
                  background: "#FFFFFF",
                  borderRadius: "24px",
                  display: "flex",
                  justifyItems: "center",
                  // alignItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "400",

                    fontSize: "16px",
                  }}
                >
                  No micro-courses completed
                  {/* {el.name} */}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              "@media (max-width: 780px)": {
                gridTemplateColumns: "1fr",
              },
              gridGap: "12px",
            }}
          >
            {/* microData */}
            {microData?.map((el) => (
              <Box
                sx={{
                  padding: "24px",
                  background: "#FFFFFF",
                  borderRadius: "24px",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "4fr 1fr",
                    color: "#6846C7",
                  }}
                >
                  {" "}
                  <Box
                    className="microlerningdiscription"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "40px",
                      color:
                        !el?.count || el.count === 0 ? "#bcbcbc" : "#6846c7",
                    }}
                  >
                    {el?.count || 0}
                  </Box>
                  <Box
                    onClick={() => {
                      navigate("/hr/GroupReportView", {
                        state: {
                          ...el,
                          type: "microcourse",
                          groupId: selectedGroup,
                        },
                      });
                    }}
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "right",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={imgarrow} alt="" />
                  </Box>
                </Box>
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "400",
                    color: "#000",
                    display: "flex",
                    // alignContent: "center",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  {el.name}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Games Start */}

        <Box
          sx={{
            padding: "24px",
            mt: "1px",
          }}
        >
          <Box
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {" "}
            Employees who have completed games
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gridGap: "12px",
              marginTop: "24px",
            }}
          >
            {lodinggames && gamesData?.length == 0 && (
              <Box
                sx={{
                  padding: "24px 10px 24px 10px",
                  width: "350px",
                  height: "188px",
                  background: "#FFFFFF",
                  borderRadius: "24px",
                  display: "flex",
                  justifyItems: "center",
                  // alignItems: "center",
                  alignItems: "center",
                  justifyContent: "center",

                  alignContent: "center",
                }}
              >
                <CircularProgress size="100px" sx={{ color: "#3A36DB" }} />
              </Box>
            )}
            {gamesData?.length == 0 && !lodinggames && (
              <Box
                sx={{
                  padding: "24px 10px 24px 10px",
                  width: "350px",
                  height: "188px",
                  background: "#FFFFFF",
                  borderRadius: "24px",
                  display: "flex",
                  justifyItems: "center",
                  // alignItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: "400",

                    fontSize: "16px",
                  }}
                >
                  No Games completed
                  {/* {el.name} */}
                </Box>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              "@media (max-width: 780px)": {
                gridTemplateColumns: "1fr",
              },
              gridGap: "12px",
              marginTop: "24px",
            }}
          >
            {/* games */}
            {gamesData?.map((el, index) => {
              let col = 0;
              if (index < 3) {
                col = index;
              } else {
                col = index % 3;
              }

              return (
                <Box
                  sx={{
                    padding: "24px",
                    background: gamesColor[col],
                    borderRadius: "24px",
                    maxHeight: "164px",
                    minHeight: "164px",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "4fr 1fr",
                      color: "#6846C7",
                    }}
                  >
                    {" "}
                    <Box
                      className="microlerningdiscription"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: "40px",
                      }}
                    >
                      {el.count}
                    </Box>
                    <Box
                      onClick={() => {
                        navigate("/hr/GroupReportView", {
                          state: {
                            ...el,
                            type: "games",
                            groupId: selectedGroup,
                          },
                        });
                      }}
                      sx={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "right",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={imgarrow} alt="" />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      color: "#666666",
                      display: "flex",
                      // alignContent: "center",
                      alignItems: "center",
                      fontSize: "16px",
                    }}
                  >
                    {el.name}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
export default GroupReport;
