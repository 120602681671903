import { CREATEROLES } from "./action";

const initialstate = {
  roles: {},
};

export const rolesReducer = (store = initialstate, { type, payload }) => {
  switch (type) {
    case CREATEROLES:
      // console.log("hii");
      return { ...store, roles: { ...payload } };

    default:
      return store;
  }
};
