import { Box, Button, Grid, Paper, styled, Typography } from "@mui/material";
import ResponsiveDrawer from "../Sidebar/HrSideBar";
import CoursesImg from "../images/courses-img.png";
import MicroLearnings from "../images/micro-learnings.png";
import {
  CertificateDownloadIcon,
  RightArrowIcon,
  RightArrowIconblue,
} from "../icons/Icons";
import { useNavigate } from "react-router";
import "./hovereffect.css";
import { lerningicons } from "./lerningIcons";
import imro from "./Vector.svg";
import union from "./Union.svg";
import coursesvg from "./Vector(1).svg";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//import { microArr } from "./microLerningdata";
import { Link } from "react-router-dom";
import { getCookie } from "../../Cookies";
import { functions } from "../../Firebase";
// import {
//   displayOnDesktop,
//   displayOnMobile,
// } from "../../../utilities/commonStyles/commonStyles";
// import {
//   CourseVideoIcons,
//   CourseVideoWatchIcons,
//   SearchIcons,
// } from "../../../utilities/Icons/Icons";

import { IP } from "../../baseUrlAndConfig";
import axios from "axios";
import { grid } from "@mui/system";
import HrTopNavbar from "../Sidebar/HrTopNavbar";
import { ValidateAuth } from "../../AuthToken";
import HrFooter from "../Sidebar/HrFooter";
import WebinarHome from "./Webinar/webonarhome";
import GamesHome from "./Game/gameshome";
import HrloadingScreen from "../LodingHrscreen";
import { DrawerContext } from "../../utilities/context/DrawerProvider";
import MobileTopBar from "../Sidebar/MobaileTopBar";
import "./learning.css";
import { useRef } from "react";
const Item = styled(Paper)(({ theme }) => ({
  position: "relative",
}));

const Learning = () => {
  const navigate = useNavigate();
  const isComponentMounted = useRef();
  const howManyTimes = useRef(0);
  howManyTimes.current++;

  const {
    rolesAndPermission,
    setRolesAndPermission,
    course_Completion,
    setCourse_Completion,
  } = useContext(DrawerContext);
  const [lodingscreen, setlodingscreen] = useState(true);
  const [loading, setLoading] = useState(true);
  const goToHome = () => navigate("/");

  // useEffect(() => {
  //   // ValidateAuth();
  //   let newobj = getCookie("bearer");
  //   // console.log(newobj.HRDI);
  //   if (!newobj) {
  //     navigate("/");
  //     return <></>;
  //   }

  //   // console.log("in the ler");
  // }, []);
  const courseLibrary = httpsCallable(functions, "courseLibrary");
  const { t } = useTranslation();
  const { clrs } = useSelector((state) => state.createClr);

  const uid = getCookie("UID");

  const [microArr, setMicroCourse] = useState([]);

  const [courses, setCourses] = useState([]);
  const [certificates, setCertificates] = useState(null);
  const [searchCoursess, setSearchCourses] = useState([]);

  const [viewAll, setViewAll] = useState(false);

  var list = [];

  async function fetchCoursesCompleted() {
    setlodingscreen(true);
    setLoading(true);

    let configrecrut = {
      method: "get",

      url: `${IP}/userProgress/cousreCompleted`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },

      data: "dataregionf",
      // withCredentials: true,
    };

    axios(configrecrut)
      .then((res) => {
        setCourse_Completion(res.data?.count);
      })
      .catch((er) => {
        setlodingscreen(false);
      });
  }

  async function fetchMicroCourse() {
    let configrecrut = {
      method: "get",

      url: `${IP}/userMicroCourse/courseLibrary`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        setMicroCourse(res.data);

        // setSearchCourses(res.data.filter((e) => e.archived != true).slice(0, 6));
      })
      .catch((e) => {
        setLoading(false);
        setlodingscreen(false);
        console.log(e);
      });

    //console.log(res.data.filter((e)=>e.archived!=true).slice(0, 6));
  }
  async function fetchCourses() {
    let configrecrut = {
      method: "get",

      url: `${IP}/userCourse/courseLibrary`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },

      data: "dataregionf",
      // withCredentials: true,
    };

    axios(configrecrut)
      .then((res) => {
        setCourses(res.data.filter((e) => e.archived != true));
        setSearchCourses(
          res.data.filter((e) => e.archived != true).slice(0, 6)
        );
        setlodingscreen(false);
        setLoading(false);
      })
      .catch((er) => {
        setlodingscreen(false);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (viewAll) {
      setSearchCourses(courses);
    }
  }, [viewAll]);

  const checkExpiry = () => {
    console.log("checkexpiry");
    // console.log(rolesAndPermission);
    let flag = 0;
    for (let [key, value] of Object.entries(rolesAndPermission)) {
      flag = 1;
      console.log(key, value);
      if (key == "Profile" || key == "Strategy") {
        continue;
      } else {
        if (value.viewPermission == true) {
          return false;
        }
      }
    }
    if (flag == 1) return true;
    return false;

    // if(rolesAndPermission['Calender']?.viewPermission == false ){
    //   setSubExpired(true);
    // }
  };
  useEffect(function () {
    howManyTimes.current++;
  }, []);
  useEffect(() => {
    // if (
    //   getCookie("UID") == null ||
    //   getCookie("ROLE") !== "USER" ||
    //   getCookie("ROLE") == "ADMIN"
    // ) {
    //   goToHome();
    //   return <></>;
    // }
    if (checkExpiry()) {
      console.log("checkit");
      navigate("/hr/HrProfilePage", { state: { expired: true } });
    }

    fetchCoursesCompleted();
    fetchMicroCourse();
    fetchCourses();
  }, []);

  //==========get data of course to know which course is allready enrolled==========//

  //resetItemProgress

  /////======= fetch user courses
  const [user_courses, setUserCourses] = useState([]);

  // //console.log(user_courses);

  const searchCourse = (e) => {
    const search = e.target.value;
    const filteredCourses = courses.filter((course) => {
      return course.courseName?.toLowerCase().includes(search.toLowerCase());
    });
    setSearchCourses(filteredCourses.slice(0, 6));
  };

  ////===================endroll here========================//
  const userEnrollment = httpsCallable(functions, "userEnrollment");
  //check single course to direct enroll
  const enrollUser = (courseId, access, status, item) => {
    // userEnrollment({ courseId: courseId, currentDate: new Date() })
    //   .then((res) => {
    //     //console.log(res.data);
    //   })
    //   .catch((err) => {
    //     //console.log(err);
    //   });
    navigate(`course-content/${courseId}`, {
      state: { ...item },
    });
  };

  const microlearningpagecontent = (courseId, item) => {
    // navigate(`/hr/Learning/MicroLearning-content/${courseId}`)
    console.log(item);
    navigate(`MicroLearning-content/${courseId}`, {
      state: { ...item },
    });
  };
  const checkUI = (param) => {
    let flag = 0;
    for (
      let i = 0;
      i < rolesAndPermission.Learning?.componentPermission?.length;
      i++
    ) {
      if (rolesAndPermission.Learning?.componentPermission[i] == param)
        flag = 1;
    }
    if (flag == 1) {
      return true;
    }
    return false;
  };
  return (
    <Box className="hrmainbox" sx={{ backgroundColor: "#F3F1FF" }}>
      <Box className="mobileSideBar_leaning">
        {/* HrTopNavbar */}

        <ResponsiveDrawer sidebar={"Learning"} />
      </Box>

      <HrloadingScreen open={lodingscreen} />
      <Box
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            backgroundColor: "#F3F1FF",
            zIndex: 20,
            top: 0,
          }}
        >
          <MobileTopBar sidebar={"Learning"} />
          <HrTopNavbar title={`Hello ${getCookie("EMNAME")}!`}>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  fontWeight: 600,
                }}
              >
                {" "}
                <Box
                  sx={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    bgcolor: "#E1F566",

                    color: "#000000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {course_Completion}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  Course completed{" "}
                </Box>
              </Box>
              {/* <Box sx={{ display: "flex", gap: "10px", fontSize: "14px" }}>
                {" "}
                <Box
                  sx={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    bgcolor: "#E1F566",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  64
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  Minutes of learning
                </Box>
              </Box> */}
            </Box>
          </HrTopNavbar>
        </Box>

        <Box
          className="leaningResponsive"
          sx={{ width: "100%", overflow: "hidden" }}
        >
          {rolesAndPermission?.Learning?.componentPermission.includes(
            "Courses"
          ) && (
            <Box sx={{ width: "100%" }}>
              <Box
                // data-aos="fade-up"
                // data-aos-duration="1500"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  position: "relative",
                  zIndex: "2",
                }}
              >
                <Box>
                  {" "}
                  <img src={coursesvg} alt="" />
                </Box>
                <Box
                  className="sectionname"
                  sx={{
                    ml: "8px",
                  }}
                >
                  Courses
                </Box>

                {searchCoursess?.length > 1 && (
                  <Box
                    sx={{
                      fontWeight: "14px",
                      fontSize: "14px",
                      ml: "6px",
                      height: "20px",
                      opacity: "0.3",

                      width: "2px",
                      backgroundColor: "#6846C7",
                    }}
                  ></Box>
                )}
                {searchCoursess?.length > 1 && (
                  <Box
                    className="sectionnameall"
                    sx={{
                      ml: "6px",
                      height: "20px",

                      cursor: "pointer",
                      color: "#6846C7",
                    }}
                    onClick={() => navigate("courses")}
                  >
                    View all
                  </Box>
                )}
              </Box>
              <Box sx={{ width: "100%", mt: "24px" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateRows: "1fr",
                    gap: "24px",
                  }}
                  // rowSpacing={10}
                  // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  {/* checkUI("Courses") && */}
                  {!lodingscreen &&
                    !loading &&
                    searchCoursess?.length == 0 &&
                    checkUI("Courses") && (
                      <div>
                        <Typography sx={{ color: "red" }}>
                          Contact your Admin
                        </Typography>
                        <img
                          src="/adminDashboard.png"
                          style={{ width: "400px" }}
                        />
                      </div>
                    )}
                  {/* {searchCoursess.length === 0 && (
                    <Typography sx={{ color: "red" }}>
                      {console.log("length", searchCoursess.length)}
                      DONTNONSAONDASOI JDKLA JSKDLALKDKLASJLKDJA LSKJDKL JSA K
                    </Typography>
                  )} */}

                  {searchCoursess?.map((item, index) => {
                    let status = (() => {
                      let courseRef = user_courses.find(
                        (ele) => ele.courseId == item.courseId
                      );

                      if (!courseRef) {
                        return "Start Course";
                      }
                      let certificateRef = certificates?.find(
                        (ele) => ele.courseId == item.courseId
                      );
                      //console.log(certificateRef, certificates);
                      if (certificateRef) {
                        let isValid = Boolean(
                          Math.floor(new Date().getTime() / 1000) <
                            certificateRef.expiry._seconds
                        );
                        //console.log(isValid, certificateRef.certificateName);
                        if (isValid) return "Completed";
                        if (
                          courseRef.progress.completed !=
                          courseRef.progress.total
                        )
                          return "Continue";
                        else return "Renew";
                      } else if (courseRef.progress.completed == "0")
                        return "Start";
                      else return "Continue";
                    })();
                    if (index >= 1) {
                      return;
                    }
                    return (
                      <Grid
                        item
                        xs={11}
                        key={item.courseId}
                        sx={{
                          borderRadius: "24px",
                          "@media (max-width: 780px)": {
                            width: "95%",
                          },
                        }}
                      >
                        <Item
                          sx={{
                            borderRadius: "24px",
                            backgroundColor: "#F5F5F5",
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: "20px",
                              overflow: "hidden",
                            }}
                          >
                            <Box sx={{ width: "100%" }} className="zoom">
                              <img
                                style={{
                                  width: "100%",
                                  height: "354px",
                                }}
                                src={item?.courseImageURL}
                                alt=""
                              />
                            </Box>
                          </Box>
                          <Box className="zoomtwo courseNameAnd_Enroll_box">
                            <Typography
                              className="coraenameinLerningpage"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.courseName}
                            </Typography>

                            <Box
                              sx={{
                                mt: "20px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Button
                                onClick={() => {
                                  enrollUser(
                                    item.courseId,
                                    item.access,
                                    status,
                                    item
                                  );
                                }}
                                className="startcoursetest"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                  mb: "-10x",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000",
                                  }}
                                >
                                  {status}
                                </Typography>
                                <RightArrowIcon />
                              </Button>
                            </Box>
                          </Box>
                        </Item>
                      </Grid>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          )}

          {rolesAndPermission?.Learning?.componentPermission.includes(
            "Microlearnings"
          ) && (
            <Box
              // data-aos="fade-up" data-aos-duration="1500"
              sx={{
                "@media (max-width: 780px)": {
                  mt: "15%",
                },
              }}
            >
              <Box
                // data-aos="fade-up"
                // data-aos-duration="1500"

                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  // mt: "24px",
                  position: "relative",
                  zIndex: "2",
                }}
              >
                <Box>
                  <img src={imro} alt="" />
                </Box>

                <Box
                  className="sectionname"
                  sx={{
                    ml: "8px",
                  }}
                >
                  Micro-learnings
                </Box>
                {microArr?.length > 2 && (
                  <Box
                    sx={{
                      fontWeight: "14px",
                      fontSize: "14px",
                      ml: "6px",
                      height: "20px",
                      width: "2px",
                      opacity: "0.3",
                      backgroundColor: "#6846C7",
                    }}
                  ></Box>
                )}

                {microArr?.length > 2 && (
                  <Box
                    onClick={() => navigate("micro-learnings")}
                    className="sectionnameall"
                    sx={{
                      ml: "6px",
                      height: "20px",

                      cursor: "pointer",
                      color: "#6846C7",
                    }}
                  >
                    View all
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  width: "100%",
                  mt: "24px",
                  height: "121px",

                  "@media (max-width: 780px)": {
                    maxWidth: "100vw",
                    height: "134px",
                    overflowX: "scroll",
                  },
                }}
              >
                <Box
                  // container
                  // columnSpacing={2}
                  // columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                  sx={{
                    display: "grid",
                    gridTemplateRows: "1fr 1fr 1fr",
                    cursor: `${microArr?.length == 0 ? "default" : "pointer"}`,
                    gap: "24px",

                    "@media (max-width: 780px)": {
                      gridTemplateColumns: "350px 350px 350px",
                      height: "100%",
                    },
                  }}
                >
                  {/* &&
                    checkUI("Microlearnings")  */}
                  {microArr?.length == 0 &&
                    checkUI("Microlearnings") &&
                    !loading &&
                    !lodingscreen && (
                      <div style={{ overflow: "hidden" }}>
                        <Typography sx={{ color: "red" }}>
                          Contact your Admin
                        </Typography>

                        <img
                          src="/adminDashboard.png"
                          style={{ width: "300px" }}
                        />
                      </div>
                    )}
                  {microArr?.map((item, index) => {
                    if (index > 2) {
                      return;
                    }
                    return (
                      <Grid item xs={4}>
                        <Item
                          sx={{
                            borderRadius: "24px",
                          }}
                        >
                          <Box
                            onClick={() => {
                              microlearningpagecontent(item.courseId, item);
                            }}
                            sx={{
                              backgroundColor: "#fff",
                              borderRadius: "24px",
                              height: "121px",
                              "@media (max-width: 780px)": {
                                height: "135px",
                              },
                              display: "grid",
                              gridTemplateColumns: "2fr 3fr",
                              gridGap: "20px",
                            }}
                          >
                            <Box>
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "20px",
                                  objectFit: "cover",
                                }}
                                src={item.courseImageURL}
                                alt=""
                              />
                            </Box>
                            <Box
                              sx={{
                                // padding: "24px 10px 24px 10px",
                                display: "grid",
                                gridTemplateRows: "5fr 1fr",
                                height: "100%",
                                width: "90%",
                                padding: "10px",

                                overflow: "hidden",
                              }}
                            >
                              <Box
                                className="microlerningdiscription"
                                sx={{
                                  overflow: "hidden",
                                  paddingTop: "12px",
                                }}
                                // sx={{ minHeight: "80px" }}
                              >
                                {item.courseName}
                              </Box>
                              <Box
                                // onClick={() => {
                                //   microlearningpagecontent(item.courseId, item);
                                // }}
                                sx={{
                                  display: "flex",
                                  justifyContent: "end",

                                  // mt: "25px",
                                }}
                              >
                                <RightArrowIconblue />
                              </Box>
                            </Box>
                          </Box>
                        </Item>
                      </Grid>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          )}

          {rolesAndPermission?.Learning?.componentPermission.includes(
            "Webinar"
          ) && <WebinarHome />}
        </Box>

        {rolesAndPermission?.Learning?.componentPermission.includes("Games") &&
          !lodingscreen && (
            <Box
              sx={{
                width: "100%",
                // ml: "25px",
                paddingLeft: "50px",
                "@media (max-width: 780px)": {
                  width: "100%%",
                  padding: "10px",
                },
              }}
            >
              <GamesHome />
            </Box>
          )}

        <HrFooter />
      </Box>
    </Box>
  );
};
export default Learning;
