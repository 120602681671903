import { Box, Button, Grid, Paper, styled, Typography } from "@mui/material";
import ResponsiveDrawer from "../../Sidebar/HrSideBar";

import { CertificateDownloadIcon, RightArrowIcon } from "../../icons/Icons";
import { useNavigate } from "react-router";

import micro from "../Vector.svg";
import { getCookie } from "../../../Cookies";
import HrTopNavbar from "../../Sidebar/HrTopNavbar";
import { ValidateAuth } from "../../../AuthToken";
import { useContext, useEffect, useState } from "react";
import HrFooter from "../../Sidebar/HrFooter";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import imro from "../Vector.svg";
import { microArr } from "../microLerningdata";
import paly from "./paly.svg";
import WebinarPopUp from "./webinarPopUp";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import "../learning.css";
import MobileTopBar from "../../Sidebar/MobaileTopBar";
const Item = styled(Paper)(({ theme }) => ({
  position: "relative",
}));

const ViewWebinarAll = () => {
  const navigate = useNavigate();
  const [webdata, setWebdata] = useState([]);
  //   const [microArr, setMicroCourse] = useState([]);
  const goToHome = () => navigate("/");
  const [openpopUpForAddYears, setOpenpopUpForAddYears] = useState(false);
  const [popdata, setpopdata] = useState({});
  const { rolesAndPermission, setRolesAndPermission, course_Completion } =
    useContext(DrawerContext);
  useEffect(() => {
    // let newobj =
    //   rolesAndPermission?.Learning?.componentPermission.includes("Webinar");
    // // console.log(newobj.HRDI);
    // if (!newobj) {
    //   goToHome();
    //   return <></>;
    // }

    if (!rolesAndPermission) {
      // setlodingscreen(false);
    } else {
      // setlodingscreen(false);

      let newobj =
        rolesAndPermission?.Report?.componentPermission.includes("Webinar");
      // console.log(newobj.HRDI);
      if (!newobj) {
        // navigate(-1);
        return <></>;
      }
    }
  }, [rolesAndPermission]);

  async function fetchMicroCourse() {
    let configrecrut = {
      method: "get",

      url: `${IP}/webinar/user`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut).then((res) => {
      setWebdata(res.data);
      //   setCourses(res.data.filter((e) => e.archived != true));
      //   setSearchCourses(res.data.filter((e) => e.archived != true).slice(0, 6));
      //   setLoading(false);
    });
  }

  useEffect(() => {
    fetchMicroCourse();
  }, []);

  const microlearningpagecontent = (courseId, item) => {
    // navigate(`/hr/Learning/MicroLearning-content/${courseId}`)

    navigate(`/hr/Learning/MicroLearning-content/${courseId}`, {
      state: { ...item },
    });
  };
  return (
    <Box className="hrmainbox" sx={{ backgroundColor: "#F3F1FF" }}>
      <Box className="mobileSideBar_leaning">
        <ResponsiveDrawer sidebar={"Learning"} />
      </Box>

      <WebinarPopUp
        open={openpopUpForAddYears}
        onClose={() => setOpenpopUpForAddYears(false)}
        // delfun={(topic) => {
        //   addYearsFunPopUp(topic);
        // }}
        data={popdata}
      />

      <Box sx={{ width: "100%" }}>
        <MobileTopBar />

        <HrTopNavbar title={`Hello ${getCookie("EMNAME")}!`}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box sx={{ display: "flex", gap: "10px", fontSize: "14px" }}>
              {" "}
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  bgcolor: "#E1F566",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                {course_Completion}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                Course completed{" "}
              </Box>
            </Box>
          </Box>
        </HrTopNavbar>
        <Box
          sx={{
            width: "100%",
            padding: "24px",
            "@media (max-width: 780px)": {
              padding: "10px",
            },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",

                position: "relative",
                zIndex: "2",
              }}
            >
              <Box>
                <img src={imro} alt="" />
              </Box>

              <Box
                className="sectionname"
                sx={{
                  ml: "8px",
                }}
              >
                Webinars
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                mt: "24px",
                position: "relative",
                zIndex: "2",
              }}
            >
              <Box
                className="sectionname"
                sx={{
                  ml: "8px",
                }}
              >
                Upcoming Webinars
              </Box>
            </Box>

            {webdata?.upcomingWebinar?.length == 0 && (
              <Box
                sx={{
                  height: "200px",
                  ml: "8px",
                  mt: "10px",
                }}
              >
                {" "}
                You Don't Have any Upcoming webinar
              </Box>
            )}
            <Box sx={{ width: "100%", mt: "24px" }}>
              <Box
                // container
                // rowSpacing={10}
                // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{
                  gridTemplateColumns: "1fr 1fr 1fr",
                  display: "grid",
                  gridGap: "25px",
                  "@media (max-width: 780px)": {
                    gridTemplateColumns: "1fr",
                    gridGap: "20px",
                  },
                }}
              >
                {webdata?.upcomingWebinar?.map((item, index) => {
                  return (
                    <Grid item xs={4}>
                      <Item
                        sx={{
                          borderRadius: "24px",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: "24px",
                            height: "160px",
                            display: "flex",
                            gap: "0px",
                          }}
                        >
                          <Box sx={{ width: "50%" }}>
                            <img
                              style={{
                                width: "154px",
                                height: "160px",
                                borderRadius: "20px",
                              }}
                              //   src={item?.courseImageURL}
                              src={item?.photo}
                              alt=""
                            />
                          </Box>
                          <Box sx={{ padding: "24px 10px 24px 10px" }}>
                            <Box
                              className="microlerningdiscription"
                              sx={{ minHeight: "80px" }}
                            >
                              {item?.topic}
                              {/* {item?.courseName} */}
                            </Box>
                            <Box
                              //   onClick={() => {
                              //     microlearningpagecontent("test", "start", {
                              //       courseid: item?.courseId,
                              //     });
                              //   }}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "13px",
                                alignContent: "center",
                              }}
                            >
                              <Box> 20 mins</Box>
                              <Box
                                // onClick={() => {
                                //   // setpopdata(item);
                                //   // setOpenpopUpForAddYears(true);

                                // }}
                                onClick={() => {
                                  // setpopdata(item);
                                  // setOpenpopUpForAddYears(true);
                                  window.open(item?.zoomlink);
                                }}
                                sx={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#6846C7",
                                }}
                              >
                                {" "}
                                Register
                                {/* <RightArrowIcon /> */}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Item>
                    </Grid>
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                mt: "24px",
                position: "relative",
                zIndex: "2",
              }}
            >
              <Box
                className="sectionname"
                sx={{
                  ml: "8px",
                }}
              >
                Past Webinars
              </Box>
            </Box>

            {webdata?.passedWebinar?.length == 0 && (
              <Box
                sx={{
                  height: "200px",
                  ml: "8px",
                  mt: "10px",
                }}
              >
                {" "}
                You Don't Have any Passed webinar
              </Box>
            )}
            <Box sx={{ width: "100%", mt: "24px" }}>
              <Grid
                // container
                // rowSpacing={10}
                // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{
                  gridTemplateColumns: "1fr 1fr 1fr",
                  display: "grid",
                  gridGap: "25px",
                  "@media (max-width: 780px)": {
                    gridTemplateColumns: "1fr",
                    gridGap: "20px",
                  },
                }}
              >
                {webdata?.passedWebinar?.map((item, index) => {
                  return (
                    <Grid item xs={4}>
                      <Item
                        sx={{
                          borderRadius: "24px",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: "24px",
                            height: "160px",
                            display: "flex",
                            gap: "0px",
                          }}
                        >
                          <Box sx={{ width: "50%" }}>
                            <img
                              style={{
                                width: "154px",
                                height: "160px",
                                borderRadius: "20px",
                              }}
                              //   src={item?.courseImageURL}
                              src={item?.photo}
                              alt=""
                            />
                          </Box>
                          <Box sx={{ padding: "24px 10px 24px 10px" }}>
                            <Box
                              className="microlerningdiscription"
                              sx={{ minHeight: "80px" }}
                            >
                              {item?.topic}
                              {/* {item?.courseName} */}
                            </Box>
                            <Box
                              //   onClick={() => {
                              //     microlearningpagecontent("test", "start", {
                              //       courseid: item?.courseId,
                              //     });
                              //   }}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "19px",
                                alignContent: "center",
                              }}
                            >
                              <Box> 20 mins</Box>
                              <Box
                                sx={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  opacity: item?.videolink != "" ? "1" : "0.4",
                                  display: "flex",
                                  color: "#6846C7",
                                  alignItems: "center",
                                  gap: "10px",
                                  alignContent: "center",
                                }}
                                onClick={() => {
                                  if (item?.videolink != "") {
                                    window.open(item?.videolink, "_blank");
                                  }
                                }}
                              >
                                <Box> Play</Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    width: "16px",
                                    justifyContent: "center",
                                    height: "16px",
                                    borderRadius: "50%",
                                    border: "1px solid #6846C7",
                                  }}
                                >
                                  {" "}
                                  <img src={paly} alt="" />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Item>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>

        <HrFooter />
      </Box>
    </Box>
  );
};
export default ViewWebinarAll;
