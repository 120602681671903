import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  ContentCourseQuizIcons,
  TickCompleted,
} from "../../../../utilities/Icons/Icons";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { ItemContext } from "../Context/ItemContext";
import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../Cookies";
const FinalQuizzes = ({ quizId }) => {
  const [quizInfo, setQuizInfo] = useState(null);
  const [completed, setCompleted] = useState(false);

  const { itemsArray, setProgressIndex, courseId } = useContext(ItemContext);
  const getQuizInfo = httpsCallable(functions, "getQuizInfo");
  const getQuizProgress = httpsCallable(functions, "getQuizProgress");
  useEffect(() => {
    let configrecrut = {
      method: "get",

      url: `${IP}/userCourseDetails/quiz`,
      params: { quizId: quizId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut).then((res) => {
      setQuizInfo(res.data);
    });

    //  getQuizInfo({ quizId: quizId }).then((res) => setQuizInfo(res.data));

    let configrecruttest = {
      method: "get",

      url: `${IP}/userCourseDetails/quiz`,
      params: { courseId: courseId, quizId: quizId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecruttest).then((result) => {
      if (result.data.error === "not present") {
        setCompleted(false);
      } else if (result.data.completed === true) {
        setCompleted(true);
      }
    });
  }, []);

  const setMainItem = (element) => element.id == quizId;

  return (
    <Box sx={{ display: "flex", gap: "10px" }}>
      <Box sx={{ ml: "6%" }}>
        <ContentCourseQuizIcons />
      </Box>
      <Typography
        sx={{
          fontSize: "14px",
          fontFamily: "Poppins",
          color: "#000",
          fontWeight: "600",
          cursor: !completed ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (completed) setProgressIndex(itemsArray.findIndex(setMainItem));
        }}
      >
        {quizInfo?.quizName}
      </Typography>
      {completed && (
        <TickCompleted style={{ marginLeft: "auto", marginRight: "30px" }} />
      )}
    </Box>
  );
};
export default FinalQuizzes;
