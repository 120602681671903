import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Input, Select } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";
import "./changepass.css";

import axios from "axios";
import { deleteCookie, getCookie, setCookie } from "../../Cookies";
import { IP } from "../../baseUrlAndConfig";
import { Timestamp } from "firebase/firestore";
import HrloadingScreen from "../LodingHrscreen";
const Changepassword = (props) => {
  const { onClose, open, data } = props;
  const handleClose = () => {
    onClose();
  };
  const navigate = useNavigate();
  const [eldata, seteldata] = useState({});
  const [checking, setChecking] = useState("");
  const [loadingopen, setLoadingopen] = useState(false);
  const [saveloading, setsaveloading] = useState("");
  const [userdata, setUserData] = useState({
    current: "",
    newpass: "",
    confirm: "",
  });

  const checkfun = () => {
    if (userdata.current == "") {
      return "enter current password";
    } else if (userdata.newpass == "") {
      return "enter new password";
    } else if (userdata.confirm == "") {
      return "enter confirm password";
    } else if (userdata.confirm !== userdata.newpass) {
      return "new password and confirm password are not equal";
    }
    return "test";
  };
  async function Ragisteruser() {
    const datacheck = checkfun();
    if (datacheck != "test") {
      alert(datacheck);
      setChecking(datacheck);
      return;
    }
    // setChecking("loading");
    setLoadingopen(true);
    let datauser = JSON.stringify({ ...userdata });
    let configrecrut = {
      method: "post",

      url: `${IP}/auth/updatePassword`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: datauser,
    };

    axios(configrecrut)
      .then((res) => {
        alert("password updated");
        setChecking("");
        setLoadingopen(false);
        deleteCookie();
        navigate("/");
        onClose();
        //   setWebdata(res.data);
        //   setCourses(res.data.filter((e) => e.archived != true));
        //   setSearchCourses(res.data.filter((e) => e.archived != true).slice(0, 6));
        //   setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.error) {
          alert("Something went wrong");
        }
        setLoadingopen(false);
        setChecking("");
      });

    //console.log(res.data.filter((e)=>e.archived!=true).slice(0, 6));
  }
  useEffect(() => {
    // fetchCourses();
    seteldata(data);
  }, [data]);
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0].split("-")[0]
  );
  const [loading, setLoading] = useState(false);

  const [inputId, setInputId] = useState("2");

  let navigater = useNavigate();
  const runGenerateCertificateTraining = () => {
    /// navigater("/admin/course-reports")
    if (inputId == 2) {
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth="700px"
      PaperProps={{
        style: {
          background: "#F5F5F5",
          borderRadius: "20px",
          width: "680px",

          height: "420px",
          padding: "5px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <HrloadingScreen
        open={loadingopen}
        onClose={() => {
          setLoadingopen(false);
        }}
      />
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <Button
          onClick={() => {
            onClose();
            handleClose();
          }}
          style={{
            width: "25px",
            height: "25px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </Button>
      </DialogTitle>
      <Box
        sx={{
          padding: "0px 24px 40px 24px",
          margin: "auto",
        }}
      >
        <Box className="hedingForwebinarPopup">Change password</Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gridGap: "20px",
            mt: "25px",
          }}
        >
          <Box>
            {" "}
            {/* <Box className="subhedingForwebinarPopup"> First nam</Box>{" "} */}
            <Box>
              {" "}
              <input
                className="webinarpopupinput"
                value={userdata.current}
                onChange={(e) => {
                  //   console.log(event.target);
                  setUserData((prev) => ({
                    ...prev,
                    current: e.target.value,
                  }));
                }}
                placeholder="   Current  password"
              />{" "}
            </Box>
          </Box>

          <Box>
            {" "}
            {/* <Box className="subhedingForwebinarPopup"> Surname</Box>{" "} */}
            <Box>
              {" "}
              <input
                className="webinarpopupinput"
                value={userdata.newpass}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    newpass: e.target.value,
                  }));
                }}
                placeholder="   New password"
              />{" "}
            </Box>
          </Box>

          <Box>
            {" "}
            {/* <Box className="subhedingForwebinarPopup">
              {" "}
              Company Email Address
            </Box>{" "} */}
            <Box>
              {" "}
              <input
                className="webinarpopupinput"
                value={userdata.confirm}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    confirm: e.target.value,
                  }));
                }}
                placeholder="    Confirm password"
              />{" "}
            </Box>
          </Box>

          <Box>
            {" "}
            {/* <Box className="subhedingForwebinarPopup"> Surname</Box>{" "} */}
            <Box>
              {" "}
              <button
                className="webinarpopupinput"
                type="submit"
                style={{
                  background: "#6846C7",
                  color: "#FFFFFF",
                  fontFamily: "Manrope",
                  fontSize: "16px",
                }}
                // value={"Change password"}
                onClick={(e) => {
                  Ragisteruser();
                }}
              >
                {" "}
                Change password{" "}
              </button>
            </Box>
            <Box
              sx={{
                mt: "10px",
                // background: "#6846C7",
                paddingLeft: "10px",
                color: "#black",
                fontFamily: "Manrope",
                fontSize: "16px",
              }}
            >
              {" "}
              {checking}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default Changepassword;
