import { useEffect, useState } from "react";
import AddYearsPopUp from "./AddyearsPopUp";
import "./table.css";
import { Country, State, City } from "country-state-city";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  FormControl,
  InputLabel,
  Typography,
  Modal,
} from "@mui/material";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";

import { getCookie } from "../../../Cookies";
import HrTopNavbar from "../../Sidebar/HrTopNavbar";
import { useNavigate } from "react-router";

import DeletePopUpForDI from "./deletePop";
import MobileTopBar from "../../Sidebar/MobaileTopBar";
import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";
// import { exportMultipleChartsToPdf } from "../../Psychological_safety/PsyclogicalSaftey/GraphUtils";
const Item = styled(Paper)(({ theme }) => ({
  background: "#FFF",
  textAlign: "center",
  borderRadius: "20px",
  padding: "20px 15px",
}));
// import {exportMultipleChartsToPdf} from "../"

const Item2 = styled(Paper)(({ theme }) => ({
  background: "#FFF",
  textAlign: "center",
  borderRadius: "20px",
}));

const Item3 = styled(Paper)(({ theme }) => ({
  background: "#FFF",
  textAlign: "center",
  borderRadius: "20px",
  // padding: "20px 15px",
}));

export default function OverallRepresentation({
  overallgender,
  setOverallgender,
  overAllFlag,
  companyId,
}) {
  const [list, setList] = useState([]);
  const [listbuver, setListbuver] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setstateListList] = useState([]);

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [bussinessvertical, setBussinessvertical] = useState([]);
  const [genderList, setgenderList] = useState([
    { name: "Junior" },
    { name: "MidLevel" },
    { name: "Senior" },
  ]);
  const [openpopUpForAddYears, setOpenpopUpForAddYears] = useState(false);

  const [
    OverallGenderRepresentationState,
    setOverallGenderRepresentationState,
  ] = useState([]);

  const [genderbyRegionStates, setgenderbyRegionStates] = useState([]);
  const [genderbyBusinessVerticalstate, setGenderbyBusinessVerticalstate] =
    useState([]);

  const [genderbyregionYear, setgenderbyregionYear] = useState("");

  // const [listele.year, setGenderbyBusinessVerticalyear] =
  //   useState("");
  const [ex, setEx] = useState([]);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  useEffect(() => {
    // ValidateAuth();
    // let newobj = JSON.parse(getCookie("roles"));
    // if (!newobj.HRDI?.includes(getCookie("ROLE"))) {
    //   goToHome();
    //   return <></>;
    // }
  }, []);
  const [open, setOpen] = useState(false);
  const getApiDataForYear = () => {
    let config = {
      method: "get",

      url: `${IP}/overAllRepresentation/overAllGenderRep?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(config)
      .then((res) => {
        setOverallGenderRepresentationState(res.data);

        // console.log(res.data);
      })
      .catch((er) => {
        // console.log(er);
      });
  };

  const getBussinessVer = () => {
    let configvr = {
      method: "get",

      url: `${IP}/bussinessVertical/?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configvr).then((res) => {
      setBussinessvertical(res.data);
    });
  };

  const getGenterBybussinessVerticalAndRegib = () => {
    let dataregionf = JSON.stringify(genderbyRegionStates);

    let configregiony = {
      method: "get",

      url: `${IP}/overAllRepresentation/genderByBussinessVertical/?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregion",
    };

    axios(configregiony)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setListbuver((prev) => prev.concat({ year: key }));
        }
        setGenderbyBusinessVerticalstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  const getoverAllRepresentationgenderByRegion = () => {
    let dataregion = JSON.stringify(genderbyRegionStates);

    let configregion = {
      method: "get",

      url: `${IP}/overAllRepresentation/genderByRegion?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregion,
    };

    axios(configregion)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setList((prev) => prev.concat({ year: key }));
        }
        setgenderbyRegionStates(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  };

  useEffect(() => {
    getBussinessVer();
    getApiDataForYear();

    getGenterBybussinessVerticalAndRegib();
    getoverAllRepresentationgenderByRegion();
  }, []);

  const addregion = (ind) => {
    if (!list[ind].year) {
      alert("please select year first ");
      return;
    }

    let data = JSON.stringify({
      year: list[ind].year,

      date: Date.now(),
      state: "",
      country: "",
      countryName: "",
      Junior: { male: 0, female: 0, nonBinary: 0 },
      MidLevel: { male: 0, female: 0, nonBinary: 0 },
      Senior: { male: 0, female: 0, nonBinary: 0 },
    });

    let config = {
      method: "post",

      url: `${IP}/overAllRepresentation/genderByRegion?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });

    setgenderbyRegionStates((prev) =>
      prev.concat({
        year: list[ind].year,

        date: Date.now(),
        state: "",
        country: "",
        countryName: "",
        Junior: { male: 0, female: 0, nonBinary: 0 },
        MidLevel: { male: 0, female: 0, nonBinary: 0 },
        Senior: { male: 0, female: 0, nonBinary: 0 },
      })
    );
  };

  const addGenderbyBusinessVertical = (ind) => {
    if (!listbuver[ind].year) {
      alert("select year first");
      return;
    }
    let data = JSON.stringify({
      year: listbuver[ind].year,

      date: Date.now(),
      businessVertical: "",

      Junior: { male: 0, female: 0, nonBinary: 0 },
      MidLevel: { male: 0, female: 0, nonBinary: 0 },
      Senior: { male: 0, female: 0, nonBinary: 0 },
    });

    let config = {
      method: "post",

      url: `${IP}/overAllRepresentation/genderByBussinessVertical?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });

    setGenderbyBusinessVerticalstate((prev) =>
      prev.concat({
        year: listbuver[ind].year,

        date: Date.now(),
        businessVertical: "",

        Junior: { male: 0, female: 0, nonBinary: 0 },
        MidLevel: { male: 0, female: 0, nonBinary: 0 },
        Senior: { male: 0, female: 0, nonBinary: 0 },
      })
    );
  };
  useEffect(() => {
    let data = JSON.stringify(OverallGenderRepresentationState);

    console.log(data);
    console.log(selectedDeleteYear);
    let config = {
      method: "patch",

      url: `${IP}/overAllRepresentation/overAllGenderRep?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    if (OverallGenderRepresentationState.length > 0) {
      axios(config)
        .then((res) => {
          if (res.data) {
            setOverallGenderRepresentationState(res.data);
          }
          // console.log(res.data);
        })
        .catch((er) => {
          // console.log(er);
        });
    }

    let dataregion = JSON.stringify(genderbyRegionStates);

    let configregion = {
      method: "patch",

      url: `${IP}/overAllRepresentation/genderByRegion?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregion,
    };
    if (genderbyRegionStates?.length > 0) {
      axios(configregion)
        .then((res) => {
          //console.log(res.data);
        })
        .catch((er) => {
          // console.log(er);
        });
    }

    setOverallgender((prev) => ({
      ...prev,
      OverallGenderRepresentationState: OverallGenderRepresentationState
        ? OverallGenderRepresentationState
        : [],

      genderbyRegionStates: genderbyRegionStates ? genderbyRegionStates : [],
      genderbyBusinessVerticalstate: genderbyBusinessVerticalstate
        ? genderbyBusinessVerticalstate
        : [],
    }));

    // setgenderbyRegionStates(ex);
  }, [
    genderbyRegionStates,
    genderbyBusinessVerticalstate,
    OverallGenderRepresentationState,
  ]);

  useEffect(() => {
    if (genderbyBusinessVerticalstate?.length > 0) {
      let data = JSON.stringify(genderbyBusinessVerticalstate);

      let config = {
        method: "patch",

        url: `${IP}/overAllRepresentation/genderByBussinessVertical?companyId=${companyId}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: data,
      };
      axios(config)
        .then((res) => {
          // console.log(res.data);
        })
        .catch((er) => {
          //console.log(er);
        });
    }
  }, [genderbyBusinessVerticalstate]);
  useEffect(() => {
    let ls = overallgender.OverallGenderRepresentationState
      ? overallgender.OverallGenderRepresentationState
      : [];

    setList([]);
    setListbuver([]);
    for (let i = 0; i < ls.length; i++) {
      // setList((prev) => prev.concat({ year: ls[i]?.year }));
      // setListbuver((prev) => prev.concat({ year: ls[i]?.year }));
    }

    setOverallGenderRepresentationState(
      overallgender.OverallGenderRepresentationState
        ? overallgender.OverallGenderRepresentationState
        : []
    );

    let recrut = overallgender.genderbyRegionStates
      ? overallgender.genderbyRegionStates
      : [];
    let newobj = {};
    for (let i = 0; i < recrut.length; i++) {
      newobj[recrut[i]?.year] = "af";
    }
    for (let key in newobj) {
      setList((prev) => prev.concat({ year: key }));
    }
    setgenderbyRegionStates(
      overallgender.genderbyRegionStates
        ? overallgender.genderbyRegionStates
        : []
    );

    let rvcall = overallgender.genderbyBusinessVerticalstate
      ? overallgender.genderbyBusinessVerticalstate
      : [];
    let newobjt = {};
    for (let i = 0; i < rvcall.length; i++) {
      newobjt[rvcall[i]?.year] = "af";
    }
    for (let key in newobjt) {
      setListbuver((prev) => prev.concat({ year: key }));
    }
    setGenderbyBusinessVerticalstate(
      overallgender.genderbyBusinessVerticalstate
        ? overallgender.genderbyBusinessVerticalstate
        : []
    );

    // setgenderbyRegionStates(ex);
  }, [overAllFlag]);

  // useEffect(() => {}, [OverallGenderRepresentationState]);

  const openPopUpForAddYears = () => {
    setOpenpopUpForAddYears(true);
  };
  const addYearsFunPopUp = (Years) => {
    console.log(Years);
    let data = JSON.stringify({
      year: Years,
      male: 0,
      female: 0,
      nonBinary: 0,
    });

    let config = {
      method: "post",

      url: `${IP}/overAllRepresentation/overAllGenderRep?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        // console.log(res.data);
      })
      .catch((er) => {
        // console.log(er);
      });

    setOverallGenderRepresentationState((prev) =>
      prev.concat({
        year: Years,
        male: 0,
        female: 0,
        nonBinary: 0,
      })
    );
    setOpenpopUpForAddYears(false);
  };
  // console.log(genderbyRegionStates);
  //console.log(Object.keys(curvalue)[0])

  // useEffect(() => {}, []);
  const deleteYearData = (inputdata) => {
    let temparr = OverallGenderRepresentationState.filter(
      (el) => el.year !== inputdata.year
    );
    let data = JSON.stringify(temparr);

    //console.log(data);
    let config = {
      method: "delete",

      url: `${IP}/overAllRepresentation/overAllGenderRep?companyId=${companyId}&yearDeleted=${inputdata.year}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    if (OverallGenderRepresentationState.length > 0) {
      axios(config)
        .then((res) => {
          // if (res.data) {
          //   setOverallGenderRepresentationState(res.data);
          // }
          //console.log(res.data);
        })
        .catch((er) => {
          // console.log(er);
        });
    }

    setOverallGenderRepresentationState(temparr);
  };

  const deleteReginAndState = (inputdata) => {
    let temparr = genderbyRegionStates.filter(
      (el) => el.date !== inputdata.date
    );

    let dataregion = JSON.stringify(temparr);

    let configregion = {
      method: "patch",

      url: `${IP}/overAllRepresentation/genderByRegion?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregion,
    };
    if (genderbyRegionStates?.length > 0) {
      axios(configregion)
        .then((res) => {
          //console.log(res.data);
        })
        .catch((er) => {
          // console.log(er);
        });
    }
    setgenderbyRegionStates(temparr);
  };

  const deleteBussinessVertical = (inputdata) => {
    let temparr = genderbyBusinessVerticalstate.filter(
      (el) => el.date !== inputdata.date
    );

    let data = JSON.stringify(temparr);

    let config = {
      method: "patch",

      url: `${IP}/overAllRepresentation/genderByBussinessVertical?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        // console.log(res.data);
        setGenderbyBusinessVerticalstate(temparr);
      })
      .catch((er) => {
        //console.log(er);
      });
  };
  const deleteFunctionCallFromUi = (data, type) => {
    console.log(data);
    let newobj = { ...data, type };
    setDeleteId(newobj);
    setopenLessonPopUp(true);
  };
  const delfunction = (name) => {
    if (name.type == "year") {
      deleteYearData(name);
      console.log("=>", name);
      setSelectedDeleteYear(name.year);
    } else if (name.type == "countrystate") {
      deleteReginAndState(name);
    } else if (name.type == "bussiness") {
      deleteBussinessVertical(name);
    }
    console.log(name);
  };
  async function exportMultipleChartsToPdf() {
    const doc = new jsPDF("p", "px"); // (1)
    console.log("i am called");

    const elements = document.getElementsByClassName("custom-chart"); // (2)

    await creatPdf({ doc, elements }); // (3-5)

    doc.save(`charts.pdf`); // (6)
  }

  async function creatPdf({ doc, elements }) {
    let top = 0;
    const padding = 0;

    let avoidIst = false;

    for (let i = 0; i < elements.length; i++) {
      const el = elements.item(i);
      console.log("line 28", el);
      const imgData = await htmlToImage.toPng(el);

      let elHeight = el.offsetHeight;
      let elWidth = el.offsetWidth;

      const pageWidth = doc.internal.pageSize.getWidth();

      if (elWidth > pageWidth) {
        const ratio = pageWidth / elWidth;
        elHeight = elHeight * ratio - padding;
        elWidth = elWidth * ratio - padding;
      }

      const pageHeight = doc.internal.pageSize.getHeight();

      if (top + elHeight > pageHeight) {
        if (avoidIst) {
          doc.addPage();
        } else {
          avoidIst = true;
        }
        top = 0;
      }

      doc.addImage(
        imgData,
        "PNG",
        padding,
        top,
        elWidth,
        elHeight,
        `image${i}`,
        undefined,
        "FAST"
      );
      top += elHeight;
    }
  }
  const generatePdf = async () => {
    // setlodingscreen(true);
    await exportMultipleChartsToPdf();
    // setlodingscreen(false);
  };
  const [selectedDeleteYear, setSelectedDeleteYear] = useState("");

  const checkAlreadyState = (country, state, year) => {
    const stateArr = [];
    for (let i = 0; i < genderbyRegionStates?.length; i++) {
      if (genderbyRegionStates[i].year == year) {
        stateArr.push(genderbyRegionStates[i].state);
      }
    }

    const tempObj = State.getStatesOfCountry(country);
    // genderbyRegionStates
    const outputArr = [];
    tempObj.map((_, idx) => {
      if (!stateArr.includes(_.name)) {
        outputArr.push({ name: _.name });
      }
    });
    outputArr.push({ name: state });
    return outputArr;
  };

  const checkAlreadyVertical = (vertical, year) => {
    // genderbyBusinessVerticalstate?.map((_,idx)=>{

    // })
    const vertArr = [];
    for (let i = 0; i < genderbyBusinessVerticalstate?.length; i++) {
      if (genderbyBusinessVerticalstate[i].year == year) {
        vertArr.push(genderbyBusinessVerticalstate[i].businessVertical);
      }
    }
    const outputArr = [];
    bussinessvertical?.map((_, idx) => {
      if (!vertArr.includes(_)) {
        outputArr.push(_);
      }
    });
    outputArr.push(vertical);

    return outputArr;
  };
  return (
    <Box>
      <AddYearsPopUp
        open={openpopUpForAddYears}
        prevData={OverallGenderRepresentationState}
        onClose={() => setOpenpopUpForAddYears(false)}
        delfun={(topic) => {
          addYearsFunPopUp(topic);
        }}
      />

      {/* stick navbar  */}
      <Box
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#F3F1FF",
          zIndex: "2",
          paddingTop: "20px",
        }}
      >
        <HrTopNavbar title={"Overall representation"} />
      </Box>

      {/* <button
        onClick={() => {
          window.open(
            "/print/DiversityMetric",
            "",
            "toolbars=no,resizable=0,width=900,height=700,left=200,top=200"
          );
          localStorage.setItem("companyName", "companyName");
          localStorage.setItem("allApiData", JSON.stringify("allApiData"));
          // localStorage.setItem("componentRef", JSON.stringify(componentRef));
        }}
      >
        Download Report
      </button> */}

      {/* popup  - dialog */}
      <DeletePopUpForDI
        open={openLessonPopUp}
        onClose={() => setopenLessonPopUp(false)}
        delfun={(topic) => {
          delfunction(topic);
        }}
        topicId={deleteId}
        itemName={"Lesson"}
      />

      <Box sx={{ marginLeft: "24px", marginTop: "24px" }}>
        <Box className="tableHedingName" sx={{ mt: "32px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#E5E0F9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              1
            </Box>
            Overall gender representation
          </Box>

          <Button
            onClick={openPopUpForAddYears}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              borderRadius: "10px",
              padding: "5px 8px",
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12H18"
                stroke="#6846C7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 18L12 6"
                stroke="#6846C7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Typography
              sx={{
                color: "#6846C7",
                fontFamily: "Poppins",
                cursor: "pointer",
              }}
            >
              Add year
            </Typography>
          </Button>
        </Box>

        <Box className="tablebgColorForHr" sx={{ mt: "24px" }}>
          <Grid container spacing={2}>
            {OverallGenderRepresentationState?.map((el) => (
              <Grid item md={2.4}>
                <Item>
                  <Stack spacing={2}>
                    {" "}
                    <Box
                      sx={{ display: "grid", gridTemplateColumns: "7fr 1fr" }}
                    >
                      <Box style={{}}>{el?.year} </Box>
                      <Box
                        sx={{
                          ml: "50px",
                          color: "#BCBCBC",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          deleteFunctionCallFromUi(el, "year");
                        }}
                      >
                        {/* <DeleteIcon /> */}

                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
                            fill="#707EAE"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E7E3F2",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Box className="genderbyRegionInputBoxLabel"> Male </Box>
                      <Box>
                        {" "}
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            padding: "10px",
                          }}
                          className="OverallGenderRepresentationTable"
                          min="0"
                          type="Number"
                          name="tes"
                          value={el.male}
                          onChange={(e) => {
                            setOverallGenderRepresentationState((prev) => {
                              return prev.filter((curvalue, idx) => {
                                if (curvalue.year == el?.year) {
                                  curvalue.male = Number(e.target.value);
                                }
                                return curvalue;
                              });
                            });
                          }}
                        />{" "}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E7E3F2",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Box className="genderbyRegionInputBoxLabel">
                        {" "}
                        Female{" "}
                      </Box>
                      <Box>
                        {" "}
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            padding: "10px",
                          }}
                          min="0"
                          type="Number"
                          className="OverallGenderRepresentationTable"
                          value={el.female}
                          onChange={(e) => {
                            setOverallGenderRepresentationState((prev) => {
                              return prev.filter((curvalue, idx) => {
                                if (curvalue.year == el?.year) {
                                  curvalue.female = Number(e.target.value);
                                }
                                return curvalue;
                              });
                            });
                          }}
                        />{" "}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        border: "1px solid #E7E3F2",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Box className="genderbyRegionInputBoxLabel">
                        {" "}
                        Non-binary{" "}
                      </Box>
                      <Box>
                        {" "}
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            textAlign: "center",
                            padding: "10px",
                          }}
                          min="0"
                          type="Number"
                          value={el.nonBinary}
                          className="OverallGenderRepresentationTable"
                          onChange={(e) => {
                            setOverallGenderRepresentationState((prev) => {
                              return prev.filter((curvalue, idx) => {
                                if (curvalue.year == el?.year) {
                                  curvalue.nonBinary = Number(e.target.value);
                                }
                                return curvalue;
                              });
                            });
                          }}
                        />{" "}
                      </Box>
                    </Box>
                  </Stack>
                </Item>
              </Grid>
            ))}

            {(() => {
              let arr = [0, 0, 0, 0, 0];
              for (
                let i = 0;
                i < OverallGenderRepresentationState?.length;
                i++
              ) {
                arr.pop();
              }
              return arr;
            })()?.map((el) => (
              <Grid item md={2.4}>
                <Item
                  onClick={openPopUpForAddYears}
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // borderStyle: "dashed",
                    "&:hover": {
                      border: "1px dashed #6846c7",
                      color: "#6846c7",
                      backgroundColor: "#fff",
                    },
                    cursor: "pointer",
                    color: "#DFD4FF",
                    backgroundColor: "#fcfcfc",
                    border: "1px dashed rgba(0, 0, 0, 0.2);",
                  }}
                >
                  <Stack spacing={2}>
                    <Box>Add year</Box>
                  </Stack>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box
          className="tableHedingNamefontFamily"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            mt: "30px",
          }}
        >
          <Box
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "#E5E0F9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            2
          </Box>{" "}
          Gender by region
        </Box>
        <Box
          className="tablebgColorForHr"
          sx={{ padding: "0px 20px", mt: "20px" }}
        >
          {list.map((listel, ind) => (
            <Box sx={{ mt: "15px" }}>
              {" "}
              <Box style={{ display: "flex" }}>
                <Box>
                  {" "}
                  <Select
                    className="select-year"
                    sx={{ height: "55px" }}
                    onChange={(e) => {
                      // let data = list;
                      // data[ind] = { year: e.target.value };
                      // setList(data);
                      // setgenderbyregionYear(e.target.value);
                      setList((prev) => {
                        return prev.filter((ele, inx) => {
                          if (inx == ind) {
                            ele.year = e.target.value;
                          }
                          return ele;
                        });
                      });
                    }}
                    value={listel.year}
                  >
                    <MenuItem value={""}>select year</MenuItem>

                    {OverallGenderRepresentationState?.map((e) => (
                      <MenuItem value={e?.year}> {e?.year}</MenuItem>
                    ))}
                  </Select>{" "}
                </Box>
              </Box>
              <Stack
                spacing={3}
                className="tableHightandSizeForThreeSections"
                sx={{ mt: "20px" }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {genderbyRegionStates?.map((elementList) => {
                      if (elementList.year == listel.year) {
                        return (
                          <Grid item xs={6}>
                            <Item3>
                              <Box className="genderbyRegionTableContent">
                                <Stack spacing={2}>
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "right",
                                        color: "#BCBCBC",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        deleteFunctionCallFromUi(
                                          elementList,
                                          "countrystate"
                                        );
                                      }}
                                    >
                                      {/* <DeleteIcon /> */}

                                      <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
                                          fill="#707EAE"
                                        />
                                      </svg>
                                    </Box>
                                    <Box>
                                      {" "}
                                      <FormControl>
                                        <InputLabel id="demo-simple-select-label61">
                                          Select country
                                        </InputLabel>

                                        <Select
                                          label="Select contry"
                                          labelId="demo-simple-select-label61"
                                          id="demo-simple-select"
                                          sx={{
                                            width: "300px",
                                            height: "55px",
                                            fontSize: "18px",
                                            backgroundColor: "transparent",
                                          }}
                                          MenuProps={{
                                            sx: {
                                              maxHeight: "250px",
                                              maxWidth: "250px",
                                            },
                                          }}
                                          value={elementList.country}
                                          onChange={(e) => {
                                            setgenderbyRegionStates((prev) => {
                                              return prev.filter(
                                                (curvalue, idx) => {
                                                  if (
                                                    curvalue.year ==
                                                      listel.year &&
                                                    curvalue.date ==
                                                      elementList.date
                                                  ) {
                                                    curvalue.country =
                                                      e.target.value;
                                                    curvalue.countryName =
                                                      Country.getCountryByCode(
                                                        e.target.value
                                                      ).name;
                                                  }
                                                  // console.log(curvalue);
                                                  return curvalue;
                                                }
                                              );
                                            });
                                          }}
                                        >
                                          {Country.getAllCountries()?.map(
                                            (el) => (
                                              <MenuItem value={el?.isoCode}>
                                                {" "}
                                                {el?.name}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                  <Box>
                                    {/* <Box> Region</Box> */}
                                    <Box>
                                      {" "}
                                      <FormControl>
                                        <InputLabel id="demo-simple-select-label60">
                                          Select state
                                        </InputLabel>
                                        <Select
                                          label="Select state"
                                          labelId="demo-simple-select-label60"
                                          id="demo-simple-select"
                                          MenuProps={{
                                            sx: {
                                              maxHeight: "250px",
                                              maxWidth: "300px",
                                            },
                                          }}
                                          sx={{
                                            width: "300px",
                                            height: "55px",
                                            fontSize: "18px",
                                          }}
                                          value={elementList.state}
                                          onChange={(e) => {
                                            setgenderbyRegionStates((prev) => {
                                              return prev.filter(
                                                (curvalue, idx) => {
                                                  if (
                                                    curvalue.year ==
                                                      listel.year &&
                                                    curvalue.date ==
                                                      elementList.date
                                                  ) {
                                                    curvalue.state =
                                                      e.target.value;
                                                  }
                                                  console.log(curvalue);
                                                  return curvalue;
                                                }
                                              );
                                            });
                                          }}
                                        >
                                          {checkAlreadyState(
                                            (() => {
                                              let da =
                                                genderbyRegionStates?.filter(
                                                  (el) =>
                                                    el?.year == listel.year &&
                                                    el.date == elementList.date
                                                )[0]?.country;
                                              return da;
                                            })(),
                                            elementList.state,
                                            listel.year
                                          ).map((el, idx) => {
                                            return (
                                              <MenuItem value={el?.name}>
                                                {" "}
                                                {el?.name}
                                              </MenuItem>
                                            );
                                          })}

                                          {/* map((el) => {
                                              return(
                                                 <MenuItem value={el?.name}>
                                              {" "}
                                              {el?.name}
                                              

                                            </MenuItem>
                                          )}
                                          ) */}
                                          {/* {stateList?.[
                                (() => {
                                  let da = genderbyRegionStates?.filter(
                                    (el) =>
                                      el?.year == listel.year &&
                                      el.date == elementList.date
                                  )[0]?.country;
                                  return da;
                                })()
                              ]?.map((el) => (
                                <MenuItem value={el?.name}>
                                  {" "}
                                  {el?.name}
                                </MenuItem>
                              ))} */}
                                        </Select>
                                      </FormControl>
                                    </Box>
                                  </Box>
                                </Stack>

                                <Box
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 1fr 1fr",
                                    gridGap: "20px",
                                  }}
                                >
                                  {genderList?.map((el) => (
                                    <Box
                                      style={{
                                        marginTop: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Box>
                                        <Box> {el.name} </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Male{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            className="genderbyRegionInputBox"
                                            value={elementList[el.name]?.male}
                                            min="0"
                                            type="Number"
                                            onChange={(e) => {
                                              setgenderbyRegionStates(
                                                (prev) => {
                                                  return prev.filter(
                                                    (curvalue, idx) => {
                                                      if (
                                                        curvalue.year ==
                                                          listel.year &&
                                                        curvalue.date ==
                                                          elementList.date
                                                      ) {
                                                        curvalue[el.name].male =
                                                          Number(
                                                            e.target.value
                                                          );
                                                      }
                                                      console.log(curvalue);
                                                      return curvalue;
                                                    }
                                                  );
                                                }
                                              );
                                            }}
                                          />{" "}
                                        </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Female{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            className="genderbyRegionInputBox"
                                            min="0"
                                            type="Number"
                                            value={elementList[el.name]?.female}
                                            onChange={(e) => {
                                              setgenderbyRegionStates(
                                                (prev) => {
                                                  return prev.filter(
                                                    (curvalue, idx) => {
                                                      if (
                                                        curvalue.year ==
                                                          listel.year &&
                                                        curvalue.date ==
                                                          elementList.date
                                                      ) {
                                                        curvalue[
                                                          el.name
                                                        ].female = Number(
                                                          e.target.value
                                                        );
                                                      }
                                                      console.log(curvalue);
                                                      return curvalue;
                                                    }
                                                  );
                                                }
                                              );
                                            }}
                                          />{" "}
                                        </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Non-binary{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            min="0"
                                            type="Number"
                                            className="genderbyRegionInputBox"
                                            value={
                                              elementList[el.name]?.nonBinary
                                            }
                                            onChange={(e) => {
                                              setgenderbyRegionStates(
                                                (prev) => {
                                                  return prev.filter(
                                                    (curvalue, idx) => {
                                                      if (
                                                        curvalue.year ==
                                                          listel.year &&
                                                        curvalue.date ==
                                                          elementList.date
                                                      ) {
                                                        curvalue[
                                                          el.name
                                                        ].nonBinary = Number(
                                                          e.target.value
                                                        );
                                                      }
                                                      console.log(curvalue);
                                                      return curvalue;
                                                    }
                                                  );
                                                }
                                              );
                                            }}
                                          />{" "}
                                        </Box>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Item3>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </Box>
              </Stack>
              <Box
                className="addYearsbutton"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  addregion(ind);
                }}
              >
                {" "}
                Add region
              </Box>
            </Box>
          ))}
          <Box
            className="addYearsbutton"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setList((prev) => prev.concat({ year: "" }));
            }}
          >
            {" "}
            Add year
          </Box>
        </Box>

        <Box
          className="tableHedingNamefontFamily"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            mt: "30px",
          }}
        >
          <Box
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "#E5E0F9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            3
          </Box>{" "}
          Gender by business vertical
        </Box>
        <Box
          className="tablebgColorForHr"
          sx={{ width: "100%", padding: "0px 20px", mt: "15px" }}
        >
          {listbuver.map((listele, ind) => (
            <Box>
              <Box style={{ display: "flex" }}>
                <Box sx={{ mt: "10px" }}>
                  <Select
                    className="select-year"
                    sx={{ height: "55px" }}
                    name="se"
                    onChange={(e) => {
                      // let data = listbuver;
                      // data[ind] = { year: e.target.value };
                      // setListbuver(data);

                      setListbuver((prev) => {
                        return prev.filter((ele, inx) => {
                          if (inx == ind) {
                            ele.year = e.target.value;
                          }
                          return ele;
                        });
                      });
                      // setGenderbyBusinessVerticalyear(e.target.value);
                    }}
                    value={listele.year}
                  >
                    {OverallGenderRepresentationState?.map((e) => (
                      <MenuItem value={e?.year}> {e?.year}</MenuItem>
                    ))}
                  </Select>{" "}
                </Box>
              </Box>

              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                className="tableHightandSizeForThreeSections"
                sx={{ mt: "5px" }}
              >
                {genderbyBusinessVerticalstate?.map((elementList) => {
                  if (elementList.year == listele.year) {
                    return (
                      <Grid item xs={6}>
                        <Item2>
                          <Box
                            className="genderbyRegionTableContent"
                            sx={{ paddingBottom: "1px" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "right",
                                color: "#BCBCBC",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                deleteFunctionCallFromUi(
                                  elementList,
                                  "bussiness"
                                );
                              }}
                            >
                              {/* <DeleteIcon /> */}

                              <svg
                                width="11"
                                height="11"
                                viewBox="0 0 11 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
                                  fill="#707EAE"
                                />
                              </svg>
                            </Box>
                            <Box>
                              {/* <Box> Business Vertical</Box> */}
                              <Box>
                                {" "}
                                <FormControl>
                                  <InputLabel id="demo-simple-select-label78">
                                    Select business vertical
                                  </InputLabel>
                                  <Select
                                    label="Select business vertical"
                                    labelId="demo-simple-select-label78"
                                    id="demo-simple-select"
                                    MenuProps={{
                                      sx: {
                                        maxHeight: "250px",
                                        maxWidth: "300px",
                                      },
                                    }}
                                    sx={{
                                      width: "260px",
                                      height: "55px",
                                      fontSize: "18px",
                                      backgroundColor: "transparent",
                                    }}
                                    value={elementList?.businessVertical}
                                    onChange={(e) => {
                                      setGenderbyBusinessVerticalstate(
                                        (prev) => {
                                          return prev.filter(
                                            (curvalue, idx) => {
                                              if (
                                                curvalue.year == listele.year &&
                                                curvalue.date ==
                                                  elementList.date
                                              ) {
                                                curvalue.businessVertical =
                                                  e.target.value;
                                              }
                                              console.log(curvalue);
                                              return curvalue;
                                            }
                                          );
                                        }
                                      );
                                    }}
                                  >
                                    {checkAlreadyVertical(
                                      elementList?.businessVertical,
                                      listele.year
                                    ).map((el) => {
                                      return (
                                        <MenuItem value={el}>{el}</MenuItem>
                                      );
                                    })}
                                    {/* {bussinessvertical?.map((el) => (
                                      <MenuItem value={el}>{el}</MenuItem>
                                    ))} */}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Box>
                          </Box>

                          <Box
                            style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr",
                              gridGap: "20px",
                              padding: "20px",
                            }}
                          >
                            {genderList?.map((el) => (
                              <Box
                                className="genterboxnameandstryle"
                                style={{
                                  marginTop: "10px",
                                  textAlign: "center",
                                }}
                              >
                                <Box>
                                  <Box> {el.name} </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Male{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.male}
                                      onChange={(e) => {
                                        setGenderbyBusinessVerticalstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    listele.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].male =
                                                    Number(e.target.value);
                                                }

                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Female{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.female}
                                      onChange={(e) => {
                                        setGenderbyBusinessVerticalstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    listele.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].female =
                                                    Number(e.target.value);
                                                }
                                                console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />{" "}
                                  </Box>
                                </Box>
                                <Box className="genderBox">
                                  <Box className="genderbyRegionInputBoxLabel">
                                    {" "}
                                    Non-binary{" "}
                                  </Box>
                                  <Box>
                                    {" "}
                                    <input
                                      min="0"
                                      type="Number"
                                      className="genderbyRegionInputBox"
                                      value={elementList[el.name]?.nonBinary}
                                      onChange={(e) => {
                                        setGenderbyBusinessVerticalstate(
                                          (prev) => {
                                            return prev.filter(
                                              (curvalue, idx) => {
                                                if (
                                                  curvalue.year ==
                                                    listele.year &&
                                                  curvalue.date ==
                                                    elementList.date
                                                ) {
                                                  curvalue[el.name].nonBinary =
                                                    Number(e.target.value);
                                                }
                                                console.log(curvalue);
                                                return curvalue;
                                              }
                                            );
                                          }
                                        );
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                        </Item2>
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Box
                className="addYearsbutton"
                onClick={() => {
                  addGenderbyBusinessVertical(ind);
                }}
              >
                {" "}
                Add business vertical
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          className="addYearsbutton"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setListbuver((prev) => prev.concat({ year: "" }));
          }}
        >
          {" "}
          Add year
        </Box>
      </Box>
    </Box>
  );
}
