import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Select } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
// import { functions } from "../../../../Firebase";

const AddCompletionDatePopUp = (props) => {
  const { onClose, open, delfun, courseDataprop } = props;

  const handleClose = () => {
    onClose();
  };
  let date = new Date().toISOString().split("T")[0];
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [courseData, setCourseData] = useState({
    courseName: "",
    courseId: "",
    select: false,
    dueDate: "",
  });
  const [loading, setLoading] = useState(false);

  const [inputId, setInputId] = useState("2");

  const [lessonData, setLessonData] = useState([]);
  let navigater = useNavigate();

  useEffect(() => {
    setCourseData({
      courseName: courseDataprop.courseName,
      courseId: courseDataprop.courseId,
      select: false,
      dueDate: dueDate,
    });

    // const dataregionf = JSON.stringify(courseDataprop);
    let configrecrutci = {
      method: "get",
      params: {
        courseId: courseDataprop.courseId,
        type: courseDataprop?.courseType,
      },
      url: `${IP}/adminUserProfile/lesson`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrutci).then((res) => {
      let temparr = res.data.lesson.map((el) => {
        return { ...el, select: false, date: dueDate };
      });
      console.log(temparr);
      setLessonData(temparr);
    });
  }, [open]);
  const runGenerateCertificateTraining = () => {
    /// navigater("/admin/course-reports")

    delfun({
      lessonData: lessonData,
    });
    onClose();
  };

  const select_function = (e) => {
    let data = lessonData?.map((ele) => {
      ele.select = e;

      return ele;
    });
    console.log(data);
    setLessonData(data);
  };
  return (
    <Dialog
      fullWidth={false}
      maxWidth="80%"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "80%",
          padding: "16px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <Box> Add date </Box>
        <Button
          onClick={() => {
            onClose();
            handleClose();
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </Button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <Box> Modules </Box>
        <Box>
          <div
            style={{
              display: "grid",
              alignItems: "center",
              gridTemplateColumns: "1fr 2fr 2fr",
              gridGap: "30px",
            }}
          >
            <div>
              {" "}
              <input
                type="checkbox"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "2px solid light gray",
                }}
                // disabled={courseData?.select}
                // value={el.select}
                onChange={(e) => {
                  select_function(e.target.checked);

                  // console.log(data);
                  // setLessonData([]);
                  // setLessonData(data);
                }}
              />
            </div>
            <div> Module name</div>
            <div>Completion date</div>
          </div>
          {lessonData?.map((el) => (
            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "1fr 2fr 2fr",
                gridGap: "30px",
                marginTop: "10px",
              }}
            >
              <div>
                <input
                  type="checkbox"
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "2px solid light gray",
                  }}
                  // value={el.select}
                  checked={el.select}
                  onChange={(e) => {
                    let data = lessonData?.map((ele) => {
                      if (ele?.lessonId == el.lessonId) {
                        ele.select = e.target.checked;
                      }
                      return ele;
                    });
                    console.log(data);
                    setLessonData(data);
                    // setDueDate(e.target.value);
                  }}
                />
              </div>
              <div> {el?.lessonName}</div>
              <div>
                <input
                  type="date"
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "2px solid light gray",
                  }}
                  disabled={courseData?.select}
                  value={el?.date}
                  onChange={(e) => {
                    let data = lessonData?.map((ele) => {
                      if (ele?.lessonId == el.lessonId) {
                        ele.date = e.target.value;
                      }
                      return ele;
                    });
                    console.log(data);
                    setLessonData(data);
                    // setDueDate(e.target.value);
                  }}
                />
              </div>
            </div>
          ))}
        </Box>
        <Button
          sx={{
            mt: "2em",
            color: "#6846C7",
            border: "1px solid #6846C7",
            padding: "5px 15px",
          }}
          disable={loading}
          onClick={runGenerateCertificateTraining}
        >
          {loading ? "Loading..." : "Save"}
        </Button>
      </Box>
    </Dialog>
  );
};

export default AddCompletionDatePopUp;
