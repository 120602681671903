import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Select } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";

const AddYearsPopUp = (props) => {
  const { onClose, open, delfun ,prevData} = props;
  const handleClose = () => {
    onClose();
  };
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0].split("-")[0]
  );
  const [loading, setLoading] = useState(false);

  const [inputId, setInputId] = useState("2");

  console.log(prevData);
  let navigater = useNavigate();
  const runGenerateCertificateTraining = () => {
    /// navigater("/admin/course-reports")
    if (dueDate < 999) {
      alert("Enter valid year");

      return;
    }
    let flag=0;
    prevData.map((val,idx)=>{
      if(val.year == dueDate){
        alert('Year Already Present');
        flag=1;
      }
    })
    
    if(flag==1)
      return;

    if (inputId == 2) {

      


      delfun(dueDate);
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth="200px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "300px",
          padding: "16px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <Box> Select the year </Box>
        <Button
          onClick={() => {
            onClose();
            handleClose();
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </Button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <input
              type="number"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "2px solid light gray",
              }}
              value={dueDate}
              onChange={(e) => {
                setDueDate(+e.target.value);
              }}
            />
          </div>
        </div>

        <Button
          sx={{
            mt: "2em",
            color: "#6846C7",
            border: "1px solid #6846C7",
            padding: "5px 15px",
          }}
          disable={loading}
          onClick={runGenerateCertificateTraining}
        >
          {loading ? "Loading..." : "Add Year"}
        </Button>
      </Box>
    </Dialog>
  );
};

export default AddYearsPopUp;
