import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  createRef,
  useCallback,
} from "react";
import ReactPlayer from "react-player/lazy";
import screenfull from "screenfull";
import { ReactComponent as Play } from "./icons/play.svg";
import { findDOMNode } from "react-dom";
import { ReactComponent as Pause } from "./icons/pause.svg";
import { ItemContext } from "../Context/ItemContext";
import { httpsCallable } from "firebase/functions";

import { IP } from "../../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../../Cookies";
import { CheckBox } from "@mui/icons-material";
import { Button } from "@mui/material";
const VideoPlayer = ({ videoURL, itemId, setCanNavigate }) => {
  // const playerRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [duration, setDuration] = useState(0);
  const { courseId, isLinear, sample } = useContext(ItemContext);

  const [progressFetched, setProgressFetched] = useState(false);
  const [progress, setProgress] = useState();
  const [percent, setPercent] = useState(0);
  const playerRef = useRef();
  const progressRef = createRef();
  progressRef.current = progress;

  const handleTabChange = (event) => {
    if (document.visibilityState == "visible") {
      // setIsPlaying(true);
      //console.log("tab is active");
    } else {
      setIsPlaying(false);
      //console.log("tab is inactive");
    }
  };
  const handlePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };
  const handlePlay = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };
  const handleProgress = (event) => {
    console.log(event.playedSeconds);
    if (event.playedSeconds > 0) setProgress(event.playedSeconds);
    let currentProgress = Math.floor((event.playedSeconds / duration) * 100);
    console.log(currentProgress, duration);
    setPercent(currentProgress);
  };

  const handleOnReady = () => {
    //console.log("Time start", progress);
    playerRef.current.seekTo(progress, "seconds");
  };

  const resetProgress = (secs) => {
    playerRef?.current.seekTo(secs);
    // setProgress(Math.floor((secs / duration) * 100));
  };

  // useEffect(() => {}, [videoURL]);
  useEffect(() => {
    console.log(videoURL);
    const COMPLETE_PERCENT = 20;
    if (percent > 0 && percent % 10 == 0 && !sample) {
      let configrecrut = {
        method: "post",

        url: `${IP}/userMicroCourseContent/video`,
        // params: { courseId: courseId, itemId: itemId },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: JSON.stringify({
          courseId: courseId,
          itemId: itemId,
          lastVisited: new Date().toString(),
          lastDuration: progress,
          completed: Boolean(percent >= COMPLETE_PERCENT),
        }),
      };
      axios(configrecrut);

      if (percent >= COMPLETE_PERCENT) setCompleted(true);
    }
  }, [percent]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleTabChange);

    let configrecrut = {
      method: "get",

      url: `${IP}/userMicroProgress/items`,
      params: { courseId: courseId, itemId: itemId },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };
    axios(configrecrut)
      .then((result) => {
        if (result.data.error === "not present") {
          setCompleted(false);
        } else if (result.data.completed === true) {
          setCompleted(true);
        }
        if (result.data.lastDuration != undefined) {
          //console.log(result.data.lastDuration);
          setProgress(result.data.lastDuration);

          //console.log("seek video");
        }
        setProgressFetched(true);
      })
      .catch((er) => {
        // console.log("dfdsg");
      });

    // very important to remove event listner when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleTabChange);
    };
  }, []);

  useEffect(() => {
    setCanNavigate(completed || !isLinear);
  }, [completed]);

  const fullscreen = () => {
    screenfull.request(findDOMNode(playerRef.current));

    // document.getElementById("testdomel").style.height = "1000px";
    // ds.style.width = "800px";
    // playerRef.current
    // screenfull.request(

    //   document.getElementById("testdomel")
    // );
  };
  return (
    <div className="videoPlayer-container">
      {progressFetched && (
        <div id="testdomel">
          <ReactPlayer
            ref={playerRef}
            url={videoURL}
            // playing={isPlaying}
            controls={true}
            // onPlay={handlePlay}
            // onPause={handlePause}
            onProgress={handleProgress}
            // controls={false}
            onDuration={(num) => setDuration(num)}
            // onReady={handleOnReady}
            width="100%"
            height={"80%"}
          />{" "}
        </div>
      )}

      {/* <div
        className="videoPlayer-controls"
        style={{
          backgroundColor: "#DACCFF",
          height: "54px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
            backgroundColor: "#E1F566",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isPlaying ? (
            <Pause
              onClick={handlePlayPause}
              className="videoPlayer-playPause"
            />
          ) : (
            <Play onClick={handlePlayPause} className="videoPlayer-playPause" />
          )}
        </div>

        <div className="markCompleted">
          <Button
            sx={{
              width: "120px",
              hight: "40px",
              // border: "1px solid #F3F1FF",
              backgroundColor: "#E1F566",
              outline: "none",
            }}
            onClick={() => {
              fullscreen();
            }}
          >
            {" "}
            Full Screen{" "}
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default VideoPlayer;
