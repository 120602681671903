import axios from "axios";
import { IP } from "./baseUrlAndConfig";

const setCookie = (name, value, date) => {
  const dev = process.env.NODE_ENV === "development";
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 30);
  const IP = dev ? "http://192.168.1.199:3000" : "https://api.sereininc.com";
  document.cookie = dev
    ? name +
      "=" +
      (value || "") +
      "; path=/" +
      `;expires=${date ? date : expirationDate}`
    : name + "=" + (value || "") + "; domain=.sereininc.com" + "; path=/";
  // document.cookie = dev
  //   ? name +
  //     "=" +
  //     (value || "") +
  //     "; path=/" +
  //     `;expires=${date ? date : expirationDate}`
  //   : name + "=" + (value || "") + "; domain=.kdev.co.in" + "; path=/";

  // cookies.set(name, value, {
  //   path: "/",
  //   expires: expirationDate,
  //   domain: dev ? "" : ".sereininc.com",
  // });
  // console.log("line 22", { name: value });

  if (name != "rfToken") {
    // console.log("line 24", { name: value });

    localStorage.setItem(name, value);
  }
};
const getCookie = (name) => {
  // return cookies.get(name);
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) {
      let val = c.substring(nameEQ.length, c.length);
      // console.log("line 37", { name: val });

      if (name != "rfToken") {
        if (val) {
          // console.log("line 39", { name: val });
          localStorage.setItem(name, val);
        }
      }
      // console.log(localStorage.getItem(name));
      return localStorage.getItem(name);
    }
  }

  return localStorage.getItem(name);
};

const getLocalStorage = (name) => {
  return localStorage.getItem(name);
};

const deleteCookie = (name) => {
  localStorage.removeItem("idc");
  deleteCookiesInServer();
  // console.log("deleteCookie");

  // //console.log("Cookies deleted");
};

const getCookieFromServer = (name) => {
  // return cookies.get(name);
  // console.log("getCookieFromServer", name);

  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) {
      let val = c.substring(nameEQ.length, c.length);
      if (name == "rfToken") {
        // console.log(val);
        return val;
        localStorage.setItem(name, val);
      }
      // console.log(localStorage.getItem(name));
      // return localStorage.getItem(name);
    }
  }
  // console.log(localStorage.getItem(name));
  return localStorage.getItem(name);
};

const deleteCookiesInServer = () => {
  let data = JSON.stringify({});

  var config = {
    method: "post",

    url: `${IP}/auth/logout`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("bearer")}`,
    },
    data: data,
  };
  axios(config).then((res) => {
    // console.log(res.data);

    localStorage.clear();
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() - 30);
    document.cookie.split(";").forEach(function (c) {
      // console.log(c);
      if (c.split("=")[0] != "clrs") {
        setCookie(c.split("=")[0], "", expirationDate);
        localStorage.removeItem(c.split("=")[0]);
      }
      // document.cookie = c.split("=")[0] + "=; Path=/;";
    });
    window.location.reload();
  });
};

export {
  deleteCookie,
  deleteCookiesInServer,
  getCookie,
  getCookieFromServer,
  getLocalStorage,
  setCookie,
};
