import { useEffect, useState } from "react";
import "./table.css";
import "./policies.css";
import { Divider } from "@material-ui/core";
import { Box, Button, Stack } from "@mui/material";
//import { ArrInfura } from "./infuraData";

import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import { ValidateAuth } from "../../../AuthToken";
import { getCookie } from "../../../Cookies";
import { useNavigate } from "react-router";
import HrTopNavbar from "../../Sidebar/HrTopNavbar";
import InfrastructureInfoPopup from "./InfrastructureInfoPopup";
export default function ProcessandTool({
  overallgender,
  setOverallgender,
  overAllFlag,
  companyId,
}) {
  const [ArrInfura, setArrInfura] = useState([]);
  const [policiesState, setPolociesState] = useState({});

  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  useEffect(() => {
    // ValidateAuth();
    // let newobj = JSON.parse(getCookie("roles"));
    // // console.log(newobj.HRDI);
    // if (!newobj.HRDI?.includes(getCookie("ROLE"))) {
    //   goToHome();
    //   return <></>;
    // }
  }, []);

  const functionCallOnSelect = (data) => {
    // let name = data.heading.replaceAll(" ", "");
    // setPolociesState((prev) => ({ ...prev, [name]: data.ans }));

    let arr = [];
    for (let i = 0; i < ArrInfura.length; i++) {
      if (i == data.index) {
        let obj = {};
        obj.heading = ArrInfura[i].heading;
        obj.description = ArrInfura[i].description;
        obj.isAgree = data.ans;
        obj.type = ArrInfura[i].type;
        arr.push(obj);
      } else {
        arr.push(ArrInfura[i]);
      }
    }
    let configrecrut = {
      method: "patch",

      url: `${IP}/processAndTools?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: JSON.stringify(arr),
    };

    axios(configrecrut)
      .then((res) => {
        fetchdata();
      })
      .catch((ee) => {
        console.log("t");
      });
  };

  // useEffect(() => {
  //   setOverallgender((prev) => ({
  //     ...prev,
  //     processandtoolpoliciesState: policiesState ? policiesState : {},
  //   }));

  //   // setgenderbyRegionStates(ex);
  // }, [policiesState]);

  const fetchdata = () => {
    let configrecrut = {
      method: "get",

      url: `${IP}/processAndTools?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut).then((res) => {
      setArrInfura([]);
      setArrInfura(res.data);
    });
  };

  useEffect(() => {
    fetchdata();

    // setgenderbyRegionStates(ex);
  }, [overAllFlag]);
  return (
    <Box>
      <Box
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#F3F1FF",
          zIndex: "2",
          pt: "24px",
        }}
      >
        <HrTopNavbar title={"Processes and tools"}>
          {/* <Box sx={{ display: "flex", gap: "10px" }}>
          <Box sx={{ display: "flex", gap: "10px", fontSize: "14px" }}>
            {" "}
            <Box
              sx={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                bgcolor: "#E1F566",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              01
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              Course completed{" "}
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10px", fontSize: "14px" }}>
            {" "}
            <Box
              sx={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                bgcolor: "#E1F566",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              64
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              Course completed{" "}
            </Box>
          </Box>
        </Box> */}
        </HrTopNavbar>
      </Box>

      <Stack
        spacing={4}
        className="tablebgColorForHr"
        style={{
          marginTop: "24px",
          backgroundColor: "#F3F1FF",
          marginLeft: "25px",
          marginBottom: "100px",
        }}
      >
        {ArrInfura?.map((el, index) => (
          <Box
            sx={{
              display: "grid",

              gridTemplateColumns: "6fr 1fr",

              // justifyContent: "space-between",
              // alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 18fr 2fr",
              }}
            >
              <Box
                sx={{
                  color: "#000",
                  backgroundColor: "#F3F1FF",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                }}
              >
                {index + 1}.
              </Box>
              <Box sx={{ display: "flex", width: "98%" }}> {el.heading} </Box>
              <Box>
                {" "}
                <InfrastructureInfoPopup data={el?.description} />
              </Box>
            </Box>

            <Box style={{ display: "flex", gap: "20px" }}>
              <Button
                sx={{
                  width: "64px",
                  height: "40px",
                  borderRadius: "12px",
                  backgroundColor: el.isAgree ? "#E1F566" : "#BCBCBC",
                }}
                onClick={() => {
                  functionCallOnSelect({
                    heading: el.heading,
                    ans: true,
                    index,
                  });
                }}
              >
                Yes
              </Button>

              <Button
                sx={{
                  width: "64px",
                  height: "40px",
                  borderRadius: "12px",
                  backgroundColor: el.isAgree === false ? "#E1F566" : "#BCBCBC",
                }}
                onClick={() => {
                  functionCallOnSelect({
                    heading: el.heading,
                    ans: false,
                    index,
                  });
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
