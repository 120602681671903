import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

export default function Chart({ chartOptions }) {
  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={{ style: { height: "100%", width: "100%" } }}
        />
      </div>
    </>
  );
}
