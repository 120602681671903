import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, MenuItem, Select } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
import axios from "axios";
import { t } from "i18next";

import { getCookie } from "../../../Cookies";
import { IP } from "../../../baseUrlAndConfig";
import HrloadingScreen from "../../../Hr/LodingHrscreen";
// import { functions } from "../../../../Firebase";

const SendEmailConfirmationPopUp = (props) => {
  const { onClose, open, sendEmailFun, reportId } = props;
  const handleClose = () => {
    onClose();
  };
  const [senderName, setSenderName] = useState([
    { name: "Serein Learning Academy" },
    { name: "Nandini From Serein" },
  ]);
  const [emailData, setEmailData] = useState({
    name: "",
    subject: "",
    body: "",
  });
  const getSenderEmailData = () => {
    let configExInt = {
      method: "get",
      // params: { company: companyId ? companyId : userData.companyId },

      url: `${IP}/emailSenderName`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataex",
    };
    axios(configExInt)
      .then((res) => {
        setSenderName(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendEmail = () => {
    sendEmailFun(emailData);
    onClose();
  };
  return (
    <Dialog
      fullWidth={true}
      PaperProps={{
        style: {
          borderRadius: "20px",
          minHeight: "40vh",
          maxHeight: "90vh",
          minWidth: "60vw",
          padding: "16px",
          overflow: "scroll",
        },
      }}
      onClose={() => {
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p>Email </p>
        <button
          onClick={() => {
            handleClose();
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
          display: "grid",
          gridTemplateColumns: "1fr",
          gridGap: "20px",
        }}
      >
        <div>
          {" "}
          <Box> Sender Name</Box>
          <Select
            value={emailData.name}
            onChange={(e) => {
              setEmailData((prev) => ({ ...prev, name: e.target.value }));
              //   setUserData({
              //     companyId: "",
              //     companyId: "",
              //   });
            }}
            sx={{
              width: "300px",
              height: "30px",
            }}
          >
            {senderName?.map((el) => (
              <MenuItem value={el.name}> {el.name}</MenuItem>
            ))}

            {/* <MenuItem value="1"> login Invite with course</MenuItem> */}
          </Select>{" "}
        </div>

        <div>
          {" "}
          <Box> Subject</Box>
          <Box>
            <div className="admin-overdue-mid-left-input">
              <textarea
                type="text"
                style={{
                  width: "300px",
                  height: "40px",
                  fontSize: "20px",
                }}
                value={emailData.subject}
                onChange={(e) => {
                  setEmailData((prev) => ({
                    ...prev,
                    subject: e.target.value,
                  }));
                }}
              />
            </div>
          </Box>
        </div>
        {/* <div>
          {" "}
          <Box> Text For Body</Box>
          <Box>
            <div className="admin-overdue-mid-left-input">
              <textarea
                type="text"
                style={{
                  width: "300px",
                  height: "40px",
                  fontSize: "20px",
                }}
                value={emailData.body}
                onChange={(e) => {
                  setEmailData((prev) => ({ ...prev, body: e.target.value }));
                }}
              />
            </div>
          </Box>
        </div> */}

        <div className="admin-overdue-mid-left-input">
          <Button
            variant="contained"
            sx={{
              mt: "3vh",
              color: "#fff",
              width: "200px",
              height: "40px",
              background: "#11047A",
              borderRadius: "70px",
            }}
            // disable={loading}
            onClick={sendEmail}
          >
            {"Send"}
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default SendEmailConfirmationPopUp;
