import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { functions } from "../../../../Firebase";
import {
  FullLeftArrowIcons,
  FullRightArrowIcons,
} from "../../../../utilities/Icons/Icons";
import { ItemContext } from "../Context/ItemContext";
import "./courseItem.css";
import HtmlItem from "./HtmlItem";
import Node from "./Node";
import { PdfContainer } from "./PdfContainer";
import QuizContainer from "./QuizContainer";
import VideoPlayer from "./VideoPlayer";
import { IP } from "../../../../baseUrlAndConfig";
import axios from "axios";
import { getCookie } from "../../../../Cookies";
import { height } from "@mui/system";
export const CourseItem = () => {
  const [itemInfo, setItemInfo] = useState(null);
  const getItemInfo = httpsCallable(functions, "getItemInfo");
  const {
    itemsArray,
    progressIndex,
    setProgressIndex,
    isLinear,
    courseStatus,
    certificateInfo,
  } = useContext(ItemContext);
  const [canNavigate, setCanNavigate] = useState(!isLinear);
  const mainItem = itemsArray[progressIndex];

  // console.log(mainItem);
  useEffect(() => {
    // if(courseStatus){
    //   setProgressIndex((index) => index+itemsArray.length+1)
    // }
    if (mainItem?.type == "head") setProgressIndex((index) => index + 1);
    //console.log("Item Loading", mainItem?.id);
    setItemInfo(null);
    if (mainItem?.type === "item") {
      let configrecrut = {
        method: "get",

        url: `${IP}/userMicroCourseDetails/item`,
        params: { itemId: mainItem.id },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: "dataregionf",
      };
      axios(configrecrut).then((result) => {
        setItemInfo(result.data);
      });
    }
  }, [progressIndex]);

  const changeProgressIndex = (index) => {
    if (index > progressIndex && !canNavigate) {
      alert(
        index > progressIndex
          ? "Complete the current item to go to the next item"
          : "You have completed the course."
      );
      return;
    }
    if (index < 0) return;
    else if (index >= itemsArray.length) return;
    // console.log(mainItem);
    // console.log(itemsArray);

    setProgressIndex(index);
  };

  //console.log(progressIndex,itemsArray.length)
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "#F3F1FF",
      }}
    >
      {/* dont remove below comments */}

      {mainItem?.type === "item" && itemInfo?.itemType == "pdf" && (
        <PdfContainer
          setCanNavigate={setCanNavigate}
          itemId={mainItem.id}
          pdfURL={itemInfo.pdfURL}
        />
      )}

      {mainItem?.type === "item" && itemInfo?.itemType == "video" && (
        <VideoPlayer
          setCanNavigate={setCanNavigate}
          itemId={mainItem.id}
          videoURL={itemInfo.videoURL}
          allow="autoplay; encrypted-media"
          allowfullscreen
          title="video"
        />
      )}

      {mainItem?.type === "item" && itemInfo?.itemType == "html" && (
        <HtmlItem
          setCanNavigate={setCanNavigate}
          itemId={mainItem.id}
          url={itemInfo.htmlURL}
        />
      )}

      {/* {mainItem?.type === "quiz" && <QuizItem />} */}

      {mainItem?.type === "quiz" && <QuizContainer quizId={mainItem.id} />}

      {mainItem?.type == "node" && (
        <Node
          node={mainItem}
          courseStatus={courseStatus}
          certificateInfo={certificateInfo}
          setCanNavigate={setCanNavigate}
        />
      )}

      {mainItem?.type != "quiz" && (
        <Box
          sx={{
            width: "100%",
            height: "30px",
            display: "flex",
            marginTop: "60px",
            background: "#f3f1ff",
            justifyContent: "space-between",
            alignItems: "center",
            // background: "#fff",
          }}
        >
          <Box
            sx={{
              width: "100%",
              marginTop: "20px",
              padding: "0px 25px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {progressIndex + 1 < itemsArray.length && (
              <Box
                sx={{
                  width: "55px",
                  height: "55px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E1F566",
                }}
                onClick={() => changeProgressIndex(progressIndex - 1)}
              >
                <FullLeftArrowIcons />
              </Box>
            )}

            {/* {JSON.stringify(canNavigate)} */}
            {progressIndex + 1 < itemsArray.length && (
              <Box
                sx={{
                  width: "55px",
                  height: "55px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E1F566",
                }}
                onClick={() => changeProgressIndex(progressIndex + 1)}
              >
                <FullRightArrowIcons />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
