export const CREATEROLES = "CREATEROLES";
// export const HORROR_MOVIE = 'HORROR_MOVIE';
// export const TOP_MOVIE = 'TOP_MOVIE'
// export const LATEST_MOVIE = 'LATEST_MOVIE'
// export const DISPLAYSINGLEMOVIE = 'DISPLAYSINGLEMOVIE'

export const roles = (recommemdedMovie) => {
  return {
    type: CREATEROLES,
    payload: recommemdedMovie,
  };
};
