import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Readex Pro', sans-serif",
      textTransform: "none",
      fontSize: 16,
    },
  },
  palette: {
    primary: {
      main: "#003A69",
      bg: "linear-gradient(0deg, #D9F0FA, #D9F0FA), rgba(235, 240, 241, 0.3)",
      btn: "#41B6E6"
    },
    secondary: {
      main: "#006747",
      second: "#509E2F"
    },
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
        p: 0,
        disableRipple: true,
      },
      variant: "text",
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

const AppThemeProvider = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default AppThemeProvider;
