import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import { Button, Input, TextField } from "@mui/material";
import "./scallist.css";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useLocation, useNavigate } from "react-router";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import axios from "axios";
import { borderRadius } from "@mui/system";
export const AdvanceMentScaleCreate = () => {
  const { clrs } = useSelector((state) => state.createClr);
  const navigate = useNavigate();

  const location = useLocation();

  const [color, setColor] = useColor("hex", "#121212");

  const [id, setId] = useState("");
  const [scalenextdata, setScalenextdata] = useState({
    index: -1,
    data: "",
  });

  const [scalelearningdata, setScaleLearningdata] = useState({
    index: -1,
    data: "",
  });

  const [image, setImage] = useState({
    file: null,
    url: "",
  });

  const [incimage, setIncImage] = useState({
    file: null,
    url: "",
  });
  const [base64Image, setBase64Image] = useState("");

  const createCompany = httpsCallable(functions, "createCompany");

  const [details, setDetails] = useState({
    name: "",
    activeImgBase64: "",
    inActiveImgBase64: "",
    description: "",
    id: "",

    sectionHeading: "",
    recommendNextSteps: [],
    recommendedLearning: [],
  });

  useEffect(() => {
    let data = location.state;
    if (data) {
      // console.log(data);
      setDetails(data);

      setImage((prev) => ({ ...prev, url: data.activeImgBase64 }));

      setIncImage((prev) => ({ ...prev, url: data.inActiveImgBase64 }));
    }
  }, []);

  const handleFileChange = (type, e) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);

    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
      //   //console.log("setBase64Image done", reader.result);
      if (type == "active") {
        setImage((prev) => ({
          file: files[0],
          url: url,
        }));
        setDetails({ ...details, activeImgBase64: reader.result });
      } else {
        setIncImage((prev) => ({
          file: files[0],
          url: url,
        }));
        setDetails({ ...details, inActiveImgBase64: reader.result });
      }
    };
    reader.readAsDataURL(files[0]);
  };

  const [subloading, setSubloading] = useState(false);
  const handleSubmit = () => {
    console.log("details", details);
    // if (
    //   details.name === "" ||
    //   details.logo === "" ||
    //   details.brandColor.bgColor === "" ||
    //   details.brandColor.headingTextColor === "" ||
    //   details.brandColor.dashboardColor === "" ||
    //   details.brandColor.bodyTextColor === "" ||
    //   details.brandColor.primaryBtnColor === "" ||
    //   details.brandColor.secondaryBtnColor === ""
    // ) {
    //   alert("Please fill all the fields");
    //   return;
    // }
    setSubloading(true);

    let data = JSON.stringify({
      ...details,
    });
    var config = {
      method: "post",

      // url: `${IP}/maturityScale/maturity?companyId=${companyId}`,
      url: `${IP}/maturityScale/maturity`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        //console.log("res", res);
        setSubloading(false);
        //navigate("/admin/settings/company-department");
      })
      .catch((err) => {
        //console.log(err);
        setSubloading(false);
      });
  };

  const handleChangenext = () => {
    let arr = details.recommendNextSteps;

    if (scalenextdata.index == -1) {
      arr.push(scalenextdata.data);
    } else {
      arr[scalenextdata.index] = scalenextdata.data;
    }
    setScalenextdata({
      index: -1,
      data: "",
    });

    setDetails((prev) => ({ ...prev, recommendNextSteps: arr }));
  };

  const handleChangeLearning = () => {
    let arr = details.recommendedLearning;

    if (scalelearningdata.index == -1) {
      arr.push(scalelearningdata.data);
    } else {
      arr[scalelearningdata.index] = scalelearningdata.data;
    }
    setScaleLearningdata({
      index: -1,
      data: "",
    });

    setDetails((prev) => ({ ...prev, recommendedLearning: arr }));
  };

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Advancement Scale")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <div
                style={{
                  background: "#FFFFFF",
                  borderRadius: "24px",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    marginBottom: "100px",
                  }}
                >
                  <div>
                    <h3>Advancement Scale </h3>

                    <input
                      className="inputbox"
                      value={details.name}
                      onChange={(e) => {
                        setDetails({
                          ...details,
                          name: e.target.value,
                        });
                      }}
                      style={{ padding: "5px", fontSize: "18px" }}
                      type="text"
                    />
                  </div>

                  <div className="inputHeding" style={{ marginTop: "50px" }}>
                    {" "}
                    Status image
                  </div>
                  <div style={{ marginTop: "30px" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                        gap: "30px",
                        marginTop: "30px",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <div className="subheding">Active Stage</div>
                      <div
                        style={{
                          display: "flex",
                          gap: "50px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {" "}
                          <Button
                            variant="contained"
                            component="label"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                            startIcon={<FileUploadIcon />}
                          >
                            Upload
                            <input
                              name="logo"
                              hidden
                              accept="image/*"
                              multiple
                              type="file"
                              onChange={(e) => {
                                handleFileChange("active", e);
                              }}
                            />
                          </Button>{" "}
                        </div>{" "}
                        <div>
                          {" "}
                          {image.url && (
                            <div style={{ width: "300px", height: "150px" }}>
                              <img
                                src={image?.url}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "5px",
                                }}
                              />
                            </div>
                          )}{" "}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 4fr",
                        gap: "30px",
                        marginTop: "30px",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <div className="subheding">Inactive Stage</div>
                      <div
                        style={{
                          display: "flex",
                          gap: "50px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {" "}
                          <Button
                            variant="contained"
                            component="label"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                            startIcon={<FileUploadIcon />}
                          >
                            Upload
                            <input
                              name="logo"
                              hidden
                              accept="image/*"
                              multiple
                              type="file"
                              onChange={(e) => {
                                handleFileChange("inactive", e);
                              }}
                            />
                          </Button>{" "}
                        </div>{" "}
                        <div>
                          {" "}
                          {incimage.url && (
                            <div style={{ width: "300px", height: "150px" }}>
                              <img
                                src={incimage?.url}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "5px",
                                }}
                              />
                            </div>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 4fr",
                    gap: "50px",
                    alignItems: "center",
                  }}
                >
                  <div className="subheding">Description</div>
                  <TextField
                    multiline
                    value={details.description}
                    onChange={(e) => {
                      setDetails((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }));
                    }}
                    sx={{
                      fontSize: "18px",
                      width: "325px",
                    }}
                    type="text"
                  />
                </div>
              </div>

              <div
                style={{
                  background: "#FFFFFF",
                  borderRadius: "24px",
                  padding: "20px",
                  marginTop: "40px",
                }}
              >
                <div className="inputHeding" style={{ marginTop: "20px" }}>
                  Strategies for Initiation
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "50px",
                    }}
                  >
                    <div className="subheding">Section Heading</div>
                    <input
                      name="bgColor"
                      className="inputbox"
                      value={details.sectionHeading}
                      onChange={(e) => {
                        setDetails((prev) => ({
                          ...prev,
                          sectionHeading: e.target.value,
                        }));
                      }}
                      style={{ padding: "5px", height: "35px" }}
                      type="text"
                    />
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "3fr 8fr",
                      alignItems: "center",

                      marginTop: "20px",
                    }}
                  >
                    <div className="subheding">Recommend next steps</div>
                    <div></div>
                  </div>

                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "300px 100px",
                      alignItems: "center",

                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <Input
                        multiline
                        className="inputbox"
                        value={scalenextdata.data}
                        onChange={(e) => {
                          setScalenextdata((prev) => ({
                            ...prev,
                            data: e.target.value,
                          }));
                        }}
                        style={{
                          fontSize: "18px",
                          width: "300px",
                        }}
                        type="text"
                      />{" "}
                    </div>

                    <button
                      className="scallistbuttub"
                      style={{ marginLeft: "30px" }}
                      onClick={() => {
                        handleChangenext();
                      }}
                    >
                      Add points
                    </button>
                  </div>

                  <div style={{ marginLeft: "15px", marginTop: "10px" }}>
                    <ul>
                      {details.recommendNextSteps?.map((el, index) => (
                        <li style={{ marginTop: "10px" }}>
                          <div
                            style={{
                              display: "grid",
                              width: "60vw",

                              gridTemplateColumns: "8fr 1fr 1fr 1fr",
                            }}
                          >
                            <div className="subheding"> {el}</div>
                            <div> </div>
                            <div>
                              {" "}
                              <Button
                                variant="contained"
                                component="span"
                                onClick={() => {
                                  setScalenextdata({
                                    index: index,
                                    data: el,
                                  });
                                  //nextEdit(index,el)
                                }}
                                style={{
                                  background: `${
                                    clrs.CourseListBtn
                                      ? clrs.CourseListBtn
                                      : "hwb(224deg 78% 3%)"
                                  }`,
                                }}
                              >
                                Edit
                              </Button>{" "}
                            </div>

                            <div>
                              {" "}
                              <Button
                                variant="contained"
                                component="span"
                                style={{
                                  background: `${
                                    clrs.CourseListBtn
                                      ? clrs.CourseListBtn
                                      : "hwb(224deg 78% 3%)"
                                  }`,
                                }}
                              >
                                Delete
                              </Button>{" "}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "3fr 8fr",
                    alignItems: "center",

                    marginTop: "20px",
                  }}
                >
                  <div className="subheding"> Recommended learning</div>
                  <div></div>
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "300px 100px",
                    alignItems: "center",

                    marginTop: "20px",
                  }}
                >
                  <Input
                    multiline
                    name="bgColor"
                    className="inputbox"
                    value={scalelearningdata.data}
                    onChange={(e) => {
                      setScaleLearningdata((prev) => ({
                        ...prev,
                        data: e.target.value,
                      }));
                    }}
                    style={{ padding: "5px", fontSize: "18px", width: "300px" }}
                    type="text"
                  />{" "}
                  <button
                    className="scallistbuttub"
                    style={{ marginLeft: "30px" }}
                    onClick={handleChangeLearning}
                  >
                    Add points
                  </button>
                </div>

                <div style={{ marginLeft: "15px", marginTop: "10px" }}>
                  <ul>
                    {details.recommendedLearning?.map((el, index) => (
                      <li style={{ marginTop: "10px" }}>
                        <div
                          style={{
                            display: "grid",
                            width: "60vw",
                            gridTemplateColumns: "8fr  1fr 1fr 1fr",
                          }}
                        >
                          <div className="subheding"> {el}</div>
                          <div> </div>
                          <div>
                            {" "}
                            <Button
                              variant="contained"
                              component="span"
                              onClick={() => {
                                setScaleLearningdata({
                                  index: index,
                                  data: el,
                                });
                                //nextEdit(index,el)
                              }}
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              Edit
                            </Button>{" "}
                          </div>

                          <div>
                            {" "}
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              Delete
                            </Button>{" "}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div style={{ textAlign: "right" }}>
                  <Button
                    disabled={subloading}
                    variant="contained"
                    component="label"
                    style={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
