import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";

import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";

const SendRemainderPopUp = (props) => {
  const { onClose, open, delfun, topicId, setConfirmation, count ,calledFrom} = props;
  const handleClose = () => {
    onClose();
  };
  const [dueDate, setDueDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  useEffect(() => {
    setFirstnum(Math.floor(Math.random() * 10));
    setSecondnum(Math.floor(Math.random() * 10));
  }, [open]);
  const [loading, setLoading] = useState(false);
  const [mathDotRendom, setMathDotRendom] = useState(topicId);
  const [inputId, setInputId] = useState(0);
  const [firstnum, setFirstnum] = useState(Math.floor(Math.random() * 100));
  const [secondnum, setSecondnum] = useState(Math.floor(Math.random() * 100));
  const [employeeNameOpt, setEmployeeNameOpt] = useState(false);
  console.log("prop line 29",calledFrom);
  let navigater = useNavigate();
  const runGenerateCertificateTraining = (type) => {
    /// navigater("/admin/course-reports")
    console.log(employeeNameOpt);
    if (type == "Yes") {
      //console.log(topicId.id)
      //alert("sum is eqel");
      console.log("I CLICKED YES", employeeNameOpt);
      // delfun("Yes");
      if (employeeNameOpt || calledFrom=='form') {
        console.log('ifBlock');

        delfun("Yes", true);
      } else {
        console.log('elseBlock');
        delfun("No", false);
      }
      onClose();
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth="300px"
      PaperProps={{
        style: {
          borderRadius: "20px",
          padding: "24px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p> Send </p>
        <button
          onClick={() => {
            if (loading) return;
            handleClose();
          }}
          style={{
            marginLeft: "auto",
            border: "none",
            background: "transparent",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </Box>
      <Box
        sx={{
          mt: "30px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <span>Do you want to send it ? </span> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              gap: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <input
                type="radio"
                style={{
                  width: "1.375rem",
                  height: "1.375rem",
                  accentColor: "#5C22FF",
                }}
                value="empName"
                onChange={() => {
                  setEmployeeNameOpt(true);
                }}
                checked={employeeNameOpt}
              />
              {!calledFrom && <p style={{ fontFamily: "Poppins", textAlign: "left" }}>
                Send Reminder to {count} employees
              </p>}
              {calledFrom=='form' && <p>Do you want to send it ?</p>}
            </div>
            {!calledFrom && <div
              style={{
                display: "flex",
                gap: "10px",

                alignItems: "center",
              }}
            >
              <input
                type="radio"
                style={{
                  width: "1.375rem",
                  height: "1.375rem",
                  accentColor: "#5C22FF",
                }}
                value="empEmail"
                onChange={() => {
                  setEmployeeNameOpt(false);
                }}
                checked={!employeeNameOpt}
              />
              <label style={{ fontFamily: "Poppins", textAlign: "left" }}>
                Send Reminder To All Employees with incomplete status
              </label>
            </div>}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Button
            sx={{
              color: "#6846C7",
              border: "1px solid #6846C7",
              padding: "5px 15px",
              width: "200px",
            }}
            disable={loading}
            onClick={() => {
              runGenerateCertificateTraining("Yes");
            }}
          >
            {loading ? "Loading..." : "Send"}
          </Button>

          <Button
            sx={{
              color: "#6846C7",
              // border: "1px solid #6846C7",
              padding: "5px 15px",
              width: "200px",
            }}
            disable={loading}
            onClick={() => {
              runGenerateCertificateTraining("No");
            }}
          >
            {loading ? "Loading..." : "Cancel"}
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default SendRemainderPopUp;
