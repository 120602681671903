import { Box, Button, Grid, Paper, styled, Typography } from "@mui/material";
import ResponsiveDrawer from "../../Sidebar/HrSideBar";

import { CertificateDownloadIcon, RightArrowIcon } from "../../icons/Icons";
import { useNavigate } from "react-router";

import micro from "../Vector.svg";
import { getCookie } from "../../../Cookies";
import HrTopNavbar from "../../Sidebar/HrTopNavbar";
import { ValidateAuth } from "../../../AuthToken";
import { useContext, useEffect, useState } from "react";
import HrFooter from "../../Sidebar/HrFooter";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import imro from "../Vector.svg";
import { microArr } from "../microLerningdata";
import paly from "./paly.svg";
import WebinarPopUp from "./webinarPopUp";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import "../learning.css";
import MobileTopBar from "../../Sidebar/MobaileTopBar";
import photu from "../../../defaultGameImage.png";
const Item = styled(Paper)(({ theme }) => ({
  position: "relative",
}));

const ViewGamesAll = () => {
  const navigate = useNavigate();
  const [webdata, setWebdata] = useState([]);
  //   const [microArr, setMicroCourse] = useState([]);
  const goToHome = () => navigate("/");
  const [openpopUpForAddYears, setOpenpopUpForAddYears] = useState(false);
  const [popdata, setpopdata] = useState({});
  const { rolesAndPermission, setRolesAndPermission, course_Completion } =
    useContext(DrawerContext);
  useEffect(() => {
    // let newobj =
    //   rolesAndPermission?.Learning?.componentPermission.includes("Games");
    // // console.log(newobj.HRDI);
    // if (!newobj) {
    //   goToHome();
    //   return <></>;
    // }

    if (!rolesAndPermission) {
      // setlodingscreen(false);
    } else {
      // setlodingscreen(false);

      let newobj =
        rolesAndPermission?.Report?.componentPermission.includes("Games");
      // console.log(newobj.HRDI);
      if (!newobj) {
        // navigate(-1);
        return <></>;
      }
    }
  }, [rolesAndPermission]);

  // change it here..............
  async function fetchMicroCourse() {
    let configExTr = {
      method: "get",

      url: `${IP}/userGames/courseLibrary`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataex",
    };
    axios(configExTr).then((result) => {
      if (result.data.error) {
        return;
      }
      setWebdata(result?.data);
    });
  }

  useEffect(() => {
    fetchMicroCourse();
  }, []);

  const microlearningpagecontent = (courseId, item) => {
    // navigate(`/hr/Learning/MicroLearning-content/${courseId}`)

    navigate(`/hr/Learning/games-content/${courseId}`, {
      state: { ...item },
    });
  };
  return (
    <Box
      className="hrmainbox"
      sx={{ backgroundColor: "#F3F1FF", minHeight: "100vh" }}
    >
      <Box className="mobileSideBar_leaning">
        <ResponsiveDrawer sidebar={"Learning"} />
      </Box>

      <WebinarPopUp
        open={openpopUpForAddYears}
        onClose={() => setOpenpopUpForAddYears(false)}
        // delfun={(topic) => {
        //   addYearsFunPopUp(topic);
        // }}
        data={popdata}
      />

      <Box sx={{ width: "100%" }}>
        <MobileTopBar />

        <HrTopNavbar title={`Hello ${getCookie("EMNAME")}!`}>
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Box sx={{ display: "flex", gap: "10px", fontSize: "14px" }}>
              {" "}
              <Box
                sx={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  bgcolor: "#E1F566",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                {course_Completion}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                Course completed{" "}
              </Box>
            </Box>
          </Box>
        </HrTopNavbar>
        <Box
          sx={{
            width: "100%",
            padding: "24px",
            "@media (max-width: 780px)": {
              padding: "10px",
            },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",

                position: "relative",
                zIndex: "2",
              }}
            >
              <Box>
                <img src={imro} alt="" />
              </Box>

              <Box
                className="sectionname"
                sx={{
                  ml: "8px",
                }}
              >
                Games
              </Box>
            </Box>

            <Box sx={{ width: "100%", mt: "24px" }}>
              <Box
                // container
                // rowSpacing={8}
                // columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                sx={{
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  display: "grid",
                  gridGap: "25px",
                  "@media (max-width: 780px)": {
                    gridTemplateColumns: "1fr",
                    gridGap: "20px",
                  },
                }}
              >
                {webdata?.map((item, index) => {
                  if (index > 3) {
                    return;
                  }
                  return (
                    <Grid item xs={3}>
                      <Item
                        sx={{
                          borderRadius: "24px",
                        }}
                      >
                        <Box
                          onClick={() => {
                            microlearningpagecontent(item.courseId, item);
                          }}
                          sx={{
                            borderRadius: "24px",
                            height: "100px",
                            display: "flex",

                            gap: "0px",
                          }}
                        >
                          <Box
                            sx={{
                              // width: "45%",
                              width: "80px",

                              display: "grid",
                              alignContent: "center",
                              justifyContent: "center",
                              justifyItems: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                background: item.color,

                                width: "60px",
                                height: "80px",
                                display: "grid",
                                alignContent: "center",
                                justifyContent: "center",
                                justifyItems: "center",
                                alignItems: "center",
                                borderRadius: "20px",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                }}
                                src={photu}
                                alt=""
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              padding: "10px",
                              width: "70%",
                            }}
                          >
                            <Box
                              className="microlerningdiscription"
                              sx={{
                                minHeight: "80px",

                                display: "flex",
                                alignContent: "center",
                                // justifyContent: "center",
                                // justifyItems: "center",
                                alignItems: "center",
                              }}
                            >
                              {item?.courseName}
                            </Box>
                          </Box>
                        </Box>
                      </Item>
                    </Grid>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>

        <HrFooter />
      </Box>
    </Box>
  );
};
export default ViewGamesAll;
