import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";

const AssignHr = () => {
  const location = useLocation();
  const data = location.state;
  console.log("LOCATION>STATE DATA", data);
  const { companyId, name } = data;
  const [companyList, setCompanyList] = useState([]);
  const companyArray = [];
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [users, setUsers] = useState([]);

  //mnew
  const [checked, setChecked] = useState([]);
  const [unChecked, setUnChecked] = useState([]);
  // Add/Remove checked item from list
  const handleCheck = (event) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
      setUnChecked([...unChecked, event.target.value]);
    }
    setChecked(updatedList);
  };

  const fetchAllCompany = () => {
    console.log("i am called");
    var config = {
      method: "get",
      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config)
      .then((res) => {
        setSelectedCompany(res.data[0].companyId);
        setSelectedCompanyName(res.data[0].companyName);
        setCompanyList(res.data);
      })
      .catch((er) => {
        toast.error("something went wrong");
      });
  };
  const fetchCompanyHr = () => {
    // console.log("seelevtedCompany", selectedCompany);
    if (!selectedCompany) return;
    const config = {
      method: "get",
      url: `${IP}/adminUserProfile/getHr/?companyId=${selectedCompany}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };
    axios(config)
      .then((res) => {
        // console.log(res.data.users);
        setUsers(res?.data?.users);
        helperAlreadyHr(res?.data?.users);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log("state", users);
  };

  useEffect(() => {
    setChecked([]);
    setUnChecked([]);
    fetchCompanyHr();
  }, [selectedCompany]);

  useEffect(() => {
    fetchAllCompany();
  }, []);

  const handleSave = () => {
    console.log("I am saved");
    console.log("CHECKED ARRAY _>", checked);
    console.log("UNCHECKED ARRAY _>", unChecked);

    const st = new Set();
    checked.map((_, i) => {
      st.add(checked[i]);
    });
    const userArray = Array.from(st);
    const st2 = new Set();
    unChecked.map((_, i) => {
      st2.add(unChecked[i]);
    });
    const userArrayUnchecked = Array.from(st2);
    let selfCompanyName;
    companyList.map((_, idx) => {
      // console.log(_);
      if (_.companyId == selectedCompany) {
        selfCompanyName = _.name;
        return;
      }
    });

    // return;
    companyArray.push({ companyId: companyId, companyName: name });
    companyArray.push({
      companyId: selectedCompany,
      companyName: selfCompanyName,
    });
    console.log(userArray);
    const config = {
      method: "POST",
      url: `${IP}/addHrToCompany`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: {
        userArray: userArray,
        companyArray: companyArray,
        userArrayUnchecked: userArrayUnchecked,
      },
    };
    axios(config)
      .then((res) => {
        console.log(res.data);
        alert("HR added Successfully");

        // setUsers(res?.data?.users);
        // if (res.data.users.length > 0) {
        // setUsers(res?.data?.users);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const helperAlreadyHr = (users) => {
    const updatedList = [];
    for (let i = 0; i < users.length; i++) {
      const companyArray = users[i].data.companyArray;
      const companyId = users[i].data.companyId;
      const userId = users[i].userId;
      if (!companyArray || !companyId || !userId) continue;
      for (let j = 0; j < companyArray.length; j++) {
        if (companyArray[j].companyId == location.state.companyId) {
          updatedList.push(userId);
        }
      }
    }

    setChecked(updatedList);
  };
  const alreadyHr = (companyArray, companyId, email) => {
    if (!companyArray || !companyId || !email) return;
    const updatedList = [];
    for (let i = 0; i < companyArray.length; i++) {
      if (companyArray[i].companyId == companyId) {
        // setChecked([...checked, email]);
        updatedList.push(email);
      }
    }
    console.log("updatedList", updatedList);
    setChecked(updatedList);
  };

  return (
    <section className="dashboard">
      <NavbarTop page={t("Company Settings")} />
      {/* <Navbar /> */}

      <div style={{ height: "41vw" }} className="dashboard-main">
        <Sidebar page={"Dashboard"} hidepage={false} />
        <div style={{ marginTop: "50px", marginLeft: "50px" }}>
          Select a company
          <select
            style={{ width: "150px" }}
            onChange={(e) => {
              setSelectedCompany(e.target.value);
            }}
          >
            {companyList.length > 0 && (
              <>
                {companyList.map((val, idx) => {
                  return (
                    <option key={idx} value={val.companyId} name={val.name}>
                      {val.name}
                    </option>
                  );
                })}
              </>
            )}
          </select>
          {/* ok */}
          <div style={{ backgroundColor: "white", marginTop: "20px" }}>
            <table>
              <tr>
                <th style={{ width: "200px" }}>Sr. No</th>
                <th style={{ width: "200px" }}>Name of HR</th>
                <th style={{ width: "200px" }}>Email id</th>
              </tr>
              {users?.map((val, idx) => {
                // console.log(val);
                return (
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        checked={checked.includes(`${val.userId}`)}
                        onChange={handleCheck}
                        value={val.userId}
                      />
                      {idx + 1}
                    </td>
                    <td style={{ textAlign: "center" }}>{val.data.name}</td>
                    <td style={{ textAlign: "center" }}>{val.data.email}</td>
                  </tr>
                );
              })}
            </table>
          </div>
          <button onClick={handleSave}>SAVE</button>
        </div>
      </div>
    </section>
  );
};

export default AssignHr;
