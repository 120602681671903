import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { Timestamp } from "firebase/firestore";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import axios from "axios";
import { certificateFunction } from "./commenFunction";
const InternalTraining = ({ uid }) => {
  const [training, setTraining] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [arrLength, setArrLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [flagForReload, setFlagForReload] = useState(false);
  useEffect(() => {
    setLoading(true);
    let dataex = JSON.stringify({
      select: 1,
      userId: uid,
    });
    let configExInt = {
      method: "get",
      params: { select: 1, userId: uid },
      url: `${IP}/adminUserProfile/report`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configExInt)
      .then((result) => {
        setLoading(false);
        if (result.data?.error) {
          return;
        }
        setTraining(result.data?.course);
        setArrLength(result.data.course?.length);
      })
      .catch((er) => {
        setLoading(false);
      });
  }, [flagForReload]);

  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / 5)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  return (
    <div style={{ margin: "0vw 2vw 2vw 2vw" }} className="admin-overdue-bottom">
      <div className="admin-overdue-bottom-table">
        <div
          className="admin-overdue-bottom-table-cnt-user-view"
          style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" }}
        >
          <div className="admin-overdue-bottom-table-head">
            {t("Course name")}
          </div>
          <div className="admin-overdue-bottom-table-head">
            {t("Total lessons")}
          </div>
          <div className="admin-overdue-bottom-table-head">
            {t("Lessons completed")}
          </div>
          <div className="admin-overdue-bottom-table-head">
            {t("Last step completed")}
          </div>
          <div className="admin-overdue-bottom-table-head">
            {t("Start date")}
          </div>

          <div className="admin-overdue-bottom-table-head">
            {t("Completion date")}
          </div>
          <div className="admin-overdue-bottom-table-head">{t("Status")}</div>
          <div className="admin-overdue-bottom-table-head">{t("Action")}</div>
        </div>
        {}

        {training?.map((item, id) => {
          if (id < 5 * currentPage) return;
          if (id >= 5 * (currentPage + 1)) return;

          return (
            <div
              className="admin-overdue-bottom-table-cnt-user-view"
              key={id}
              style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" }}
            >
              <div className="admin-overdue-bottom-table-td">
                {item.courseName}
              </div>
              <div className="admin-overdue-bottom-table-td">
                {item.totalLessonCount}
              </div>
              <div className="admin-overdue-bottom-table-td">
                {item.lessonCompleted}
              </div>

              <div className="admin-overdue-bottom-table-td">
                {item?.course_last_step}
              </div>
              <div className="admin-overdue-bottom-table-td">
                {item.course_started_on}
              </div>
              <div className="admin-overdue-bottom-table-td">
                {item?.course_completed_on}
              </div>
              <div className="admin-overdue-bottom-table-td">{item.status}</div>

              <div className="admin-overdue-bottom-table-td">
                <div>
                  <select
                    style={{
                      border: "none",
                      backgroundColor: "#F2F2F2",
                      height: "4vh",
                      width: "7vw",
                      padding: "0 1vh",
                      fontWeight: "700",
                      color: "#717579",
                      borderRadius: "6px",
                    }}
                    onClick={(e) => {
                      certificateFunction(
                        item,
                        e.target.value,
                        setLoading,
                        setFlagForReload,
                        flagForReload
                      );
                    }}
                  >
                    <option>{t("Action")}</option>
                    <option value={"certificate"}>{t("Download")}</option>
                    <option value={"delete"}>{t("Delete Progress")}</option>
                  </select>
                </div>
              </div>
            </div>
          );
        })}
        {loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        {!training && !loading && (
          <div style={{ width: "100%", textAlign: "center", color: "#1e1e1e" }}>
            No Records found
          </div>
        )}
      </div>
      <div className="admin-overdue-bottom-pagination">
        <div className="admin-overdue-bottom-pagination-cnt">
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage - 1)}
              width="auto"
              height="22"
              viewBox="0 0 14 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                fill="#717579"
              />
            </svg>
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item-btn">
            {currentPage + 1} of {Math.ceil(arrLength / 5)}
          </div>
          <div className="admin-overdue-bottom-pagination-cnt-item">
            <svg
              onClick={() => clickhandler(currentPage + 1)}
              width="auto"
              height="20"
              viewBox="0 0 13 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                fill="#717579"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalTraining;
