import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../../../Firebase";
import "../../AdminSetting.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip, Typography } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Sidebar from "../../../../Component/Sidebar";
import NavbarTop from "../../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../../baseUrlAndConfig";
import { getCookie } from "../../../../../Cookies";
import { toast } from "react-toastify";
export const CompanyLocationsList = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([
    { name: "tsest", location: "delhi" },
  ]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);
  const location = useLocation();

  const fetchCompany = httpsCallable(functions, "fetchCompany");

  const fetchDepartment = httpsCallable(functions, "fetchDepartment");
  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // archive company
  const archiveCompany = (id, name) => {
    console.log(id, name);
    // return;
    setLoading(true);
    const data = JSON.stringify({
      companyId: id,
      isArchive: true,
      // name: name,
    });

    var config = {
      method: "delete",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        //console.log(res);
        alert("Company Archived");
        setdeltemp(!deltemp);
        setArchiveDialog(false);
        setLoading(false);
      })
      .catch((er) => {
        setLoading(false);

        toast.error("something went wrong");
      });
  };
  // delete company
  const deleteCompany = (id, name) => {
    //console.log(id);

    const data = JSON.stringify({
      companyId: id,
      location: name,
    });

    var config = {
      method: "delete",

      url: `${IP}/iccRoutes/createLocations`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        //console.log(res);
        alert("Company Deleted");
        setdeltemp(!deltemp);
      })
      .catch((er) => {
        toast.error("something went wrong");
      });
  };
  // get company and department data
  const fetchdata = () => {
    setLoading(true);
    console.log("list", location?.state);

    var config = {
      method: "get",
      // params: { id: location.state.companyId },

      params: { companyId: location?.state },
      url: `${IP}/iccRoutes/getLocations`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        if (res.data?.error) {
        } else {
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);
  //   useEffect(() => {
  //     fetchDepartmentdata();
  //   }, [deledep]);

  const navigate = useNavigate();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [archiveDialog, setArchiveDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({
    name: "",
    id: "",
  });
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Company Settings")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              <h1>Company locations Details</h1>
              <div
                className="create-new-box"
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "flex-end",
                }}
              >
                <Link
                  to={{ pathname: "/admin/settings/company/locations/create" }}
                >
                  <Button
                    variant="contained"
                    component="span"
                    sx={{
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<AddIcon />}
                  >
                    Create New
                  </Button>
                </Link>
              </div>

              <div
                className="bigger-container"
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <div> Company Name</div>
                <div> Location Name </div>

                <div style={{ display: "flex" }}>
                  <div>Action</div>
                </div>
              </div>
              <div className="setting_details_table">
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}

                {template?.map((ele) => {
                  return (
                    <div className="outer-inner-container">
                      <div className="templatename">{ele?.companyName}</div>
                      <div
                        className="templatename"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {ele?.location}
                      </div>
                      <div className="button-box">
                        {/*  */}
                        <div className="button-box-inner mx-3 my-1">
                          <Link
                            to={{
                              pathname:
                                "/admin/settings/company/locations/create",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              Edit
                            </Button>
                          </Link>
                        </div>
                        {/* delete button */}
                        <div className="button-box-inner mx-3 my-1">
                          {" "}
                          <Button
                            variant="contained"
                            component="span"
                            onClick={() => {
                              deleteCompany(ele.companyId, ele.location);
                            }}
                            // onClick={() => deleteCompany(true)}
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                        {/* archieve */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* delete dialog */}
        <Dialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          // sx={{backgroundColor:'white'}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete Company?
              <Typography sx={{ color: "blue", cursor: "pointer" }}>
                Archieve Instead.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
            <Button
              onClick={() => setDeleteDialog(false)}
              autoFocus
              sx={{ color: "red" }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* archive dialog */}
        <Dialog
          open={archiveDialog}
          onClose={() => setArchiveDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Archive Confirmation"}
          </DialogTitle>
          {showLoading && (
            <DialogContent>
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </DialogContent>
          )}
          {!showLoading && (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to Archive this Company?
              </DialogContentText>
            </DialogContent>
          )}

          {!showLoading && (
            <DialogActions>
              <Button onClick={() => setArchiveDialog(false)}>Cancel</Button>
              <Button
                onClick={() => {
                  setLoading(true);
                  archiveCompany(selectedCompany.id, selectedCompany.name);
                }}
                autoFocus
                sx={{ color: "red" }}
              >
                Archive
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </section>
    </>
  );
};
