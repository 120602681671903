import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import "./event.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { Input, MenuItem, Select, TextField } from "@mui/material";
import EventTopic from "./eventTopic";
import TrueAndFalse from "../../Microlerning/TrueAndFalse";
export const CreateEvent = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([1, 2, 3, 4, 5, 6]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [eventid, setEventId] = useState("");
  const [eventflagforadd, setEvemtflagforadd] = useState(true);
  const [eventeditflag, setEventeditflag] = useState(false);
  const [itemData, setItemData] = useState({});

  const [listitemdata, setlistitemdata] = useState([]);
  const [webinarsData, setWebinarsData] = useState({
    year: "",
    month: "",
    name: "",
    basedon: "",
    dayfrom: "",
    dayto: "",
    list: "",
    description: [],
    eventId: "",
  });
  const location = useLocation();

  useEffect(() => {
    let data = location?.state;
    console.log(data);
    if (data) {
      setWebinarsData(data);
      console.log(data);
      setEvemtflagforadd(false);
      setEventeditflag(false);
      getlistitem(data?.eventId);
      //   setWebinarsData(((prev)=>({...prev,eventId:})));

      //   eventId
      setImage((prev) => ({ ...prev, url: data.photo }));
    } else {
      setEvemtflagforadd(true);

      setWebinarsData({
        year: "",
        month: "",
        name: "",
        basedon: "",
        dayfrom: "",
        dayto: "",
        list: "",
        description: [],
        eventId: "",
      });
      setEventId("");
    }
  }, []);
  const deleteCompany = httpsCallable(functions, "deleteCompany");

  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  const [image, setImage] = useState([]);

  // get company and department data
  const getlistitem = (id) => {
    setEvemtflagforadd(false);
    setLoading(true);
    let data = JSON.stringify(webinarsData);
    var config = {
      method: "get",
      params: { eventId: id },
      url: `${IP}/event/item`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          setlistitemdata(res.data);
          //   settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  // get company and department data
  const createWebinar = () => {
    setEvemtflagforadd(false);
    setLoading(true);
    let data = JSON.stringify(webinarsData);
    var config = {
      method: "post",

      url: `${IP}/event`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    // console.log(webinarsData);
    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          //   settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      //  setBase64Image(reader.result);
      //   //console.log("setBase64Image done", reader.result);
      // setDetails({ ...details, logo: reader.result });
      setWebinarsData({ ...webinarsData, photo: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  const navigate = useNavigate();

  const eventlistdelete = (ele) => {
    setLoading(true);
    // console.log(ele);
    const data = JSON.stringify(ele);
    var config = {
      method: "delete",

      url: `${IP}/event/item`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setLoading(false);

        getlistitem(location?.state?.eventId);
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  useEffect(() => {
    if (location?.state?.eventId) {
      getlistitem(location?.state?.eventId);
    }
  }, [openLessonPopUp]);
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Event")} />
        {/* <Navbar /> */}

        <EventTopic
          open={openLessonPopUp}
          onClose={() => setopenLessonPopUp(false)}
          //   delfun={(topic) => {
          //     delfunction(topic);
          //   }}
          topicId={eventid}
          itemName={itemData}
        />
        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>{" "}
                </div>

                <div> </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <div
                  className="inputlabelnameForstrategi"
                  style={{ color: "#000000" }}
                >
                  Add Event
                </div>

                {eventflagforadd ? (
                  <div>
                    {" "}
                    <div className="inputandlabelboxforstrategies">
                      <div className="inputlabelnameForstrategi">
                        {" "}
                        Select Year
                      </div>
                      <div>
                        <Select
                          className="inputbox"
                          value={webinarsData.year}
                          onChange={(e) => {
                            setWebinarsData((prev) => ({
                              ...prev,
                              year: e.target.value,
                            }));
                          }}
                          placeholder="  Webinar Topic"
                          sx={{
                            padding: "5px",
                            height: "35px",
                            width: "250px",
                          }}
                        >
                          <MenuItem> select</MenuItem>
                          <MenuItem value="2020"> 2020</MenuItem>

                          <MenuItem value="2021"> 2021</MenuItem>

                          <MenuItem value="2023"> 2023</MenuItem>
                          <MenuItem value="2024"> 2024</MenuItem>
                          <MenuItem value="2025"> 2025</MenuItem>
                          <MenuItem value="2026"> 2026</MenuItem>
                        </Select>{" "}
                      </div>
                    </div>
                    <div className="inputandlabelboxforstrategies">
                      <div className="inputlabelnameForstrategi">
                        {" "}
                        Select Month
                      </div>
                      <div>
                        {" "}
                        <Select
                          className="inputbox"
                          value={webinarsData.month}
                          onChange={(e) => {
                            setWebinarsData((prev) => ({
                              ...prev,
                              month: e.target.value,
                            }));
                          }}
                          MenuProps={{
                            sx: {
                              height: "250px",
                            },
                          }}
                          placeholder="  Webinar Topic"
                          sx={{
                            padding: "5px",
                            height: "35px",
                            width: "250px",
                          }}
                        >
                          <MenuItem value="january"> January </MenuItem>
                          <MenuItem value="february"> February </MenuItem>
                          <MenuItem value="march"> March </MenuItem>

                          <MenuItem value="april"> April</MenuItem>

                          <MenuItem value="may"> May </MenuItem>
                          <MenuItem value="june"> June</MenuItem>
                          <MenuItem value="july"> July </MenuItem>

                          <MenuItem value="august"> August </MenuItem>
                          <MenuItem value="september"> September </MenuItem>
                          <MenuItem value="october"> October</MenuItem>
                          <MenuItem value="november"> November </MenuItem>

                          <MenuItem value="december"> December </MenuItem>
                        </Select>{" "}
                      </div>
                    </div>
                    <div className="inputandlabelboxforstrategies">
                      <div className="inputlabelnameForstrategi">
                        {" "}
                        Event Name
                      </div>
                      <div>
                        {" "}
                        <input
                          className="inputbox"
                          type="text"
                          value={webinarsData.name}
                          onChange={(e) => {
                            if (!location.state) {
                              let name = e.target.value;
                              let modi = name.replace(" ", "");
                              let id = modi + Date.now();

                              setWebinarsData((prev) => ({
                                ...prev,
                                eventId: id,
                              }));
                            }
                            setWebinarsData((prev) => ({
                              ...prev,
                              name: e.target.value,
                            }));
                          }}
                          placeholder=" Event Name"
                          style={{
                            padding: "5px",
                            height: "35px",
                            width: "250px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="inputandlabelboxforstrategies">
                      <div className="inputlabelnameForstrategi">
                        {" "}
                        Event Description
                      </div>
                      <div>
                        {" "}
                        {/* <input
                          className="inputbox"
                          type="text"
                          value={webinarsData.description}
                          onChange={(e) => {
                            setWebinarsData((prev) => ({
                              ...prev,
                              description: e.target.value,
                            }));
                          }}
                          placeholder=" Event Description"
                          style={{
                            padding: "5px",
                            height: "35px",
                            width: "250px",
                          }}
                        /> */}
                        <Button
                          variant="contained"
                          component="label"
                          sx={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                          onClick={() => {
                            let arr = webinarsData.description;

                            arr.push("");
                            setWebinarsData((prev) => ({
                              ...prev,
                              description: arr,
                            }));
                          }}
                        >
                          Add Description
                        </Button>
                      </div>
                    </div>
                    {webinarsData?.description?.map((ele, el) => (
                      <div className="inputandlabelboxforstrategies">
                        <div className="inputlabelnameForstrategi">
                          {" "}
                          Paragraph {el + 1}
                        </div>
                        <div>
                          {" "}
                          <TextField
                            value={webinarsData.description[el]}
                            onChange={(e) => {
                              let arr = webinarsData.description;
                              arr[el] = e.target.value;
                              setWebinarsData((prev) => ({
                                ...prev,
                                description: arr,
                              }));
                            }}
                            InputProps={{
                              sx: {
                                resize: "both",
                                overflow: "auto",
                              },
                            }}
                            multiline
                            className="inputbox"
                            sx={{ width: "250px" }}
                            type="text"
                            placeholder="   Webinar Description"
                          />
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              ml: "20px",
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                            onClick={() => {
                              let arr = webinarsData.description;

                              arr.splice(el, 1);
                              setWebinarsData((prev) => ({
                                ...prev,
                                description: arr,
                              }));
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    ))}
                    <div className="inputandlabelboxforstrategies">
                      <div className="inputlabelnameForstrategi">
                        {" "}
                        Event Based On
                      </div>
                      <div style={{ display: "flex", gap: "20px" }}>
                        {" "}
                        <div>
                          {" "}
                          <input
                            // value={webinarsData.basedon}
                            type="radio"
                            name="eventradio"
                            checked={webinarsData.basedon == "month"}
                            onChange={(e) => {
                              setWebinarsData((prev) => ({
                                ...prev,
                                basedon: "month",
                              }));
                            }}
                            className="inputbox"
                            placeholder="  From"
                            style={{
                              padding: "5px",
                              height: "25px",
                              width: "30px",
                            }}
                          />
                          <label htmlFor=""> Month</label>
                        </div>
                        <div>
                          {" "}
                          <input
                            //value={webinarsData.basedon}
                            type="radio"
                            name="eventradio"
                            checked={webinarsData.basedon == "day"}
                            onChange={(e) => {
                              setWebinarsData((prev) => ({
                                ...prev,
                                basedon: "day",
                              }));
                            }}
                            className="inputbox"
                            placeholder="  From"
                            style={{
                              padding: "5px",
                              height: "25px",
                              width: "100px",
                            }}
                          />
                          <label htmlFor=""> Day</label>
                        </div>
                      </div>
                    </div>
                    {webinarsData.basedon == "day" && (
                      <div className="inputandlabelboxforstrategies">
                        <div className="inputlabelnameForstrategi"> Day</div>
                        <div style={{ display: "flex", gap: "20px" }}>
                          {" "}
                          <div>
                            {" "}
                            <input
                              value={webinarsData.dayfrom}
                              onChange={(e) => {
                                setWebinarsData((prev) => ({
                                  ...prev,
                                  dayfrom: e.target.value,
                                }));
                              }}
                              className="inputbox"
                              type="text"
                              placeholder="  From"
                              style={{
                                padding: "5px",
                                height: "35px",
                                width: "100px",
                              }}
                            />
                            <label htmlFor="" style={{ marginLeft: "10px" }}>
                              To
                            </label>
                          </div>
                          <div>
                            {" "}
                            <input
                              value={webinarsData.dayto}
                              onChange={(e) => {
                                setWebinarsData((prev) => ({
                                  ...prev,
                                  dayto: e.target.value,
                                }));
                              }}
                              className="inputbox"
                              type="text"
                              placeholder="  To"
                              style={{
                                padding: "5px",
                                height: "35px",
                                width: "100px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      height: "40px",
                    }}
                    onClick={() => {
                      setEvemtflagforadd(true);
                    }}
                  >
                    {" "}
                    <Button> Show Data</Button>
                  </div>
                )}

                <div style={{ display: "flex", gap: "50px" }}>
                  {" "}
                  <Button
                    onClick={createWebinar}
                    style={{
                      backgroundColor: "#007C84",
                      marginTop: "24px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {" "}
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      if (webinarsData.eventId) {
                        setEventId(webinarsData.eventId);
                        setopenLessonPopUp(true);
                      } else {
                        alert("add event first");
                      }
                    }}
                    style={{
                      backgroundColor: "#007C84",
                      marginTop: "24px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {" "}
                    Add item
                  </Button>
                </div>

                <div
                  style={{
                    color: "#8B8D8F",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    marginTop: "50px",
                  }}
                >
                  list of items
                </div>

                <div className="eventlistTabel eventTabelTabelHeders">
                  <div> S.No</div>
                  <div>Item Topic </div>
                  {/* <div>Item Description </div> */}

                  <div>File</div>

                  <div>Action</div>
                </div>

                {listitemdata?.map((ele, index) => (
                  <div className="eventlistTabel eventTabelTabelBody">
                    <div> {index + 1}</div>
                    <div>{ele.topic} </div>
                    {/* <div>{ele.description} </div> */}

                    <div>
                      {ele.filetype == "videolink" ? (
                        <iframe
                          width={"200px"}
                          src={ele.link}
                          frameborder="0"
                        ></iframe>
                      ) : (
                        <iframe
                          width={"200px"}
                          src={ele.file}
                          frameborder="0"
                        ></iframe>
                      )}
                    </div>

                    <div style={{ display: "flex", gap: "10px" }}>
                      <div>
                        <Button
                          variant="contained"
                          component="span"
                          onClick={() => {
                            setItemData(ele);

                            setopenLessonPopUp(true);
                          }}
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          Edit
                        </Button>
                      </div>

                      <div>
                        {" "}
                        <Button
                          onClick={() => {
                            eventlistdelete(ele);
                          }}
                          variant="contained"
                          component="span"
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                        >
                          delete
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
