import React, { useEffect, useRef } from "react";
import { useState } from "react";
// import $ from "jquery";
import { auth, db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import "./Form.css";
import "../StyleAdminComponents/GroupList.css";
import { useNavigate } from "react-router-dom";
import { EditSvg, DeleteSVg } from "./icons";
import { getCookie } from "../../../Cookies";

import Select from "@mui/material/Select";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const Course = ({ editCourseInfo, setIsCourseIdSet, checkEditFlag }) => {
  //Course

  const [courseName, setCourseName] = useState("");
  const [courseDescription, setCourseDescription] = useState("");

  const [templateArr, setTemplateArr] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const [courseId, setCourseId] = useState("");
  // const [courseList, setCourseList] = useState(null);
  const [flow, setFlow] = useState("linear");
  let newDate = new Date().toDateString();
  const [createdDate, setCreatedDate] = useState(newDate);
  const [lastVisited, setLastVisited] = useState(newDate);

  // const storage = getStorage();
  const [editable, setEditable] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [base64Img, setBase64Img] = useState("");
  const [imgfilename, setImgFileName] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [base64ImgForBigImage, setBase64bigImage] = useState("");

  const [bigimageUrl, setbigImageUrl] = useState("");

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    courseName: "",
    courseDescription: "",

    imageUrl: "",

    selectedTemplate: "",
  });
  const [editdataflagForLoading, setEditdataflagForLoading] = useState(true);
  const imageRef = useRef(null);
  const bigimageRef = useRef(null);
  var docURL = [];
  var files = [];
  const [role, setRole] = useState("");

  const onImageClick = () => {
    imageRef.current.click();
  };
  const onbigImageClick = () => {
    bigimageRef.current.click();
  };
  const formCheck = () => {
    if (courseName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, courseName: "error" }));
      return "Course Name";
    }
    if (courseDescription === "") {
      setCheckErrorInForm((prev) => ({ ...prev, courseDescription: "error" }));
      return "Description";
    }

    if (imageUrl === "") {
      setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "error" }));
      alert("Upload image");
      return "base64Img";
    }
    if (!bigimageUrl) {
      setCheckErrorInForm((prev) => ({ ...prev, bigimageUrl: "error" }));
      alert("Upload image");
      return "base64Img";
    }

    if (selectedTemplate == "") {
      setCheckErrorInForm((prev) => ({ ...prev, selectedTemplate: "error" }));
      return "Certificate Template";
    }
    return "allIsWell";
  };

  if (editdataflagForLoading) {
    // //console.log(checkEditFlag)

    document.getElementsByClassName("course-builder-container").display =
      "none";
  }

  useEffect(() => {
    var config = {
      method: "get",

      url: `${IP}/certificate`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(config)
      .then((res) => {
        setTemplateArr(res.data);
      })
      .catch((er) => {});
  }, []);

  useEffect(() => {
    if (!editCourseInfo) return;
    setCourseId(editCourseInfo.courseId);
    setCourseName(editCourseInfo.courseName);
    setCourseDescription(editCourseInfo.courseDescription);
    setCourseDisclaimer(editCourseInfo.disclaimer);
    if (editCourseInfo.disclaimer) {
      setDisclaimerCheckBox(true);
    } else {
      setDisclaimerCheckBox(false);
    }
    setImageUrl(editCourseInfo.courseImageURL);
    setbigImageUrl(editCourseInfo.bigImage);
    setSelectedTemplate(editCourseInfo.extraInfo.certifiateTemplate);
    setFlow(editCourseInfo.extraInfo.courseProgress);

    let created = new Date(
      editCourseInfo.created._seconds * 1000
    ).toDateString();
    setCreatedDate(created);
    setEditdataflagForLoading(false);
    //console.log("i am in useEffect", new Date());
    //console.log(editCourseInfo);
  }, [editCourseInfo]);

  const handleCourseImage = (e, typeimg) => {
    let reader = new FileReader();
    //console.log(e);

    let filenameType = e.target.value.split(".");

    //console.log(filenameType[filenameType.length - 1]);

    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (
      filetypedata == "png" ||
      filetypedata == "jpeg" ||
      filetypedata == "jpg"
    ) {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }

    let rfilesize = Number(e.target.files[0].size) / 1024;

    rfilesize = rfilesize / 1024;
    //console.log(rfilesize);
    if (Number(rfilesize) > 5) {
      alert("size must be less than 5 mb");
      setImgFileName("");
      e.target.value = null;
      return;
    } else {
      //console.log(rfilesize);
      let url = URL.createObjectURL(e.target.files[0]);
      if (typeimg == "bigimage") {
        setbigImageUrl(url);
      } else {
        setImageUrl(url);
      }
      setImgFileName(e.target.files[0].name);
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        //console.log(reader.result);
        if (typeimg == "bigimage") {
          setBase64bigImage(reader.result);
        } else {
          setBase64Img(reader.result);
        }
      };
    }
  };

  const addCourse = () => {
    const check = formCheck();

    if (check !== "allIsWell") {
      return;
    }
    let nsn = courseName.replaceAll(" ", "");

    for (let i = 0; i < nsn.length; i++) {
      if (nsn[i] == "/") {
        alert("you can not use any special characters");
        return;
      }
    }

    setCheckErrorInForm({
      courseName: "",
      courseDescription: "",

      imageUrl: "",

      selectedTemplate: "",
    });
    setCheckFailed(false); // removes error message after succesful creation

    let data = JSON.stringify({
      courseId: courseId,
      courseDescription: courseDescription,
      disclaimer: courseDisclaimer,
      image: base64Img ? base64Img : imageUrl,
      bigImage: base64ImgForBigImage ? base64ImgForBigImage : bigimageUrl,
      courseName: courseName,
      created: createdDate, // date
    });

    var config = {
      method: "post",

      url: `${IP}/microCourseBuilder/course`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        let dataex = JSON.stringify({
          certifiateTemplate: selectedTemplate,
          courseId: courseId,
          courseProgress: flow,
        });

        let config = {
          method: "patch",

          url: `${IP}/microCourseBuilder/course`,

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: dataex,
        };
        axios(config)
          .then((result) => {})
          .catch((err) => {});
      })
      .catch((er) => {});

    sessionStorage.setItem("courseId", courseId);

    alert("Course " + courseName + " added");
    document.getElementById("courseName").disabled = true; // disable the input field
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
    document.getElementById("addC").style.display = "none";
    document.getElementById("editC").style.display = "";
    localStorage.setItem("courseId", courseId);

    setIsCourseIdSet(courseId);
  };
  function edit() {
    document.getElementById("addC").style.display = "";
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "";
    }
  }
  //change this and do it the React Way
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ color: [] }], // dropdown with defaults from theme

      // [
      //   {
      //     color: ["red", "blue", "yellow"],
      //   },
      // ],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "color",
    "link",
  ];

  const modulesForDisclaimer = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ color: [] }], // dropdown with defaults from theme
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
      [{ header: [1, 2, false] }],

      // { size: ["small", false, "large", "huge"] },
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const formatsForDisclaimer = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "color",
    "link",
    "header",
    "font",
    "size",
  ];
  const [courseDisclaimer, setCourseDisclaimer] = useState("");
  const [disclaimerCheckBox, setDisclaimerCheckBox] = useState(false);

  return (
    <>
      {" "}
      {!checkEditFlag ? (
        <div className="course-builder-container">
          <h2 className="course-builder-heading">Micro learning</h2>
          <div
            id="editC"
            style={{ display: "none" }}
            className="course-builder-top-info"
          >
            <table className="course-builder-top-table">
              <thead className="course-builder-top-thead">
                <tr>
                  <th>Course Name</th>
                  <th>Created Date</th>
                  <th>Last Updated</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{courseName}</td>
                  <td>{createdDate}</td>
                  <td>{lastVisited}</td>

                  <td>
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          setEditable(true);
                          edit();
                        }}
                      >
                        <EditSvg style={{ marginRight: "3px" }} />
                      </div>
                      <div>{/* <DeleteSVg /> */}</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="course-builder-top">
            <div className="course-builder-info">
              <label htmlFor="courseName" className="course-builder-inputLable">
                Micro learning Title *
              </label>
              <input
                type="text"
                className="course-builder-inputBar"
                id="courseName"
                value={courseName}
                style={{
                  border: `${
                    checkErrorInForm.courseName == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(e) => {
                  setCourseName(e.target.value);
                  let value = e.target.value.replaceAll(" ", "");
                  let time = new Date().getTime();
                  setCheckErrorInForm((prev) => ({ ...prev, courseName: "" }));
                  if (!editCourseInfo) {
                    setCourseId(value + "_" + time);
                  }
                }}
              />
              {checkErrorInForm.courseName == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Title
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Featured Image *</p>
              {imageUrl == "" ? (
                <div
                  style={{
                    width: "20vw",
                    height: "8em",
                    background: "#d5d5d5",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                  onClick={onImageClick}
                >
                  Click to upload Image
                </div>
              ) : (
                <img
                  style={{
                    width: "20vw",
                    height: "8em",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                  onClick={onImageClick}
                  src={imageUrl}
                ></img>
              )}
              <input
                ref={imageRef}
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="course-builder-inputImg"
                id="courseImage"
                onChange={(e) => {
                  handleCourseImage(e);
                  setCheckErrorInForm((prev) => ({ ...prev, imageUrl: "" }));
                }}
              />
              {checkErrorInForm.imageUrl == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  upload Image
                </span>
              )}
            </div>

            <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Featured Image (Big) *
              </p>
              {bigimageUrl == "" ? (
                <div
                  style={{
                    width: "20vw",
                    height: "8em",
                    background: "#d5d5d5",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                  onClick={onbigImageClick}
                >
                  Click to upload Image
                </div>
              ) : (
                <img
                  style={{
                    width: "20vw",
                    height: "8em",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                  onClick={onbigImageClick}
                  src={bigimageUrl}
                ></img>
              )}
              <input
                ref={bigimageRef}
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/gif, image/jpeg"
                className="course-builder-inputImg"
                id="courseImage"
                onChange={(e) => {
                  handleCourseImage(e, "bigimage");
                  setCheckErrorInForm((prev) => ({ ...prev, bigimageUrl: "" }));
                }}
              />
              {checkErrorInForm.bigimageUrl == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  upload Image
                </span>
              )}
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Short description*</p>
              <div style={{ width: "500px" }}>
                <ReactQuill
                  theme="snow"
                  value={courseDescription}
                  modules={modules}
                  formats={formats}
                  onChange={setCourseDescription}
                />
              </div>
              {/* <textarea
                type="text"
                className="course-builder-textarea"
                value={courseDescription}
                style={{
                  border: `${
                    checkErrorInForm.courseDescription == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="courseDescription"
                onChange={(event) => {
                  setCourseDescription(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    courseDescription: "",
                  }));
                }}
              /> */}
              {checkErrorInForm.courseDescription == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Description
                </span>
              )}
            </div>
            {/* Disclaimer */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={disclaimerCheckBox}
                    onChange={(e) => {
                      setDisclaimerCheckBox(e.target.checked);
                      setCourseDisclaimer("");
                    }}
                  />
                }
                label="Disclaimer Required?"
              />
            </FormGroup>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Enter Disclaimer*</p>
              <div style={{ width: "500px" }}>
                <ReactQuill
                  theme="snow"
                  value={courseDisclaimer}
                  readOnly={!disclaimerCheckBox}
                  modules={modulesForDisclaimer}
                  formats={formatsForDisclaimer}
                  onChange={setCourseDisclaimer}
                  height={500}
                />
              </div>

              {checkErrorInForm.courseDescription == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Description
                </span>
              )}
            </div>
            {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Course Duration (hrs)*
              </p>
              <input
                type="number"
                className="course-builder-inputBar"
                value={courseDuration}
                style={{
                  border: `${
                    checkErrorInForm.courseDuration == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="courseDuration"
                min={0}
                onChange={(event) => {
                  setCourseDuration(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    courseDuration: "",
                  }));
                }}
              />

              {checkErrorInForm.courseDuration === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course Duration
                </span>
              )}
            </div> */}
            {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">Training Provider*</p>
              <input
                type="text"
                className="course-builder-inputBar"
                value={trainingProvider}
                style={{
                  border: `${
                    checkErrorInForm.trainingProvider == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                id="trainingProvider"
                onChange={(event) => {
                  setTrainingProvider(event.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    trainingProvider: "",
                  }));
                }}
              />
              {checkErrorInForm.trainingProvider == "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Training Provider Name
                </span>
              )}
            </div> */}
            {/* <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Access Mode
              </div>
              <select
                value={accessMode}
                id="accessMode"
                className="course-builder-inputSelect"
                onChange={(e) => setAccessMode(e.target.value)}
              >
                <option value="free">Free</option>
                <option value="closed">Closed</option>
              </select>
            </div> */}
            {/* <div className="course-builder-info">
            <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
              Quiz
            </div>
            <select
              value={hasQuiz}
              onChange={(e) => setHasQuiz(e.target.value)}
              id="hasQuiz"
              className="course-builder-inputSelect"
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div> */}
          </div>
          <div className="course-builder-top">
            {/* <div className="course-builder-info">
            <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
              Course Dependency
            </div>
            <select
              onChange={(e) => {
                const { value } = e.target;
                let arr = Array.from(e.target.selectedOptions);
                setDependencyArr(arr.map((ele) => ele.value));
              }}
              id="courseDependency"
              className="course-builder-multipleSelect"
              multiple
            >
              {courseList?.map((ele, id) => (
                <option value={ele.courseId} key={id}>
                  {ele.courseName}
                </option>
              ))}
            </select>
          </div>
          <div className="course-builder-info">
            <div className="course-builder-inputLable" style={{ marginTop: "2vw" }}>
              Course Dependency Mode
            </div>
            <select
              value={dependencyMode}
              onChange={(e) => setDependencyMode(e.target.value)}
              id="inclusive"
              className="course-builder-inputSelect"
            >
              <option value="or">Or</option>
              <option value="and">And</option>
            </select>
          </div> */}
            {/* <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Course Access Expiration (Days)*
              </p>
              <input
                type="number"
                className="course-builder-inputBar"
                id="courseExpirationDays"
                value={courseExpirationDays}
                style={{
                  border: `${
                    checkErrorInForm.courseExpirationDays == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
                onChange={(event) => {
                  if (event.target.value < 0) {
                    event.target.value = courseExpirationDays;
                  } else {
                    setCourseExpirationDays(event.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      courseExpirationDays: "",
                    }));
                  }
                }}
              />
              {checkErrorInForm.courseExpirationDays === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  Enter Course ExpirationDays
                </span>
              )}
            </div> */}
            <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Course Progress
              </div>
              <select
                id="flow"
                value={flow}
                onChange={(e) => setFlow(e.target.value)}
                className="course-builder-inputSelect"
              >
                <option value="linear">Sequential</option>
                <option value="free">Free flow</option>
              </select>
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">Course ID</p>
              <input
                disabled
                type="text"
                className="course-builder-inputBar"
                id="courseId"
                value={courseId}
              />
            </div>
            <div className="course-builder-info">
              <p className="course-builder-inputLable">
                Certificate Template *
              </p>
              <select
                className="course-builder-inputSelect"
                value={selectedTemplate}
                onChange={(e) => {
                  setSelectedTemplate(e.target.value);
                  setCheckErrorInForm((prev) => ({
                    ...prev,
                    selectedTemplate: "",
                  }));
                }}
                style={{
                  border: `${
                    checkErrorInForm.selectedTemplate == "error"
                      ? "2px solid red"
                      : "1px solid #d7d7d7"
                  }`,
                }}
              >
                <option value="" selected disabled hidden>
                  Select an Option
                </option>
                {templateArr?.map((item, id) => (
                  <option key={id} value={item.id}>
                    {item.templateName}
                  </option>
                ))}
              </select>
              {checkErrorInForm.selectedTemplate === "error" && (
                <span
                  style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
                >
                  {" "}
                  select Template
                </span>
              )}
            </div>
            {/* {role == "admin" && (
              <div className="course-builder-info">
                <div
                  className="course-builder-inputLable"
                  style={{ marginTop: "2vw" }}
                >
                  Course Instructor *
                </div>
                <select
                  id="courseInstructor"
                  className="course-builder-inputSelect"
                  value={instructorId}
                  onChange={(e) => {
                    setInstructorId(e.target.value);
                    setCheckErrorInForm((prev) => ({
                      ...prev,
                      instructorId: "",
                    }));
                  }}
                  style={{
                    border: `${
                      checkErrorInForm.instructorId == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select an Option
                  </option>
                  {instructorArr?.map((item, id) => (
                    <option value={item.userId} key={id}>
                      {item.name}
                    </option>
                  ))}
                </select>

                {checkErrorInForm.instructorId === "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    {" "}
                    select instructor
                  </span>
                )}
              </div>
            )} */}
            {/* <div className="course-builder-info">
              <div
                className="course-builder-inputLable"
                style={{ marginTop: "2vw" }}
              >
                Course Shared With
              </div>
              <Select
                id="courseShare"
                // className="course-builder-multipleSelect"
                value={courseShare}
                onChange={handleCourseShare}
                native
                multiple
              >
                {sharedByArr?.map((item, id) => (
                  <option key={id} value={item.userId}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </div> */}
            <br></br>
            {checkFailed && (
              <div>
                <p style={{ color: "red" }}>Fill all the field in the Form</p>
              </div>
            )}
          </div>
          <button
            id="addC"
            className="course-builder-button"
            onClick={addCourse}
          >
            Save Course
          </button>
          {/* <div style={{ height: "100px", margin: "auto" }} className="course-builder-top">
        <button
        id="addC"
          style={{ margin: "auto" }}
          className="course-builder-button"
          onClick={() => navigate(-1)}
        >
          Back
        </button>{" "}
      </div> */}
          {/* {courseAdd==false?<ViewCourses/>:<Lesson/>} */}
        </div>
      ) : (
        <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td">
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div className="admin-overdue-bottom-table-td"></div>
          <div
            className="admin-overdue-bottom-table-td"
            style={{ color: "#004577" }}
          ></div>
        </div>
      )}
    </>
  );
};

export default Course;
