import { useEffect, useState } from "react";

import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  FormControl,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import HrTopNavbar from "../../../Sidebar/HrTopNavbar";
import "./Print.css";

const RecruitmentPrint = () => {
  const [list, setList] = useState([0, 0, 0, 0]);
  const [recievedList, setrecievedList] = useState([]);
  const [shortlistedList, setshortlistedList] = useState([]);
  const [recruitedList, setrecruitedList] = useState([]);
  const [bussinessvertical, setBussinessvertical] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const companyId = localStorage.getItem("companyId");

  const [overallgender, setOverallgender] = useState([]);

  const [genderList, setgenderList] = useState([
    { name: "Junior" },
    { name: "MidLevel" },
    { name: "Senior" },
  ]);

  const Item = styled(Paper)(({ theme }) => ({
    background: "#FFF",
    textAlign: "center",
    borderRadius: "20px",
  }));

  const Item2 = styled(Paper)(({ theme }) => ({
    background: "#FFF",
    textAlign: "center",
    borderRadius: "20px",
  }));

  const Item3 = styled(Paper)(({ theme }) => ({
    background: "#FFF",
    textAlign: "center",
    borderRadius: "20px",
    // padding: "20px 15px",
  }));

  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const [
    OverallGenderRepresentationState,
    setOverallGenderRepresentationState,
  ] = useState([]);

  const [
    numberofCandidatesShortlistedstate,
    setNumberofCandidatesShortlistedstate,
  ] = useState([]);

  const [
    numberofCandidatesRecruitedstate,
    setNumberofCandidatesRecruitedstate,
  ] = useState([]);

  const [
    numberofCandidatesOfferlaterstate,
    setNumberofCandidatesOfferlaterstate,
  ] = useState([]);

  const [genderbyBusinessVerticalstate, setGenderbyBusinessVerticalstate] =
    useState([]);

  useEffect(() => {
    let configvr = {
      method: "get",

      url: `${IP}/bussinessVertical?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configvr).then((res) => {
      setBussinessvertical(res.data);
    });

    let configregiony = {
      method: "get",

      url: `${IP}/recruitment/receivedCvs?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configregiony)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setrecievedList((prev) => prev.concat({ year: key }));
        }
        setGenderbyBusinessVerticalstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });

    let configSort = {
      method: "get",

      url: `${IP}/recruitment/sortListedCandidate?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configSort)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setshortlistedList((prev) => prev.concat({ year: key }));
        }
        setNumberofCandidatesShortlistedstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });

    let configoffer = {
      method: "get",

      url: `${IP}/recruitment/offerLetterGenerated?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configoffer)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setOfferList((prev) => prev.concat({ year: key }));
        }
        setNumberofCandidatesOfferlaterstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });

    let configrecrut = {
      method: "get",

      url: `${IP}/recruitment/candidatesRecurited?companyId=${companyId}`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataregionf",
    };

    axios(configrecrut)
      .then((res) => {
        let recrut = res.data;
        let newobj = {};
        for (let i = 0; i < recrut.length; i++) {
          newobj[recrut[i]?.year] = "af";
        }
        for (let key in newobj) {
          setrecruitedList((prev) => prev.concat({ year: key }));
        }
        setNumberofCandidatesRecruitedstate(res.data);
        //console.log(res.data);
      })
      .catch((er) => {
        console.log(er);
      });
  }, []);

  useEffect(() => {
    let dataregionf = JSON.stringify(genderbyBusinessVerticalstate);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/receivedCvs?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (genderbyBusinessVerticalstate?.length > 0) {
      axios(configregiony)
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
    }
  }, []);

  useEffect(() => {
    let dataregionf = JSON.stringify(numberofCandidatesShortlistedstate);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/sortListedCandidate?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (numberofCandidatesShortlistedstate?.length > 0) {
      axios(configregiony)
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
    }
  }, []);

  useEffect(() => {
    let dataregionf = JSON.stringify(numberofCandidatesRecruitedstate);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/candidatesRecurited?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (numberofCandidatesRecruitedstate?.length > 0) {
      axios(configregiony)
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
    }
  }, []);

  useEffect(() => {
    let dataregionf = JSON.stringify(numberofCandidatesOfferlaterstate);

    let configregiony = {
      method: "patch",

      url: `${IP}/recruitment/offerLetterGenerated?companyId=${companyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataregionf,
    };

    if (numberofCandidatesOfferlaterstate?.length > 0) {
      axios(configregiony)
        .then((res) => {})
        .catch((er) => {
          console.log(er);
        });
    }
  }, []);

  useEffect(() => {
    setOverallgender((prev) => ({
      ...prev,

      recrotmentbusinessvertical: genderbyBusinessVerticalstate
        ? genderbyBusinessVerticalstate
        : [],
      recrotmentnumberofCandidatesShortlistedstate:
        numberofCandidatesShortlistedstate
          ? numberofCandidatesShortlistedstate
          : [],

      recrotmentnumberofCandidatesRecruitedstate:
        numberofCandidatesRecruitedstate
          ? numberofCandidatesRecruitedstate
          : [],
      recnumberofCandidatesOfferlaterstate: numberofCandidatesOfferlaterstate
        ? numberofCandidatesOfferlaterstate
        : [],
    }));
    // setgenderbyRegionStates(ex);
  }, []);

  useEffect(() => {
    console.log(overallgender);
    let ls = overallgender.OverallGenderRepresentationState
      ? overallgender.OverallGenderRepresentationState
      : [];

    setOverallGenderRepresentationState(
      overallgender.OverallGenderRepresentationState
        ? overallgender.OverallGenderRepresentationState
        : []
    );
  }, []);
  return (
    <Box className="pagebreak" sx={{ backgroundColor: "#EEECFF" }}>
      {console.log("line 621", OverallGenderRepresentationState)}

      <Box
        sx={{
          position: "sticky",
          top: "0",
          backgroundColor: "#F3F1FF",
          zIndex: "2",
          pt: "24px",
        }}
      >
        <HrTopNavbar title={"Recruitment"} index={2}>
          {/* <Box
          sx={{
            color: "#6846C7",
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: 600,
            fontFamily: "Manrope",
          }}
        >
          Save & Exit
        </Box> */}
        </HrTopNavbar>
      </Box>

      <Box
        className="tableHedingNamefontFamily"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          mt: "24px",
          ml: "24px",
        }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E5E0F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          1
        </Box>{" "}
        Number of Resumes/CVs received
      </Box>

      <Box
        className="pagebreak tablebgColorForHr"
        sx={{ padding: "0px 20px", mt: "20px" }}
      >
        {recievedList.map((rclist, ind) => {
          let z = 0;
          return (
            <Box className="pagebreak" sx={{ mt: "15px" }}>
              {console.log("line434", rclist)}
              <Box style={{ display: "flex" }}>
                <h1>{rclist.year}</h1>
              </Box>
              <Stack
                spacing={3}
                className="tableHightandSizeForThreeSections"
                sx={{ mt: "20px" }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {genderbyBusinessVerticalstate?.map((elementList, i) => {
                      if (elementList.year == rclist.year) {
                        z += 1;
                        return (
                          <Grid
                            item
                            xs={6}
                            className={`${z % 4 == 0 ? "pagebreak" : ""}`}
                            sx={{ mb: "10px" }}
                          >
                            <Item3>
                              <Box className="genderbyRegionTableContent">
                                <Stack spacing={2}>
                                  <Box>
                                    {" "}
                                    <Box
                                      sx={{ display: "flex", fontSize: "20px" }}
                                    >
                                      <Typography
                                        sx={{
                                          fontWeight: 400,
                                          color: "#666",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Business Vertical -&nbsp;
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontWeight: 600,
                                          color: "black",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {elementList.businessVertical}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Stack>
                                <Box
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "0.5fr 0.5fr 0.5fr",
                                    gridGap: "10px",
                                    // padding: "20px",
                                  }}
                                >
                                  {genderList?.map((el) => (
                                    <Box
                                      style={{
                                        marginTop: "20px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <Box>
                                        <Box> {el.name} </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Male{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            min="0"
                                            type="Number"
                                            className="genderbyRegionInputBox"
                                            value={elementList[el.name]?.male}
                                          />{" "}
                                        </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Female{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            min="0"
                                            type="Number"
                                            className="genderbyRegionInputBox"
                                            value={elementList[el.name]?.female}
                                          />{" "}
                                        </Box>
                                      </Box>
                                      <Box className="genderBox">
                                        <Box className="genderbyRegionInputBoxLabel">
                                          {" "}
                                          Non-binary{" "}
                                        </Box>
                                        <Box>
                                          {" "}
                                          <input
                                            disabled={true}
                                            min="0"
                                            type="Number"
                                            className="genderbyRegionInputBox"
                                            value={
                                              elementList[el.name]?.nonBinary
                                            }
                                          />{" "}
                                        </Box>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Item3>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Box>

      {/* ///////// condidate shortlist */}

      <Box
        className="tableHedingNamefontFamily"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          mt: "30px",
          ml: "24px",
        }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E5E0F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          2
        </Box>{" "}
        Number of candidates shortlisted
      </Box>

      <Box
        className="pagebreak tablebgColorForHr"
        sx={{ padding: "0px 20px", mt: "20px" }}
      >
        {shortlistedList.map((rclist, ind) => {
          let z = 0;
          return (
            <Box className="pagebreak" sx={{ mt: "15px" }}>
              <Box style={{ display: "flex" }}>
                <h1>{rclist.year}</h1>
              </Box>
              <Stack
                spacing={3}
                className="tableHightandSizeForThreeSections"
                sx={{ mt: "20px" }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {numberofCandidatesShortlistedstate?.map(
                      (elementList, i) => {
                        if (elementList.year == rclist.year) {
                          z += 1;
                          return (
                            <Grid
                              item
                              xs={6}
                              className={`${z % 4 == 0 ? "pagebreak" : ""}`}
                              sx={{ mb: "10px" }}
                            >
                              <Item3>
                                <Box className="genderbyRegionTableContent">
                                  <Stack spacing={2}>
                                    <Box>
                                      {" "}
                                      <Box
                                        sx={{
                                          display: "flex",
                                          fontSize: "20px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontWeight: 400,
                                            color: "#666",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Business Vertical -&nbsp;
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            color: "black",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {elementList.businessVertical}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Stack>
                                  <Box
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "0.5fr 0.5fr 0.5fr",
                                      gridGap: "10px",
                                      // padding: "20px",
                                    }}
                                  >
                                    {genderList?.map((el) => (
                                      <Box
                                        style={{
                                          marginTop: "20px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Box>
                                          <Box> {el.name} </Box>
                                        </Box>
                                        <Box className="genderBox">
                                          <Box className="genderbyRegionInputBoxLabel">
                                            {" "}
                                            Male{" "}
                                          </Box>
                                          <Box>
                                            {" "}
                                            <input
                                              disabled={true}
                                              min="0"
                                              type="Number"
                                              className="genderbyRegionInputBox"
                                              value={elementList[el.name]?.male}
                                            />{" "}
                                          </Box>
                                        </Box>
                                        <Box className="genderBox">
                                          <Box className="genderbyRegionInputBoxLabel">
                                            {" "}
                                            Female{" "}
                                          </Box>
                                          <Box>
                                            {" "}
                                            <input
                                              disabled={true}
                                              min="0"
                                              type="Number"
                                              className="genderbyRegionInputBox"
                                              value={
                                                elementList[el.name]?.female
                                              }
                                            />{" "}
                                          </Box>
                                        </Box>
                                        <Box className="genderBox">
                                          <Box className="genderbyRegionInputBoxLabel">
                                            {" "}
                                            Non-binary{" "}
                                          </Box>
                                          <Box>
                                            {" "}
                                            <input
                                              disabled={true}
                                              min="0"
                                              type="Number"
                                              className="genderbyRegionInputBox"
                                              value={
                                                elementList[el.name]?.nonBinary
                                              }
                                            />{" "}
                                          </Box>
                                        </Box>
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>
                              </Item3>
                            </Grid>
                          );
                        }
                      }
                    )}
                  </Grid>
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Box>

      {/* Number of offer leter */}

      <Box
        className="tableHedingNamefontFamily"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          mt: "30px",
          ml: "24px",
        }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E5E0F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          3
        </Box>{" "}
        Number of offer letters sent
      </Box>

      <Box
        className="pagebreak tablebgColorForHr"
        sx={{ padding: "0px 20px", mt: "20px" }}
      >
        {offerList.map((offList, ind) => {
          let z = 0;
          return (
            <Box className="pagebreak" sx={{ mt: "15px" }}>
              <Box style={{ display: "flex" }}>
                <h1>{offList.year}</h1>
              </Box>
              <Stack
                spacing={3}
                className="tableHightandSizeForThreeSections"
                sx={{ mt: "20px" }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {numberofCandidatesShortlistedstate?.map(
                      (elementList, i) => {
                        if (elementList.year == offList.year) {
                          z += 1;
                          return (
                            <Grid
                              item
                              xs={6}
                              className={`${z % 4 == 0 ? "pagebreak" : ""}`}
                              sx={{ mb: "10px" }}
                            >
                              <Item3>
                                <Box className="genderbyRegionTableContent">
                                  <Stack spacing={2}>
                                    <Box>
                                      {" "}
                                      <Box
                                        sx={{
                                          display: "flex",
                                          fontSize: "20px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontWeight: 400,
                                            color: "#666",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Business Vertical -&nbsp;
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            color: "black",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {elementList.businessVertical}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Stack>
                                  <Box
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "0.5fr 0.5fr 0.5fr",
                                      gridGap: "10px",
                                      // padding: "20px",
                                    }}
                                  >
                                    {genderList?.map((el) => (
                                      <Box
                                        style={{
                                          marginTop: "20px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Box>
                                          <Box> {el.name} </Box>
                                        </Box>
                                        <Box className="genderBox">
                                          <Box className="genderbyRegionInputBoxLabel">
                                            {" "}
                                            Male{" "}
                                          </Box>
                                          <Box>
                                            {" "}
                                            <input
                                              disabled={true}
                                              min="0"
                                              type="Number"
                                              className="genderbyRegionInputBox"
                                              value={elementList[el.name]?.male}
                                            />{" "}
                                          </Box>
                                        </Box>
                                        <Box className="genderBox">
                                          <Box className="genderbyRegionInputBoxLabel">
                                            {" "}
                                            Female{" "}
                                          </Box>
                                          <Box>
                                            {" "}
                                            <input
                                              disabled={true}
                                              min="0"
                                              type="Number"
                                              className="genderbyRegionInputBox"
                                              value={
                                                elementList[el.name]?.female
                                              }
                                            />{" "}
                                          </Box>
                                        </Box>
                                        <Box className="genderBox">
                                          <Box className="genderbyRegionInputBoxLabel">
                                            {" "}
                                            Non-binary{" "}
                                          </Box>
                                          <Box>
                                            {" "}
                                            <input
                                              disabled={true}
                                              min="0"
                                              type="Number"
                                              className="genderbyRegionInputBox"
                                              value={
                                                elementList[el.name]?.nonBinary
                                              }
                                            />{" "}
                                          </Box>
                                        </Box>
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>
                              </Item3>
                            </Grid>
                          );
                        }
                      }
                    )}
                  </Grid>
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Box>

      {/* ///  Number of Candidates Recruited */}

      <Box
        className="tableHedingNamefontFamily"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          mt: "30px",
          ml: "24px",
        }}
      >
        <Box
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#E5E0F9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          4
        </Box>{" "}
        Number of offer letters accepted
      </Box>

      <Box
        className="pagebreak tablebgColorForHr"
        sx={{ padding: "0px 20px", mt: "20px" }}
      >
        {recruitedList.map((recList, ind) => {
          let z = 0;
          return (
            <Box className="pagebreak" sx={{ mt: "15px" }}>
              <Box style={{ display: "flex" }}>
                <h1>{recList.year}</h1>
              </Box>
              <Stack
                spacing={3}
                className="tableHightandSizeForThreeSections"
                sx={{ mt: "20px" }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {numberofCandidatesShortlistedstate?.map(
                      (elementList, i) => {
                        if (elementList.year == recList.year) {
                          z += 1;
                          return (
                            <Grid
                              item
                              xs={6}
                              className={`${z % 4 == 0 ? "pagebreak" : ""}`}
                              sx={{ mb: "10px" }}
                            >
                              <Item3>
                                <Box className="genderbyRegionTableContent">
                                  <Stack spacing={2}>
                                    <Box>
                                      {" "}
                                      <Box
                                        sx={{
                                          display: "flex",
                                          fontSize: "20px",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontWeight: 400,
                                            color: "#666",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Business Vertical -&nbsp;
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: 600,
                                            color: "black",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {elementList.businessVertical}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Stack>
                                  <Box
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "0.5fr 0.5fr 0.5fr",
                                      gridGap: "10px",
                                      // padding: "20px",
                                    }}
                                  >
                                    {genderList?.map((el) => (
                                      <Box
                                        style={{
                                          marginTop: "20px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Box>
                                          <Box> {el.name} </Box>
                                        </Box>
                                        <Box className="genderBox">
                                          <Box className="genderbyRegionInputBoxLabel">
                                            {" "}
                                            Male{" "}
                                          </Box>
                                          <Box>
                                            {" "}
                                            <input
                                              disabled={true}
                                              min="0"
                                              type="Number"
                                              className="genderbyRegionInputBox"
                                              value={elementList[el.name]?.male}
                                            />{" "}
                                          </Box>
                                        </Box>
                                        <Box className="genderBox">
                                          <Box className="genderbyRegionInputBoxLabel">
                                            {" "}
                                            Female{" "}
                                          </Box>
                                          <Box>
                                            {" "}
                                            <input
                                              disabled={true}
                                              min="0"
                                              type="Number"
                                              className="genderbyRegionInputBox"
                                              value={
                                                elementList[el.name]?.female
                                              }
                                            />{" "}
                                          </Box>
                                        </Box>
                                        <Box className="genderBox">
                                          <Box className="genderbyRegionInputBoxLabel">
                                            {" "}
                                            Non-binary{" "}
                                          </Box>
                                          <Box>
                                            {" "}
                                            <input
                                              disabled={true}
                                              min="0"
                                              type="Number"
                                              className="genderbyRegionInputBox"
                                              value={
                                                elementList[el.name]?.nonBinary
                                              }
                                            />{" "}
                                          </Box>
                                        </Box>
                                      </Box>
                                    ))}
                                  </Box>
                                </Box>
                              </Item3>
                            </Grid>
                          );
                        }
                      }
                    )}
                  </Grid>
                </Box>
              </Stack>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RecruitmentPrint;
