import { Box, Grid, Paper, styled, Typography } from "@mui/material";

import { useNavigate } from "react-router";
import "../hovereffect.css";
// import { lerningicons } from "./lerningIcons";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { microArr } from "../microLerningdata";
// import photu from "../../../defaultGameImage.png";
import { Link } from "react-router-dom";
import { getCookie } from "../../../Cookies";
import imro from "../Vector.svg";

import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";

import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import WebinarPopUp from "./webinarPopUp";

const Item = styled(Paper)(({ theme }) => ({
  position: "relative",
}));

const GamesHome = () => {
  const navigate = useNavigate();
  const goToHome = () => navigate("/");

  const [openpopUpForAddYears, setOpenpopUpForAddYears] = useState(false);
  const [popdata, setpopdata] = useState();
  const [webdata, setWebdata] = useState([]);
  const [loading, setLoading] = useState(false);

  const { rolesAndPermission, setRolesAndPermiss } = useContext(DrawerContext);
  async function fetchCourses() {
    setLoading(true);
    let configExTr = {
      method: "get",

      url: `${IP}/userGames/courseLibrary`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataex",
    };
    axios(configExTr).then((result) => {
      setLoading(false);
      if (result.data.error) {
        return;
      }
      setWebdata(result?.data);
    });
  }

  useEffect(() => {
    fetchCourses();
  }, []);

  //==========get data of course to know which course is allready enrolled==========//
  const microlearningpagecontent = (courseId, item) => {
    // navigate(`/hr/Learning/MicroLearning-content/${courseId}`)

    navigate(`/hr/Learning/games-content/${courseId}`, {
      state: { ...item },
    });
  };
  const checkUI = (param) => {
    let flag = 0;
    for (
      let i = 0;
      i < rolesAndPermission.Learning?.componentPermission?.length;
      i++
    ) {
      if (rolesAndPermission.Learning?.componentPermission[i] == param)
        flag = 1;
    }
    if (flag == 1) return true;
    return false;
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <WebinarPopUp
          open={openpopUpForAddYears}
          onClose={() => setOpenpopUpForAddYears(false)}
          // delfun={(topic) => {
          //   addYearsFunPopUp(topic);
          // }}
          data={popdata}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            // mt: "100px",
            position: "relative",
            zIndex: "2",
          }}
        >
          <Box>
            <img src={imro} alt="" />
          </Box>

          <Box
            className="sectionname"
            sx={
              {
                // ml: "8px",
              }
            }
          >
            Games
          </Box>

          <Box
            sx={{
              fontWeight: "14px",
              fontSize: "14px",
              ml: "6px",
              height: "20px",
              width: "2px",
              opacity: "0.3",
              backgroundColor: "#6846C7",
            }}
          ></Box>
          {webdata?.length > 0 && (
            <Box
              onClick={() => navigate("view-games")}
              className="sectionnameall"
              sx={{
                ml: "6px",
                height: "20px",

                cursor: "pointer",
                color: "#6846C7",
              }}
            >
              View all
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: "95%",
            "@media (max-width: 780px)": {
              // width: "500px",
              width: "100%",
              overflowX: "scroll",
            },
          }}
        >
          <Box
            sx={{
              mt: "24px",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              display: "grid",
              gridGap: "20px",
              "@media (max-width: 780px)": {
                gridTemplateColumns: "350px 350px 350px 350px",
                height: "100%",
              },
            }}
          >
            {/* <Box
            container
            rowSpacing={8}
            columnSpacing={{ xs: 1, sm: 2, md: 2 }}
           
          > */}
            {webdata?.length == 0 && !loading && checkUI("Games") && (
              <div>
                <Typography sx={{ color: "red" }}>
                  Contact your Admin
                </Typography>

                <img src="/adminDashboard.png" style={{ width: "400px" }} />
              </div>
            )}
            {webdata?.map((item, index) => {
              console.log("item_______", item);
              if (index > 3) {
                return;
              }
              return (
                <Grid item xs={3}>
                  <Item
                    sx={{
                      borderRadius: "24px",
                    }}
                  >
                    <Box
                      onClick={() => {
                        microlearningpagecontent(item.courseId, item);
                      }}
                      sx={{
                        // opacity: index == 0 ? "1" : "0.4",
                        borderRadius: "24px",
                        height: "110px",
                        display: "flex",
                        padding: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "95%",
                          // border: "1px solid red",
                        }}
                      >
                        <Box
                          className="microlerningdiscription"
                          sx={{
                            minHeight: "80px",
                            display: "flex",
                            justifyItems: "left",
                            alignItems: "center",
                            paddingLeft: "10px",
                            textOverflow: "clip",
                          }}
                        >
                          {item?.courseName}
                          {/* What cards have you been dealt ? */}
                        </Box>
                      </Box>
                    </Box>
                  </Item>
                </Grid>
              );
            })}
            {/* </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default GamesHome;
