import React, { useEffect, useState } from "react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadBytes,
  deleteObject,
} from "@firebase/storage";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
} from "firebase/firestore";
import { db, functions } from "../../../Firebase";
import { httpsCallable } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { Timestamp } from "firebase/firestore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import "./createQuiz.css";
import { ExpandMoreSharp } from "@mui/icons-material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdEdit, MdDelete } from "react-icons/md";
import { ExpandMoreSvg } from "./icons";
import DeleteLesson from "./lessonDeletePopUp";
import CreateMcqQuestion from "./mcqQuestion";

import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";
import axios from "axios";
const TrueAndFalse = ({
  quizId,
  questionData,
  editQuestion,
  setfetchDataFromApi,
  fetchDataFromApi,
  flagForSeconstEdit,
}) => {
  const storage = getStorage();

  const createQuestion = httpsCallable(functions, "createQuestion");
  const addQuestionSetOrder = httpsCallable(functions, "addQuestionSetOrder");
  //addQuestionSetOrder
  // added by Himanshu
  const [deleteQuestionId, setDeleteQuestionId] = useState("");
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [questionName, setQuestionName] = useState("");
  const [questionId, setQuestionId] = useState("");

  const initialList = [];
  const [list, setList] = useState(initialList);

  const [picMCQ, setPicMCQ] = useState({
    url: "",
    base64: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    answer: 0,
  });
  const [trueFalse, setTrueFalse] = useState(false);

  function onPicMCQChange(e) {
    const { value, name, files } = e.target;
    setPicMCQ((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  function setPicMCQFile(e) {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image Too large, compress and try again");
        e.target.value = "";
        return;
      }
      setPicMCQ((prev) => ({ ...prev, url: url, base64: reader.result }));
      //console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    // setPicMCQ((prev) => ({ ...prev,  }));
  }

  function uploadPicMCQ(file) {
    if (!file) return;
    let time = JSON.stringify(new Date().getTime());
    const storageref = ref(storage, `/files/${file.name + "_" + time}`);
    const uploadTask = uploadBytesResumable(storageref, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // can set progress.
      },
      (
          err //console.log(err),
        ) =>
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) =>
            setPicMCQ((prev) => ({ ...prev, url: url }))
          );
        }
    );
  }

  const [fillUpsAns, setFillUpsAns] = useState("");
  // rearrange
  const [canEditRearrange, setCanEditRearrange] = useState(true);
  const [rearrangeOptions, setRearrangeOptions] = useState([
    {
      text: "First",
      position: 1,
    },
    {
      text: "Second",
      position: 2,
    },
    {
      text: "Third",
      position: 3,
    },
    {
      text: "Fourth",
      position: 4,
    },
  ]);
  /* arr element example
    {
      text:"rearrange",
      position: 1
    }
  */
  const handleRearrange = (e, order) => {
    //console.log("onChange");
    if (!canEditRearrange) return;
    setRearrangeOptions((prev) => {
      let item = {
        text: e.target.value,
        position: order,
      };
      let res = Array.from(prev);
      res[order - 1] = item;
      return res;
    });
  };
  const handleScramble = () => {
    setCanEditRearrange(false);
    setRearrangeOptions((prev) => {
      let res = scrambleArr(prev);
      //console.log(res);
      return res;
    });
  };
  const scrambleArr = (arr) => {
    //console.log(arr[0]);
    let res = Array.from(arr);
    return res.sort((a, b) => 0.5 - Math.random());
  };

  const [canEditMatchPair, setCanEditMatchPair] = useState(true);
  const [leftOptions, setLeftOptions] = useState(new Array(4).fill(""));
  const [rightOptions, setRightOptions] = useState([
    {
      text: "",
      match: 1,
    },
    {
      text: "",
      match: 2,
    },
    {
      text: "",
      match: 3,
    },
    {
      text: "",
      match: 4,
    },
  ]);

  const handleMatchPair = (e, order, side) => {
    //console.log("onChange");
    if (!canEditMatchPair) return;
    if (side == "left") {
      setLeftOptions((prev) => {
        let item = e.target.value;

        let res = Array.from(prev);
        res[order - 1] = item;
        return res;
      });
    } else {
      setRightOptions((prev) => {
        let item = {
          text: e.target.value,
          match: order,
        };
        let res = Array.from(prev);
        res[order - 1] = item;
        return res;
      });
    }
  };

  const handleMatchUpScramble = () => {
    setCanEditMatchPair(false);
    setRightOptions((prev) => {
      let res = scrambleArr(prev);
      //console.log(res);
      return res;
    });
  };

  // match words to image

  const [imageOptions, setImageOptions] = useState(
    new Array(4).fill("options")
  );
  const [imageFiles, setImageFiles] = useState([
    {
      base64: "",
      match: 1,
      url: "",
    },
    {
      base64: "",
      match: 2,
      url: "",
    },
    {
      base64: "",
      match: 3,
      url: "",
    },
    {
      base64: "",
      match: 4,
      url: "",
    },
  ]);
  const [disableImage, setDisableImage] = useState(false);
  const [uploading, setUploading] = useState(false);
  const handleOptionsChange = (e, index) => {
    let { value } = e.target;
    setImageOptions((prev) => {
      let newArr = Array.from(prev);
      newArr[index] = value;
      return newArr;
    });
  };
  const handleImageChange = (e, index) => {
    let { files } = e.target;
    let reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image Too large, compress and try again");
        e.target.value = "";
        return;
      }
      setImageFiles((prev) => {
        let newArr = Array.from(prev);
        let item = {
          match: index + 1,
          url: URL.createObjectURL(files[0]),
          base64: reader.result,
        };
        newArr[index] = item;
        return newArr;
      });
      //console.log("Image converted ", index);
    };
    reader.readAsDataURL(files[0]);
  };

  const scrambleImage = () => {
    setDisableImage(true);
    setImageFiles((prev) => scrambleArr(prev));
  };

  async function uplaodMatchImage(file) {
    if (!file) return;
    let resURL = "";
    let time = JSON.stringify(new Date().getTime());
    const storageref = ref(storage, `/files/${time + "_" + file.name}`);
    const uploadTask = await uploadBytes(storageref, file);
    resURL = await getDownloadURL(storageref);
    //console.log(resURL);
    return resURL;
  }

  const [imageMcq, setImageMcq] = useState([
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
    { base64: "", blobUrl: "" },
  ]);
  const [imageMcqAnswer, setImageMcqAnswer] = useState(0);

  const handleImageMcqInput = (e, index) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result.length > 1000000) {
        alert("Image too large, compress and try again");
        e.target.value = "";
        return;
      }
      setImageMcq((prev) => {
        let newArr = Array.from(prev);
        newArr[index].base64 = reader.result;
        newArr[index].blobUrl = url;
        return newArr;
      });
      //console.log("setBase64Image done", reader.result);
    };
    reader.readAsDataURL(files[0]);

    // setImageMcq((prev) => {
    //   let newArr = Array.from(prev);
    //   return newArr;
    // });
  };

  //

  useEffect(() => {
    if (!editQuestion) return;
    // console.log(questionData)
    setqus(questionData.data?.question);
    setQuestionName(questionData?.name);
    setQuestionId(questionData?.id);
    setqusInstruction(questionData.data?.questionInstruction);

    if (questionData.data.answer) {
      setTrueFalse(questionData.data.answer);
    }
  }, [flagForSeconstEdit]);

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    questionName: "",
    qusInstruction: "",
    qus: "",
  });
  const formCheckFunction = () => {
    if (questionName === "") {
      setCheckErrorInForm((prev) => ({ ...prev, questionName: "error" }));
      return "question Name";
    }

    if (qus === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qus: "error" }));
      return "question ";
    }
    if (qusInstruction === "") {
      setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "error" }));
      return "question ";
    }
    return "alliswell";
  };

  const [qusMode, setqusMode] = useState("");
  const [qustype, setqustype] = useState("trueAndFalse");
  const [qus, setqus] = useState("");
  const [qusInstruction, setqusInstruction] = useState("");
  const [PointorScore, setPointorScore] = useState("");
  const [qus5, setqus5] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [qus5Answer, setqus5Answer] = useState("Option 1");
  const [qus2, setqus2] = useState({ word: "", correctImage: "" });
  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  function saveOrder(items) {
    var orderList = [];
    for (let i = 0; i < items.length; i++) {
      orderList.push(items[i].id);
    }
    let dataex = JSON.stringify({
      questionSetId: quizId,
      questions: orderList,
      edit: true,
    });
    let configEx = {
      method: "patch",

      url: `${IP}/courseBuilder/questionSet`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx);
    // addQuestionSetOrder({
    //   questionSetId: quizId,
    //   questions: orderList,
    //   edit: true,
    // });

    // db.collection("quiz")
    //   .doc(quizId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
    // db.collection("questionSet")
    //   .doc(quizId)
    //   .collection("extraInfo")
    //   .doc("infoDoc")
    //   .update({
    //     questions: orderList,
    //   });
  }

  const storedDataOnFirebase = async () => {
    let check = formCheckFunction();
    let nsn = questionName.replaceAll(" ", "");

    let dat = /[^A-Za-z0-9]/.test(nsn);

    if (!dat) {
    } else {
      alert("you can not use any special characters");
      return;
    }
    if (check != "alliswell") {
      setFormCheck("check");
      return;
    }

    setFormCheck("");
    let questionTypeEnum;
    let managequestionData;

    if (qustype == "MCQ") {
      let setoptionFromuser;
      if (qus5Answer == "Option 1") {
        let optionsFromUser = [
          { iscorrect: true, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 2") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: true, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 3") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: true, option: qus5.option3 },
          { iscorrect: false, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      } else if (qus5Answer == "Option 4") {
        let optionsFromUser = [
          { iscorrect: false, option: qus5.option1 },
          { iscorrect: false, option: qus5.option2 },
          { iscorrect: false, option: qus5.option3 },
          { iscorrect: true, option: qus5.option4 },
        ];
        setoptionFromuser = optionsFromUser;
      }
      let questionDatafromUser = {
        options: setoptionFromuser,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;

      questionTypeEnum = 5;
    } else if (qustype == "Choose the correct sequence") {
      if (canEditRearrange) return; // didn't scramble before submit
      const myArray = Array.from(rearrangeOptions);
      let questionDatafromUser = {
        options: myArray,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 1;
    } else if (qustype == "trueAndFalse") {
      let questionDatafromUser = {
        answer: trueFalse,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      // mcq with one pic
    } else if (qustype == "Picture MCQ") {
      let optionsFromUser = [
        {
          iscorrect: Boolean(picMCQ.answer === "0"),
          option: picMCQ.option1,
        },
        {
          iscorrect: Boolean(picMCQ.answer === "1"),
          option: picMCQ.option2,
        },
        {
          iscorrect: Boolean(picMCQ.answer === "2"),
          option: picMCQ.option3,
        },
        {
          iscorrect: Boolean(picMCQ.answer === "3"),
          option: picMCQ.option4,
        },
      ];
      let questionDatafromUser = {
        options: optionsFromUser,
        base64: picMCQ.base64,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      // mcq with one pic
    } else if (qustype == "Choose the correct image") {
      let imageOptionFromUser = imageMcq.map((val) => val.base64);
      let questionDatafromUser = {
        correctImage: imageMcqAnswer,
        imageOptions: imageOptionFromUser,
        question: qus,
        questionInstruction: qusInstruction,
        word: qus2.word,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 2;
    } else if (qustype == "Fill in the blank") {
      const myAnswer = fillUpsAns.split(",");
      let questionDatafromUser = {
        answer: myAnswer,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
    } else if (qustype == "Match the pair") {
      if (canEditMatchPair) return;

      let questionDatafromUser = {
        LHS: leftOptions,
        RHS: rightOptions,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 7;
    } else if (qustype == "Match the pairs picture based") {
      if (!disableImage) return;
      let imageArr = [];
      imageFiles.forEach((item) => {
        let ele = {
          base64: item.base64,
          match: item.match,
        };
        imageArr.push(ele);
      });

      let questionDatafromUser = {
        textOptions: imageOptions,
        imageOptions: imageArr,
        question: qus,
        questionInstruction: qusInstruction,
      };
      managequestionData = questionDatafromUser;
      questionTypeEnum = 0;
    }
    const docData = {
      questionName: questionName,
      questionId: questionId,
      associatedQuiz: quizId,
      questionSet: quizId,
      data: managequestionData,
      questionType: qustype,
      created: Timestamp.now(),
    };
    //console.log(docData);

    let dataex = JSON.stringify({
      questionId: questionId,
      questionName: questionName,
      questionType: qustype,
      created: new Date().toString(),
      data: managequestionData,
      quizId: quizId,
    });
    var configEx = {
      method: "post",

      url: `${IP}/courseBuilder/question`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx).then((res) => {
      setfetchDataFromApi(fetchDataFromApi + 1);
      alert("data saved successfully");
    });

    // createQuestion({
    //   questionId: questionId,
    //   questionName: questionName,
    //   questionType: qustype,
    //   created: new Date().toString(),
    //   data: managequestionData,
    //   quizId: quizId,
    // }).then((res)=>{
    //   setfetchDataFromApi(fetchDataFromApi+1)
    //   alert("data saved successfully");

    // });

    const newList = list.concat({
      id: questionId,
      name: questionName,
      type: qustype,
    });
    setList(newList);
    saveOrder(newList);
    document.getElementById(quizId + "question-form").reset();
    document.getElementById(quizId + "questionName").value = "";
    toggleQuestionForm();
  };

  const toggleQuestionForm = (e) => {
    resetData();
    document.getElementById(quizId + "questionForm").style.display =
      document.getElementById(quizId + "questionForm").style.display == "none"
        ? "block"
        : "none";
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    saveOrder(items);
  }

  function edit(lesson) {
    toggleQuestionForm();
  }

  function resetData() {
    setQuestionName("");
    setQuestionId("");
    setqustype("trueAndFalse");
    setqus("");
    setqusInstruction("");
    setRearrangeOptions([
      {
        text: "First",
        position: 1,
      },
      {
        text: "Second",
        position: 2,
      },
      {
        text: "Third",
        position: 3,
      },
      {
        text: "Fourth",
        position: 4,
      },
    ]);
    setqus5({
      option1: "",
      option2: "",
      option3: "",
      option4: "",
    });
    setqus5Answer("Option 1");
    setLeftOptions(new Array(4).fill(""));
    setCanEditMatchPair(true);
    setRightOptions([
      {
        text: "",
        match: 1,
      },
      {
        text: "",
        match: 2,
      },
      {
        text: "",
        match: 3,
      },
      {
        text: "",
        match: 4,
      },
    ]);
    setImageOptions(new Array(4).fill("options"));
    setDisableImage(false);
    setImageFiles([
      {
        base64: "",
        match: 1,
        url: "",
      },
      {
        base64: "",
        match: 2,
        url: "",
      },
      {
        base64: "",
        match: 3,
        url: "",
      },
      {
        base64: "",
        match: 4,
        url: "",
      },
    ]);
    setImageMcq([
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
      { base64: "", blobUrl: "" },
    ]);
    setPicMCQ({
      url: "",
      base64: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      answer: 0,
    });
  }
  async function del(question) {
    setopenLessonPopUp(true);
    setDeleteQuestionId(question);
  }

  const delfunction = (question) => {
    db.collection("question").doc(question.id).delete();

    alert("Deleted " + question.name);
    const item1 = [];
    for (let i = 0; i < list.length; i++) {
      if (question.id !== list[i].id) {
        item1.push(list[i]);
      }
    }
    setList(item1);
    saveOrder(item1);
  };
  return (
    <section className="quizForm-container">
      <form id={quizId + "question-form"} onSubmit={submit}>
        <div className="quizForm-mainForm-infoRow">
          <p className="quizForm-mainForm-label">Question Name :</p>
          <input
            type="text"
            className="quizForm-mainForm-inputBar"
            value={questionName}
            style={{
              border: `${
                checkErrorInForm.questionName == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            id={quizId + "questionName"}
            onChange={(e) => {
              setQuestionName(e.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, questionName: "" }));
              let value = e.target.value.replaceAll(" ", "");
              let time = new Date().getTime();
              setQuestionId(value + "_" + time);
            }}
          />
          {checkErrorInForm.questionName == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Name
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Question : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qus}
            style={{
              border: `${
                checkErrorInForm.qus == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqus(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qus: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qus == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question
            </span>
          )}
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Instruction : </label>
          <textarea
            className="quizForm-mainForm-textarea"
            value={qusInstruction}
            style={{
              border: `${
                checkErrorInForm.qusInstruction == "error"
                  ? "2px solid red"
                  : "1px solid #d7d7d7"
              }`,
            }}
            onChange={(event) => {
              setqusInstruction(event.target.value);
              setCheckErrorInForm((prev) => ({ ...prev, qusInstruction: "" }));
            }}
          ></textarea>
          {checkErrorInForm.qusInstruction == "error" && (
            <span
              style={{ marginLeft: "20px", color: "red", fontSize: "15px" }}
            >
              Enter Question Instruction
            </span>
          )}
        </div>

        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">True </label>
          <input
            className="quizForm-mainForm-radio"
            type="radio"
            name="radio"
            checked={trueFalse ? true : false}
            onChange={() => setTrueFalse(true)}
          />
        </div>
        <div className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">False </label>
          <input
            className="quizForm-mainForm-radio"
            name="radio"
            type="radio"
            checked={trueFalse ? false : true}
            onChange={() => setTrueFalse(false)}
          />
        </div>
      </form>
      {formCheck && <div> Fill all the Required fields.</div>}
      <div className="quizForm-mainForm-infoRow">
        <button
          className="quizFrom-button"
          style={{ marginLeft: "auto" }}
          onClick={storedDataOnFirebase}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export default TrueAndFalse;
