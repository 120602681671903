import React, { useRef } from "react";

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridGap: "20px",
      }}
    >
      My cool content here!
    </div>
  );
});
