import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import "./webinars.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { Input, TextField } from "@mui/material";
export const CreateWebinars = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([1, 2, 3, 4, 5, 6]);
  const [deltemp, setdeltemp] = useState(false);
  const [errorSms, setErrorSms] = useState("");
  const [showLoading, setLoading] = useState(true);

  const [webinarsData, setWebinarsData] = useState({
    topic: "",
    description: "",
    photo: "",
    date: new Date().toISOString().split("T")[0],
    timefrom: "",
    timeto: "",
    zoomlink: "",
    videolink: "",
  });
  const location = useLocation();

  useEffect(() => {
    let data = location.state;
    // new Date(webinarsData?.date?._seconds * 1000).toISOString().split("T")[0];
    if (data) {
      setWebinarsData((prev) => ({
        ...prev,
        ...data,
        date: new Date(data?.date?._seconds * 1000).toISOString().split("T")[0],
      }));
      setImage((prev) => ({ ...prev, url: data.photo }));
    }
  }, []);
  const deleteCompany = httpsCallable(functions, "deleteCompany");

  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  const [image, setImage] = useState([]);

  // get company and department data

  const checkFormData = () => {
    if (
      webinarsData.description == "" ||
      webinarsData.topic == "" ||
      webinarsData.photo == "" ||
      webinarsData.zoomlink == ""
    ) {
      return "enter all the required data";
    }
    return true;
  };
  const createWebinar = () => {
    let check = checkFormData();
    if (check != true) {
      setErrorSms(check);
      return;
    }
    setErrorSms("");
    setLoading(true);
    let data = JSON.stringify(webinarsData);
    var config = {
      method: "post",

      url: `${IP}/webinar`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        navigate(-1);
        //console.log(res);
        if (res.data?.error) {
        } else {
          //   settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    const url = URL.createObjectURL(files[0]);
    setImage((prev) => ({
      file: files[0],
      url: url,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      //  setBase64Image(reader.result);
      //   //console.log("setBase64Image done", reader.result);
      // setDetails({ ...details, logo: reader.result });
      setWebinarsData({ ...webinarsData, photo: reader.result });
    };
    reader.readAsDataURL(files[0]);
  };

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Webinars")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>{" "}
                </div>

                <div> </div>
              </div>

              <div
                style={{
                  backgroundColor: "#ffffff",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <div
                  className="inputlabelnameForstrategi"
                  style={{ color: "#000000" }}
                >
                  Add Webinars
                </div>

                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi">
                    {" "}
                    Webinar Topic
                  </div>
                  <div>
                    {" "}
                    <input
                      className="inputbox"
                      type="text"
                      value={webinarsData.topic}
                      onChange={(e) => {
                        setWebinarsData((prev) => ({
                          ...prev,
                          topic: e.target.value,
                        }));
                      }}
                      placeholder="  Webinar Topic"
                      style={{
                        padding: "5px",
                        height: "35px",
                        width: "250px",
                      }}
                    />
                  </div>
                </div>

                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi">
                    {" "}
                    Webinar Description{" "}
                  </div>
                  <div>
                    {" "}
                    <TextField
                      value={webinarsData.description}
                      onChange={(e) => {
                        setWebinarsData((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }));
                      }}
                      InputProps={{
                        sx: {
                          resize: "both",
                          overflow: "auto",
                        },
                      }}
                      multiline
                      className="inputbox"
                      sx={{ width: "250px" }}
                      type="text"
                      placeholder="   Webinar Description"
                    />
                  </div>
                </div>

                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi"> Cover Photo</div>
                  <div>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "50px",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {" "}
                        <Button
                          variant="contained"
                          component="label"
                          style={{
                            background: `${
                              clrs.CourseListBtn
                                ? clrs.CourseListBtn
                                : "hwb(224deg 78% 3%)"
                            }`,
                          }}
                          startIcon={<FileUploadIcon />}
                        >
                          Upload
                          <input
                            name="logo"
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleFileChange}
                          />
                        </Button>{" "}
                      </div>{" "}
                      <div>
                        {" "}
                        {image.url && (
                          <div style={{ width: "300px", height: "150px" }}>
                            <img
                              src={image?.url}
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi"> Date</div>
                  <div>
                    {" "}
                    <Input
                      type="date"
                      placeholder="Question"
                      // defaultValue={
                      //   new Date(webinarsData?.date?._seconds * 1000)
                      //     .toISOString()
                      //     .split("T")[0]
                      // }
                      value={webinarsData?.date}
                      onChange={(e) => {
                        setWebinarsData((prev) => ({
                          ...prev,
                          date: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi"> Time</div>
                  <div style={{ display: "flex", gap: "20px" }}>
                    {" "}
                    <div>
                      {" "}
                      <input
                        value={webinarsData.timefrom}
                        onChange={(e) => {
                          setWebinarsData((prev) => ({
                            ...prev,
                            timefrom: e.target.value,
                          }));
                        }}
                        className="inputbox"
                        type="Number"
                        placeholder="  From"
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "100px",
                        }}
                      />
                    </div>
                    To
                    <div>
                      {" "}
                      <input
                        value={webinarsData.timeto}
                        onChange={(e) => {
                          setWebinarsData((prev) => ({
                            ...prev,
                            timeto: e.target.value,
                          }));
                        }}
                        className="inputbox"
                        type="text"
                        placeholder="  To"
                        style={{
                          padding: "5px",
                          height: "35px",
                          width: "100px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi"> Zoom Link</div>
                  <div>
                    {" "}
                    <input
                      value={webinarsData.zoomlink}
                      onChange={(e) => {
                        setWebinarsData((prev) => ({
                          ...prev,
                          zoomlink: e.target.value,
                        }));
                      }}
                      className="inputbox"
                      type="text"
                      placeholder="Zoom Link"
                      style={{
                        padding: "5px",
                        height: "35px",
                        width: "250px",
                      }}
                    />
                  </div>
                </div>

                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi"> Video Link</div>
                  <div>
                    {" "}
                    <input
                      value={webinarsData.videolink}
                      onChange={(e) => {
                        setWebinarsData((prev) => ({
                          ...prev,
                          videolink: e.target.value,
                        }));
                      }}
                      className="inputbox"
                      type="text"
                      placeholder="Video Link"
                      style={{
                        padding: "5px",
                        height: "35px",
                        width: "250px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "24px",
                    color: "red",
                  }}
                >
                  {errorSms}
                </div>
                <div>
                  {" "}
                  <Button
                    onClick={createWebinar}
                    style={{
                      backgroundColor: "#007C84",
                      marginTop: "24px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {" "}
                    Save
                  </Button>
                </div>
              </div>

              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
