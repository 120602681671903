export const TRANSLATIONS_EN = {
  ShowArchive:"Show Archive",
  MainMenu: "Main Menu",
  home: "home",
  CourseLibrary: "Course Library",
  Training: "Training",
  Profile: "Profile",
  YourAssignment: "Your Assignment",
  Certificates: "Certificates",
  UserReports: "User Reports",
  SearchCourse: "Search Course",
  viewAll: "View all",
  AddNewIndividualEnrollment:"Add New Individual Enrollment",
  ListOfIndividualEnrollment:"List Of Individual Enrollment",
  ListOfGroupEnrollment:"List Of Group Enrollment",
  IndividualEnrollments:"Individual Enrollments",
  GenerateReport: "Generate Report",
  Enroll: "Enroll",
  CourseContent: "Course Content",
  Material: "Materials",
  Overview: "Overview",
  MyProgress: "My Progress",
  Aboutlession: "Aboutlession",
  InviteAccept:"Invite Accept",
  Dashboard: "Dashboard",
  TypeOfTraining:"Type Of Training",
  LMSCourseBuilder: " LMS Course Builder",
  Groups: "Groups",
  GroupEnrollments: "Group of Enrollments",
  GroupEnrollment:"Group Enrollment",
  Users: "User",
  ExternalTraining: "External Training",
  InternalOfflineTraining: "Internal Offline Training",
  PreviousTraining: "Previous Training",
  CourseReports: "Reports",
  Enrollments: "Enrollments",
  ExpiredTrainingCertificates:"Expired Training Certificates",
  SubmittedAssignment: "Submitted Assignment",
  Settings: "Settings",
  TrainingEnrollments: "Training Enrollments",
  CompanyName: "Company Name",
  GroupName: "Group Name",
  CourseName: "Course Name",
  FromDate: "From Date",
  ToDate: "To Date",
  SelectCompany: "Select Company",
  SelectGroup: "Select Group",
  SelectCourseName: "Select Course Name",
  SelectDate: "Select Date",
  UserView: "User View",
  Filter: "Filter",
  OverdueEnrollments: "Overdue Enrollments",
  NumberOfOverdueEnrollments: "Number Of Overdue Enrollments",
  ViewAll: "View All",
  UserPassedInThisMonth: "User Passed In This Month",
  UserFailedInThisMonth: "User Failed In This Month",
  NumberOfUserPassed: " Number Of User Passed",
  NumberOfUserFailed: "Number Of User Failed",
  NewEmployees: " New Employees",
  NumberOfNewEmployes: " Number Of New Employes",
  ExpiringTrainingCertificates: "Expiring Training Certificates",
  Reports: "Reports",
  SendReminder: "Send Reminder",
  Show: "Show",
  BulkCreateUser:"Bulk Create User",
  PagePerItem: "Page Per Item",
  AddGroup: "Add New Group",
  UserList: "List Of User",
  Statistics: "Statistics ",
  OverdueEnrollments: "Overdue Enrollments ",
  NoofOverdueEnrollments: "No of Overdue Enrollments ",
  Viewall: "View all ",
  CourseName: "Course Name ",
  CompanyName: "Company Name ",
  Filter: "Filter ",
  SelectCourse: "Select Course ",
  FromDate: "From Date ",
  ToDate: "To Date ",
  Clear: "Clear ",
  SRNo: "SR. No. ",
  Name: "Name ",
  CourseTitle: "Course Title ",
  Company: "Company ",
  Department: "Department ",
  Duedate: "Due date ",
  Type: "Type ",
  TrainingProvider: "Training Provider ",
  Show: "Show ",
  PagePerItem: "Page Per Item ",
  Back: "Back ",
  UserPassedthismonth: "User Passed this month ",
  NoofUserPassed: "No of User Passed ",
  CourseType: "Course Type ",
  CompletionFromDate: "Completion From Date ",
  CompletionToDate: "Completion To Date ",
  CompletionDate: "Completion Date ",
  UserFailedThisMonth: "User Failed This Month ",
  NoofUserFailed: "No of User Failed ",
  DueDateFrom: "Due Date From ",
  DueDateTo: "Due Date To ",
  NewEmployees: "New Employees ",
  Noofnewemployees: "No of new employees ",
  NewEmployeeDetails: "New Employee Details ",
  JoiningDatefrom: "Joining Date from ",
  JoiningdateTo: "Joining date To ",
  JobTitle: "Job Title ",
  JoiningDate: "Joining Date ",
  EmployeeNo: "Employee No. ",
  Email: "Email ",
  Role: "Role ",
  Action: "Action ",
  ExpiringTrainingCertificates: "Expiring Training Certificates ",
  Nooftrainingcertificates: "No of training certificates ",
  SendReminder: "Send Reminder ",
  EmployeeName: "Employee Name ",
  DateFrom: "Date From ",
  Dateto: "Date to ",
  SNo: "S No. ",
  Coursetitle: "Course title ",
  IssuedOn: "Issued On ",
  Expiry: "Expiry ",
  Provider: "Provider ",
  InternalTrainingLMS: "Internal Training LMS ",
  Add: "Add ",
  LMSCourseBuilder: "LMS Course Builder ",
  FeaturedImage: "Featured Image ",
  ShortDescription: "Short Description ",
  CourseDuration: "Course Duration ",
  AccessMode: "Access Mode ",
  ClicktouploadImage: "Click to upload Image ",
  Free: "Free ",
  Closed: "Closed ",
  Courseaccessexpiration: "  Course access expiration  ",
  CourseProgress: "Course Progress  ",
  CourseID: "Course ID ",
  CertificateTemplate: "Certificate Template ",
  CourseInstructor: "Course Instructor ",
  CourseSharedWith: "Course Shared With ",
  FreeFlow: "Free Flow",
  Sequential: "Sequential",
  days: "days ",
  Save: "Save ",
  View: "View ",
  Training: "Training ",
  InternalTraining: "Internal Training ",
  AddNewInternalTraining: "Add New Internal Training ",
  Createddate: "Created date ",
  ExternalTraining: "External Training  ",
  ListofExternalTrainings: "List of External Trainings ",
  AddNewExternaltarining: "Add New External tarining ",
  ExternalCourseProvider: "External Course Provider ",
  ExternalCourseID: "External Course ID ",
  UploadMaterials: "Upload Materials  ",
  DragAndDropFilesorBrowse: "Drag & Drop Files or Browse",
  SaveAndUpdate: "Save & Update ",
  AddNewExternalTraining: "Add New External Training ",
  TrainingName: "Training Name ",
  Createdon: "Created on ",
  InternalOfflineTrainng: "Internal Offline Trainng ",
  ListofInternalOfflineTraining: "List of Internal Offline Training ",
  AddNewInternalOfflineTraining: "Add New Internal Offline Training ",
  OfflineCourseProvider: "Offline Course Provider ",
  OfflineCourseID: "Offline Course ID ",
  CreatedDate: " Created Date ",
  Searchbycoursetitle: "Search by course title ",
  Search: "Search ",
  GroupName: "Group Name ",
  Searchbygroupname: "Search by group name ",
  SelectTraining: "Select Training ",
  ClearFilters: "Clear Filters ",
  SingleUserEnrollment:"Single User Enrollment",
  AddNewGroupEnrollment: "Add New Group Enrollment ",
  SelecttrainingCourse: "Select training Course ",
  Cancel: "Cancel ",
  ShowGroups:"Show Groups",
  ListofGroupEnrollment: "List of Group Enrollment ",
  EnrollmentDate: "Enrollment Date ",
  DueDate: "Due Date ",
  SearchbyEmployeeName: "Search by Employee Name ",
  EmployeeNo: "Employee No ",
  SearchbyEmployeeNo: "Search by Employee No ",
  ClearSearch: "Clear Search ",
  AddnewIndividualEnrollment: "Add new Individual Enrollment ",
  ListOfUsers: "List Of Users ",
  ShowUser:"Show User",
  Select: "Select ",
  Joiningdate: "Joining date ",
  Assign: "Assign ",
  ListofIndividualEnrollment: "List of Individual Enrollment ",
  UserName: "User Name ",
  EnterUsername: "Enter User name ",
  SelectRole: "Select Role ",
  AddNewUser: "Add New User ",
  User: "User ",
  AddNewUserOrEditUser: "Add New User / Edit User ",
  FullName: "Full Name ",
  EmailID: "Email ID ",
  EmployeeID: "Employee ID ",
  Signature: "Signature  ",
  ClicktoUploadImage: " Click to Upload Image ",
  Admin: "Admin ",
  Instructor: "Instructor ",
  ListofUser: "List of User ",
  AddNewGroup: "Add New Group ",
  Groups: "Groups ",
  AddNewGroupOrEditGroup: "Add New Group / Edit Group ",
  GroupDescription: "Group Description ",
  ListofGroupMember: "List of Group Member ",
  Remove: "Remove ",
  AllMembersfromthelistbelow: "All Members from the list below ",
  ListofAllUser: "List of All User ",
  Username: "User name ",
  Sort: "Sort ",
  ClearSort: "Clear Sort ",
  ClearFilter: "Clear Filter ",
  ListofGroup: "List of Group ",
  GroupID: "Group ID ",
  NumberofMember: "Number of Member ",
  NoofCourse: "No. of Course ",
  EnterEmployeeName: "Enter Employee Name ",
  EnterTrainingProvider: "Enter Training Provider ",
  CertificateName: "Certificate Name ",
  EnterCertificateName: "Enter Certificate Name ",
  EnterTrainingType: "Enter Training Type ",
  Status: "Status ",
  EnterStatus: "Enter Status ",
  ExpiryDate: "Expiry Date ",
  GenerateReports: "Generate Reports ",
  ReportsType: "Reports Type ",
  name: "name ",
  SNo: "S No ",
  ReportID: "Report ID ",
  ReporGeneratedBy: "Repor Generated By ",
  ReportType: "Report Type ",
  DateofGeneration: "Date of Generation ",
  DownloadFile: "Download File ",
  MyTraining: "My Training ",
  ViewAll: "View All ",
  PreviousTraining: "Previous Training ",
  AddCertificate: "Add Certificate  ",
  AddyourPreviousTrainingCertificatesfromPreviousCompany:
    " Add your Previous Training Certificates from Previous Company",
  YouNeedToEnrollinanExternalTrainingFirst: "You Need To Enroll in an External Training First",
  InternalOfflineTraining: "Internal Offline Training  ",
  YouNeedToEnrollInternalOfflineTrainingFirst:
    " You Need To Enroll Internal Offline Training First",
  Certificates: "Certificates ",
  YouDontHaveAnyCertificate: "You Don't Have Any Certificate ",
  SearchCourse: "Search Course ",
  Enroll: "Enroll ",
  YourlatestTraining: "Your latest Training",
  PreviousTrainining: "Previous Trainining ",
  ChangePassword: "Change Password  ",
  Change: "Change ",
  ChangeyourPassword: "Change your Password",
  CurrentPassword: "Current Password ",
  EnterNewPassword: "Enter New Password ",
  ConfirmPassword: "Confirm Password ",
  Logout: "Logout ",
};
