import { InputLabel, Modal, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Chart from "./Chart";
import { psyclogicalSafetyData } from "./Option";

import ReactToPrint from "react-to-print";

// import React, { useRef } from "react";
import React, { useContext, useRef, useEffect, useState } from "react";
import psy1 from "../../../utilities/pdfImages/psy11.jpg";

import psy2 from "../../../utilities/pdfImages/psy12.jpg";
import psy3 from "../../../utilities/pdfImages/psy13.jpg";
import psy4 from "../../../utilities/pdfImages/psy14.jpg";
import psy5 from "../../../utilities/pdfImages/psy15.jpg";
import psy6 from "../../../utilities/pdfImages/psy16.jpg";
import psy7 from "../../../utilities/pdfImages/psy17.jpg";
import HrloadingScreen from "../../LodingHrscreen";

import { exportMultipleChartsToPdf } from "./GraphUtils";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { IP } from "../../../baseUrlAndConfig";
import { getCookie } from "../../../Cookies";

export const ComponentToPrint = React.forwardRef(
  ({ children, companyName, allApiData }, ref) => {
    // console.log(props);

    // const {}
    return (
      <div
        ref={ref}
        style={{
          overflow: "scroll",
          whiteSpace: "nowrap",

          margin: "auto",
        }}
      >
        {children}
      </div>
    );
  }
);
