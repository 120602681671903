import React, { useRef } from "react";

import ReactToPrint from "react-to-print";

import { ComponentToPrint } from "./ComponentToPrint";
export default function TestSteper({ setSteperStep, steperStep }) {
  const componentRef = useRef();

  return (
    <div>
      <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      />
      <ComponentToPrint ref={componentRef} />
    </div>
  );
}

{
  /* <Chart
        options={option.options}
        series={option.series}
        width="100%"
        height={400}
        type="bar"
      /> */
}

// { <Box
//     sx={{
//       width: "60%",
//       // minWidth: 400,
//       mt: "25px",
//       height: "600px",
//       // backgroundColor: "#E1F566",
//       borderRadius: "24px",
//       display: "flex",
//       margin: "auto",
//       justifyItems: "center",
//       //  gridTemplateColumns: "1fr 1fr 1fr 1fr",
//       marginBottom: "24px",
//     }}
//   >
//     {steps?.map((el, index) => (
//       <Box sx={{ width: "100%" }}>
//         <Box sx={{ width: "100%", textAlign: "center" }}>
//           <Box
//             sx={{
//               height: "200px",
//               // aspectRatio: "3/2",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "flex-end",
//               mb: 7,
//             }}
//           >
//             {index == 0 ? el.cimg : el.img}
//           </Box>

//           <Box>{el.name}</Box>
//         </Box>
//         <Box
//           sx={{
//             display: "grid",
//             width: "100%",
//             gridTemplateColumns: "3fr 1fr 3fr",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Box>
//             {" "}
//             <hr />
//           </Box>
//           <Box>
//             {" "}
//             <div
//               style={{
//                 width: "40px",
//                 height: "40px",
//                 borderRadius: "50%",
//                 border: "1px solid #000000",
//                 justifyContent: "center",
//                 alignContent: "center",
//                 alignItems: "center",
//                 display: "flex",
//               }}
//             >
//               {index == 0 && (
//                 <div
//                   style={{
//                     width: "20px",
//                     height: "20px",
//                     borderRadius: "50%",
//                     border: "1px solid #000000",

//                     backgroundColor: "#E1F566",
//                   }}
//                 ></div>
//               )}{" "}
//             </div>{" "}
//           </Box>
//           <Box>
//             {" "}
//             <hr />
//           </Box>
//         </Box>
//       </Box>
//     ))}
//   </Box>}
