import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../../../Firebase";
import "../../AdminSetting.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip, Typography } from "@mui/material";
import Papa, { parse } from "papaparse";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Sidebar from "../../../../Component/Sidebar";
import NavbarTop from "../../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../../baseUrlAndConfig";
import { getCookie } from "../../../../../Cookies";
import { toast } from "react-toastify";
import ICCBulk from "./popUpForBulkUpload";
export const ICCMembersCompanyAndLocationList = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(false);

  const [csvData, setCsvData] = useState(null);
  const [showDeleteBulk, setShowDeleteBulk] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  // archive company
  const archiveCompany = (id, name) => {
    console.log(id, name);
    // return;
    setLoading(true);
    const data = JSON.stringify({
      companyId: id,
      isArchive: true,
      // name: name,
    });

    var config = {
      method: "delete",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        //console.log(res);
        alert("Company Archived");
        setdeltemp(!deltemp);
        setArchiveDialog(false);
        setLoading(false);
      })
      .catch((er) => {
        setLoading(false);

        toast.error("something went wrong");
      });
  };
  // delete company
  const deleteCompany = (id, name) => {
    //console.log(id);

    const data = JSON.stringify({
      companyId: id,
      location: name,
    });

    var config = {
      method: "delete",

      url: `${IP}/iccRoutes/createLocations`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };

    axios(config)
      .then((res) => {
        //console.log(res);
        alert("Company Deleted");
        setdeltemp(!deltemp);
        getLocations(id);
      })
      .catch((er) => {
        toast.error("something went wrong");
      });
  };
  // get company and department data
  const fetchdata = () => {
    // setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/company`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        // setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          setCompanyList(res.data);
        }
      })
      .catch((err) => {
        // setLoading(false);
        //console.log(err);
      });
  };
  const getLocations = (companyId) => {
    setLoading(true);
    // console.log("list", location?.state);

    var config = {
      method: "get",
      // params: { id: location.state.companyId },

      params: { companyId: companyId },
      url: `${IP}/iccRoutes/getLocations`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        if (res.data?.error) {
        } else {
          settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);
  //   useEffect(() => {
  //     fetchDepartmentdata();
  //   }, [deledep]);

  const navigate = useNavigate();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [archiveDialog, setArchiveDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({
    name: "",
    id: "",
  });

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Company Settings")} />
        {/* <Navbar /> */}
        <ICCBulk
          showBulk={showDeleteBulk}
          handleClose={() => {
            setShowDeleteBulk(false);
          }}
        />
        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              <h1>Company locations Details</h1>

              <div
                className="create-new-box"
                style={{
                  display: "flex",
                  gap: "20px",
                  justifyContent: "space-between",
                }}
              >
                {/* <input
                  type="file"
                  onChange={(e) => {
                    setCsvData(e.target.files);
                  }}
                /> */}

                <div
                  className="course-builder-info"
                  style={{ display: "flex", gap: "30px" }}
                >
                  {/* <h3 className="course-builder-inputLable">company name *</h3> */}
                  <select
                    name=""
                    id=""
                    className="course-builder-inputBar"
                    style={{ padding: "5px", fontSize: "18px", height: "50px" }}
                    // value={companyId}
                    onChange={(e) => {
                      getLocations(e.target.value);
                    }}
                  >
                    <option value="">select company</option>
                    {companyList?.map((el) => (
                      <option value={el.companyId}>{el.name}</option>
                    ))}
                  </select>

                  {/* {errors.companyId && <span>This field is required</span>} */}
                </div>

                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={() => {
                    navigate("/admin/settings/ICC/Templatelist");
                  }}
                  // startIcon={<AddIcon />}
                >
                  Templates list
                </Button>
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                  onClick={() => {
                    // uploadCsv();
                    setShowDeleteBulk(true);
                  }}
                  // startIcon={<AddIcon />}
                >
                  Upload csv
                </Button>
              </div>
              <div
                className="bigger-container"
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <div> Company Name</div>
                <div> Location Name </div>

                <div style={{ display: "flex" }}>
                  <div>Action</div>
                </div>
              </div>
              <div className="setting_details_table">
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}

                {template?.map((ele) => {
                  return (
                    <div className="outer-inner-container">
                      <div className="templatename">{ele.companyName}</div>
                      <div
                        className="templatename"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {ele.location}
                      </div>
                      <div className="button-box">
                        {/*  */}
                        <div className="button-box-inner mx-3 my-1">
                          <Link
                            to={{
                              pathname:
                                "/admin/settings/company/locations/create",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              Edit
                            </Button>
                          </Link>
                        </div>
                        {/* delete button */}
                        <div className="button-box-inner mx-3 my-1">
                          {" "}
                          <Button
                            variant="contained"
                            component="span"
                            onClick={() => {
                              deleteCompany(ele.companyId, ele.location);
                            }}
                            // onClick={() => setDeleteDialog(true)}
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                        {/* archieve */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* delete dialog */}
        <Dialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          // sx={{backgroundColor:'white'}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Confirmation"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you really want to delete Company?
              <Typography sx={{ color: "blue", cursor: "pointer" }}>
                Archieve Instead.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
            <Button
              onClick={() => setDeleteDialog(false)}
              autoFocus
              sx={{ color: "red" }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* archive dialog */}
        <Dialog
          open={archiveDialog}
          onClose={() => setArchiveDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Archive Confirmation"}
          </DialogTitle>
          {showLoading && (
            <DialogContent>
              <div className="spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </DialogContent>
          )}
          {!showLoading && (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to Archive this Company?
              </DialogContentText>
            </DialogContent>
          )}

          {!showLoading && (
            <DialogActions>
              <Button onClick={() => setArchiveDialog(false)}>Cancel</Button>
              <Button
                onClick={() => {
                  setLoading(true);
                  archiveCompany(selectedCompany.id, selectedCompany.name);
                }}
                autoFocus
                sx={{ color: "red" }}
              >
                Archive
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </section>
    </>
  );
};
