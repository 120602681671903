import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";

import "../Policies/policiesList.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import { MenuItem, Select, TextField } from "@mui/material";

//import { arr } from "../Policies/policiesData";

export const InfrastructureAddList = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  //================company settings===========================//
  const [template, settemplate] = useState([1, 2, 3, 4, 5, 6]);
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const deleteCompany = httpsCallable(functions, "deleteCompany");
  const [editFlag, setEditFlag] = useState(false);
  const [arr, setArr] = useState([]);
  const [policesdata, setPolicesdata] = useState({
    heading: "",
    description: "",
    type: "",
    isAgree: "",
  });
  const [editIndex, seteditIndex] = useState("");
  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  // delete company
  const deletetemplate = (id) => {
    //console.log(id);
    deleteCompany({ companyId: id }).then((res) => {
      //console.log(res);
      alert("Company Deleted");
      setdeltemp(!deltemp);
    });
  };
  // get company and department data

  const cretePolices = () => {
    setLoading(true);

    let arrdata = arr;
    // console.log(editFlag, editIndex);
    if (editFlag) {
      arrdata[editIndex] = policesdata;
    } else {
      arrdata.push(policesdata);
    }
    let data = JSON.stringify(arrdata);
    var config = {
      method: "post",

      url: `${IP}/infrastructure/admin`,

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    //console.log(arrdata);

    axios(config)
      .then((res) => {
        setLoading(false);
        fetchdata();
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
    setPolicesdata({
      heading: "",
      description: "",
      type: "",
      isAgree: "",
    });
    setEditFlag(false);
    seteditIndex("");
  };
  const fetchdata = () => {
    setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/infrastructure/admin`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        //console.log(res);
        if (res.data?.error) {
        } else {
          setArr(res.data);
          //   settemplate(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  useEffect(() => {
    fetchdata();
  }, [deltemp]);

  const navigate = useNavigate();

  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Infrastructure")} />
        {/* <Navbar /> */}

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}

              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              <div
                style={{
                  background: "#FFFFFF",
                  padding: "10px",
                  borderRadius: "4px",
                }}
              >
                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi"> Question</div>
                  <div>
                    {" "}
                    <input
                      value={policesdata.heading}
                      onChange={(e) => {
                        setPolicesdata((prev) => ({
                          ...prev,
                          heading: e.target.value,
                        }));
                      }}
                      className="inputbox"
                      type="text"
                      placeholder="Question"
                      style={{
                        padding: "5px",
                        height: "35px",
                        width: "250px",
                      }}
                    />
                  </div>
                </div>

                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi"> Description</div>
                  <div>
                    {" "}
                    <TextField
                      value={policesdata.description}
                      onChange={(e) => {
                        setPolicesdata((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }));
                      }}
                      InputProps={{
                        sx: {
                          resize: "both",
                          overflow: "auto",
                        },
                      }}
                      multiline
                      className="inputbox"
                      sx={{ width: "250px" }}
                      type="text"
                      placeholder="Description"
                    />
                  </div>
                </div>

                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi"> Type</div>
                  <div>
                    {" "}
                    <Select
                      value={policesdata.type}
                      onChange={(e) => {
                        setPolicesdata((prev) => ({
                          ...prev,
                          type: e.target.value,
                        }));
                      }}
                      style={{ width: "250px", height: "30px" }}
                      type="text"
                    >
                      {" "}
                      <MenuItem value={"basic"}> Basic</MenuItem>{" "}
                      <MenuItem value={"intermediate"}> Intermediate</MenuItem>{" "}
                      <MenuItem value={"advance"}> Advance</MenuItem>{" "}
                    </Select>
                  </div>
                </div>

                <div>
                  {" "}
                  <Button
                    onClick={cretePolices}
                    style={{
                      backgroundColor: "#007C84",
                      marginTop: "24px",
                      borderRadius: "5px",
                      color: "#FFFFFF",
                    }}
                  >
                    {" "}
                    Save
                  </Button>
                </div>
              </div>

              <div
                style={{
                  background: "#FFFFFF",
                  padding: "10px 10px 10px 60px",
                  marginTop: "20px",
                }}
              >
                <div className="policiesListTabel policiesListTabelHeders">
                  <div> S.No</div>
                  <div>Question </div>

                  <div>Action</div>
                </div>
                {arr?.map((ele, index) => {
                  return (
                    <div
                      className="policiesListTabel policiesListTabelBody borderStyleFortabel"
                      style={{}}
                    >
                      <div>{index + 1}</div>
                      <div>{ele.heading}</div>

                      <div className="button-box">
                        <div className="button-box-inner mx-3 my-1">
                          <Button
                            onClick={() => {
                              setPolicesdata(ele);
                              setEditFlag(true);
                              seteditIndex(index);
                            }}
                            variant="contained"
                            component="span"
                            style={{
                              background: `${
                                clrs.CourseListBtn
                                  ? clrs.CourseListBtn
                                  : "hwb(224deg 78% 3%)"
                              }`,
                            }}
                          >
                            Edit
                          </Button>
                        </div>

                        {/* <div className="button-box-inner mx-3 my-1">
                          <Link
                            to={{
                              pathname: "/admin/settings/StrategiesEdit",
                              state: { fromDashboard: "it is first" },
                            }}
                            state={ele}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              style={{
                                background: `${
                                  clrs.CourseListBtn
                                    ? clrs.CourseListBtn
                                    : "hwb(224deg 78% 3%)"
                                }`,
                              }}
                            >
                              delete
                            </Button>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
