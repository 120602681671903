import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { t } from "i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ValidateAuth } from "../../../AuthToken";
import { getCookie } from "../../../Cookies";
import { auth, functions } from "../../../Firebase";
import { ReactComponent as Delete } from "../../AdminComponents/assets/delete.svg";
import { ReactComponent as PdfIcon } from "../../AdminComponents/assets/pdfIcon.svg";
import { ReactComponent as UploadIcon } from "../../AdminComponents/assets/upload.svg";
import Navbar from "../../Component/NavbarTop";
import Sidebar from "../../Component/Sidebar";
import "./Enrollment.css";
import DeleteCertificate from "./modal/DeleteCertificate";
import GenrateCertificate from "./modal/GenrateCertificate";
import UploadCertificate from "./modal/UploadCertificate";
import Warning from "./modal/Warning";
import { IP } from "../../../baseUrlAndConfig";
import axios from "axios";
import { DrawerContext } from "../../../utilities/context/DrawerProvider";
function GroupEnrollmentEdits() {
  const navigate = useNavigate();

  const goToHome = () => navigate("/");
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);

  const location = useLocation();
  const editState = location.state;
  //console.log(editState);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [generateOpen, setGenerateOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [enrollmentData, setEnrollmentData] = useState();
  //deleteOpen

  const [training_value, setTraining] = useState("course");
  const [courseId, setCourseId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [expDate, setExpDate] = useState("");

  // const [setNotification, setSetNotification] = useState("no");
  const [notiFreq, setNotiFreq] = useState("");
  const [completed, setCompleted] = useState("not completed");
  const [enrollmentId, setEnrollmentId] = useState("");
  const [groupInfo, setGroupInfo] = useState(null);
  const [groupUsers, setGroupUsers] = useState([]);
  const [courseInfo, setCourseInfo] = useState(null);
  const [internalOfflineInfo, setInternalOfflineInfo] = useState(null);
  const [externalTrainingInfo, setExternalTrainingInfo] = useState(null);
  const [gameInfo, setGameInfo] = useState([]);
  const [clickedUser, setClickedUser] = useState({ userId: "", userName: "" });
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { clrs } = useSelector((state) => state.createClr);
  const setTrainingRef = useRef();
  const setCourseNameRef = useRef();
  const setGroupNameRef = useRef();
  const dropRef = useRef(null);
  const inputRef = useRef(null);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (editState) {
      setEdit(true);
      //console.log(editState);
      setTraining(editState?.courseType);
      setCompleted(editState?.completed);
      setCourseId(editState?.courseId);
      setCourseName(editState?.courseName);
      setGroupId(editState?.groupId);
      setGroupName(editState?.groupName);
      setNotiFreq(editState?.notiFreq);
      setEnrollmentId(editState?.enrollmentId);
      setFileList(editState?.notes);
      setGroupNameRef.current.disabled = true; // should not edit
      setCourseNameRef.current.disabled = true; // should not edit
      setTrainingRef.current.disabled = true; // should not edit
      // let dueDateEdit = Timestamp.fromMillis(
      //   editState.dueDate?._seconds * 1000
      // ).toDate();
      // console.log(dueDateEdit?.toISOString().split("T")[0]);

      setDueDate(editState.dueDate?.split("T")[0]);
      // setExpDate(editState.expDate?.split("T")[0]);

      setLoading(true);

      // let configEx = {
      //   method: "get",
      //   params: {
      //     courseType: editState?.courseType,
      //     courseId: editState?.courseId,
      //     groupId: editState?.groupId,
      //   },
      //   url: `${IP}/groupEnrollment/userInfo`,
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${getCookie("bearer")}`,
      //   },
      //   data: "dataex",
      // };
      // axios(configEx).then((result) => {
      //   setLoading(false);

      //   let tempArr = [];
      //   for (let i = 0; i < result.data.length; i++) {
      //     let element = result.data[i];
      //     let completionDate = "";
      //     let expiryDate = "";
      //     let dueDate = "";
      //     if (element.dueDate) {
      //       dueDate = Timestamp.fromMillis(element.dueDate?._seconds * 1000)
      //         .toDate()
      //         .toISOString()
      //         .split("T")[0];
      //     }
      //     if (element.completionDate) {
      //       completionDate = Timestamp.fromMillis(
      //         element.completionDate?._seconds * 1000
      //       )
      //         .toDate()
      //         .toISOString()
      //         .split("T")[0];
      //     }
      //     if (element.expiryDate) {
      //       expiryDate = Timestamp.fromMillis(
      //         element.expiryDate?._seconds * 1000
      //       )
      //         .toDate()
      //         .toISOString()
      //         .split("T")[0];
      //     }
      //     if (element.archived) {
      //     } else {
      //       tempArr.push({
      //         ...element,
      //         completeDate: completionDate,
      //         expiry: expiryDate,
      //         due: dueDate,
      //         loading: false,
      //       });
      //     }
      //   }

      //   //console.log(tempArr)

      //   setGroupUsers(tempArr);
      // });
    }
  }, []);

  useEffect(() => {
    let configEx = {
      method: "get",
      params: {
        courseType: editState?.courseType,
        courseId: editState?.courseId,
        groupId: editState?.groupId,
      },
      url: `${IP}/group/list`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataex",
    };
    axios(configEx).then((result) => {
      if (result.data?.error) {
        setGroupInfo([]);
        return;
      }
      setGroupInfo(
        result.data.filter((ele) => ele.archived == false || !ele.archived)
      );
    });
  }, []);

  useEffect(() => {
    switch (training_value) {
      case "course":
        if (courseInfo != null) break;

        let configEx = {
          method: "get",

          url: `${IP}/trainningEnrollment/courses`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataex",
        };
        axios(configEx).then((result) => {
          if (result.data.error) {
            return;
          }
          setCourseInfo(result?.data);
        });
        break;
      case "games":
        if (externalTrainingInfo != null) break;

        let configExTr = {
          method: "get",

          url: `${IP}/userGames/courseLibrary`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataex",
        };
        axios(configExTr).then((result) => {
          if (result.data.error) {
            return;
          }
          setGameInfo(result?.data);
        });
        break;

      case "microCourse":
        if (internalOfflineInfo != null) break;

        let configExInt = {
          method: "get",

          url: `${IP}/userMicroCourse/courseLibrary`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: "dataex",
        };
        axios(configExInt).then((result) => {
          if (result.data.error) {
            return;
          }
          setInternalOfflineInfo(result?.data);
        });
        break;

      default:
        break;
    }
  }, [training_value]);

  useEffect(() => {
    let div = dropRef?.current;
    if (!div) return;
    div.addEventListener("dragenter", handleDragIn);
    div.addEventListener("dragleave", handleDragOut);
    div.addEventListener("dragover", handleDrag);
    div.addEventListener("drop", handleDrop);
    return () => {
      let div = dropRef?.current;
      div?.removeEventListener("dragenter", handleDragIn);
      div?.removeEventListener("dragleave", handleDragOut);
      div?.removeEventListener("dragover", handleDrag);
      div?.removeEventListener("drop", handleDrop);
    };
  }, [training_value]);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // //console.log(e);
  };
  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // //console.log("In");
  };
  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // //console.log("out");
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    //console.log("drop", e.dataTransfer.files);
    handleFileUpload(e.dataTransfer.files);
  };

  const deleteNotes = (index) => {
    //console.log("saklm", index);
    let arr = Array.from(fileList);
    arr.splice(index, 1);
    //console.log(arr);
    setFileList(arr);
  };

  const handleFileUpload = async (files) => {
    //console.log("hola", files);
    for (let i = 0; i < files.length; i++) {
      if (files[i].size / 1024 / 1024 > 5) {
        alert("Material should be less than 5MB.");
        return;
      }
      setUploading(true);
      const reader = new FileReader();
      reader.onloadend = async () => {
        let dataex = JSON.stringify({
          pdf: reader.result,
        });
        let configEx = {
          method: "patch",

          url: `${IP}/courseBuilder/item`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: dataex,
        };
        const result = await axios(configEx);
        //console.log("uploading");
        setFileList((prev) =>
          prev.concat({
            noteId:
              files[i].name.replaceAll(" ", "") +
              "_" +
              new Date().getTime().toString(),
            fileName: files[i].name,
            link: result.data.link,
            addedBy: auth.currentUser.uid,
          })
        );
        if (i == files.length - 1) setUploading(false);
      };
      reader.readAsDataURL(files[i]);
      // upload file and get
      // add link in state
    }
  };

  const callUpdateDueDate = async (userId, dueDate, id) => {
    setGroupUsers((prev) => {
      let newArr = Array.from(prev);
      newArr[id].loading = true;
      return newArr;
    });

    let dataex = JSON.stringify({
      userId: userId,
      courseId: courseId,
      courseType: training_value,
      dueDate: dueDate,
    });

    let configEx = {
      method: "patch",

      url: `${IP}/groupEnrollment/userInfo`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: dataex,
    };
    axios(configEx).then(() => {
      alert("updated due date");
      setGroupUsers((prev) => {
        let newArr = Array.from(prev);
        newArr[id].loading = false;
        return newArr;
      });
    });
  };

  const handleDueChange = (e, id) => {
    const { value } = e.target;

    setGroupUsers((prev) => {
      let newArr = Array.from(prev);
      newArr[id].due = value;
      return newArr;
    });
  };
  var today = new Date().toISOString().split("T")[0];
  const allIsWell = () => {
    if (
      training_value == "" ||
      groupId == "" ||
      dueDate == "" ||
      groupName == "" ||
      courseId == "" ||
      courseName == ""
    ) {
      return false;
    }
    return true;
  };

  const handleOnSave = async (e) => {
    e.preventDefault();

    if (!allIsWell()) {
      setDisable(false);
      setError(true);
      return;
    }
    if (edit) {
      setDisable(true);
      let datax = JSON.stringify({
        courseType: training_value,
        groupId: groupId,
        createdOn: new Date().toString(),
        dueDate: dueDate,
        // expDate: expDate,
        completed: false,
        notiFreq: notiFreq,
        groupName: groupName,
        courseId: courseId,
        courseName: courseName,
        enrollmentId: enrollmentId,
        materials: fileList,
      });
      let configEx = {
        method: "patch",

        url: `${IP}/groupEnrollment/userInfo`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: datax,
      };
      axios(configEx).then((result) => {
        navigate("/admin/groupEnrollment");
      });

      setWarningOpen(true);
      return;
    }

    setDisable(true);

    let configEx = {
      method: "get",
      params: { courseId: courseId, enrollmentId: groupId },
      url: `${IP}/groupEnrollment/group`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "dataex",
    };
    axios(configEx).then((res) => {
      if (res.data > 0) {
        alert(
          "GroupEnrollment already created with this groupId and courseId use can select other options"
        );
        setDisable(false);
      } else {
        let dataex = JSON.stringify({
          courseType: training_value,
          groupId: groupId,
          createdOn: new Date().toString(),
          dueDate: dueDate,
          // expDate: expDate,

          completed: false,
          notiFreq: notiFreq,
          groupName: groupName,
          courseId: courseId,
          courseName: courseName,
          enrollmentId: enrollmentId,
          materials: fileList,
          updateDueDate: true, // if new enrollment is created
        });
        var configEx = {
          method: "patch",

          url: `${IP}/groupEnrollment`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("bearer")}`,
          },
          data: dataex,
        };
        axios(configEx).then((result) => {
          navigate("/admin/groupEnrollment");
        });
      }
    });
  };

  //console.log(groupUsers);

  return (
    <div className="section" style={{ backgroundColor: "#e5e5e5" }}>
      <Navbar page={t("Enrollments")} />
      <div className="createcourse-main">
        <Sidebar page={"enrollments"} hidepage={true} />
        <div className="admin-overdue">
          <div className="admin-overdue-top">
            <div className="admin-overdue-top-item">
              {t("Add New / Edit Group Enrollments")}
            </div>
          </div>
          <div className="admin-overdue-grop-cnt">
            <div className="admin-enrollment-main">
              <div>
                <div id="admin-course-main-top-id-grp">
                  <div
                    className="admin-course-main-top-inputlable"
                    style={{ margin: "0" }}
                  >
                    {t("SelectTrainingCourse")}
                  </div>
                  <select
                    className="admin-course-main-top-select "
                    onChange={(e) => {
                      setTraining(e.target.value);
                    }}
                    value={training_value}
                    ref={setTrainingRef}
                  >
                    <option value="course">{t("course")}</option>
                    <option value="microCourse">{t("Micro course")}</option>
                    <option value="games">{t("Games")}</option>
                  </select>
                </div>
                <div id="admin-course-main-top-id-grp">
                  <div>
                    <div
                      className="admin-course-main-top-inputlable"
                      style={{ margin: "0" }}
                    >
                      {t("CourseName")}*
                    </div>
                    <div>
                      <select
                        className="admin-course-main-top-select widthFull"
                        size="4"
                        id="course-ids"
                        style={{ height: "20vh" }}
                        defaultValue=""
                        value={courseId + "---" + courseName}
                        onChange={(e) => {
                          const [id, name] = e.target.value.split("---");
                          setCourseId(id);
                          setCourseName(name);
                        }}
                        ref={setCourseNameRef}
                      >
                        <option style={{ fontWeight: "700" }} value="">
                          Select A Value From The List Below
                        </option>
                        {training_value == "course" &&
                          courseInfo?.map((item, id) => (
                            <option
                              value={item.courseId + "---" + item.courseName}
                              key={id}
                            >
                              {item.courseName}
                            </option>
                          ))}
                        {training_value == "microCourse" &&
                          internalOfflineInfo?.map((item, id) => (
                            <option
                              value={item.courseId + "---" + item.courseName}
                              key={id}
                            >
                              {item.courseName}
                            </option>
                          ))}
                        {training_value == "games" &&
                          gameInfo?.map((item, id) => (
                            <option
                              value={item.courseId + "---" + item.courseName}
                              key={id}
                            >
                              {item.courseName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div id="admin-course-main-top-id-grp">
                  <div
                    className="admin-course-main-top-inputlable"
                    style={{ margin: "0" }}
                  >
                    {t("GroupName")}*
                  </div>
                  <div>
                    <select
                      size="4"
                      className="admin-course-main-top-select widthFull"
                      id="group-ids"
                      value={groupId + "---" + groupName}
                      onChange={(e) => {
                        const [id, name] = e.target.value.split("---");
                        setGroupId(id);
                        setGroupName(name);
                      }}
                      style={{ height: "20vh" }}
                      ref={setGroupNameRef}
                    >
                      <option style={{ fontWeight: "700" }} value="">
                        Select A Value From The List Below
                      </option>

                      {groupInfo?.map((item, id) => (
                        <option
                          value={item.groupId + "---" + item.groupName}
                          key={id}
                        >
                          {item.groupName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* expDate:expDate, */}
                <div id="admin-course-main-top-id-grp">
                  <div
                    className="admin-course-main-top-inputlable"
                    style={{ margin: "0" }}
                  >
                    {t("DueDate")}*
                  </div>
                  <input
                    type="date"
                    name="due-date"
                    value={dueDate}
                    min={today}
                    className="admin-course-main-top-input"
                    onChange={(e) => setDueDate(e.target.value)}
                  />
                </div>
                {/* <div id="admin-course-main-top-id-grp">
                  <div
                    className="admin-course-main-top-inputlable"
                    style={{ margin: "0" }}
                  >
                    {t("Expiry Date")}*
                  </div>
                  <input
                    type="date"
                    name="due-date"
                    value={expDate}
                    min={today}
                    className="admin-course-main-top-input"
                    onChange={(e) => setExpDate(e.target.value)}
                  />
                </div> */}
              </div>
            </div>

            {edit &&
              (loading ? (
                <div style={{ width: "100%" }}></div>
              ) : (
                <div> </div>
                // <div
                //   id="admin-course-main-top-id-grp"
                //   style={{
                //     border: "2px solid #D7D7D7",
                //     borderRadius: "6px",
                //   }}
                // >
                //   <div
                //     className="admin-course-main-top-id-grp-row"
                //     style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 2fr" }}
                //   >
                //     <div>{t("Members")}</div>
                //     <div>{t("CompletionDate")}</div>
                //     <div>{t("DueDate(Reminder date)")}</div>
                //     <div>{t("ExpiryDate")}</div>
                //     <div>{t("Action")}</div>
                //   </div>
                //   {groupUsers.map((item, id) => (
                //     <div
                //       className="admin-course-main-top-id-grp-row"
                //       style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr 2fr" }}
                //     >
                //       <div>{item.userName}</div>
                //       <div>
                //         <input
                //           type={"date"}
                //           disabled={true}
                //           value={item.completeDate}
                //         />
                //       </div>
                //       <div>
                //         <input
                //           type={"date"}
                //           value={item.due}
                //           disabled={Boolean(item.completeDate)}
                //           onChange={(e) => handleDueChange(e, id)}
                //         />
                //       </div>
                //       <div>
                //         <input
                //           type={"date"}
                //           value={item.expiry}
                //           disabled={true}
                //           // onChange={(e) => handleDueChange(e, id)}
                //         />
                //       </div>
                //       <div>
                //         {!item.completeDate && (
                //           <button
                //             style={{ margin: "0.5em" }}
                //             disable={item.loading}
                //             onClick={() =>
                //               callUpdateDueDate(item.userId, item.due, id)
                //             }
                //           >
                //             {item.loading ? t("Updating") : t("Update")}
                //           </button>
                //         )}
                //         {item.completeDate && (
                //           <button
                //             style={{ margin: "0.5em" }}
                //             onClick={() => {
                //               setDeleteOpen(true);
                //               setClickedUser({
                //                 userId: item.userId,
                //                 userName: item.userName,
                //               });
                //             }}
                //           >
                //             {t("Delete")}
                //           </button>
                //         )}
                //         {training_value != "course" && !item.completeDate && (
                //           <button
                //             style={{ margin: "0.5em" }}
                //             onClick={() => {
                //               setOpen(true);
                //               setClickedUser({
                //                 userId: item.userId,
                //                 userName: item.userName,
                //               });
                //             }}
                //           >
                //             {t("Upload")}
                //           </button>
                //         )}
                //         {training_value != "course" && !item.completeDate && (
                //           <button
                //             style={{ margin: "0.5em" }}
                //             onClick={() => {
                //               setGenerateOpen(true);
                //               setClickedUser({
                //                 userId: item.userId,
                //                 userName: item.userName,
                //               });
                //             }}
                //           >
                //             {t("Generate")}
                //           </button>
                //         )}
                //       </div>
                //     </div>
                //   ))}
                //   <UploadCertificate
                //     open={open}
                //     clickedUser={clickedUser}
                //     courseId={courseId}
                //     courseType={training_value}
                //     setGroupUsers={setGroupUsers}
                //     onClose={() => setOpen(false)}
                //   />
                //   <DeleteCertificate
                //     open={deleteOpen}
                //     clickedUser={clickedUser}
                //     courseId={courseId}
                //     courseType={training_value}
                //     setGroupUsers={setGroupUsers}
                //     onClose={() => setDeleteOpen(false)}
                //   />
                //   <GenrateCertificate
                //     open={generateOpen}
                //     clickedUser={clickedUser}
                //     courseId={courseId}
                //     courseType={training_value}
                //     setGroupUsers={setGroupUsers}
                //     onClose={() => setGenerateOpen(false)}
                //   />
                // </div>
              ))}
            {error && (
              <div
                style={{ marginLeft: "20px", color: "red", fontSize: "20px" }}
              >
                Fill all the fileds!
              </div>
            )}

            <div style={{ display: "grid", justifyContent: "right" }}>
              <div className="courselist-main-cnt-back-btn">
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    margin: "1vw",
                  }}
                  onClick={() => navigate(-1)}
                >
                  {t("Cancel")}
                </button>
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                    cursor: `${disable ? "wait" : "pointer"}`,
                    margin: "1vw",
                  }}
                  disabled={disable}
                  onClick={handleOnSave}
                >
                  {t(disable ? "Saving..." : "Save")}
                </button>
                <Warning
                  open={warningOpen}
                  enrollmentData={enrollmentData}
                  onClose={() => setWarningOpen(false)}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ height: "100%", width: "6vw", backgroundColor: "white" }}
        ></div>
        <div className="courselist-main-cnt-back">
          <div className="courselist-main-cnt-back-btn">
            <Link to={"/admin/groupEnrollment"}>
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={() => navigate(-1)}
              >
                {t("Back")}
              </button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupEnrollmentEdits;
