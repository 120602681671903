import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

function Fillblanks({ question, instruction, qno, qtot, setActiveSubmit }) {
  const [fillblanksQuestion, setFillblanksQuestion] = useState([]);
  useEffect(() => {
    let qArr = question.question.split("_");
    setFillblanksQuestion(qArr);
  }, [question]);

  const handleChange = (e) => {
    const value = e.target.value;
    value === "" ? setActiveSubmit(false) : setActiveSubmit(true);
    let ans = {
      qno: qno,
      type: "fillUps",
      correct: value === question.answer[0], // only one answer?
    };
    localStorage.setItem("answer", JSON.stringify(ans));
  };
  //console.log(fillblanksQuestion);
  return (
    <Box sx={{ padding: "30px 30px 30px 15px" }}>
      <Stack spacing={2}>
        <Box>
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question {qno} of {qtot}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            gap: { xs: "3px", md: "5px" },
          }}
        >
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Question : {fillblanksQuestion[0]}
          </Typography>
          <input
            onChange={handleChange}
            style={{
              border: "none",
              borderBottom: "1px dashed #000",
              outline: "none",
              color: "#000",
            }}
          />
          <Typography
            sx={{
              color: "#000",
              fontWeight: 600,
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            {fillblanksQuestion[1]}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#fff",
              background: "#003A69",
              fontWeight: 700,
              padding: { xs: "10px 15px", md: "10px 40px" },
              borderRadius: "5px",
              mt: "20px",
              fontSize: { xs: "12px", md: "16px" },
            }}
          >
            Instruction: {instruction}
          </Typography>
        </Box>
      </Stack>
      <Box style={{}} sx={{ my: "30px" }}></Box>
    </Box>
  );
}

export default Fillblanks;

{
  /* 
  <div className="mcq">
      <div className="mcq-main">
        <div className="mcq-main-question-no">
          Questin {qno} of {qtot}
        </div>
        <div className="mcq-main-questin-fillblanks">
          <div>Questin : {fillblanksQuestion[0]}</div>
          <input onChange={handleChange} className="fillblanks-input"></input>
          <div>{fillblanksQuestion[1]}</div>
        </div>
        <div className="mcq-main-inst" style={{ marginTop: "3vw" }}>
          {instruction}
        </div>
      </div>
    </div>
  */
}
