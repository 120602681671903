import { Box } from "@mui/system";
import React, { useContext } from "react";
import { ItemContext } from "../Context/ItemContext";
import LessonTimeline from "./LessonTimeline";

const CourseProgressAccordions = ({ lessons }) => {
  console.log(lessons);
  const { sample } = useContext(ItemContext);
  const sampleLesson = lessons.filter((ele) => ele == sample);
  return (
    <>
      {sample ? (
        <Box sx={{ width: "90%", margin: "auto" }}>
          {sampleLesson.map((lessonId, index) => (
            <LessonTimeline
              key={index}
              first={index === 0}
              lessonId={lessonId}
            />
          ))}
        </Box>
      ) : (
        <Box sx={{ width: "90%", margin: "auto" }}>
          {lessons.map((lessonId, index) => (
            <LessonTimeline
              key={index}
              first={index === 0}
              lessonId={lessonId}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default CourseProgressAccordions;
