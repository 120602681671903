import axios from "axios";
import { IP } from "./baseUrlAndConfig";

export const supportLogger = (data) => {
  var config = {
    method: "post",

    url: `https://sereindevapi.kdev.co.in/dumplogs/dumplogs`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  };

  axios(config);
};
