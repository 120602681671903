import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Papa, { parse } from "papaparse";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import axios from "axios";
import { ValidateAuth } from "../../../../AuthToken";
import { useNavigate } from "react-router";
import { DrawerContext } from "../../../../utilities/context/DrawerProvider";
const BulkUserDeleteDialog = ({ showBulk, handleClose }) => {
  const bulkUserCreation = httpsCallable(functions, "bulkUserCreation");
  const [showHistory, setShowHistory] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);
  useEffect(() => {
    setJsonData([]);
  }, [showBulk]);
  const uploadBulkUser = async () => {
    setLoading(true);

    // let dataex = JSON.stringify({
    //   csvArray: jsonData,
    // });
    // console.log(dataex);
    // let configExInt = {
    //   method: "get",

    //   url: `https://api.sendgrid.com/v3/suppression/blocks`,
    //   headers: {
    //     "Content-Type": "application/json",

    //     withCredentials: "false",
    //     Authorization: `Bearer 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`,
    //   },
    //   data: dataex,
    // };
    // axios(configExInt).then((res) => {
    //   setLoading(false);
    //   setJsonData(res.data);
    // });

    // "https://api.sendgrid.com/v3/suppression/blocks",
    const response = await fetch(
      // "https://api.sendgrid.com/v3/messages",
      "https://api.sendgrid.com/v3/suppression/blocks",
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "omit", // include, *same-origin, omit

        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer api_kei_sendgrid`,
        },
      }
    );
    response.json().then((res) => {
      setLoading(false);
      console.log(res);
      setJsonData(res);
    });
  };
  const handleFile = async (e) => {
    const file = e.target.files[0];
    //console.log(file);
    const name = file.name;
    if (name.split(".")[1] != "csv") {
      alert("Not proper format");
      e.target.value = "";
      return;
    }
    let temp = [];
    let text = await file.text();
    const parsedData = Papa.parse(text);
    if (parsedData.data.length - 1 > 100) {
      alert("you can not delete more than 100 user at a time");
      return;
    }
    console.log("text", parsedData.data.length - 1);
    for (let i = 1; i < parsedData.data.length; i++) {
      //console.log(i)
      let data = {};
      parsedData.data[0].forEach((item, id) => {
        data[item] = parsedData.data[i][id]?.trim();
      });

      temp.push({ ...data, status: "TBD", role: "USER" }); // default role by bulk add is user
    }
    //console.log(temp);
    setJsonData(temp);
  };

  return (
    <Dialog
      open={showBulk}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      fullWidth
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        {"Bulk User Email history"}
      </DialogTitle>
      <DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => {
              window.open(
                "https://firebasestorage.googleapis.com/v0/b/serein-demo-lms-gcp.appspot.com/o/deleteBulkuser.csv?alt=media&token=cbf93d5b-da8b-4ce7-8949-226097272308",
                "_blank"
              );
            }}
          >
            Block emails list
          </Button>
          <Button disabled={loading} onClick={() => setShowHistory(true)}>
            Bounce emails list
          </Button>
          <Button disabled={loading} onClick={uploadBulkUser}>
            Invalid emails list
          </Button>
        </DialogActions>
        {/* <DialogContentText id="alert-dialog-description">Bulk User Create</DialogContentText> */}

        <div style={{ display: "flex", flexDirection: "column" }}>
          {jsonData?.length != 0 && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 2fr 1fr 1fr",
              }}
            >
              <div>{"Email"}</div>
              <div>{"Reason"}</div>
              <div>{"Time"}</div>

              <div>{"Status code"}</div>
            </div>
          )}
          {jsonData.map((item) => {
            return (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 2fr 1fr 1fr",
                  marginTop: "10px",
                }}
              >
                <div>{item["email"]}</div>
                <div>{item["reason"]}</div>
                <div>{new Date(item?.created * 1000).toLocaleString()}</div>

                <div>{item.error ? item.error : item.status}</div>
              </div>
            );
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default BulkUserDeleteDialog;
