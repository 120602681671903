import axios from "axios";
import { IP } from "./baseUrlAndConfig";
const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const ValidateAuth = () => {
  let exp = getCookie("exp");
  let refToken = getCookie("rfToken");
  let presentTime = Date.now() / 1000;
  // console.log(Number(exp) > Number(presentTime));
  // console.log({ exp, presentTime });

  // if (Number(exp) < Number(presentTime)) {
  //   let data = JSON.stringify({
  //     refresh_token: refToken,
  //   });
  //   // console.log(refToken);
  //   // console.log(presentTime, exp);

  //   var config = {
  //     method: "get",
  //     params: { refresh_token: JSON.parse(refToken) },
  //     url: `${IP}/auth/refresh`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },

  //     data: data,
  //   };
  //   console.log("rahkj");
  //   // axios(config)
  //   //   .then((res) => {
  //   //     // console.log(res.data.idToken);

  //   //     document.cookie =
  //   //       "bearer" + "=" + (res.data.idToken || "") + "; path=/";

  //   //     document.cookie =
  //   //       "exp" + "=" + (Date.now() / 1000 + 1800 || "") + "; path=/";
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log(err);
  //   //   });
  // }
};

export { ValidateAuth };
