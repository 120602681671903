export const TRANSLATIONS_AR = {
  MainMenu: "القائمة الرئيسية",
  Trainings: "التدريبات",
  Enrollments: "التسجيلات",
  IndividualEnrollments: "التسجيلات الفردية",
  Report: "تقرير",
  UserView: "عرض المستخدم",
  Home: "الصفحة الرئيسية",
  CourseLibrary: "مكتبة الدورة",
  Profile: "الملف الشخصي",
  Certificate: "الشهادات",
  Statistics: "إحصائيات",
  Dashboard:"لوحة القيادة",
  UserPassedInThisMonth:"مر المستخدم هذا الشهر",
  OverdueEnrollments: "التسجيلات المتأخرة",
  ExpiredTrainingCertificates:"شهادات التدريب المنتهية",
  UserFailedInThisMonth:"فشل المستخدم هذا الشهر",
  NoofOverdueEnrollments: "عدد التسجيلات المتأخرة",
  Viewall: "مشاهدة الكل",
  CourseName: "اسم الدورة التدريبية",
  CompanyName: "اسم الشركة",
  Filter: "منقي",
  SelectCourse: "حدد دورة",
  FromDate: "من التاريخ",
  ToDate: "حتي اليوم",
  Clear: "صافي",
  SRNo: "الرقم التسلسلي",
  Name: "اسم",
  CourseTitle: "عنوان الدورة",
  Company: "شركة",
  Department: "قسم",
  Duedate: "تاريخ الاستحقاق",
  Type: "يكتب",
  TrainingProvider: "مزود التدريب",
  Show: "يعرض",
  PagePerItem: "صفحة لكل عنصر",
  Back: "خلف",
  UserPassedthismonth: "مر المستخدم هذا الشهر",
  NoofUserPassed: "تم تمرير عدد المستخدمين",
  CourseType: "دورة كتابية",
  CompletionFromDate: "الإكمال من التاريخ",
  CompletionToDate: "اكتمال حتى تاريخه",
  CompletionDate: "موعد الإكمال",
  UserFailedThisMonth: "فشل المستخدم هذا الشهر",
  NoofUserFailed: "فشل لا من المستخدم",
  DueDateFrom: "تاريخ الاستحقاق من",
  DueDateTo: "تاريخ الاستحقاق إلى",
  NewEmployees: "موظفين جدد",
  Noofnewemployees: "عدد الموظفين الجدد",
  NewEmployeeDetails: "تفاصيل الموظف الجديد",
  JoiningDatefrom: "تاريخ الانضمام من",
  JoiningdateTo: "تاريخ الانضمام إلى",
  JobTitle: "مسمى وظيفي",
  JoiningDate: "تاريخ الانضمام",
  EmployeeNo: "رقم الموظف",
  Email: "البريد الإلكتروني",
  Role: "دور",
  Action: "عمل",
  ExpiringTrainingCertificates: "شهادات التدريب المنتهية",
  Nooftrainingcertificates: "عدد شهادات التدريب",
  SendReminder: "يرسل تذكير",
  EmployeeName: "اسم الموظف",
  DateFrom: "التاريخ من",
  Dateto: "تاريخ ل",
  SNo: "الرقم التسلسلي",
  Coursetitle: "عنوان الدورة",
  IssuedOn: "الصادر في",
  Expiry: "انقضاء",
  Provider: "مزود",
  InternalTrainingLMS: "التدريب الداخلي LMS",
  ListofInternalTrainingLMS: "قائمة إدارة التدريب الداخلي LMS",
  Add: "يضيف",
  LMSCourseBuilder: "منشئ دورة LMS",
  FeaturedImage: "صورة مميزة ",
  ShortDescription: "وصف قصير",
  CourseDuration: "مدة الدورة",
  AccessMode: "وضع الوصول",
  ClicktouploadImage: "اضغط لتحميل الصورة",
  Free: "فتح",
  Closed: "مغلق",
  Courseaccessexpiration: "انتهاء صلاحية الوصول إلى الدورة ",
  CourseProgress: "تقدم الدورة ",
  CourseID: "معرف بالطبع",
  CertificateTemplate: "قالب الشهادة",
  CourseInstructor: "مدرس المقرر",
  CourseSharedWith: "دورة مشتركة مع",
  FreeFlow: "التدفق الحر",
  Sequential: "تسلسلي",
  days: "أيام",
  Save: "يحفظ",
  View: "رأي",
  Training: "تمرين",
  InternalTraining: "التدريب الداخلي",
  AddNewInternalTraining: "إضافة تدريب داخلي جديد",
  Createddate: "تاريخ الإنشاء",
  ExternalTraining: "التدريب الخارجي ",
  ListofExternalTrainings: "قائمة التدريبات الخارجية",
  AddNewExternaltarining: "إضافة تدريب خارجي جديد",
  ExternalCourseProvider: "مزود الدورة الخارجية",
  ExternalCourseID: "معرف المقرر الخارجي",
  UploadMaterials: "تحميل المواد ",
  DragAndDropFilesorBrowse: "سحب وإفلات الملفات أو تصفح",
  SaveAndUpdate: "حفظ وتحديث",
  AddNewExternalTraining: "إضافة تدريب خارجي جديد",
  TrainingName: "اسم التدريب",
  Createdon: "تم إنشاؤها على",
  InternalOfflineTrainng: "التدريب الداخلي دون اتصال",
  ListofInternalOfflineTraining: "قائمة التدريب الداخلي دون اتصال",
  AddNewInternalOfflineTraining: "إضافة تدريب داخلي جديد دون اتصال بالإنترنت",
  OfflineCourseProvider: "مزود الدورة دون اتصال",
  OfflineCourseID: "معرف الدورة دون اتصال",
  CreatedDate: "تاريخ الإنشاء",
  Searchbycoursetitle: "البحث عن طريق عنوان الدورة",
  Search: "يبحث",
  ShowGroups:"إظهار المجموعات",
  GroupName: "أسم المجموعة",
  Searchbygroupname: "البحث باسم المجموعة",
  TypeofTraining: "نوع من التدريب",
  SelectTraining: "حدد التدريب",
  ClearFilters: "مسح عوامل التصفية",
  BulkCreateUser:"إنشاء مستخدم جماعي",
  UserList:"قائمة المستخدم",
  ShowArchive:"عرض الأرشيف",
  AddNewGroupEnrollment: "أضف محتوى مجموعة جديد",
  SingleUserEnrollment:"تسجيل مستخدم واحد",
  SelecttrainingCourse: "اختر دورة تدريبية",
  GroupEnrollment:"تسجيل المجموعة",
  IndividualEnrollments:"التسجيلات الفردية",
  Cancel: "يلغي",
  ListofGroupEnrollment: "قائمة تسجيل المجموعة",
  EnrollmentDate: "تاريخ التسجيل",
  DueDate: "تاريخ الاستحقاق",
  SearchbyEmployeeName: "البحث باسم الموظف",
  EmployeeNo: "رقم الموظف",
  SearchbyEmployeeNo: "البحث عن طريق رقم الموظف",
  ClearSearch: "مسح البحث",
  AddnewIndividualEnrollment: "إضافة تسجيل فردي جديد",
  Users:"المستخدمون",
  ListOfUsers: "قائمة المستخدمين",
  ShowUser:"إظهار المستخدم",
  Select: "تحديد",
  Joiningdate: "تاريخ الانضمام",
  Assign: "تعيين",
  ListofIndividualEnrollment: "قائمة التسجيل الفردي",
  UserName: "اسم االمستخدم",
  EnterUsername: "أدخل اسم المستخدم ",
  SelectRole: "حدد الدور",
  InviteAccept:"قم بدعوة قبول",
  AddNewUser: "إضافة مستخدم جديد",
  TypeOfTraining:"نوع من التدريب",
  User: "المستعمل",
  AddNewUserOrEditUser: "إضافة مستخدم جديد / تحرير مستخدم",
  FullName: "الاسم الكامل",
  EmailID: "عنوان الايميل",
  EmployeeID: "هوية الموظف",
  Signature: "التوقيع",
  ClicktoUploadImage: "انقر لرفع الصورة",
  GroupEnrollments:"تسجيلات المجموعة",
  Admin: "مسؤل",
  Instructor: "معلم",
  ListofUser: "قائمة المستخدمين",
  AddNewGroup: "أضف مجموعة جديدة",
  Groups: "مجموعات",
  AddNewGroupOrEditGroup: "إضافة مجموعة جديدة / تحرير المجموعة",
  ListOfGroupEnrollment:"قائمة تسجيل المجموعة",
  ListOfIndividualEnrollment:"قائمة القيد الفردي",
  AddNewIndividualEnrollment:"إضافة تسجيل فردي جديد",
  GroupDescription: "وصف المجموعة",
  ListofGroupMember: "قائمة أعضاء المجموعة",
  Remove: "إزالة",
  AllMembersfromthelistbelow: "كل الأعضاء من القائمة أدناه",
  ListofAllUser: "قائمة بجميع المستخدمين",
  Username: "اسم المستخدم ",
  Sort: "فرز",
  ClearSort: "فرز واضح",
  ClearFilter: "مرشح واضح",
  ListofGroup: "قائمة المجموعة",
  GroupID: "معرف مجموعة",
  NumberofMember: "عدد الأعضاء",
  NoofCourse: "عدد الدورات",
  EnterEmployeeName: "أدخل اسم الموظف",
  EnterTrainingProvider: "أدخل مزود التدريب",
  CertificateName: "اسم الشهادة",
  EnterCertificateName: "أدخل اسم الشهادة",
  EnterTrainingType: "أدخل نوع التدريب",
  Status: "حالة",
  EnterStatus: "أدخل الحالة",
  ExpiryDate: "تاريخ انتهاء الصلاحية",
  GenerateReports: "توليد التقارير",
  ReportsType: "نوع التقارير",
  name: "اسم",
  SNo: "رقم سري",
  ReportID: "معرّف التقرير",
  ReporGeneratedBy: "تم إنشاء التقرير بواسطة",
  ReportType: "نوع التقرير",
  DateofGeneration: "تاريخ التوليد",
  DownloadFile: "تحميل الملف",
  MyTraining: "تدريبي",
  ViewAll: "مشاهدة الكل",
  PreviousTraining: "التدريب السابق",
  AddCertificate: "أضف شهادة ",
  AddyourPreviousTrainingCertificatesfromPreviousCompany:
    "أضف شهاداتك التدريبية السابقة من الشركة السابقة",
  YouNeedToEnrollinanExternalTrainingFirst: "تحتاج إلى التسجيل في تدريب خارجي أولاً",
  InternalOfflineTraining: "تدريب داخلي دون اتصال بالإنترنت",
  YouNeedToEnrollInternalOfflineTrainingFirst:
    "تحتاج إلى التسجيل في التدريب الداخلي دون اتصال بالإنترنت أولاً",
  Certificates: "شهادات",
  YouDontHaveAnyCertificate: "ليس لديك أي شهادة",
  SearchCourse: "بحث في الدورة",
  Enroll: "يتسجل، يلتحق",
  YourlatestTraining: "آخر تدريبك",
  PreviousTrainining: "التدريب السابق",
  ChangePassword: "تغيير كلمة المرور",
  Change: "يتغيرون",
  ChangeyourPassword: "تغيير كلمة المرور الخاصة بك",
  CurrentPassword: "كلمة المرور الحالية",
  EnterNewPassword: "أدخل كلمة مرور جديدة",
  ConfirmPassword: "تأكيد كلمة المرور",
  Logout: "تسجيل خروج",
};
