import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { Navigate, useNavigate } from "react-router";
// import { functions } from "../../../../Firebase";

const GenerateReport = (props) => {
  const { onClose, open ,reportId } = props;
  const handleClose = () => {
    onClose();
  };
  const [dueDate, setDueDate] = useState(new Date().toISOString().split("T")[0]);
  const [loading, setLoading] = useState(false);

  let navigater=useNavigate()
  const runGenerateCertificateTraining = () => {

    navigater("/admin/course-reports")
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "250px",
          padding: "16px",
        },
      }}
      onClose={() => {
        if (loading) return;
        handleClose();
      }}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p>Report Generating </p>
        <button
          onClick={() => {
            if (loading) return;
          
          }}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "5px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div >Your report is being generated, your report IDs, {reportId} You will receive an email.
it will take some time as per the volume of the report. </div>
        </div>

        <Button
          fullWidth
          variant="contained"
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
          }}
          disable={loading}
          onClick={runGenerateCertificateTraining}
        >
          {loading ? "Generating..." : "Go To Reports Page"}
        </Button>
      </Box>
    </Dialog>
  );
};

export default GenerateReport;
