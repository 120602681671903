import { t } from "i18next";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Component/NavbarTop";
import Sidebar from "../Component/Sidebar";
import "./CourseList.css";
import { Timestamp } from "firebase/firestore";
import { getCookie } from "../../Cookies";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Cdata } from "./CourseData";
import { Checkbox } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase";
import { ValidateAuth } from "../../AuthToken";
import { IP } from "../../baseUrlAndConfig";
import axios from "axios";
import { DrawerContext } from "../../utilities/context/DrawerProvider";

function GamesList() {
  const [courseArray, setCourseArray] = useState(null);
  const getCourses = httpsCallable(functions, "getCourses");
  const archiveCourse = httpsCallable(functions, "archiveCourse");
  const unarchiveCourse = httpsCallable(functions, "unarchiveCourse");
  const getAdminAndInstructorList = httpsCallable(
    functions,
    "getAdminAndInstructorList"
  );
  const { clrs } = useSelector((state) => state.createClr);
  const [showLoading, setLoading] = useState(true);
  const [extraArrForCourseData, setExtraArrForCourse] = useState([]);
  const [archiveDataArr, setArchivedDataArr] = useState([]);
  const [buttonTogalForChangeState, setButtonTogalForarchived] = useState(true);
  const [handelUseEffectTogal, setHandelUseEffectTogal] = useState(true);
  const [tempLoading, setTemploading] = useState("test");
  const [InstrucMap, setInstrucMap] = useState({});
  const UID = getCookie("UID");
  const ROLE = getCookie("ROLE");

  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const goToBack = () => navigate("/admin/training");
  const { rolesAndPermission, setRolesAndPermission } =
    useContext(DrawerContext);
  useEffect(() => {
    let flag =
      rolesAndPermission?.isAdmin?.componentPermission?.includes("all");

    // ValidateAuth();
    if (!flag) {
      navigate(-1);
      return <></>;
    }
  }, []);
  useEffect(() => {
    setLoading(true);

    var configs = {
      method: "get",

      url: `${IP}/userProfile/adminAndInstructor`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };
    axios(configs)
      .then((res) => {
        let map = {};

        setInstructoreList(res.data?.instructor_list);
        let fildata = res.data.instructor_list.map((re) => {
          map[re.userId] = re.name;
        });
        setInstrucMap(map);
        //setTemplateArr(res.data);
      })
      .catch((er) => {});

    let data = JSON.stringify({});

    var configs = {
      method: "get",

      url: `${IP}/games/all`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: data,
    };
    axios(configs)
      .then((res) => {
        let mainArr = res.data;
        console.log(mainArr);
        mainArr
          .map((item) => ({ ...item, selected: false }))
          .filter((item) => item.archived != true);

        setCourseArray(mainArr);
        setExtraArrForCourse(mainArr.filter((item) => item.archived != true));
        setArchivedDataArr(res.data.filter((item) => item.archived == true));
        setArrLength(mainArr.length);
        setButtonTogalForarchived(true);
        setLoading(false);
      })
      .catch((er) => {
        console.log(er.message);
      });
  }, []);

  const [arrLength, setArrLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const [trainingName, setTrainingName] = useState("");
  const [instructorList, setInstructoreList] = useState([]);
  // useEffect(() => {
  //   getAdminAndInstructorList({ data: {} }).then((res) => {
  //     let map = {};

  //     setInstructoreList(res.data?.instructor_list);
  //     let fildata = res.data.instructor_list.map((re) => {
  //       map[re.userId] = re.name;
  //     });
  //     setInstrucMap(map);
  //     ////console.log(res.data.instructor_list)
  //   });

  //   getCourses().then((res) => {
  //     if (res.data?.error) {
  //       setCourseArray([]);
  //       //console.log(res.data?.error);
  //       setArrLength(0);
  //       setLoading(false);
  //       return;
  //     }
  //     // //console.log(JSON.stringify(res.data.filter((ele) => ele.archived)));
  //     let mainArr = res.data
  //       .map((item) => ({ ...item, selected: false }))
  //       .filter((item) => item.archived != true)
  //       .filter((item) => item.accessArr.includes(UID) || ROLE == "admin");
  //     setCourseArray(mainArr);
  //     setExtraArrForCourse(mainArr.filter((item) => item.archived != true));
  //     setArchivedDataArr(res.data.filter((item) => item.archived == true));
  //     setArrLength(mainArr.length);
  //     setButtonTogalForarchived(true);
  //     setLoading(false);
  //   });
  // }, [handelUseEffectTogal]);

  /// //console.log(InstrucMap)
  const clickhandler = (i) => {
    if (i < 0) return;
    if (i + 1 > Math.ceil(arrLength / pageSize)) return;
    //TODO: limit upper
    setCurrentPage(i);
  };
  const handleAction = (e, item, id) => {
    if (e.target.value == "archive") {
      setTemploading(id);
      archiveCourse({
        course: item.courseId,
      })
        .then((res) => {
          // setHandelUseEffectTogal(!handelUseEffectTogal);
          //console.log(res);

          let archivedData = { ...item, archived: true };

          let dataddd = extraArrForCourseData.filter(
            (el, index) => index != id
          );
          setExtraArrForCourse(dataddd);
          setCourseArray(dataddd);
          setArchivedDataArr((prev) => prev.concat(archivedData));
          setTemploading("test");
        })
        .catch((err) => {
          setTemploading("test");
        });
    }

    if (e.target.value == "unarchive") {
      setTemploading(id);
      unarchiveCourse({
        course: item.courseId,
      })
        .then((res) => {
          // setHandelUseEffectTogal(!handelUseEffectTogal);
          let archivedData = { ...item, archived: false };
          let dataddd = archiveDataArr.filter((el, index) => index != id);
          setExtraArrForCourse((prev) => prev.concat(archivedData));
          setCourseArray(dataddd);
          setArchivedDataArr(dataddd);
          //console.log(res);
          setTemploading("test");
        })
        .catch((err) => {
          setTemploading("test");
        });
    }
    if (e.target.value == "edit") {
      navigate("/admin/Games", {
        state: { courseId: courseArray[id].courseId },
      });
    }
  };
  const SelectHand = (i) => {
    let newData;
    newData = courseArray.map((item, id) => {
      if (id === i) {
        item.selected = item.selected ? false : true;
        return item;
      } else {
        return item;
      }
    });
    if (newData) setCourseArray(newData);
  };

  function createdDate(createdOn) {
    let displayDate = Timestamp.fromMillis(createdOn._seconds * 1000)
      .toDate()
      .toDateString();
    return displayDate;
  }

  const buttonForArchivedFun = (type) => {
    if (type == "archived") {
      setCourseArray(archiveDataArr);
      setArrLength(archiveDataArr.length);
      setCurrentPage(0);
    } else if (type == "course") {
      setCourseArray(extraArrForCourseData);
      setArrLength(extraArrForCourseData.length);
      setCurrentPage(0);
    }
    setButtonTogalForarchived(!buttonTogalForChangeState);
  };

  const filterData = (el, type) => {
    if (type == "instructore") {
      if (buttonTogalForChangeState) {
        let filData = extraArrForCourseData.filter(
          (ele) => ele.courseInstructor == el
        );
        setCourseArray(filData);
        setArrLength(filData.length);
        setCurrentPage(0);
      } else {
        let filData = archiveDataArr.filter(
          (ele) => ele.courseInstructor == el
        );
        setCourseArray(filData);
        setArrLength(filData.length);
        setCurrentPage(0);
      }
    } else if (type == "clear") {
      if (buttonTogalForChangeState) {
        setCourseArray(extraArrForCourseData);
        setArrLength(extraArrForCourseData.length);
        setCurrentPage(0);
      } else {
        setCourseArray(archiveDataArr);
        setArrLength(archiveDataArr.length);
        setCurrentPage(0);
      }
    } else if (type == "traningPro") {
      if (buttonTogalForChangeState) {
        let filData = extraArrForCourseData.filter(
          (ele) => ele.courseInstructor == el
        );
        setCourseArray(filData);
        setArrLength(filData.length);
        setCurrentPage(0);
      } else {
        let filData = archiveDataArr.filter(
          (ele) => ele.courseInstructor == el
        );
        setCourseArray(filData);
        setArrLength(filData.length);
        setCurrentPage(0);
      }
    }
  };
  return (
    <div className="courselist">
      <Navbar page={t("Training")} />
      <div
        className="courselist-main"
        style={{ gridTemplateColumns: "6vw 1fr" }}
      >
        <Sidebar page={"training"} hidepage={true} />
        <div className="courselist-main-cnt">
          <div
            class="admin-dashboard-main-top-last"
            style={{
              height: " 3vh",
              width: "10vh",
              marginTop: "1vw",
              marginLeft: "75.5vw",
              marginBottom: "1vw",
            }}
          >
            {buttonTogalForChangeState ? (
              <></>
              // <button
              //   style={{
              //     width: "10vw",

              //     background: `${
              //       clrs.CourseListBtn
              //         ? clrs.CourseListBtn
              //         : "hwb(224deg 78% 3%)"
              //     }`,
              //   }}
              //   onClick={() => {
              //     buttonForArchivedFun("archived");
              //   }}
              // >
              //   <div></div>
              //   <div style={{ marginLeft: "-2vw" }}>{t("Show Archive ")}</div>
              // </button>
            ) : (
              <button
                style={{
                  width: "10vw",

                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={() => {
                  buttonForArchivedFun("course");
                }}
              >
                <div></div>
                <div style={{ marginLeft: "-2vw" }}>{t("Show Course")}</div>
              </button>
            )}
          </div>

          <div className="courselist-main-cnt-top">
            <div className="courselist-main-cnt-top-txt"></div>

            <div>
              <Link to={"/admin/Games"}>
                <button
                  style={{
                    background: `${
                      clrs.CourseListBtn
                        ? clrs.CourseListBtn
                        : "hwb(224deg 78% 3%)"
                    }`,
                  }}
                >
                  {t("Add New Games")}
                </button>
              </Link>
            </div>
          </div>

          <div className="admin-overdue-mid">
            <div className="admin-overdue-mid-left">
              <div className="admin-overdue-mid-left-txt">
                {t("Instructor name")}
              </div>
              <div className="admin-overdue-mid-left-input">
                <select
                  type="text"
                  onChange={(e) => {
                    filterData(e.target.value, "instructore");
                  }}
                >
                  <option>select Instructor</option>
                  {instructorList?.map((res) => (
                    <option value={res?.userId}>{res?.name}</option>
                  ))}
                </select>
              </div>
            </div>

            <div
              style={{ marginTop: "3vw" }}
              className="admin-overdue-mid-right"
            >
              {/*    <div className="admin-overdue-mid-left-txt">{t("Date")}</div> */}
              <div className="admin-overdue-mid-left-input-right">
                {/*     <input type="date" placeholder="Select Date" /> */}
                <div className="admin-dashboard-main-mid-item-last-filter">
                  {" "}
                  <div className="admin-dashboard-main-top-last-filter">
                    <button
                      // onClick={() => {
                      //   selectedDateTohumanDate("name");
                      // }}
                      style={{
                        background: `${
                          clrs.CourseListBtn
                            ? clrs.CourseListBtn
                            : "hwb(224deg 78% 3%)"
                        }`,
                      }}
                    >
                      <div></div>
                      <div
                        onClick={() => {
                          filterData("hfh", "clear");
                        }}
                      >
                        {t("Clear")}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="course-head" style={{ marginTop: "40px" }}>
            {t("Games")}
          </div>
          <div className="courselist-main-cnt-bottom">
            <div
              className="courselist-main-cnt-bottom-heading"
              style={{
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                textAlign: "center",
              }}
            >
              <div>{t("SNO")}</div>

              <div>{t("Trainings")}</div>
              <div>{t("Created Date")}</div>
              <div>{t("Action")}</div>
            </div>
            <div className="admin-overdue-bottom">
              <div className="admin-overdue-bottom-table">
                {showLoading && (
                  <div className="admin-overdue-bottom-table-cnt-certi" key={0}>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td">
                      <div className="spinner-container">
                        <div className="loading-spinner"></div>
                      </div>
                    </div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div className="admin-overdue-bottom-table-td"></div>
                    <div
                      className="admin-overdue-bottom-table-td"
                      style={{ color: "#004577" }}
                    ></div>
                  </div>
                )}
                {courseArray?.map((item, id) => {
                  if (id < pageSize * currentPage) return;
                  if (id >= pageSize * (currentPage + 1)) return;
                  return (
                    <div
                      className="admin-overdue-bottom-table-cnt-c "
                      style={{
                        backgroundColor: `${
                          item.selected
                            ? "rgba(0, 69, 119, 0.1)"
                            : "rgba(255, 255, 255, 1)"
                        }`,
                        gridTemplateColumns: "1fr 1fr 1fr 1fr",
                        textAlign: "center",
                      }}
                      key={id}
                    >
                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        {/* <Checkbox
                          checked={item.selected}
                          onClick={() => SelectHand(id)}
                        /> */}
                        {id + 1}
                      </div>

                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        {item.courseName}
                      </div>
                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        {createdDate(item.created)}
                      </div>

                      <div className="admin-overdue-bottom-table-td" id="c-div">
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "3vw 3vw",
                          }}
                        >
                          <div>
                            {item.archived ? (
                              tempLoading == id ? (
                                <p
                                  style={{
                                    width: "4vw",
                                    display: "block",
                                  }}
                                >
                                  Loading...
                                </p>
                              ) : (
                                <select
                                  style={{
                                    border: "none",
                                    backgroundColor: "#F2F2F2",
                                    height: "4vh",
                                    width: "7vw",
                                    padding: "0 1vh",
                                    fontWeight: "700",
                                    color: "#717579",
                                    borderRadius: "6px",
                                  }}
                                  onChange={(e) => handleAction(e, item, id)}
                                >
                                  <option value={"action"}>
                                    {t("Action")}
                                  </option>
                                  <option value={"unarchive"}>
                                    {t("UnArchive")}
                                  </option>
                                </select>
                              )
                            ) : tempLoading == id ? (
                              <p
                                style={{
                                  width: "4vw",
                                  display: "block",
                                }}
                              >
                                Loading...
                              </p>
                            ) : (
                              <select
                                style={{
                                  border: "none",
                                  backgroundColor: "#F2F2F2",
                                  height: "4vh",
                                  width: "7vw",
                                  padding: "0 1vh",
                                  fontWeight: "700",
                                  color: "#717579",
                                  borderRadius: "6px",
                                }}
                                onChange={(e) => handleAction(e, item, id)}
                              >
                                <option value={"action"}>{t("Action")}</option>
                                <option value={"edit"}>{t("Edit")}</option>
                                {/* <option value={"archive"}>
                                  {t("Archive")}
                                </option> */}
                              </select>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="admin-overdue-bottom-pagination">
                <div className="admin-overdue-bottom-pagination-cnt">
                  <div className="admin-overdue-bottom-pagination-cnt-item">
                    <svg
                      onClick={() => clickhandler(currentPage - 1)}
                      width="auto"
                      height="22"
                      viewBox="0 0 14 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.0293 10.4584L12.1855 0.837334C12.6016 0.479912 13.2109 0.802178 13.2109 1.37933V20.6215C13.2109 21.1987 12.6016 21.5209 12.1855 21.1635L1.0293 11.5424C0.709961 11.267 0.709961 10.7338 1.0293 10.4584Z"
                        fill="#717579"
                      />
                    </svg>
                  </div>
                  <div className="admin-overdue-bottom-pagination-cnt-item-btn">
                    {currentPage + 1} of {Math.ceil(arrLength / pageSize)}
                  </div>
                  <div
                    style={{ marginRight: "19vw" }}
                    className="admin-overdue-bottom-pagination-cnt-item"
                  >
                    <svg
                      onClick={() => clickhandler(currentPage + 1)}
                      width="auto"
                      height="20"
                      viewBox="0 0 13 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.1816 9.22727L1.02539 0.141497C0.609375 -0.196038 0 0.108297 0 0.653332V18.8249C0 19.3699 0.609375 19.6742 1.02539 19.3367L12.1816 10.2509C12.501 9.99087 12.501 9.48733 12.1816 9.22727Z"
                        fill="#717579"
                      />
                    </svg>
                  </div>
                  <div
                    style={{ display: "flex", marginRight: "-30vw" }}
                    className="admin-overdue-bottom-pagination-cnt-item"
                  >
                    <label className="admin-row">{t("Show")}</label>
                    <select
                      style={{
                        width: "4vw",
                        margin: "0.5vw",
                        marginBottom: "1.5vw",
                        height: "1.5vw",
                      }}
                      value={pageSize}
                      onChange={(e) => setPageSize(e.target.value)}
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                    <label className="admin-row"> {t("PagePerItem")}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="courselist-main-cnt-back">
            <div className="courselist-main-cnt-back-btn">
              <button
                style={{
                  background: `${
                    clrs.CourseListBtn
                      ? clrs.CourseListBtn
                      : "hwb(224deg 78% 3%)"
                  }`,
                }}
                onClick={goToBack}
              >
                Back
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GamesList;
