import React, { useEffect } from "react";
import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import Button from "@mui/material/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { functions } from "../../../../Firebase";
import "../AdminSetting.css";

import "./subcription.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Sidebar from "../../../Component/Sidebar";
import NavbarTop from "../../../Component/NavbarTop";
import { t } from "i18next";
import axios from "axios";
import { IP } from "../../../../baseUrlAndConfig";
import { getCookie } from "../../../../Cookies";
import SubscriptionPopUp from "./subscriptionPopUp";
import AdminloadingScreen from "../loadingadmin";
export const CreateSubscription = () => {
  const [showdepform, setShowdepform] = useState(false);

  const { clrs } = useSelector((state) => state.createClr);
  const location = useLocation();
  //================company settings===========================//
  const [templatename, settemplatename] = useState("");
  const [deltemp, setdeltemp] = useState(false);
  const [showLoading, setLoading] = useState(true);

  const [dataList, setDataList] = useState({});
  const [department, setDepartment] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [deledep, setDeldep] = useState(true);
  const [openLessonPopUp, setopenLessonPopUp] = useState(false);

  const [selectedDataList, setSelectedDataList] = useState([]);
  // get company and department data
  console.log(selectedDataList);
  const fetchdata = () => {
    setLoading(true);
    var config = {
      method: "get",

      url: `${IP}/subscription/feature`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getCookie("bearer")}`,
      },
      data: "data",
    };

    axios(config)
      .then((res) => {
        setLoading(false);
        setDataList(res.data);
        //console.log(res);
        if (location.state) {
          console.log(location.state);
          var configtwo = {
            method: "get",
            params: { id: location.state.id },
            url: `${IP}/subscription`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCookie("bearer")}`,
            },
            data: "data",
          };
          axios(configtwo).then((re) => {
            setSelectedDataList(re.data?.data);
            settemplatename(re.data.subscriptionName);
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err);
      });
  };

  const addsubscription = () => {
    if (templatename == "") {
      alert("Enter subscription name");
      return;
    }
    if (location.state) {
      // console.log(location.state);
      let data = JSON.stringify({
        subscriptionName: templatename,
        data: selectedDataList,
        id: location.state.id,
      });
      var config = {
        method: "patch",

        url: `${IP}/subscription`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: data,
      };

      axios(config)
        .then((res) => {
          setLoading(false);
          alert("updated");
          navigate(-1);
          // setDataList(res.data);
          //console.log(res);
          if (res.data?.error) {
          } else {
            //   settemplate(res.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          //console.log(err);
        });
    } else {
      let data = JSON.stringify({
        subscriptionName: templatename,
        data: selectedDataList,
      });
      var config = {
        method: "post",

        url: `${IP}/subscription`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("bearer")}`,
        },
        data: data,
      };

      axios(config)
        .then((res) => {
          setLoading(false);
          alert("saved");
          navigate(-1);
          // setDataList(res.data);
          //console.log(res);
          if (res.data?.error) {
          } else {
            //   settemplate(res.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          //console.log(err);
        });
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchdata();
  }, []);
  return (
    <>
      <section className="dashboard">
        <NavbarTop page={t("Subscription")} />
        {/* <Navbar /> */}
        <AdminloadingScreen open={showLoading} />

        <div style={{ height: "41vw" }} className="dashboard-main">
          <Sidebar page={"Dashboard"} hidepage={false} />
          <div className="admin-dashboard-main srollbar_div">
            <div className="outer-bigger-container">
              {/* Company settings */}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    startIcon={<ArrowBackIcon />}
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>{" "}
                </div>

                <div> </div>
              </div>
              <div style={{ backgroundColor: "#ffffff", padding: "20px" }}>
                <div className="inputandlabelboxforstrategies">
                  <div className="inputlabelnameForstrategi">
                    {" "}
                    Subscription Name
                  </div>
                  <div>
                    {" "}
                    <input
                      type="text"
                      value={templatename}
                      placeholder="  Subscription Name"
                      onChange={(e) => {
                        settemplatename(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div
                  className="subscriptionUserTabel subscriptionTabelTabelHeders"
                  style={{
                    gridTemplateColumns: "1fr 3fr 5fr",
                    marginTop: "30px",
                  }}
                >
                  <div> S.No</div>
                  <div> Name</div>
                  <div>Feature Name </div>
                </div>

                {/* <div className="webinarsTabel webinarsTabelTabelBody"> */}
                {dataList?.features?.map((ele, index) => {
                  return (
                    <div
                      className="subscriptionUserTabel subscriptionTabelTabelBody"
                      style={{
                        gridTemplateColumns: "1fr 3fr 5fr",
                        borderBottom: "2px solid #000000",
                      }}
                    >
                      <div>{index + 1}</div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div>
                          {" "}
                          <input
                            type="checkbox"
                            checked={
                              selectedDataList.filter(
                                (el) => el.featureId == index
                              ).length > 0
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              setSelectedDataList((prev) => {
                                console.log(e.target.checked);
                                if (e.target.checked) {
                                  return prev?.concat({
                                    featureId: ele.featureId,
                                    featureName: ele.featureName,
                                  });
                                } else {
                                  return prev?.filter(
                                    (element) =>
                                      element.featureId != ele.featureId
                                  );
                                }
                              });
                            }}
                          />
                        </div>
                        <div> {ele?.featureName}</div>
                      </div>

                      <div
                        style={{
                          display: "grid",
                          gridGap: "20px",
                        }}
                      >
                        {dataList?.uiMappingwithFeature[ele?.featureId]?.map(
                          (el, nindex) => (
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div>
                                {" "}
                                {/* {JSON.stringify(
                                  selectedDataList.filter(
                                    (el) => el.featureId == index
                                  )[0]?.[`${nindex}`]
                                )} */}
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedDataList.filter(
                                      (el) => el.featureId == index
                                    )[0]?.[`${nindex}`]
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    setSelectedDataList((prev) => {
                                      console.log(e.target.checked);

                                      return prev?.map((element) => {
                                        // element.featureId != ele.featureId
                                        if (
                                          element.featureId == ele.featureId
                                        ) {
                                          if (e.target.checked) {
                                            return {
                                              ...element,
                                              [`${nindex}`]: el,
                                            };
                                          } else {
                                            delete element[`${nindex}`];
                                            return element;
                                          }
                                        }
                                        return element;
                                      });
                                    });
                                  }}
                                />
                              </div>
                              <div> {el}</div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  );
                })}

                <div
                  style={{
                    marginTop: "20px",
                  }}
                >
                  {" "}
                  <Button
                    variant="contained"
                    style={{
                      marginBottom: "20px",
                      background: `${
                        clrs.CourseListBtn
                          ? clrs.CourseListBtn
                          : "hwb(224deg 78% 3%)"
                      }`,
                    }}
                    onClick={
                      () => {
                        addsubscription();
                      }
                      // navigate("/admin/settings/CreateSubscription")
                    }
                  >
                    Save
                  </Button>{" "}
                </div>
              </div>

              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
